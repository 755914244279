import { Box, TableContainer, Table, TableHead, useTheme, TableRow, TableCell, TableBody, Typography, Theme } from '@mui/material'
import { makeStyles, withStyles } from '@mui/styles'
import { FC, useLayoutEffect, useState } from 'react'
import CustomSwitch from '../../components/CustomSwitch'
import { NotificationSettingsDto } from '../../shared/types/notificationSettingsTypes'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import NotificationPreview from '../../components/NotificationPreview'
import { NotificationsPreviewsStringsProps } from '../../components/types'
import { generateNotificationPreviews } from '../../generic-utils'
import { useTranslation } from 'react-i18next'

interface NotificationsTableProps {
    notifications: NotificationSettingsDto[]
    onStatusChange?: (id: string) => void
    isDisabled: boolean
    onDelete?: (id: string) => void
    onNotificationSelect?: (id: string) => void
}

const StyledTableRow = withStyles((theme: Theme) => ({
    root: {
        height: 70,
        backgroundColor: theme.palette.natural.t6,
    },
}))(TableRow)

const NotificationsTable: FC<NotificationsTableProps> = ({
    notifications,
    onStatusChange,
    isDisabled,
    onDelete,
    onNotificationSelect,
}) => {
    const classes = useStyles()
    const theme = useTheme()
    const { t, i18n } = useTranslation()

    const [notificationsPreviewsStrings, setNotificationsPreviewsStrings] = useState<NotificationsPreviewsStringsProps>({
        lastOrders: '',
        lastRegistrations: '',
        actualVisitors: '',
        visitorSummary: '',
        registrationSummary: '',
        orderSummary: '',
        heureka: '',
        zboziCompanyRating: '',
        zboziProductRating: '',
        individual: '',
        shoptetLastOrders: '',
        shoptetCouponCode: '',
        spolehlivaRecenzeShopRating: '',
        spolehlivaRecenzeProductRating: '',
    })

    useLayoutEffect(() => {
        const getReview = async () => {
            const result = await generateNotificationPreviews(t, i18n.language)
            setNotificationsPreviewsStrings(result)
        }
        getReview()
    }, [t])

    return (
        <TableContainer component={Box}>
            <Table className={classes.table} style={{ overflow: 'hidden', whiteSpace: 'normal' }} sx={{ minWidth: 650 }}>
                <TableHead classes={{ root: classes.thead }}>
                    <TableRow
                        style={{
                            backgroundColor: theme.palette.main.primary.d2,
                            height: 52,
                            borderRadius: 4,
                        }}
                    >
                        <TableCell className={classes.tableCell} style={{ color: theme.palette.natural.t }} align='left'>
                            {t('campaign.notifications.table.type')}
                        </TableCell>
                        <TableCell className={classes.tableCell} style={{ color: theme.palette.natural.t }} align='left'>
                            {t('campaign.notifications.table.preview')}
                        </TableCell>
                        <TableCell className={classes.tableCell} style={{ color: theme.palette.natural.t }} align='right'>
                            <Typography style={{ marginRight: 8, fontSize: 14 }}>
                                {t('campaign.notifications.table.status')}
                            </Typography>
                        </TableCell>
                        <TableCell className={classes.tableCell} style={{ color: theme.palette.natural.t }} align='right' />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {notifications.map((notification) => (
                        <StyledTableRow
                            key={notification.id}
                            sx={{
                                '&:last-child td, &:last-child th': { border: 0 },
                            }}
                        >
                            <TableCell
                                className={classes.tableCell}
                                component='th'
                                scope='row'
                                style={{ color: theme.palette.natural.t1 }}
                            >
                                <Typography
                                    style={{ fontWeight: 'bold', cursor: 'pointer' }}
                                    onClick={() => {
                                        if (onNotificationSelect) onNotificationSelect(notification.id)
                                    }}
                                >
                                    {t(
                                        'campaign.notifications.' +
                                            (notification.type === 'zboziCompanyRating' ||
                                            notification.type === 'zboziProductRating'
                                                ? 'zbozi'
                                                : notification.type) +
                                            '.title'
                                    )}
                                </Typography>
                            </TableCell>

                            <TableCell
                                className={classes.tableCell}
                                component='th'
                                scope='row'
                                style={{ color: theme.palette.natural.t1 }}
                            >
                                <Box
                                    style={{
                                        height: 150,
                                        width: 362,
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        cursor: 'pointer',
                                    }}
                                    onClick={() => {
                                        if (onNotificationSelect) onNotificationSelect(notification.id)
                                    }}
                                >
                                    <NotificationPreview
                                        backgroundColor='transparent'
                                        notificationHtml={notificationsPreviewsStrings[notification.type]}
                                    />
                                </Box>
                            </TableCell>

                            <TableCell className={classes.tableCell} style={{ color: theme.palette.natural.t1 }} align='right'>
                                <CustomSwitch
                                    checked={notification.isActive}
                                    disabled={isDisabled}
                                    onSwitch={() => (onStatusChange ? onStatusChange(notification.id) : undefined)}
                                />
                            </TableCell>
                            <TableCell className={classes.tableCell} style={{ color: theme.palette.natural.t1 }} align='right'>
                                <DeleteOutlineIcon
                                    onClick={() => (onDelete ? onDelete(notification.id) : undefined)}
                                    style={{
                                        color: theme.palette.main.alert.o,
                                        cursor: 'pointer',
                                    }}
                                />
                            </TableCell>
                        </StyledTableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

const useStyles = makeStyles((theme: Theme) => ({
    table: {
        minWidth: 550,
    },
    tableCell: {
        padding: '0px 24px',
    },
    thead: {
        backgroundColor: theme.palette.natural.t2,
        '& th:first-child': {
            borderRadius: '4px 0 0 0px',
        },
        '& th:last-child': {
            borderRadius: '0 4px 0 0',
        },
    },
    dropDown: {
        width: 166,
        height: 44,
        backgroundColor: 'white',
        borderRadius: 6,
        filter: 'drop-shadow(0px 1px 2px rgba(65, 32, 158, 0.06)) drop-shadow(0px 0px 2px rgba(65, 32, 158, 0.04)) drop-shadow(0px 4px 8px rgba(136, 100, 211, 0.16))',
        '&:hover': {
            backgroundColor: theme.palette.main.primary.d3,
        },
    },
}))

export default NotificationsTable

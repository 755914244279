import { ReduxAction, ReduxState } from '../types/main-reducer-types'
import userReducer from './user-reducer'
import flashMessagesReducer from './flashMessages-reducer'
import initState from '../types/initState'
import webReducer from './web-reducer'
import authReducer from './auth-reducer'

export default function mainReducer(state: ReduxState = initState, action: ReduxAction): ReduxState {
    return {
        user: userReducer(state.user, action),
        flashMessages: flashMessagesReducer(state.flashMessages, action),
        web: webReducer(state.web, action),
        auth: authReducer(state.auth, action),
    }
}

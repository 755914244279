import React from 'react'
import { EditorState } from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { useTranslation } from 'react-i18next'

interface TextEditorProps {
    value: EditorState
    onChange: (value: EditorState) => void
}
const TextEditor: React.FC<TextEditorProps> = ({ value, onChange }: TextEditorProps) => {
    const { t } = useTranslation()
    return (
        <div
            style={{
                border: '1px solid #ccc',
                borderRadius: 4,
            }}
        >
            <Editor
                editorState={value}
                onEditorStateChange={onChange}
                placeholder={t('campaign.notifications.settings.displayRules.fillNotificationText') ?? ''}
                toolbar={{
                    options: ['inline', 'fontFamily'],
                    inline: {
                        options: ['bold', 'italic', 'underline', 'monospace'],
                    },
                }}
            />
        </div>
    )
}

export default TextEditor

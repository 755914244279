import { FC } from 'react'
import { Box, Button, Stack, Typography } from '@mui/material'
import SidePanel from './SidePanel'
import TopPanel from './TopPanel'
import { Routes } from './navigation-types'
import { useLocation, useNavigate } from 'react-router-dom'
import { makeStyles } from '@mui/styles'
import { useSelector } from 'react-redux'
import { getUserInfo } from '../services/selectors/user-selectors'
import BlockOutlinedIcon from '@mui/icons-material/BlockOutlined'
import { useTranslation } from 'react-i18next'

interface SideNavigationProps {
    children?: React.ReactNode
}

export const SIDE_PANEL_WIDTH = 201
export const TOP_PANEL_HEIGHT = 57

const Navigation: FC<SideNavigationProps> = ({ children }) => {
    const location = useLocation()
    const classes = useStyles()
    const user = useSelector(getUserInfo)
    const navigate = useNavigate()
    const todaysDate = new Date()
    const { t } = useTranslation()

    const shouldHideBanner =
        !user ||
        user.pricingPlanName ||
        (user.lastPricingPlanPeriodEndsAt && new Date(user.lastPricingPlanPeriodEndsAt).getTime() > todaysDate.getTime()) ||
        (location.pathname.includes('/') && location.pathname.length === 1) ||
        location.pathname.includes(Routes.onboarding) ||
        location.pathname.includes(Routes.registration) ||
        location.pathname.includes(Routes.login) ||
        location.pathname.includes(Routes.loggedOut) ||
        location.pathname.includes(Routes.shoptetLogin) ||
        location.pathname.includes(Routes.upgatesLogin) ||
        location.pathname.includes(Routes.webarealLogin)

    const shouldHideSidePanel =
        (location.pathname.includes('/') && location.pathname.length === 1) ||
        location.pathname.includes(Routes.onboarding) ||
        location.pathname.includes(Routes.registration) ||
        location.pathname.includes(Routes.login) ||
        location.pathname.includes(Routes.loggedOut) ||
        location.pathname.includes(Routes.shoptetLogin) ||
        location.pathname.includes(Routes.upgatesLogin) ||
        location.pathname.includes(Routes.webarealLogin)

    const shouldHideTopPanel =
        location.pathname.includes('login') ||
        location.pathname.includes('registration') ||
        location.pathname.includes(Routes.loggedOut) ||
        location.pathname.includes(Routes.shoptetLogin) ||
        location.pathname.includes(Routes.upgatesLogin) ||
        location.pathname.includes(Routes.webarealLogin)

    return (
        <Box className={classes.container}>
            {!shouldHideSidePanel && <SidePanel />}
            {!shouldHideTopPanel && <TopPanel />}

            <Box
                className={classes.body}
                style={{
                    marginLeft: shouldHideSidePanel ? 0 : SIDE_PANEL_WIDTH,
                    marginTop: shouldHideTopPanel ? 0 : TOP_PANEL_HEIGHT,
                }}
            >
                {!shouldHideBanner && (
                    <Box className={classes.banner}>
                        <Stack direction='row' justifyContent='space-between' p={1}>
                            <Stack direction='row' spacing={1} mt={0.5}>
                                <BlockOutlinedIcon />
                                <Stack>
                                    <Typography variant='body1' mt={0.1}>
                                        {t('noTariffBanner.title')}
                                    </Typography>
                                    <Typography variant='body1' mt={0.1}>
                                        {t('noTariffBanner.description')}
                                    </Typography>
                                </Stack>
                            </Stack>
                            <div>
                                <Button
                                    variant='contained'
                                    size='small'
                                    onClick={() =>
                                        navigate(Routes.profile, {
                                            state: { preselectedTab: 'subscription' },
                                        })
                                    }
                                    color='inherit'
                                    sx={{ backgroundColor: 'white', color: 'black', marginTop: 3 }}
                                >
                                    {t('noTariffBanner.button1')}{' '}
                                    {user?.lastPricingPlanName ? '' : ' ' + t('noTariffBanner.button2')}
                                </Button>
                            </div>
                        </Stack>
                    </Box>
                )}
                {children}
            </Box>
        </Box>
    )
}

const useStyles = makeStyles(() => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        minHeight: '100vh',
        overflow: 'hidden',
    },
    body: {
        backgroundColor: 'white',
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
    },
    banner: {
        backgroundColor: '#DB3545',
        overflow: 'hidden',
        width: '100%',
        color: 'white',
    },
}))

export default Navigation

import { Stack } from '@mui/material'
import React from 'react'
import CustomButton from '../CustomButton'
import BorderComponent from './BorderComponent'

export enum BorderType {
    None = 1,
    Small = 5,
    Large = 30,
}

interface BorderSelectProps {
    selectedBorderValue: BorderType | null
    onChange: (borderValue: BorderType | null) => void
    defaultBorderValue?: BorderType
}

const BorderSelect: React.FC<BorderSelectProps> = ({ selectedBorderValue, onChange, defaultBorderValue }: BorderSelectProps) => {
    return (
        <Stack direction='row' alignItems='center' justifyContent='space-between' pt={1}>
            <Stack direction='row' spacing={3}>
                {Object.values(BorderType).map((value) =>
                    typeof value === 'string' ? null : (
                        <BorderComponent
                            key={value}
                            borderValue={value as BorderType}
                            isSelected={(selectedBorderValue || defaultBorderValue) === (value as BorderType)}
                            onClick={() => onChange(value as BorderType)}
                        />
                    )
                )}
            </Stack>
            {selectedBorderValue || selectedBorderValue === 0 ? (
                <CustomButton
                    onClick={() => onChange(null)}
                    title='Reset'
                    size='medium'
                    type='secondary'
                    style={{
                        width: 100,
                    }}
                />
            ) : null}
        </Stack>
    )
}

export default BorderSelect

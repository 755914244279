import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, useTheme, Theme, Typography } from '@mui/material'
import { FC, ReactElement } from 'react'
import { UserBriefDto } from '../../shared/types/userTypes'
import { makeStyles, withStyles } from '@mui/styles'
import CustomButton from '../../components/CustomButton'
import { PricingPlanPeriod } from '../../shared/types/pricingPlanTypes'

interface UsersTableProps {
    users: UserBriefDto[]
    onTakeControl: (userId: string) => void
}

const StyledTableRow = withStyles((theme: Theme) => ({
    root: {
        height: 70,
        backgroundColor: theme.palette.natural.t6,
    },
}))(TableRow)

const transformPricingPlanPeriodToText = (pricingPlanPeriod?: PricingPlanPeriod) => {
    switch (pricingPlanPeriod) {
        case 'month':
            return 'Měsíční'
        case 'year':
            return 'Roční'
        default:
            return ''
    }
}

const UsersTable: FC<UsersTableProps> = ({ users, onTakeControl }) => {
    const classes = useStyles()
    const theme = useTheme()
    const rows: ReactElement[] = []

    users.forEach((user, index) => {
        if (user.isAdmin) return
        rows.push(
            <StyledTableRow
                key={index}
                sx={{
                    '&:last-child td, &:last-child th': { border: 0 },
                }}
            >
                <TableCell className={classes.tableHeaderCell} component='th' scope='row'>
                    <Typography style={{ fontWeight: 'bold', cursor: 'pointer' }}>{user.email}</Typography>
                </TableCell>
                <TableCell className={classes.tableHeaderCell} align='left'>
                    <Typography style={{ fontWeight: 'bold', cursor: 'pointer' }}>{user.name}</Typography>
                </TableCell>
                <TableCell className={classes.tableHeaderCell} align='left'>
                    <Typography style={{ fontWeight: 'bold', cursor: 'pointer' }}>{user.accountType}</Typography>
                </TableCell>
                <TableCell className={classes.tableHeaderCell} align='left'>
                    <Typography style={{ fontWeight: 'bold', cursor: 'pointer' }}>{user.webCount}</Typography>
                </TableCell>
                <TableCell className={classes.tableHeaderCell} align='left'>
                    <Typography style={{ fontWeight: 'bold', cursor: 'pointer' }}>{user.campaignCount}</Typography>
                </TableCell>
                <TableCell className={classes.tableHeaderCell} align='left'>
                    {user.pricingPlanName !== undefined && user.pricingPlanPeriod !== undefined && (
                        <Typography style={{ fontWeight: 'bold', cursor: 'pointer' }}>{`${transformPricingPlanPeriodToText(
                            user.pricingPlanPeriod
                        )} - ${user.pricingPlanName}`}</Typography>
                    )}
                    {user.pricingPlanName === undefined && user.pricingPlanPeriod === undefined && (
                        <Typography style={{ fontWeight: 'bold', cursor: 'pointer' }}>-------</Typography>
                    )}
                </TableCell>
                <TableCell className={classes.tableHeaderCell} align='left'>
                    <CustomButton
                        style={{ width: 104 }}
                        size='small'
                        title='Přihlásit se'
                        onClick={() => onTakeControl(user.id)}
                    />
                </TableCell>
            </StyledTableRow>
        )
    })

    return (
        <TableContainer component={Box}>
            <Table className={classes.table} sx={{ minWidth: 650 }} aria-label='simple table'>
                <TableHead classes={{ root: classes.thead }}>
                    <TableRow
                        style={{
                            backgroundColor: theme.palette.main.primary.d2,
                            height: 52,
                            borderRadius: 4,
                        }}
                    >
                        <TableCell className={classes.tableCell} align='left'>
                            Email
                        </TableCell>
                        <TableCell className={classes.tableCell} align='left'>
                            Celé jméno
                        </TableCell>
                        <TableCell className={classes.tableCell} align='left'>
                            Typ účtu
                        </TableCell>
                        <TableCell className={classes.tableCell} align='left'>
                            Weby
                        </TableCell>
                        <TableCell className={classes.tableCell} align='left'>
                            Kampaně
                        </TableCell>
                        <TableCell className={classes.tableCell} align='left'>
                            Předplatné
                        </TableCell>
                        <TableCell className={classes.tableCell} align='left'>
                            Převzít kontrolu
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>{rows}</TableBody>
            </Table>
        </TableContainer>
    )
}

const useStyles = makeStyles((theme: Theme) => ({
    table: {
        minWidth: 550,
    },
    tableHeaderCell: {
        padding: '0px 24px',
        color: theme.palette.natural.t1,
    },
    tableCell: {
        padding: '0px 24px',
        color: theme.palette.natural.t,
    },
    thead: {
        backgroundColor: theme.palette.natural.t2,
        '& th:first-child': {
            borderRadius: '4px 0 0 0px',
        },
        '& th:last-child': {
            borderRadius: '0 4px 0 0',
        },
    },
    dropDown: {
        width: 166,
        height: 44,
        backgroundColor: 'white',
        borderRadius: 6,
        filter: 'drop-shadow(0px 1px 2px rgba(65, 32, 158, 0.06)) drop-shadow(0px 0px 2px rgba(65, 32, 158, 0.04)) drop-shadow(0px 4px 8px rgba(136, 100, 211, 0.16))',
        '&:hover': {
            backgroundColor: theme.palette.main.primary.d3,
        },
    },
    dropdownBody: {
        height: 44,
        display: 'flex',
        alignItems: 'center',
        borderRadius: 6,
        cursor: 'pointer',
    },
}))

export default UsersTable

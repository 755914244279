import { Box, Theme, useTheme } from '@mui/material'
import LoginForm from './LoginForm'
import { makeStyles } from '@mui/styles'

const Login = () => {
    const classes = useStyles()
    const theme = useTheme()

    return (
        <Box className={classes.container}>
            <Box
                style={{
                    width: 832,
                    backgroundColor: theme.palette.natural.t6,
                    boxShadow: theme.palette.main.shadow.primary,
                    borderRadius: 16,
                }}
            >
                <LoginForm />
            </Box>
        </Box>
    )
}

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.palette.main.primary.d2,
        overflow: 'scroll',
    },
}))

export default Login

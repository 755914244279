import { useEffect, useLayoutEffect, useState } from 'react'
import { Box, Typography, useTheme, Theme, Radio, TextField, Slider } from '@mui/material'
import CustomSwitch from '../../../components/CustomSwitch'
import { CampaignPosition } from '../../../shared/types/campaignTypes'
import { makeStyles } from '@mui/styles'
import PositionPreview from './PositionPreview'
import CustomButton from '../../../components/CustomButton'
import { useDispatch } from 'react-redux'
import { CampaignManager } from '../../../services/api/CampaignManager'
import { showFlashMessageWithTimeout } from '../../../services/actions/flashMessage-actions'
import { useParams } from 'react-router-dom'
import { CampaignVisualSettingsDto } from '../../../shared/types/campaignTypes'
import StandardSettingsModal from './StandardSettingsModal'
import CircularProgressBox from '../../../components/CircularProgressBox'
import NotificationPreview from '../../../components/NotificationPreview'
import DOMPurify from 'dompurify'
import HelpButton from '../../../components/HelpButton'
import BorderSelect, { BorderType } from '../../../components/NotificationEditComponents/BorderSelect'
import { NotificationDefaultSettings } from '../../../types/notification-default-settings'
import { convertStringToLanguage, emptyNotificationSettings } from '../../../generic-utils'
import { useTranslation } from 'react-i18next'

interface PcSettingsType {
    isActive: boolean
    position: CampaignPosition
    showAfterSeconds?: number
    showDurationSeconds?: number
    nextNotificationDelaySeconds?: number
    animationSpeed?: number
}

interface PhoneSettingsType {
    isActive: boolean
    position: CampaignPosition
    showAfterSeconds?: number
    showDurationSeconds?: number
    nextNotificationDelaySeconds?: number
    animationSpeed?: number
}

const pcPositionOptions: CampaignPosition[] = ['bottomLeft', 'topLeft', 'topRight', 'bottomRight']
const phonePositionOptions: CampaignPosition[] = ['bottom', 'top']

const getPositionString = (position: CampaignPosition) => {
    switch (position) {
        case 'bottom':
            return 'down'
        case 'top':
            return 'up'
        case 'bottomLeft':
            return 'downLeftCorner'
        case 'bottomRight':
            return 'downRightCorner'
        case 'topLeft':
            return 'upLeftCorner'
        case 'topRight':
            return 'upRightCorner'
        default:
            return ''
    }
}

const getLocalSettings = (settings: CampaignVisualSettingsDto, type: 'pc' | 'phone'): PcSettingsType | PhoneSettingsType => {
    if (type === 'pc') {
        let result: PcSettingsType = {
            isActive: settings.position ? true : false,
            position: settings.position ? settings.position : 'bottomLeft',
            showAfterSeconds: settings.showAfterSeconds ? settings.showAfterSeconds : 5,
            showDurationSeconds: settings.showDurationSeconds ? settings.showDurationSeconds : 5,
            nextNotificationDelaySeconds: settings.nextNotificationDelaySeconds ? settings.nextNotificationDelaySeconds : 10,
            animationSpeed: settings.animationSpeed ? settings.animationSpeed : 0,
        }
        return result
    } else {
        let result: PhoneSettingsType = {
            isActive: settings.position ? true : false,
            position: settings.position ? settings.position : 'bottom',
            showAfterSeconds: settings.showAfterSeconds ? settings.showAfterSeconds : 5,
            showDurationSeconds: settings.showDurationSeconds ? settings.showDurationSeconds : 5,
            nextNotificationDelaySeconds: settings.nextNotificationDelaySeconds ? settings.nextNotificationDelaySeconds : 10,
            animationSpeed: settings.animationSpeed ? settings.animationSpeed : 0,
        }
        return result
    }
}

const CampaignView = () => {
    const theme = useTheme()
    const classes = useStyles()
    const dispatch = useDispatch()
    const { id } = useParams()
    const { t, i18n } = useTranslation()
    const language = convertStringToLanguage(i18n.language)

    const [pcSettings, setPcSettings] = useState<PcSettingsType>({
        isActive: false,
        position: 'bottomLeft',
        showAfterSeconds: 5,
        showDurationSeconds: 5,
        nextNotificationDelaySeconds: 10,
        animationSpeed: 50,
    })

    const [phoneSettings, setPhoneSettings] = useState<PhoneSettingsType>({
        isActive: false,
        position: 'bottom',
        showAfterSeconds: 5,
        showDurationSeconds: 5,
        nextNotificationDelaySeconds: 10,
        animationSpeed: 50,
    })

    const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [isFetching, setIsFetching] = useState<boolean>(false)
    const [isDefaultUpdateLoading, setIsDefaultUpdateLoading] = useState<boolean>(false)

    const [pcPreview, setPcPreview] = useState<string>('')
    const [phonePreview, setPhonePreview] = useState<string>('')
    const [selectedBorder, setSelectedBorder] = useState<BorderType | null>(null)

    const createPcPreview = async () => {
        setPcPreview('')
        const result = await window.getNotificationPreview(
            emptyNotificationSettings,
            'lastOrders',
            t('campaign.notifications.lastOrders.previewText1') ?? '',
            t('campaign.notifications.lastOrders.previewText2') ?? '',
            undefined,
            language,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            pcSettings.animationSpeed
        )
        const cleanHtmlString = DOMPurify.sanitize(result, {
            USE_PROFILES: { html: true, svg: true },
        })
        setPcPreview(cleanHtmlString)
    }

    const createPhonePreview = async () => {
        setPhonePreview('')
        const result = await window.getNotificationPreview(
            emptyNotificationSettings,
            'lastOrders',
            t('campaign.notifications.lastOrders.previewText1') ?? '',
            t('campaign.notifications.lastOrders.previewText2') ?? '',
            undefined,
            language,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            phoneSettings.animationSpeed
        )
        const cleanHtmlString = DOMPurify.sanitize(result, {
            USE_PROFILES: { html: true, svg: true },
        })

        setPhonePreview(cleanHtmlString)
    }

    useLayoutEffect(() => {
        createPhonePreview()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [phoneSettings.animationSpeed])

    useLayoutEffect(() => {
        createPcPreview()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pcSettings.animationSpeed])

    useEffect(() => {
        if (id) {
            const fetchCampaign = async () => {
                try {
                    setIsFetching(true)
                    const _campaignManager = CampaignManager.getManager()
                    const result = await _campaignManager.getSingleCampaignAsync(id)
                    const localPcSettings = getLocalSettings(result.visualPcSettings, 'pc')
                    const localPhoneSettings = getLocalSettings(result.visualPhoneSettings, 'phone')
                    if (result.notificationVisualSettings?.roundness) {
                        setSelectedBorder(result.notificationVisualSettings?.roundness)
                    }
                    setPcSettings(localPcSettings)
                    setPhoneSettings(localPhoneSettings)
                    setIsFetching(false)
                } catch (e: any) {
                    setIsFetching(false)
                    showFlashMessageWithTimeout(dispatch, e.toString(), 'error')
                }
            }

            fetchCampaign()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const toggleSettings = (type: 'phone' | 'pc') => {
        if (type === 'pc') {
            setPcSettings((state) => {
                const copy: PcSettingsType = { ...state }
                copy.isActive = !copy.isActive
                return copy
            })
        } else {
            setPhoneSettings((state) => {
                const copy: PhoneSettingsType = { ...state }
                copy.isActive = !copy.isActive
                return copy
            })
        }
    }

    const handlePositionChange = (position: CampaignPosition, type: 'phone' | 'pc') => {
        if (type === 'pc') {
            setPcSettings((state) => {
                const copy: PcSettingsType = { ...state }
                copy.position = position
                return copy
            })
        }
        if (type === 'phone') {
            setPhoneSettings((state) => {
                const copy: PhoneSettingsType = { ...state }
                copy.position = position
                return copy
            })
        }
    }

    const handleShowAfterSecondsChange = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        type: 'phone' | 'pc'
    ) => {
        if (type === 'pc') {
            setPcSettings((state) => {
                const copy: PcSettingsType = { ...state }
                copy.showAfterSeconds = parseInt(event.target.value, 10)
                return copy
            })
        }
        if (type === 'phone') {
            setPhoneSettings((state) => {
                const copy: PhoneSettingsType = { ...state }
                copy.showAfterSeconds = parseInt(event.target.value, 10)
                return copy
            })
        }
    }

    const handleShowDurationSecondsChange = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        type: 'phone' | 'pc'
    ) => {
        if (type === 'pc') {
            setPcSettings((state) => {
                const copy: PcSettingsType = { ...state }
                copy.showDurationSeconds = parseInt(event.target.value, 10)
                return copy
            })
        }
        if (type === 'phone') {
            setPhoneSettings((state) => {
                const copy: PhoneSettingsType = { ...state }
                copy.showDurationSeconds = parseInt(event.target.value, 10)
                return copy
            })
        }
    }

    const handleNextNotificationDelaySecondsChange = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        type: 'phone' | 'pc'
    ) => {
        if (type === 'pc') {
            setPcSettings((state) => {
                const copy: PcSettingsType = { ...state }
                copy.nextNotificationDelaySeconds = parseInt(event.target.value, 10)
                return copy
            })
        }
        if (type === 'phone') {
            setPhoneSettings((state) => {
                const copy: PhoneSettingsType = { ...state }
                copy.nextNotificationDelaySeconds = parseInt(event.target.value, 10)
                return copy
            })
        }
    }

    const handleAnimationSpeedChange = (event: Event, newValue: number | number[], type: 'pc' | 'phone') => {
        if (type === 'pc') {
            setPcSettings((state) => {
                const copy: PcSettingsType = { ...state }
                if (copy.animationSpeed === newValue) {
                    createPcPreview()
                }
                copy.animationSpeed = newValue as number
                return copy
            })
        }
        if (type === 'phone') {
            setPhoneSettings((state) => {
                const copy: PhoneSettingsType = { ...state }
                if (copy.animationSpeed === newValue) {
                    createPhonePreview()
                }
                copy.animationSpeed = newValue as number
                return copy
            })
        }
    }

    const onSaveChanges = async () => {
        if (id) {
            try {
                setIsLoading(true)
                const _campaignManager = CampaignManager.getManager()
                const pcResult = pcSettings.isActive ? pcSettings : {}
                const phoneResult = phoneSettings.isActive ? phoneSettings : {}
                await _campaignManager.updateCampaignVisualAsync(id, pcResult, phoneResult, { roundness: selectedBorder ?? 0 })
                setIsLoading(false)
                showFlashMessageWithTimeout(dispatch, t('popups.visualChanged'), 'success', 3000)
            } catch (e: any) {
                setIsLoading(false)
                showFlashMessageWithTimeout(dispatch, e.toString(), 'error')
            }
        }
    }

    const onSetDefaultSettings = async () => {
        if (id) {
            try {
                setIsDefaultUpdateLoading(true)
                const _campaignManager = CampaignManager.getManager()
                const result = await _campaignManager.updateCampaignVisualAsync(
                    id,
                    {
                        position: undefined,
                        showAfterSeconds: undefined,
                        showDurationSeconds: undefined,
                        nextNotificationDelaySeconds: undefined,
                        animationSpeed: undefined,
                    },
                    {
                        position: undefined,
                        showAfterSeconds: undefined,
                        showDurationSeconds: undefined,
                        nextNotificationDelaySeconds: undefined,
                        animationSpeed: undefined,
                    }
                )
                const localPcSettings = getLocalSettings(result.visualPcSettings, 'pc')
                const localPhoneSettings = getLocalSettings(result.visualPhoneSettings, 'phone')
                setPcSettings(localPcSettings)
                setPhoneSettings(localPhoneSettings)
                setIsDefaultUpdateLoading(false)
                setIsModalOpen(false)
                showFlashMessageWithTimeout(dispatch, t('popups.visualChanged'), 'success', 3000)
            } catch (e: any) {
                setIsDefaultUpdateLoading(false)
                showFlashMessageWithTimeout(dispatch, e.toString(), 'error')
            }
        }
    }

    const sliderStyles = (disabled: boolean) => {
        return {
            color: '#FFCF96',
            '& .MuiSlider-track': {
                border: 'none',
                color: disabled ? theme.palette.natural.t3 : undefined,
            },
            '& .MuiSlider-thumb': {
                width: 20,
                height: 20,
                borderRadius: 0,
                background: disabled ? theme.palette.natural.t3 : theme.palette.main.gradient.primary.p2,
                '&:before': {
                    boxShadow: '0 2px 4px rgba(0,0,0,0.4)',
                },
                '&:hover, &.Mui-focusVisible, &.Mui-active': {
                    boxShadow: 'none',
                },
            },
        }
    }

    if (isFetching) return <CircularProgressBox />

    return (
        <Box style={{ flex: 1 }}>
            <div style={{ marginTop: theme.spacing(1), marginBottom: theme.spacing(1) }}>
                <HelpButton link='https://www.overenyweb.cz/napoveda/zacnete-zobrazovat-notifikace/spustte-prvni-kampan/' />
            </div>

            {/* NOTIFICATION DESIGN part */}

            <Typography className={classes.rowTitle} my={2}>
                {t('campaign.design.notificationsDesign')}
            </Typography>
            <Typography mb={1}>{t('campaign.design.designDescription')}</Typography>
            <div style={{ width: '25%' }}>
                <BorderSelect
                    selectedBorderValue={selectedBorder}
                    onChange={setSelectedBorder}
                    defaultBorderValue={Number(NotificationDefaultSettings.BorderRoundness)}
                />
            </div>

            {/* PC part */}

            <Box className={classes.row}>
                <Typography className={classes.rowTitle}>{t('campaign.design.computer')}</Typography>
                <CustomSwitch checked={pcSettings.isActive} disabled={false} onSwitch={() => toggleSettings('pc')} />
            </Box>

            {pcSettings.isActive && (
                <>
                    <Typography className={classes.positionTitle} style={{ marginTop: 24 }}>
                        {t('campaign.design.position')}
                    </Typography>

                    <Box
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                        }}
                    >
                        <Box style={{ display: 'flex', flexDirection: 'column' }}>
                            <Box
                                style={{
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                }}
                            >
                                {pcPositionOptions.map((option, index) => (
                                    <Box key={index} className={classes.listItem}>
                                        <Box style={{ marginLeft: 13 }} onClick={() => handlePositionChange(option, 'pc')}>
                                            <PositionPreview
                                                key={`${option}-${index}`}
                                                isActive={!pcSettings.isActive ? false : pcSettings.position === option}
                                                position={option}
                                                index={index}
                                            />
                                        </Box>
                                        <Box className={classes.radioRow}>
                                            <Radio
                                                sx={{
                                                    color: theme.palette.natural.t3,
                                                    '&.Mui-checked': {
                                                        color: pcSettings.isActive
                                                            ? theme.palette.main.primary.o
                                                            : theme.palette.natural.t3,
                                                    },
                                                }}
                                                checked={pcSettings.position === option}
                                                onChange={() => handlePositionChange(option, 'pc')}
                                                value={option}
                                                disabled={!pcSettings.isActive}
                                            />
                                            <Typography className={classes.radioLabel}>
                                                {t('campaign.design.' + getPositionString(option))}
                                            </Typography>
                                        </Box>
                                    </Box>
                                ))}
                            </Box>
                            <Typography className={classes.positionTitle} style={{ marginTop: 40 }}>
                                {t('campaign.design.animations')}
                            </Typography>
                            <Box style={{ display: 'flex', flexDirection: 'column' }}>
                                <Box className={classes.paramRow}>
                                    <Typography className={classes.primaryText}>
                                        {t('campaign.design.animationSettings.showAfter')}
                                    </Typography>
                                    <TextField
                                        disabled={!pcSettings.isActive}
                                        style={{ width: 108 }}
                                        InputProps={{
                                            inputProps: { min: 0 },
                                            style: { height: 44 },
                                        }}
                                        type='number'
                                        variant='outlined'
                                        value={pcSettings.showAfterSeconds}
                                        onChange={(event) => handleShowAfterSecondsChange(event, 'pc')}
                                    />
                                    <Typography className={classes.secondaryText}>
                                        {t('campaign.design.animationSettings.seconds')}
                                    </Typography>
                                </Box>
                                <Box className={classes.paramRow}>
                                    <Typography className={classes.primaryText}>
                                        {t('campaign.design.animationSettings.showDelay')}
                                    </Typography>
                                    <TextField
                                        disabled={!pcSettings.isActive}
                                        style={{ width: 108 }}
                                        InputProps={{
                                            inputProps: { min: 0 },
                                            style: { height: 44 },
                                        }}
                                        type='number'
                                        variant='outlined'
                                        value={pcSettings.showDurationSeconds}
                                        onChange={(event) => handleShowDurationSecondsChange(event, 'pc')}
                                    />
                                    <Typography className={classes.secondaryText}>
                                        {t('campaign.design.animationSettings.second')}
                                    </Typography>
                                </Box>
                                <Box className={classes.paramRow}>
                                    <Typography className={classes.primaryText}>
                                        {t('campaign.design.animationSettings.delayBetween')}
                                    </Typography>
                                    <TextField
                                        disabled={!pcSettings.isActive}
                                        style={{ width: 108 }}
                                        InputProps={{
                                            inputProps: { min: 0 },
                                            style: { height: 44 },
                                        }}
                                        type='number'
                                        variant='outlined'
                                        value={pcSettings.nextNotificationDelaySeconds}
                                        onChange={(event) => handleNextNotificationDelaySecondsChange(event, 'pc')}
                                    />
                                    <Typography className={classes.secondaryText}>
                                        {t('campaign.design.animationSettings.second')}
                                    </Typography>
                                </Box>
                                <Box className={classes.paramRow}>
                                    <Typography className={classes.primaryText}>
                                        {t('campaign.design.animationSettings.showSpeed')}
                                    </Typography>
                                    <Box style={{ width: 200 }}>
                                        <Slider
                                            disabled={!pcSettings.isActive}
                                            aria-label='Volume'
                                            value={pcSettings.animationSpeed}
                                            onChange={(event, newValue) => handleAnimationSpeedChange(event, newValue, 'pc')}
                                            sx={sliderStyles(!pcSettings.isActive)}
                                        />
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                        <Box style={{ display: 'flex', flexDirection: 'column' }}>
                            <Box className={classes.previewContainer}>
                                <Box style={{ height: 20, width: '100%' }} />
                                <NotificationPreview backgroundColor='transparent' notificationHtml={pcPreview} />
                            </Box>
                            <Typography className={classes.replayAnimationText} onClick={() => createPcPreview()}>
                                {t('campaign.design.playAnimation')}
                            </Typography>
                        </Box>
                    </Box>
                </>
            )}

            {/* PHONE part */}

            <Box className={classes.row}>
                <Typography className={classes.rowTitle}>{t('campaign.design.mobile')}</Typography>
                <CustomSwitch checked={phoneSettings.isActive} disabled={false} onSwitch={() => toggleSettings('phone')} />
            </Box>
            {phoneSettings.isActive && (
                <>
                    <Typography className={classes.positionTitle} style={{ marginTop: 24 }}>
                        {t('campaign.design.position')}
                    </Typography>
                    <Box
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                        }}
                    >
                        <Box
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                flex: 1,
                                maxWidth: 800,
                            }}
                        >
                            <Box
                                style={{
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                }}
                            >
                                <Box className={classes.pcPositionList}>
                                    {phonePositionOptions.map((option, index) => (
                                        <Box
                                            key={index}
                                            className={classes.listItem}
                                            style={{
                                                marginLeft: index === 0 ? 0 : 38,
                                                width: 79,
                                            }}
                                        >
                                            <Box style={{ marginLeft: 13 }} onClick={() => handlePositionChange(option, 'phone')}>
                                                <PositionPreview
                                                    key={`${option}-${index}`}
                                                    isActive={!phoneSettings.isActive ? false : phoneSettings.position === option}
                                                    position={option}
                                                    index={index}
                                                />
                                            </Box>
                                            <Box className={classes.radioRow}>
                                                <Radio
                                                    sx={{
                                                        color: theme.palette.natural.t3,
                                                        '&.Mui-checked': {
                                                            color: phoneSettings.isActive
                                                                ? theme.palette.main.primary.o
                                                                : theme.palette.natural.t3,
                                                        },
                                                    }}
                                                    checked={phoneSettings.position === option}
                                                    onChange={() => handlePositionChange(option, 'phone')}
                                                    value={option}
                                                    disabled={!phoneSettings.isActive}
                                                />
                                                <Typography className={classes.radioLabel}>
                                                    {t('campaign.design.' + getPositionString(option))}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    ))}
                                </Box>
                            </Box>
                            <Typography className={classes.positionTitle} style={{ marginTop: 40 }}>
                                {t('campaign.design.animations')}
                            </Typography>
                            <Box style={{ display: 'flex', flexDirection: 'column' }}>
                                <Box className={classes.paramRow}>
                                    <Typography className={classes.primaryText}>
                                        {t('campaign.design.animationSettings.showAfter')}
                                    </Typography>
                                    <TextField
                                        style={{ width: 108 }}
                                        InputProps={{
                                            inputProps: { min: 0 },
                                            style: { height: 44 },
                                        }}
                                        type='number'
                                        variant='outlined'
                                        value={phoneSettings.showAfterSeconds}
                                        onChange={(event) => handleShowAfterSecondsChange(event, 'phone')}
                                        disabled={!phoneSettings.isActive}
                                    />
                                    <Typography className={classes.secondaryText}>
                                        {t('campaign.design.animationSettings.seconds')}
                                    </Typography>
                                </Box>
                                <Box className={classes.paramRow}>
                                    <Typography className={classes.primaryText}>
                                        {t('campaign.design.animationSettings.showDelay')}
                                    </Typography>
                                    <TextField
                                        style={{ width: 108 }}
                                        InputProps={{
                                            inputProps: { min: 0 },
                                            style: { height: 44 },
                                        }}
                                        type='number'
                                        variant='outlined'
                                        value={phoneSettings.showDurationSeconds}
                                        onChange={(event) => handleShowDurationSecondsChange(event, 'phone')}
                                        disabled={!phoneSettings.isActive}
                                    />
                                    <Typography className={classes.secondaryText}>
                                        {t('campaign.design.animationSettings.second')}
                                    </Typography>
                                </Box>
                                <Box className={classes.paramRow}>
                                    <Typography className={classes.primaryText}>
                                        {t('campaign.design.animationSettings.delayBetween')}
                                    </Typography>
                                    <TextField
                                        style={{ width: 108 }}
                                        InputProps={{
                                            inputProps: { min: 0 },
                                            style: { height: 44 },
                                        }}
                                        type='number'
                                        variant='outlined'
                                        value={phoneSettings.nextNotificationDelaySeconds}
                                        onChange={(event) => handleNextNotificationDelaySecondsChange(event, 'phone')}
                                        disabled={!phoneSettings.isActive}
                                    />
                                    <Typography className={classes.secondaryText}>
                                        {t('campaign.design.animationSettings.second')}
                                    </Typography>
                                </Box>
                                <Box className={classes.paramRow}>
                                    <Typography className={classes.primaryText}>
                                        {t('campaign.design.animationSettings.showSpeed')}
                                    </Typography>
                                    <Box style={{ width: 200 }}>
                                        <Slider
                                            aria-label='Volume'
                                            value={phoneSettings.animationSpeed}
                                            onChange={(event, newValue) => {
                                                handleAnimationSpeedChange(event, newValue, 'phone')
                                            }}
                                            sx={sliderStyles(!phoneSettings.isActive)}
                                            disabled={!phoneSettings.isActive}
                                        />
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                        <Box style={{ display: 'flex', flexDirection: 'column' }}>
                            <Box className={classes.previewContainer}>
                                <Box style={{ height: 20, width: '100%' }} />
                                <NotificationPreview backgroundColor='transparent' notificationHtml={phonePreview} />
                            </Box>
                            <Typography className={classes.replayAnimationText} onClick={() => createPhonePreview()}>
                                {t('campaign.design.playAnimation')}
                            </Typography>
                        </Box>
                    </Box>
                </>
            )}

            <Box className={classes.divider} />
            <Box className={classes.buttonsContainer}>
                <CustomButton
                    size='medium'
                    style={{ width: 186 }}
                    type='primary'
                    title={t('saveChanges')}
                    isLoading={isLoading}
                    onClick={onSaveChanges}
                />
                <CustomButton
                    size='medium'
                    style={{ width: 186, marginRight: 24 }}
                    type='secondary'
                    title={t('defaultSettings')}
                    onClick={() => setIsModalOpen(true)}
                />
            </Box>
            <StandardSettingsModal
                isLoading={isDefaultUpdateLoading}
                isOpen={isModalOpen}
                onModalClose={() => setIsModalOpen(false)}
                onMainButtonPress={onSetDefaultSettings}
            />
        </Box>
    )
}

const useStyles = makeStyles((theme: Theme) => ({
    row: {
        flex: 1,
        height: 44,
        width: '100%',
        marginRight: 24,
        marginTop: 24,
        backgroundColor: theme.palette.natural.t6,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    rowTitle: {
        fontSize: 22,
        color: theme.palette.natural.t,
        fontWeight: 'bold',
        marginRight: 24,
        marginLeft: 14,
    },
    positionTitle: {
        fontSize: 18,
        fontWeight: 'bold',
        color: theme.palette.natural.t,
        marginBottom: 16,
    },
    pcPositionList: {
        display: 'flex',
        flexDirection: 'row',
        maxWidth: 782,
    },
    listItem: {
        width: 200,
        display: 'flex',
        flexDirection: 'column',
    },
    radioRow: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    radioLabel: {
        userSelect: 'none',
        fontSize: 16,
        color: theme.palette.natural.t1,
    },
    paramRow: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 24,
    },
    secondaryText: {
        marginLeft: 16,
        fontSize: 16,
        color: theme.palette.natural.t1,
    },
    primaryText: {
        width: 300,
        fontSize: 16,
        color: theme.palette.natural.t1,
    },
    buttonsContainer: {
        display: 'flex',
        flexDirection: 'row-reverse',
        flex: 1,
        height: 44,
        marginBottom: 32,
        marginRight: '10%',
    },
    divider: {
        height: 2,
        flex: 1,
        backgroundColor: theme.palette.natural.t8,
        marginBottom: 32,
    },
    previewContainer: {
        height: 300,
        minWidth: 300,
        backgroundColor: theme.palette.main.primary.d3,
        display: 'flex',
        paddingLeft: 12,
        paddingRight: 12,
        flexDirection: 'column',
    },
    replayAnimationText: {
        fontSize: 16,
        color: theme.palette.main.primary.o,
        marginTop: 20,
        cursor: 'pointer',
    },
}))

export default CampaignView

import { GlobalVars } from '../../global'
import {
    CreateCampaignRequest,
    CreateCampaignResponse,
    GetCampaignOrdersResponse,
    GetCampaignRegistrationsResponse,
    GetCampaignResponse,
    GetCampaignsResponse,
    UpdateCampaignRequest,
    UpdateCampaignResponse,
    UpdateCampaignStateRequest,
    UpdateCampaignStateResponse,
    UpdateCampaignVisualsRequest,
    UpdateCampaignVisualsResponse,
} from '../../shared/payloads/campaignPayloads'
import { CampaignVisualSettingsDto } from '../../shared/types/campaignTypes'
import { UrlFilterDto } from '../../shared/types/commonTypes'
import { NotificationVisualSettingsDto } from '../../shared/types/notificationSettingsTypes'
import { handleNetworkResponseAndGetJsonAsync, handleNetworkResponseWithNoPayloadAsync, Network } from './Network'

export class CampaignManager {
    static _instance: CampaignManager | null = null

    static getManager(): CampaignManager {
        if (this._instance === null) {
            this._instance = new CampaignManager()
        }
        return this._instance
    }

    async getSingleCampaignAsync(id: string): Promise<GetCampaignResponse> {
        let response: Response | null = null
        const url = `${GlobalVars.SERVER_ENDPOINT}/campaign/single?id=${id}`

        try {
            response = await Network.fetch(url, {
                method: 'GET',
                credentials: 'include',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
            })
        } catch (e) {
            throw new Error('Error executing fetch request.')
        }
        if (response) {
            const result: GetCampaignResponse = await handleNetworkResponseAndGetJsonAsync(response)
            return result
        } else {
            throw new Error('No response from server returned.')
        }
    }

    async getCampaignsAsync(websiteId: string): Promise<GetCampaignsResponse> {
        let response: Response | null = null
        const url = `${GlobalVars.SERVER_ENDPOINT}/campaign?websiteId=${websiteId}`

        try {
            response = await Network.fetch(url, {
                method: 'GET',
                credentials: 'include',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
            })
        } catch (e) {
            throw new Error('Error executing fetch request.')
        }
        if (response) {
            const result: GetCampaignsResponse = await handleNetworkResponseAndGetJsonAsync(response)
            return result
        } else {
            throw new Error('No response from server returned.')
        }
    }

    async createCampaignAsync(websiteId: string): Promise<CreateCampaignResponse> {
        let response: Response | null = null
        const url = `${GlobalVars.SERVER_ENDPOINT}/campaign/create`
        const body: CreateCampaignRequest = {
            websiteId: websiteId,
        }

        try {
            response = await Network.fetch(url, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body),
            })
        } catch (e) {
            throw new Error('Error executing fetch request.')
        }
        if (response) {
            const result: CreateCampaignResponse = await handleNetworkResponseAndGetJsonAsync(response)
            return result
        } else {
            throw new Error('No response from server returned.')
        }
    }

    async updateCampaignAsync(id: string, name: string, urlFilter: UrlFilterDto): Promise<UpdateCampaignResponse> {
        let response: Response | null = null
        const url = `${GlobalVars.SERVER_ENDPOINT}/campaign/update`

        const body: UpdateCampaignRequest = {
            id: id,
            name: name,
            urlFilter: urlFilter,
        }
        try {
            response = await Network.fetch(url, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body),
            })
        } catch (e) {
            throw new Error('Error executing fetch request.')
        }
        if (response) {
            const result: UpdateCampaignResponse = await handleNetworkResponseAndGetJsonAsync(response)
            return result
        } else {
            throw new Error('No response from server returned.')
        }
    }

    async updateCampaignVisualAsync(
        id: string,
        pcSettings: CampaignVisualSettingsDto,
        phoneSettings: CampaignVisualSettingsDto,
        notificationVisualSettings?: NotificationVisualSettingsDto
    ): Promise<UpdateCampaignVisualsResponse> {
        let response: Response | null = null
        const url = `${GlobalVars.SERVER_ENDPOINT}/campaign/visual/update`
        const body: UpdateCampaignVisualsRequest = {
            id: id,
            pcSettings: pcSettings,
            phoneSettings: phoneSettings,
            notificationVisualSettings: notificationVisualSettings,
        }
        try {
            response = await Network.fetch(url, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body),
            })
        } catch (e) {
            throw new Error('Error executing fetch request.')
        }
        if (response) {
            const result: UpdateCampaignVisualsResponse = await handleNetworkResponseAndGetJsonAsync(response)
            return result
        } else {
            throw new Error('No response from server returned.')
        }
    }

    async updateCampaignStateAsync(id: string, isActive: boolean): Promise<UpdateCampaignStateResponse> {
        let response: Response | null = null
        const url = `${GlobalVars.SERVER_ENDPOINT}/campaign/state/update`
        const body: UpdateCampaignStateRequest = {
            id: id,
            isActive: isActive,
        }
        try {
            response = await Network.fetch(url, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body),
            })
        } catch (e) {
            throw new Error('Error executing fetch request.')
        }
        if (response) {
            const result: UpdateCampaignStateResponse = await handleNetworkResponseAndGetJsonAsync(response)
            return result
        } else {
            throw new Error('No response from server returned.')
        }
    }

    async deleteCampaignAsync(id: string) {
        let response: Response | null = null
        const url = `${GlobalVars.SERVER_ENDPOINT}/campaign?id=${id}`
        try {
            response = await Network.fetch(url, {
                method: 'DELETE',
                credentials: 'include',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
            })
        } catch (e) {
            throw new Error('Error executing fetch request.')
        }
        if (response) {
            const result = await handleNetworkResponseWithNoPayloadAsync(response)
            return result
        } else {
            throw new Error('No response from server returned.')
        }
    }

    async getCampaignOrdersAnalyticsAsync(id: string): Promise<GetCampaignOrdersResponse> {
        let response: Response | null = null
        const url = `${GlobalVars.SERVER_ENDPOINT}/campaign/${id}/orders`

        try {
            response = await Network.fetch(url, {
                method: 'GET',
                credentials: 'include',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
            })
        } catch (e) {
            throw new Error('Error executing fetch request.')
        }
        if (response) {
            const result: GetCampaignOrdersResponse = await handleNetworkResponseAndGetJsonAsync(response)
            return result
        } else {
            throw new Error('No response from server returned.')
        }
    }

    async getCampaignRegistrationsAnalyticsAsync(id: string): Promise<GetCampaignRegistrationsResponse> {
        let response: Response | null = null
        const url = `${GlobalVars.SERVER_ENDPOINT}/campaign/${id}/registrations`

        try {
            response = await Network.fetch(url, {
                method: 'GET',
                credentials: 'include',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
            })
        } catch (e) {
            throw new Error('Error executing fetch request.')
        }
        if (response) {
            const result: GetCampaignRegistrationsResponse = await handleNetworkResponseAndGetJsonAsync(response)
            return result
        } else {
            throw new Error('No response from server returned.')
        }
    }
}

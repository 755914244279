import { Dispatch } from 'redux'
import {
    ClearAllFlashMessages,
    FlashMessagesActionTypes,
    FlashMessageType,
    RemoveFlashMessage,
    ShowFlashMessage,
} from '../types/flash-messages-types'

/**
 * Displays new flash message.
 * @param flashMessage Flash message to display
 */
export const showFlashMessage = (message: string, type: FlashMessageType, id?: number): ShowFlashMessage => ({
    type: FlashMessagesActionTypes.SHOW_FLASH_MESSAGE,
    payload: {
        id,
        message,
        type,
    },
})

/**
 * Removes displayed flash message.
 * @param flashMessageId id of flash message to remove
 */
export const removeFlashMessage = (flashMessageId: number): RemoveFlashMessage => ({
    type: FlashMessagesActionTypes.REMOVE_FLASH_MESSAGE,
    payload: {
        flashMessageId,
    },
})

/**
 * Removes all flash messages.
 */
export const clearAllFlashMessages = (): ClearAllFlashMessages => ({
    type: FlashMessagesActionTypes.CLEAR_ALL_FLASH_MESSAGES,
})

/**
 *
 * @param dispatch is Dispatch from redux
 * @param message string for flash message
 * @param type type for flash message
 * @param timeout number in ms, if not provided default is 3000
 */
export const showFlashMessageWithTimeout = (
    dispatch: Dispatch,
    message: string,
    type: FlashMessageType,
    timeout?: number
): void => {
    const simpleId = Date.now()
    dispatch(showFlashMessage(message, type, simpleId))
    setTimeout(
        () => {
            dispatch(removeFlashMessage(simpleId))
        },
        timeout ? timeout : 5000
    )
}

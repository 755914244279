import { ReduxAction } from '../types/main-reducer-types'
import { UserActionTypes, UserReduxState } from '../types/user-types'

export default function userReducer(state: UserReduxState, action: ReduxAction): UserReduxState {
    switch (action.type) {
        case UserActionTypes.SET_USER_INFO: {
            const {
                id,
                createdAt,
                lastModifiedAt,
                name,
                email,
                isAdmin,
                accountType,
                remainingPricingPlanCredits,
                remainingExtraCredits,
                isInTrial,
                pricingPlanName,
                lastPricingPlanName,
                lastPricingPlanPeriodEndsAt,
                hasUnlimitedAccess,
                language,
                registeredFrom,
                isActivationConfirmed,
            } = action.payload
            return {
                ...state,
                info: {
                    id,
                    createdAt,
                    lastModifiedAt,
                    name,
                    email,
                    isAdmin,
                    accountType,
                    remainingPricingPlanCredits,
                    remainingExtraCredits,
                    isInTrial,
                    pricingPlanName,
                    lastPricingPlanName,
                    lastPricingPlanPeriodEndsAt,
                    hasUnlimitedAccess,
                    language,
                    registeredFrom,
                    isActivationConfirmed,
                },
            }
        }
        case UserActionTypes.REMOVE_USER_INFO: {
            return {
                ...state,
                info: {
                    id: '',
                    createdAt: null,
                    lastModifiedAt: null,
                    name: '',
                    email: '',
                    isAdmin: false,
                    accountType: 'personal',
                    remainingPricingPlanCredits: 0,
                    remainingExtraCredits: 0,
                    isInTrial: false,
                    pricingPlanName: undefined,
                    lastPricingPlanName: undefined,
                    lastPricingPlanPeriodEndsAt: undefined,
                    hasUnlimitedAccess: false,
                    language: 'cs',
                    registeredFrom: 'internal',
                },
            }
        }
        case UserActionTypes.SET_ADMIN_INFO: {
            const {
                id,
                createdAt,
                lastModifiedAt,
                name,
                email,
                isAdmin,
                accountType,
                remainingPricingPlanCredits,
                remainingExtraCredits,
                isInTrial,
                pricingPlanName,
                lastPricingPlanName,
                lastPricingPlanPeriodEndsAt,
                hasUnlimitedAccess,
                language,
                registeredFrom,
            } = action.payload
            return {
                ...state,
                adminInfo: {
                    id,
                    createdAt,
                    lastModifiedAt,
                    name,
                    email,
                    isAdmin,
                    accountType,
                    remainingPricingPlanCredits,
                    remainingExtraCredits,
                    isInTrial,
                    pricingPlanName,
                    lastPricingPlanName,
                    lastPricingPlanPeriodEndsAt,
                    hasUnlimitedAccess,
                    language,
                    registeredFrom,
                },
            }
        }
        case UserActionTypes.REMOVE_ADMIN_INFO: {
            return {
                ...state,
                adminInfo: {
                    id: '',
                    createdAt: null,
                    lastModifiedAt: null,
                    name: '',
                    email: '',
                    isAdmin: false,
                    accountType: 'personal',
                    remainingPricingPlanCredits: 0,
                    remainingExtraCredits: 0,
                    isInTrial: false,
                    pricingPlanName: undefined,
                    lastPricingPlanName: undefined,
                    lastPricingPlanPeriodEndsAt: undefined,
                    hasUnlimitedAccess: false,
                    language: 'cs',
                    registeredFrom: 'internal',
                },
            }
        }
        default:
            return state
    }
}

import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import { Box, Tab, useTheme, Theme, Typography, Stack, Grid } from '@mui/material'
import { FC, useLayoutEffect, useState } from 'react'
import ModalBase from '../../components/ModalBase'
import { makeStyles } from '@mui/styles'
import CloseIcon from '@mui/icons-material/Close'
import TabPanel from '@mui/lab/TabPanel'
import CustomSwitch from '../../components/CustomSwitch'
import CustomButton from '../../components/CustomButton'
import { useDispatch } from 'react-redux'
import { NotificationManager } from '../../services/api/NotificationManager'
import {
    GetNotificationSettingsResponse,
    UpdateNotificationSettingsRequest,
} from '../../shared/payloads/notificationSettingsPayloads'
import { showFlashMessageWithTimeout } from '../../services/actions/flashMessage-actions'
import CustomDropdown from '../../components/CustomDropdown'
import { DropdownMenuItem } from '../../components/types'
import { Language } from '../../shared/types/websiteTypes'
import CustomColorPicker from '../../components/NotificationEditComponents/CustomColorPicker'
import BorderSelect, { BorderType } from '../../components/NotificationEditComponents/BorderSelect'
import { NotificationDefaultSettings } from '../../types/notification-default-settings'
import { useTranslation } from 'react-i18next'
import NotificationPreview from '../../components/NotificationPreview'
import DOMPurify from 'dompurify'
import { v4 } from 'uuid'

interface SummaryModalProps {
    isOpen: boolean
    onClose: () => void
    createdNotificationId: string
    language: Language
    openedFor?: 'visitorSummary' | 'registrationSummary' | 'orderSummary'
    data?: GetNotificationSettingsResponse
}

const dropdownTimeRanges: DropdownMenuItem[] = [
    { title: '7 dní', value: '1' },
    { title: 'měsíc', value: '2' },
    { title: 'campaign.notifications.shoptetLastOrders.twoMonths', value: '3' },
]

type TabType = 'rules' | 'pairing' | 'design'

const notificationStyleId = v4()

const ShoptetLastOrdersNotificationModal: FC<SummaryModalProps> = ({
    isOpen,
    onClose,
    createdNotificationId,
    language,
    data,
}) => {
    const theme = useTheme()
    const classes = useStyles()
    const dispatch = useDispatch()
    const { t } = useTranslation()

    const [selectedTab, setSelectedTab] = useState<TabType>('rules')
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [selectedTimeRange, setSelectedTimeRange] = useState<string>(dropdownTimeRanges[2].value)
    const [withRepeat, setWithRepeat] = useState<boolean>(
        data?.repeatNotification !== undefined ? data?.repeatNotification : true
    )
    const [backgroundColor, setBackgroundColor] = useState<string | null>(
        data?.notificationVisualSettings?.backgroundColor || null
    )
    const [textColor, setTextColor] = useState<string | null>(data?.notificationVisualSettings?.textColor || null)
    const [borderRadius, setBorderRadius] = useState<BorderType | null>(data?.notificationVisualSettings?.roundness || null)
    const [notificationHtml, setNotificationHtml] = useState<string>('')

    const onCloseAndClearInputs = () => {
        setSelectedTab('rules')
        setWithRepeat(true)
        onClose()
    }

    const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
        setSelectedTab(newValue as TabType)
    }

    const toggleWithRepeat = () => {
        setWithRepeat(!withRepeat)
    }

    const onSave = async () => {
        setIsLoading(true)
        try {
            const _notificationManager = NotificationManager.getManager()
            const body: UpdateNotificationSettingsRequest = {
                id: createdNotificationId,
                isActive: true,
                repeatNotification: withRepeat,
                notificationVisualSettings: {
                    backgroundColor: backgroundColor ?? '',
                    textColor: textColor ?? '',
                    iconBackgroundColor: '',
                    iconColor: '',
                    icon: '',
                    roundness: borderRadius ?? 0,
                },
            }

            await _notificationManager.updateNotificationSettingsAsync(body)
            setIsLoading(false)
            onCloseAndClearInputs()
        } catch (e: any) {
            showFlashMessageWithTimeout(dispatch, e.toString(), 'error')
            setIsLoading(false)
        }
    }

    useLayoutEffect(() => {
        const createNotificationPreviewHtml = async () => {
            const result = await window.getNotificationPreview(
                {
                    campaign: {
                        notificationVisualSettings: {
                            roundness: 1,
                        },
                    },
                    settings: {
                        type: 'shoptetLastOrders',
                        notificationVisualSettings: {
                            backgroundColor: backgroundColor ?? '',
                            textColor: textColor ?? '',
                            iconBackgroundColor: '',
                            iconColor: '',
                            icon: '',
                            roundness: borderRadius ?? 0,
                        },
                    },
                },
                'shoptetLastOrders',
                t('campaign.notifications.lastOrders.previewText1') ?? '',
                t('campaign.notifications.shoptetLastOrders.screwdriver') ?? '',
                undefined,
                language,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                'https://creazilla-store.fra1.digitaloceanspaces.com/cliparts/3164156/screwdriver-clipart-md.png',
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                notificationStyleId
            )
            // Transforms object htmldivelement to pure string
            const cleanHtmlString = DOMPurify.sanitize(result, {
                USE_PROFILES: { html: true, svg: true },
            })
            setNotificationHtml(cleanHtmlString)
        }

        createNotificationPreviewHtml()
    })

    const activeColor = theme.palette.natural.t1
    const notActiveColor = theme.palette.natural.t2

    return (
        <ModalBase isOpen={isOpen} onClose={onCloseAndClearInputs} style={{ overflow: 'auto' }} center>
            <Stack
                direction='column'
                bgcolor={theme.palette.natural.t4}
                p={3}
                overflow='auto'
                sx={{
                    width: { xs: '80%', md: 900 },
                    maxHeight: '90vh',
                }}
            >
                <Stack direction='row' justifyContent='space-between'>
                    <Typography color={theme.palette.main.secondary.u1} fontWeight='bold' fontSize={{ xs: 18, md: 24 }}>
                        {t('campaign.notifications.shoptetLastOrders.title')}
                    </Typography>
                    <CloseIcon style={{ color: theme.palette.natural.t2, cursor: 'pointer' }} onClick={onCloseAndClearInputs} />
                </Stack>

                <TabContext value={selectedTab}>
                    <TabList
                        TabIndicatorProps={{
                            style: {
                                overflow: 'auto',
                                background: theme.palette.main.primary.o,
                                height: 3,
                            },
                        }}
                        onChange={handleTabChange}
                    >
                        <Tab
                            disableRipple
                            style={{
                                color: selectedTab === 'rules' ? activeColor : notActiveColor,
                                textTransform: 'none',
                                fontSize: 16,
                            }}
                            label={t('campaign.notifications.settings.displayRules.title')}
                            value='rules'
                        />
                        <Tab
                            disableRipple
                            style={{
                                color: selectedTab === 'design' ? activeColor : notActiveColor,
                                textTransform: 'none',
                                fontSize: 16,
                            }}
                            label={t('campaign.notifications.settings.design.title')}
                            value='design'
                        />

                        <Box className={classes.tabsDivider} />
                    </TabList>
                    <TabPanel value='rules' style={{ padding: 0, flex: 1 }}>
                        <NotificationPreview notificationHtml={notificationHtml} />
                        <Grid container rowSpacing={2} columnSpacing={2} width='100%'>
                            <Grid item xs={12} md={6}>
                                <Stack direction='row' alignItems='center' justifyContent='space-between'>
                                    <Typography color={theme.palette.natural.t1}>
                                        {t('campaign.notifications.settings.displayRules.repeatNotification')}
                                    </Typography>
                                    <CustomSwitch checked={withRepeat} onSwitch={() => toggleWithRepeat()} disabled={false} />
                                </Stack>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Stack direction='row' alignItems='center' justifyContent='space-between'>
                                    <Typography color={theme.palette.natural.t1}>
                                        {t('campaign.notifications.shoptetLastOrders.showRandom')}
                                    </Typography>
                                    <CustomDropdown
                                        label={t('campaign.notifications.shoptetLastOrders.timeRange')}
                                        items={dropdownTimeRanges.map((item) => ({
                                            ...item,
                                            title: t(item.title),
                                        }))}
                                        disabled
                                        onChange={(event) => {
                                            setSelectedTimeRange(event.target.value)
                                        }}
                                        value={selectedTimeRange}
                                    />
                                </Stack>
                            </Grid>
                        </Grid>

                        <Box className={classes.firstButtonsContainer}>
                            <CustomButton
                                size='medium'
                                style={{ width: 132 }}
                                title={t('continue')}
                                onClick={() => {
                                    setSelectedTab('design')
                                }}
                            />
                        </Box>
                    </TabPanel>

                    <TabPanel value='design' style={{ padding: 0 }}>
                        <NotificationPreview notificationHtml={notificationHtml} />

                        <Typography style={{ marginTop: 16 }} mb={1} color={theme.palette.natural.t1}>
                            {t('campaign.notifications.settings.design.description')}
                        </Typography>
                        <Grid container columnSpacing={3}>
                            <Grid item xs={12} md={6}>
                                <Stack direction='row' alignItems='center'>
                                    <Typography className={classes.sectionTitle} width={300}>
                                        {t('campaign.notifications.settings.design.backgroundColour')}
                                    </Typography>

                                    <CustomColorPicker
                                        color={backgroundColor}
                                        onChange={setBackgroundColor}
                                        defaultColor={NotificationDefaultSettings.BackgroundColor}
                                    />
                                </Stack>
                                <Stack direction='row' alignItems='center'>
                                    <Typography className={classes.sectionTitle} width={300}>
                                        {t('campaign.notifications.settings.design.textColour')}
                                    </Typography>

                                    <CustomColorPicker
                                        color={textColor}
                                        onChange={setTextColor}
                                        defaultColor={NotificationDefaultSettings.TextColor}
                                    />
                                </Stack>
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <Typography className={classes.sectionTitle}>
                                    {t('campaign.notifications.settings.design.roundedCorners')}
                                </Typography>

                                <BorderSelect
                                    selectedBorderValue={borderRadius}
                                    onChange={setBorderRadius}
                                    defaultBorderValue={Number(NotificationDefaultSettings.BorderRoundness)}
                                />

                                <Stack direction='row' justifyContent='flex-end' mt={1}>
                                    <CustomButton
                                        style={{ width: 132 }}
                                        size='medium'
                                        type='secondary'
                                        title={t('back')}
                                        onClick={() => setSelectedTab('rules')}
                                    />
                                    <CustomButton
                                        style={{ width: 132, marginLeft: 24 }}
                                        size='medium'
                                        type='primary'
                                        title={t('save')}
                                        isLoading={isLoading}
                                        onClick={onSave}
                                    />
                                </Stack>
                            </Grid>
                        </Grid>
                    </TabPanel>
                </TabContext>
            </Stack>
        </ModalBase>
    )
}

const useStyles = makeStyles((theme: Theme) => ({
    tabsDivider: {
        width: 370,
        height: 3,
        backgroundColor: theme.palette.natural.t3,
        position: 'absolute',
        bottom: 0,
    },
    sectionTitle: {
        fontSize: 18,
        color: theme.palette.main.primary.o,
        fontWeight: 'bold',
    },
    firstButtonsContainer: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row-reverse',
        marginTop: 52,
    },
    secondTitle: {
        marginTop: 24,
        color: theme.palette.main.secondary.u1,
        fontSize: 18,
        fontWeight: 'bold',
    },
    titleHint: {
        fontSize: 14,
        color: theme.palette.natural.t2,
        marginTop: 8,
    },
    secondButtonsContainer: {
        flex: 1,
        height: 44,
        display: 'flex',
        flexDirection: 'row-reverse',
    },
    formContainer: {
        flex: 1,
        padding: 24,
        backgroundColor: theme.palette.natural.t8,
        marginBottom: 16,
        borderRadius: 8,
        maxWidth: 735,
    },
    urlContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    icon: {
        color: theme.palette.main.alert.o,
        fontSize: 24,
        cursor: 'pointer',
    },
    secondTabContainer: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        height: 522,
        overflow: 'auto',
    },
}))

export default ShoptetLastOrdersNotificationModal

import { Box, Typography, useTheme, Theme } from '@mui/material'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { makeStyles, withStyles } from '@mui/styles'
import moment from 'moment'
import { ReactElement, useState } from 'react'
import CustomSwitch from '../../components/CustomSwitch'
import { CampaignDto } from '../../shared/types/campaignTypes'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import DropdownMenu from '../../components/DropdownMenu'
import { useTranslation } from 'react-i18next'

const StyledTableRow = withStyles((theme: Theme) => ({
    root: {
        height: 70,
        backgroundColor: theme.palette.natural.t6,
    },
}))(TableRow)

interface CampaignsTableProps {
    campaigns: CampaignDto[]
    onStatusChange?: (id: string) => void
    isDisabled: boolean
    onOpenDeleteModal?: (id: string) => void
    onCampaignSelect?: (id: string) => void
}

const CampaignsTable: React.FC<CampaignsTableProps> = ({
    campaigns,
    onStatusChange,
    isDisabled,
    onOpenDeleteModal,
    onCampaignSelect,
}) => {
    const classes = useStyles()
    const theme = useTheme()
    const { t } = useTranslation();

    const rows: ReactElement[] = []
    const [dropdownOpenForId, setDropdownOpenForId] = useState<string | undefined>()

    campaigns.forEach((campaign) => {
        rows.push(
            <StyledTableRow
                key={campaign.id}
                sx={{
                    '&:last-child td, &:last-child th': { border: 0 },
                }}
            >
                <TableCell className={classes.tableHeaderCell} component='th' scope='row'>
                    <Typography
                        style={{ fontWeight: 'bold', cursor: 'pointer' }}
                        onClick={() => (onCampaignSelect ? onCampaignSelect(campaign.id) : undefined)}
                    >
                        {campaign.name}
                    </Typography>
                </TableCell>
                <TableCell className={classes.tableHeaderCell} align='left'>
                    {moment(campaign.createdAt).format('DD. MM. YYYY')}
                </TableCell>
                <TableCell className={classes.tableHeaderCell} align='left'>
                    {campaign.notificationSettingsCount}
                </TableCell>
                <TableCell className={classes.tableHeaderCell} align='center'>
                    <CustomSwitch
                        checked={campaign.isActive}
                        disabled={isDisabled}
                        onSwitch={() => (onStatusChange ? onStatusChange(campaign.id) : undefined)}
                    />
                </TableCell>
                <TableCell className={classes.tableHeaderCell} align='right'>
                    <DropdownMenu
                        placement='left'
                        open={campaign.id === dropdownOpenForId}
                        onClose={() => setDropdownOpenForId(undefined)}
                        content={
                            <Box className={classes.dropDown}>
                                <Box
                                    className={classes.dropdownBody}
                                    onClick={() => {
                                        setDropdownOpenForId(undefined)
                                        if (onOpenDeleteModal) onOpenDeleteModal(campaign.id)
                                    }}
                                >
                                    <Typography style={{ color: theme.palette.natural.t1, marginLeft: 16 }}>{t('campaign.table.delete')}</Typography>
                                </Box>
                            </Box>
                        }
                    >
                        <MoreVertIcon
                            onClick={() => setDropdownOpenForId(campaign.id)}
                            style={{ color: theme.palette.natural.t2, cursor: 'pointer' }}
                        />
                    </DropdownMenu>
                </TableCell>
            </StyledTableRow>
        )
    })

    return (
        <TableContainer component={Box}>
            <Table className={classes.table} sx={{ minWidth: 650 }} aria-label='simple table'>
                <TableHead classes={{ root: classes.thead }}>
                    <TableRow
                        style={{
                            backgroundColor: theme.palette.main.primary.d2,
                            height: 52,
                            borderRadius: 4,
                        }}
                    >
                        <TableCell className={classes.tableCell} align='left'>
                            {t('campaign.table.name')}
                        </TableCell>
                        <TableCell className={classes.tableCell} align='left'>
                            {t('campaign.table.creationDate')}
                        </TableCell>
                        <TableCell className={classes.tableCell} align='left'>
                            {t('campaign.table.notificationCount')}
                        </TableCell>
                        <TableCell className={classes.tableCell} align='center'>
                            {t('campaign.table.status')}
                        </TableCell>
                        <TableCell className={classes.tableCell} align='center'></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>{rows}</TableBody>
            </Table>
        </TableContainer>
    )
}

const useStyles = makeStyles((theme: Theme) => ({
    table: {
        minWidth: 550,
    },
    tableHeaderCell: {
        padding: '0px 24px',
        color: theme.palette.natural.t1,
    },
    tableCell: {
        padding: '0px 24px',
        color: theme.palette.natural.t,
    },
    thead: {
        backgroundColor: theme.palette.natural.t2,
        '& th:first-child': {
            borderRadius: '4px 0 0 0px',
        },
        '& th:last-child': {
            borderRadius: '0 4px 0 0',
        },
    },
    dropDown: {
        width: 166,
        height: 44,
        backgroundColor: 'white',
        borderRadius: 6,
        filter: 'drop-shadow(0px 1px 2px rgba(65, 32, 158, 0.06)) drop-shadow(0px 0px 2px rgba(65, 32, 158, 0.04)) drop-shadow(0px 4px 8px rgba(136, 100, 211, 0.16))',
        '&:hover': {
            backgroundColor: theme.palette.main.primary.d3,
        },
    },
    dropdownBody: {
        height: 44,
        display: 'flex',
        alignItems: 'center',
        borderRadius: 6,
        cursor: 'pointer',
    },
}))

export default CampaignsTable

import { Box, Typography, Theme, SelectChangeEvent, Autocomplete, Chip, TextField, Stack } from '@mui/material'
import { FC, useEffect, useState } from 'react'
import CustomTextField from '../../../components/CustomTextField'
import { makeStyles } from '@mui/styles'
import CustomDropdown from '../../../components/CustomDropdown'
import CustomButton from '../../../components/CustomButton'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import { DropdownMenuItem } from '../../../components/types'
import { useDispatch } from 'react-redux'
import { showFlashMessageWithTimeout } from '../../../services/actions/flashMessage-actions'
import { useParams } from 'react-router-dom'
import { CampaignManager } from '../../../services/api/CampaignManager'
import { fireEvent } from '@testing-library/react'
import CircularProgressBox from '../../../components/CircularProgressBox'
import { transformFormsToUrlFilterDto, transformUrlFilterToForms } from '../../../generic-utils'
import HelpButton from '../../../components/HelpButton'
import { useTranslation } from 'react-i18next'

type ControlType = 'beginsWith' | 'contains' | 'isEqual'

interface FormType {
    control: ControlType
    include: string[]
    excludes: string[]
}

const menuItems: DropdownMenuItem[] = [
    {
        title: 'Začíná na',
        value: 'beginsWith',
    },
    {
        title: 'Obsahuje',
        value: 'contains',
    },
    {
        title: 'Shoduje se',
        value: 'isEqual',
    },
]

const getAvailableControls = (forms: FormType[]) => {
    const unavailableControls: ControlType[] = []
    const allControls: ControlType[] = ['beginsWith', 'contains', 'isEqual']
    forms.forEach((form) => {
        unavailableControls.push(form.control)
    })
    const result = allControls.filter((ac) => !unavailableControls.includes(ac))
    return result
}

interface CampaignSettingsProps {
    setCampaignNameInParent: (name: string) => void
}

const CampaignSettings: FC<CampaignSettingsProps> = ({ setCampaignNameInParent }) => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const { id } = useParams()
    const { t } = useTranslation()

    const [campaignName, setCampaignName] = useState<string>('')
    const [forms, setForms] = useState<FormType[]>([{ control: 'beginsWith', include: [], excludes: [] }])
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [isFetching, setIsFetching] = useState<boolean>(false)

    useEffect(() => {
        if (id) {
            const fetchCampaign = async () => {
                try {
                    setIsFetching(true)
                    const _campaignManager = CampaignManager.getManager()
                    const result = await _campaignManager.getSingleCampaignAsync(id)
                    const transformedForms = transformUrlFilterToForms(result.urlFilter)
                    if (transformedForms.length > 0) {
                        setForms(transformedForms)
                    }
                    setCampaignName(result.name)
                    setCampaignNameInParent(result.name)
                    setIsFetching(false)
                } catch (e: any) {
                    setIsFetching(false)
                    showFlashMessageWithTimeout(dispatch, e.toString(), 'error')
                }
            }

            fetchCampaign()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, id])

    const addEmptyForm = () => {
        if (forms.length < 3) {
            const availableControls = getAvailableControls(forms)
            setForms((state) => {
                const result = [
                    ...state,
                    {
                        control: availableControls[0],
                        include: [],
                        excludes: [],
                    },
                ]
                return result
            })
        }
    }

    const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCampaignName(event.target.value)
    }

    const handleControlChange = (event: SelectChangeEvent, index: number) => {
        setForms((state) => {
            const copy: FormType[] = [...state]
            const formToUpdate = copy[index]
            formToUpdate.control = event.target.value as ControlType
            return copy
        })
    }

    const getValuesToHide = (forms: FormType[]): string[] => {
        let unavailableControls: string[] = []
        forms.forEach((form) => unavailableControls.push(form.control))
        return unavailableControls
    }

    const onDeleteForm = (index: number) => {
        setForms((state) => {
            const copy: FormType[] = [...state]
            copy.splice(index, 1)
            return copy
        })
    }

    const onAddIncludeChip = (formIndex: number, values: string[]) => {
        setForms((state) => {
            const copy: FormType[] = [...state]
            copy[formIndex].include = values
            return copy
        })
    }

    const onAddExcludeChip = (formIndex: number, values: string[]) => {
        setForms((state) => {
            const copy: FormType[] = [...state]
            copy[formIndex].excludes = values
            return copy
        })
    }

    const onSaveChanges = async () => {
        if (id) {
            try {
                setIsLoading(true)
                const _campaignManager = CampaignManager.getManager()
                const result = await _campaignManager.updateCampaignAsync(id, campaignName, transformFormsToUrlFilterDto(forms))
                const transformedForms = transformUrlFilterToForms(result.urlFilter)
                if (transformedForms.length === 0) {
                    setForms([{ control: 'beginsWith', include: [], excludes: [] }])
                } else {
                    setForms(transformedForms)
                }

                setIsLoading(false)
                setCampaignNameInParent(campaignName)
                showFlashMessageWithTimeout(dispatch, t('popups.saveWasSuccessful'), 'success', 2000)
            } catch (e: any) {
                setIsLoading(false)
                showFlashMessageWithTimeout(dispatch, e.toString(), 'error', 2000)
            }
        }
    }

    if (isFetching) return <CircularProgressBox />

    return (
        <Box style={{ flex: 1 }}>
            <div style={{ marginTop: 12 }}>
                <HelpButton link='https://www.overenyweb.cz/napoveda/zacnete-zobrazovat-notifikace/spustte-prvni-kampan/' />
            </div>
            <Box className={classes.nameTextfield}>
                <CustomTextField
                    fullWidth
                    label={t('campaign.settings.campaignName') ?? ''}
                    value={campaignName}
                    onChange={handleNameChange}
                />
            </Box>
            <Box style={{ marginBottom: 16 }}>
                <Typography className={classes.title}>{t('campaign.settings.whereWillCampaignBeShown')}</Typography>
            </Box>
            {forms.map((form, index) => {
                return (
                    <Box key={`dropdown-key-${index}`} className={classes.formContainer}>
                        <Box className={classes.urlContainer}>
                            <Box style={{ width: 280, marginBottom: 40 }}>
                                <CustomDropdown
                                    label='URL'
                                    id={`${index}-form-dropdown`}
                                    value={form.control}
                                    items={menuItems.map((item) => {
                                        return {
                                            value: item.value,
                                            title:
                                                item.value === 'beginsWith'
                                                    ? t('campaign.settings.url.startsWith')
                                                    : item.value === 'contains'
                                                    ? t('campaign.settings.url.includes')
                                                    : t('campaign.settings.url.equals') ?? '',
                                        }
                                    })}
                                    hiddenValues={getValuesToHide(forms)}
                                    onChange={(event) => handleControlChange(event, index)}
                                />
                            </Box>
                            <Box>
                                <DeleteOutlineIcon onClick={() => onDeleteForm(index)} className={classes.icon} />
                            </Box>
                        </Box>

                        <Autocomplete
                            id={`include-${index}`}
                            style={{ marginBottom: 40 }}
                            multiple
                            options={[]}
                            freeSolo
                            onChange={(event: any, value: any) => {
                                onAddIncludeChip(index, value)
                            }}
                            value={form.include || []}
                            renderTags={(value: readonly string[], getTagProps) =>
                                value.map((option: string, index: number) => (
                                    <Chip variant='outlined' label={option} {...getTagProps({ index })} />
                                ))
                            }
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant='outlined'
                                    label={t('campaign.settings.includes')}
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <Stack direction='row' spacing={1}>
                                                <CustomButton
                                                    size='micro'
                                                    style={{ width: 40 }}
                                                    title='+'
                                                    onClick={() => {
                                                        const element = document.getElementById(`include-${index}`)
                                                        if (element) {
                                                            fireEvent.keyDown(element, { key: 'Enter' })
                                                        }
                                                    }}
                                                />
                                                {params.InputProps.endAdornment}
                                            </Stack>
                                        ),
                                    }}
                                />
                            )}
                        />
                        <Autocomplete
                            id={`excludes-${index}`}
                            multiple
                            options={[]}
                            freeSolo
                            onChange={(event: any, value: any, reason) => {
                                onAddExcludeChip(index, value)
                            }}
                            value={form.excludes || []}
                            renderTags={(value: readonly string[], getTagProps) =>
                                value.map((option: string, index: number) => (
                                    <Chip variant='outlined' label={option} {...getTagProps({ index })} />
                                ))
                            }
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant='outlined'
                                    label={t('campaign.settings.exclude')}
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <Stack direction='row' spacing={1}>
                                                <CustomButton
                                                    size='micro'
                                                    style={{ width: 40 }}
                                                    title='+'
                                                    onClick={() => {
                                                        const element = document.getElementById(`excludes-${index}`)
                                                        if (element) {
                                                            fireEvent.keyDown(element, { key: 'Enter' })
                                                        }
                                                    }}
                                                />
                                                {params.InputProps.endAdornment}
                                            </Stack>
                                        ),
                                    }}
                                />
                            )}
                        />
                    </Box>
                )
            })}
            {forms.length < 3 && (
                <CustomButton
                    size='small'
                    style={{ width: 180 }}
                    type='secondary'
                    title={t('campaign.settings.addCondition')}
                    onClick={addEmptyForm}
                />
            )}
            <Box
                style={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'row-reverse',
                    marginBottom: 24,
                    marginTop: 16,
                    maxWidth: 883,
                }}
            >
                <CustomButton
                    size='medium'
                    style={{ width: 180 }}
                    type='primary'
                    title={t('saveChanges')}
                    isLoading={isLoading}
                    onClick={onSaveChanges}
                />
            </Box>
        </Box>
    )
}

const useStyles = makeStyles((theme: Theme) => ({
    nameTextfield: {
        width: 400,
        marginTop: 15,
        marginBottom: 24,
    },
    title: {
        color: theme.palette.natural.t,
        fontSize: 18,
        fontWeight: 'bold',
    },
    formContainer: {
        flex: 1,
        padding: 24,
        backgroundColor: theme.palette.natural.t8,
        marginBottom: 16,
        borderRadius: 8,
        maxWidth: 835,
    },
    urlContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    icon: {
        color: theme.palette.main.alert.o,
        fontSize: 24,
        cursor: 'pointer',
    },
}))

export default CampaignSettings

import { Box, CircularProgress, Typography, useTheme, Theme } from '@mui/material'
import { FC, useEffect, useState } from 'react'
import { NotificationSettingsDto } from '../../../shared/types/notificationSettingsTypes'
import NotificationsTable from '../../Notifications/NotificationsTable'
import { NotificationManager } from '../../../services/api/NotificationManager'
import { useDispatch } from 'react-redux'
import { showFlashMessageWithTimeout } from '../../../services/actions/flashMessage-actions'
import { useNavigate, useParams } from 'react-router-dom'
import { makeStyles } from '@mui/styles'
import AddNotification from '../../../assets/images/addNotification.png'
import CircularProgressBox from '../../../components/CircularProgressBox'
import { Routes } from '../../../navigation/navigation-types'
import DeleteModal from '../../../components/DeleteModal'
import HelpButton from '../../../components/HelpButton'
import { useTranslation } from 'react-i18next'

const CampaignNotifications: FC = () => {
    const dispatch = useDispatch()
    const { id } = useParams()
    const { t } = useTranslation()
    const classes = useStyles()
    const theme = useTheme()
    const navigate = useNavigate()

    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [isDeleteLoading, setIsDeleteLoading] = useState<boolean>(false)
    const [isTableDisabled, setIsTableDisabled] = useState<boolean>(false)
    const [idOfNotificationToDelete, setIdOfNotificationToDelete] = useState<string | undefined>(undefined)
    const [notifications, setNotifications] = useState<NotificationSettingsDto[]>([])

    const fetchCampaignNotifications = async () => {
        if (id) {
            try {
                setIsLoading(true)
                const _notificationManager = NotificationManager.getManager()
                const notificationsResponse = await _notificationManager.getNotificationSettingsListAsync(id)
                setNotifications(notificationsResponse.notificationSettings)
                setIsLoading(false)
            } catch (e: any) {
                setIsLoading(false)
                showFlashMessageWithTimeout(dispatch, e.toString(), 'error')
            }
        }
    }

    useEffect(() => {
        fetchCampaignNotifications()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onStatusChange = async (id: string) => {
        setIsTableDisabled(true)
        const copy: NotificationSettingsDto[] = [...notifications]
        let isActive = false
        copy.forEach((notification) => {
            if (notification.id === id) {
                notification.isActive = !notification.isActive
                isActive = notification.isActive
            }
        })
        try {
            const _notificationManager = NotificationManager.getManager()
            await _notificationManager.updateNotificationSettingsStateAsync(id, isActive)
            setNotifications(copy)
            setIsTableDisabled(false)
        } catch (e: any) {
            setIsTableDisabled(false)
            setNotifications(notifications)
            showFlashMessageWithTimeout(dispatch, e.toString(), 'error')
        }
    }

    const onDelete = async (notificationId: string) => {
        if (id) {
            try {
                setIsTableDisabled(true)
                setIsDeleteLoading(true)
                const _notificationManager = NotificationManager.getManager()
                const result = await _notificationManager.deleteNotificationSettings(notificationId)
                if (result) {
                    showFlashMessageWithTimeout(dispatch, t('popups.deletionWasSuccessful'), 'success')
                    fetchCampaignNotifications()
                }
                setIsTableDisabled(false)
                setIsDeleteLoading(false)
                setIdOfNotificationToDelete(undefined)
            } catch (e: any) {
                setIsTableDisabled(false)
                setIsDeleteLoading(false)
                showFlashMessageWithTimeout(dispatch, e.toString(), 'error')
            }
        }
    }

    if (isLoading) return <CircularProgressBox />
    return (
        <Box style={{ flex: 1, overflow: 'hidden', whiteSpace: 'nowrap' }}>
            {isLoading && (
                <Box className={classes.loadingContainer}>
                    <CircularProgress style={{ color: theme.palette.main.primary.o }} />
                </Box>
            )}
            {!isLoading && notifications.length > 0 && (
                <Box
                    style={{
                        marginRight: 78,
                        marginTop: 24,
                        marginLeft: 24,
                    }}
                >
                    <div style={{ marginBottom: theme.spacing(3) }}>
                        <HelpButton link='https://www.overenyweb.cz/napoveda/nastaveni-notifikaci/' />
                    </div>
                    <NotificationsTable
                        notifications={notifications}
                        isDisabled={isTableDisabled}
                        onStatusChange={onStatusChange}
                        onDelete={(id) => setIdOfNotificationToDelete(id)}
                        onNotificationSelect={(notificationId) => {
                            navigate(`${Routes.campaign}/${id}${Routes.notification}/${notificationId}`)
                        }}
                    />
                </Box>
            )}
            {!isLoading && notifications.length === 0 && (
                <Box className={classes.imgContainer}>
                    <img src={AddNotification} alt='add-notification' width={252} height={199} />
                    <Typography className={classes.firstText}>{t('campaign.notifications.noNotifications')}</Typography>
                    <Typography
                        className={classes.secondText}
                        onClick={() => navigate(`${Routes.campaign}/${id}${Routes.notification}`)}
                    >
                        {t('campaign.notifications.addNewNotification')}
                    </Typography>
                </Box>
            )}
            {idOfNotificationToDelete && (
                <DeleteModal
                    primaryText={t('campaign.notifications.deleteModal.title') ?? ''}
                    secondaryText={t('campaign.notifications.deleteModal.description') ?? ''}
                    onDelete={() => onDelete(idOfNotificationToDelete)}
                    isLoading={isDeleteLoading}
                    isOpen={!!idOfNotificationToDelete}
                    onClose={() => setIdOfNotificationToDelete(undefined)}
                />
            )}
        </Box>
    )
}

const useStyles = makeStyles((theme: Theme) => ({
    loadingContainer: {
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 32,
    },
    imgContainer: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: 88,
    },
    firstText: {
        marginTop: 32,
        fontSize: 16,
        color: theme.palette.natural.t1,
    },
    secondText: {
        marginTop: 16,
        fontSize: 16,
        color: theme.palette.main.primary.o,
        cursor: 'pointer',
    },
}))

export default CampaignNotifications

import { Box, Typography, Theme } from '@mui/material'
import { useEffect, useState } from 'react'
import { UserManager } from '../../services/api/UserManager'
import { useNavigate } from 'react-router-dom'
import { Routes } from '../../navigation/navigation-types'
import { useDispatch, useSelector } from 'react-redux'
import { removeUserInfo, setUserInfo } from '../../services/actions/user-actions'
import PasswordField from '../../components/PasswordField'
import CustomTextField from '../../components/CustomTextField'
import CustomButton from '../../components/CustomButton'
import { makeStyles } from '@mui/styles'
import { WebsiteManager } from '../../services/api/WebsiteManager'
import { clearWebs } from '../../services/actions/web-actions'
import LoginSvg from '../../assets/images/login.svg'
import { showFlashMessageWithTimeout } from '../../services/actions/flashMessage-actions'
import ResetPasswordModal from '../../components/ResetPasswordModal'
import { GlobalVars } from '../../global'
import { getAuthenticationStatus } from '../../services/selectors/user-selectors'
import { getUserWebs } from '../../services/selectors/web-selectors'
import { AUTH_LOCAL_STORAGE_KEY, parseJwtEncodedTokenAndGetReduxState } from '../../services/types/auth-types'
import { setAuthState } from '../../services/actions/auth-actions'
import { useTranslation } from 'react-i18next'

const MARGIN_HIGHER = 45

const LoginForm: React.FC = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const classes = useStyles()
    const { t } = useTranslation()

    const [email, setEmail] = useState<string>('')
    const [password, setPassword] = useState<string>('')
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [isEmailValid, setIsEmailValid] = useState<boolean>(false)
    const [isResetPasswordOpen, setIsResetPasswordOpen] = useState<boolean>(false)
    const [screenWidth, setScreenWidth] = useState<number>(window.innerWidth)
    const isLoggedIn = useSelector(getAuthenticationStatus)
    const userWebs = useSelector(getUserWebs)

    const isMobile = screenWidth <= GlobalVars.MOBILE_SCREEN_WIDTH

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange)
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange)
        }
    }, [screenWidth])

    useEffect(() => {
        document.addEventListener('keydown', handleEnterPress)
        return () => {
            document.removeEventListener('keydown', handleEnterPress)
        }
    })

    useEffect(() => {
        if (isLoggedIn) {
            if (userWebs.length > 0) navigate(Routes.campaign)
            else navigate(Routes.onboarding)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = event.target.value,
            pattern = new RegExp(
                /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
            )

        if (pattern.test(newValue)) {
            setIsEmailValid(true)
        } else {
            setIsEmailValid(false)
        }

        setEmail(newValue)
    }

    const handleWindowSizeChange = () => {
        setScreenWidth(window.innerWidth)
    }

    const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(event.target.value)
    }

    const handleEnterPress = (e: KeyboardEvent) => {
        if (e.key === 'Enter') {
            onLoginSubmit()
        }
    }

    const onLoginSubmit = async () => {
        if (email.length === 0 || password.length === 0) {
            return
        }
        setIsLoading(true)
        try {
            dispatch(clearWebs())
            dispatch(removeUserInfo())
            const userManager = UserManager.getManager()
            const websiteManager = WebsiteManager.getManager()
            const loggedUserInfo = await userManager.loginAsync(email, password)
            // Object "user" has all props from "loggedUserInfo" object, except "token" prop
            const { token, ...user } = loggedUserInfo
            if (user) dispatch(setUserInfo(user))
            localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, token)

            const state = parseJwtEncodedTokenAndGetReduxState(token)
            dispatch(setAuthState(state))
            const result = await websiteManager.getWebsitesForUserAsync(state.parsedToken.userId)
            if (result.websites.length > 0) navigate(Routes.campaign)
            else navigate(Routes.onboarding)
        } catch (e: any) {
            if (e.toString().includes('not found in DB')) {
                showFlashMessageWithTimeout(dispatch, 'Uživatel s tímto emailem neexistuje', 'error')
            } else {
                showFlashMessageWithTimeout(dispatch, e.toString(), 'error')
            }
            setIsLoading(false)
        }
    }

    return (
        <Box style={{ display: 'flex', flexDirection: 'row' }}>
            {!isMobile && (
                <Box className={classes.imgContainer}>
                    <img src={LoginSvg} alt='login' height={130} width={198} />
                </Box>
            )}
            <Box className={classes.container} style={{ marginLeft: isMobile ? 56 : 0 }}>
                <Typography className={classes.title}>{t('loginScreen.title') ?? ''}</Typography>
                <CustomTextField
                    label={t('loginScreen.email') ?? ''}
                    name='email'
                    fullWidth
                    value={email}
                    onChange={handleEmailChange}
                    style={{ marginBottom: MARGIN_HIGHER }}
                    isRequired
                />
                <Box style={{ marginBottom: MARGIN_HIGHER }}>
                    <PasswordField
                        label={t('loginScreen.password') ?? ''}
                        value={password}
                        onChange={handlePasswordChange}
                        fullWidth
                        isRequired
                    />
                </Box>
                <CustomButton
                    title={t('loginScreen.button') ?? ''}
                    onClick={onLoginSubmit}
                    isLoading={isLoading}
                    style={{ marginBottom: 16 }}
                    isDisabled={!isEmailValid || email.length === 0 || password.length === 0}
                />
                <Box className={classes.textContainer}>
                    <Typography className={classes.text}>{t('loginScreen.newUsers') ?? ''}</Typography>
                    <Typography className={classes.textUnderline} onClick={() => navigate(Routes.registration)}>
                        {t('loginScreen.signUp') ?? ''}
                    </Typography>
                </Box>
                <Box className={classes.textContainer} style={{ marginBottom: 40, marginTop: 8 }}>
                    <Typography className={classes.text}>{t('loginScreen.forgotPassword') ?? ''}</Typography>
                    <Typography className={classes.textUnderline} onClick={() => setIsResetPasswordOpen(true)}>
                        {t('loginScreen.resetPassword') ?? ''}
                    </Typography>
                </Box>
            </Box>
            <ResetPasswordModal isOpen={isResetPasswordOpen} onClose={() => setIsResetPasswordOpen(false)} />
        </Box>
    )
}

const useStyles = makeStyles((theme: Theme) => ({
    textContainer: {
        display: 'flex',
    },
    title: {
        fontSize: 28,
        fontWeight: 'bold',
        marginTop: 40,
        color: theme.palette.main.secondary.u1,
        marginBottom: 45,
    },
    text: {
        marginRight: 4,
        fontSize: 14,
        color: theme.palette.natural.t1,
    },
    textUnderline: {
        fontWeight: 'bold',
        color: theme.palette.main.secondary.u1,
        cursor: 'pointer',
        fontSize: 14,
    },
    imgContainer: {
        height: 465,
        width: 400,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        marginRight: 56,
    },
}))

export default LoginForm

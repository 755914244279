import { UserDto } from '../../shared/types/userTypes'
import { RemoveAdminInfo, RemoveUserInfo, SetAdminInfo, SetUserInfo, UserActionTypes } from '../types/user-types'

export const setUserInfo = (user: UserDto): SetUserInfo => ({
    type: UserActionTypes.SET_USER_INFO,
    payload: {
        id: user.id,
        createdAt: user.createdAt,
        lastModifiedAt: user.lastModifiedAt,
        email: user.email,
        name: user.name ? user.name : '',
        isAdmin: user.isAdmin,
        accountType: user.accountType,
        remainingPricingPlanCredits: user.remainingPricingPlanCredits,
        remainingExtraCredits: user.remainingExtraCredits,
        isInTrial: user.isInTrial ?? false,
        pricingPlanName: user.pricingPlanName,
        lastPricingPlanName: user.lastPricingPlanName,
        lastPricingPlanPeriodEndsAt: user.lastPricingPlanPeriodEndsAt,
        lastCreditsChargedAt: user.lastCreditsChargedAt,
        nextCreditsChargedAt: user.nextCreditsChargedAt,
        hasUnlimitedAccess: user.hasUnlimitedAccess,
        language: user.language,
        registeredFrom: user.registeredFrom,
    },
})

export const removeUserInfo = (): RemoveUserInfo => ({
    type: UserActionTypes.REMOVE_USER_INFO,
})

export const setAdminInfo = (user: UserDto): SetAdminInfo => ({
    type: UserActionTypes.SET_ADMIN_INFO,
    payload: {
        id: user.id,
        createdAt: user.createdAt,
        lastModifiedAt: user.lastModifiedAt,
        email: user.email,
        name: user.name ? user.name : '',
        isAdmin: user.isAdmin,
        accountType: user.accountType,
        remainingPricingPlanCredits: user.remainingPricingPlanCredits,
        remainingExtraCredits: user.remainingExtraCredits,
        isInTrial: user.isInTrial ?? false,
        pricingPlanName: user.pricingPlanName,
        lastPricingPlanName: user.lastPricingPlanName,
        lastPricingPlanPeriodEndsAt: user.lastPricingPlanPeriodEndsAt,
        lastCreditsChargedAt: user.lastCreditsChargedAt,
        nextCreditsChargedAt: user.nextCreditsChargedAt,
        hasUnlimitedAccess: user.hasUnlimitedAccess,
        language: user.language,
        registeredFrom: user.registeredFrom,
    },
})

export const removeAdminInfo = (): RemoveAdminInfo => ({
    type: UserActionTypes.REMOVE_ADMIN_INFO,
})

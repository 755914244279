import { FC, HTMLInputTypeAttribute } from 'react'
import { TextField } from '@mui/material'
import { makeStyles } from '@mui/styles'

interface CustomTextFieldProps {
    value: string
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
    name?: string
    fullWidth?: boolean
    type?: HTMLInputTypeAttribute
    label?: string
    style?: React.CSSProperties
    disabled?: boolean
    multiline?: boolean
    maxRows?: number
    rows?: number
    isRequired?: boolean
    placeholder?: string
}

const useStyles = makeStyles({
    input: {
        height: 44,
    },
    label: {
        marginTop: -5,
        '&.Mui-focused': {
            marginTop: 0,
        },
        '&.Mui-disabled': {
            marginTop: 0,
        },
    },
})

const CustomTextField: FC<CustomTextFieldProps> = ({
    value,
    onChange,
    name,
    style,
    label,
    fullWidth,
    type,
    disabled,
    multiline,
    maxRows,
    rows,
    isRequired,
    placeholder,
}) => {
    const classes = useStyles()
    return (
        <TextField
            InputProps={{
                className: classes.input,
            }}
            style={{ ...style, height: 44 }}
            fullWidth={fullWidth}
            label={label}
            type={type}
            variant='outlined'
            InputLabelProps={{
                className: value.length === 0 ? classes.label : undefined,
            }}
            value={value}
            name={name}
            onChange={onChange}
            disabled={disabled}
            multiline={multiline}
            maxRows={maxRows}
            rows={rows}
            required={isRequired}
            placeholder={placeholder}
        />
    )
}

export default CustomTextField

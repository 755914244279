import { createTheme } from '@mui/material/styles'

export const theme = createTheme({
    palette: {
        main: {
            primary: {
                u2: '#1F287B',
                u1: '#4A4EBA',
                o: '#7075F3',
                d1: '#9A9DFF',
                d2: '#D0D2FF',
                d3: '#F1F2FF',
            },
            secondary: {
                u2: '#C86117',
                u1: '#EF9F42',
                o: '#FFB35A',
                d1: '#FFCF96',
                d2: '#FDE1B7',
                d3: '#FFF4E4',
            },
            accent: {
                u2: '#07555A',
                u1: '#1B8188',
                o: '#54C6BE',
                d1: '#91E1D3',
                d2: '#C4FCF2',
                d3: '#F1FFFD',
            },
            alert: {
                u2: '#931020',
                u1: '#CF1B1B',
                o: '#EE3B3B',
                d1: '#F9675D',
                d2: '#FFA89C',
                d3: '#FFE8E3',
            },
            success: {
                u2: '#1F287B',
                u1: '#4A4EBA',
                o: '#7075F3',
                d1: '#9A9DFF',
                d2: '#D0D2FF',
                d3: '#F1F2FF',
            },
            gradient: {
                primary: {
                    p1: 'linear-gradient(89.91deg, #1843B3 -81.08%, #3054D2 30.57%, #8A78FA 149.77%)',
                    p2: 'linear-gradient(89.91deg, #3054D2 -81.08%, #7075F3 30.57%, #8A78FB 149.77%)',
                    p3: 'linear-gradient(89.91deg, #7075F3 -81.08%, #8A96FF 22.32%, #BD95FF 149.77%)',
                },
            },
            shadow: {
                primary: '0px 2px 8px rgba(65, 32, 158, 0.04), 0px 16px 24px rgba(136, 100, 211, 0.16)',
                secondary: '0px 2px 8px rgba(65, 32, 158, 0.08), 0px 20px 32px rgba(136, 100, 211, 0.24)',
            },
        },
        natural: {
            t: '#28293D',
            t1: '#555770',
            t2: '#86879D',
            t3: '#C5C7D9',
            t4: '#FFFFFF',
            t5: '#000000',
            t6: '#FCFCFD',
            t7: '#FBFAFC',
            t8: '#F7F7FB',
            t9: '#FFF9F1',
            t10: '#F5F5F8',
            t11: '#DCDCDC',
        },
    },
})

import { Box, SelectChangeEvent, Typography, useTheme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { FC, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CustomButton from '../../components/CustomButton'
import CustomDropdown from '../../components/CustomDropdown'
import CustomTextField from '../../components/CustomTextField'
import { showFlashMessageWithTimeout } from '../../services/actions/flashMessage-actions'
import { setActiveWeb } from '../../services/actions/web-actions'
import { WebsiteManager } from '../../services/api/WebsiteManager'
import { getUserInfo } from '../../services/selectors/user-selectors'
import { CreateWebsiteResponse } from '../../shared/payloads/websitePayloads'
import { Language } from '../../shared/types/websiteTypes'
import { getUserWebs } from '../../services/selectors/web-selectors'
import { getUserWebsNames } from '../../utils/web-utils'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import { useTranslation } from 'react-i18next'

interface AddWebProps {
    goToNext: () => void
    goToPrevious: () => void
    setCreatedWebId: (id: string) => void
}

const AddWeb: FC<AddWebProps> = ({ goToNext, goToPrevious, setCreatedWebId }) => {
    const userInfo = useSelector(getUserInfo)
    const classes = useStyles()
    const dispatch = useDispatch()
    const theme = useTheme()
    const userWebs = useSelector(getUserWebs)
    const { t } = useTranslation()

    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [webName, setWebName] = useState<string>('')
    const [webUrl, setWebUrl] = useState<string>('')
    const [selectedLanguage, setSelectedLanguage] = useState<Language>('cs')

    const handleWebNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setWebName(event.target.value)
    }

    const handleWebUrlChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setWebUrl(event.target.value)
    }

    const onLanguageChange = (event: SelectChangeEvent) => {
        setSelectedLanguage(event.target.value as Language)
    }

    const addWebAndRedirect = async () => {
        if (userInfo.id && webName && webUrl && selectedLanguage) {
            try {
                setIsLoading(true)
                const userWebsNames = getUserWebsNames(userWebs)
                if (userWebsNames.includes(webName)) {
                    showFlashMessageWithTimeout(dispatch, 'Web s tímto názvem je již obsazen', 'error')
                    setIsLoading(false)
                    return
                }
                const _websiteManager = WebsiteManager.getManager()
                const result: CreateWebsiteResponse = await _websiteManager.createWebsiteAsync(
                    userInfo.id,
                    webName,
                    webUrl,
                    selectedLanguage
                )
                setCreatedWebId(result.id)
                dispatch(setActiveWeb(result.url, result.id))
                setIsLoading(false)
                goToNext()
            } catch (e: any) {
                setIsLoading(false)
                showFlashMessageWithTimeout(dispatch, e.toString(), 'error')
            }
        } else {
            showFlashMessageWithTimeout(dispatch, 'Vyplňte všechna pole', 'info')
        }
    }

    return (
        <Box className={classes.container}>
            <Box className={classes.formContainer}>
                <Typography
                    textAlign='center'
                    style={{
                        color: theme.palette.main.secondary.u1,
                        fontSize: 28,
                        fontWeight: 'bold',
                    }}
                >
                    {t('addWebsite.title')}
                </Typography>

                <CustomTextField
                    style={{ marginTop: 49 }}
                    fullWidth
                    label={t('addWebsite.name') ?? ''}
                    value={webName}
                    onChange={handleWebNameChange}
                />
                <Box display='flex' flexDirection='row' alignItems='center'>
                    <ErrorOutlineIcon style={{ fontSize: 20, marginRight: 10, color: theme.palette.main.secondary.u1 }} />
                    <Typography style={{ color: theme.palette.natural.t1, fontSize: 14, marginTop: 23, marginBottom: 23 }}>
                        {t('addWebsite.warning')}
                    </Typography>
                </Box>
                <CustomTextField
                    style={{ marginBottom: 46 }}
                    fullWidth
                    label={t('addWebsite.url') ?? ''}
                    value={webUrl}
                    onChange={handleWebUrlChange}
                />

                <CustomDropdown
                    id='language-dropdown'
                    label={t('addWebsite.notificationLanguage') ?? ''}
                    items={[
                        { title: t('contact.address.slovakia'), value: 'sk' },
                        { title: t('contact.address.czechRepublic'), value: 'cs' },
                        { title: t('contact.address.polish'), value: 'pl' },
                    ]}
                    onChange={onLanguageChange}
                    value={selectedLanguage}
                />
                <Box className={classes.buttonContainer}>
                    <CustomButton
                        isLoading={isLoading}
                        title={t('continue') ?? ''}
                        onClick={addWebAndRedirect}
                        style={{ width: 120, marginLeft: 20 }}
                        isDisabled={webName.length === 0 || webUrl.length === 0}
                    />
                    <CustomButton type='secondary' title={t('back') ?? ''} onClick={goToPrevious} style={{ width: 100 }} />
                </Box>
            </Box>
        </Box>
    )
}

const useStyles = makeStyles(() => ({
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    formContainer: {
        width: 408,
        display: 'flex',
        flexDirection: 'column',
        marginTop: 160,
    },
    buttonContainer: {
        height: 52,
        flex: 1,
        display: 'flex',
        flexDirection: 'row-reverse',
        marginTop: 24,
    },
}))

export default AddWeb

import { FormType } from './components/types'
import { UrlFilterDto } from './shared/types/commonTypes'
import DOMPurify from 'dompurify'
import { GlobalVars } from './global'
import { TFunction } from 'i18next'
import { Language } from './shared/types/websiteTypes'

export const transformFormsToUrlFilterDto = (forms: FormType[]): UrlFilterDto => {
    let result: UrlFilterDto = {}
    forms.forEach((form) => {
        if (form.control === 'beginsWith') {
            result.includeUrlBeginsWith = form.include.length > 0 ? form.include : undefined
            result.excludeUrlBeginsWith = form.excludes.length > 0 ? form.excludes : undefined
        }
        if (form.control === 'contains') {
            result.includeUrlContains = form.include.length > 0 ? form.include : undefined
            result.excludeUrlContains = form.excludes.length > 0 ? form.excludes : undefined
        }
        if (form.control === 'isEqual') {
            result.includeUrlIsEqual = form.include.length > 0 ? form.include : undefined
            result.excludeUrlIsEqual = form.excludes.length > 0 ? form.excludes : undefined
        }
    })
    return result
}

export const transformUrlFilterToForms = (urlFilter: UrlFilterDto): FormType[] => {
    const result: FormType[] = []
    if (urlFilter.includeUrlBeginsWith || urlFilter.excludeUrlBeginsWith) {
        result.push({
            control: 'beginsWith',
            include: urlFilter.includeUrlBeginsWith ? urlFilter.includeUrlBeginsWith : [],
            excludes: urlFilter.excludeUrlBeginsWith ? urlFilter.excludeUrlBeginsWith : [],
        })
    }
    if (urlFilter.includeUrlContains || urlFilter.excludeUrlContains) {
        result.push({
            control: 'contains',
            include: urlFilter.includeUrlContains ? urlFilter.includeUrlContains : [],
            excludes: urlFilter.excludeUrlContains ? urlFilter.excludeUrlContains : [],
        })
    }
    if (urlFilter.includeUrlIsEqual || urlFilter.includeUrlIsEqual) {
        result.push({
            control: 'isEqual',
            include: urlFilter.includeUrlIsEqual ? urlFilter.includeUrlIsEqual : [],
            excludes: urlFilter.excludeUrlIsEqual ? urlFilter.excludeUrlIsEqual : [],
        })
    }
    return result
}

const getPureString = (value: string | Node) => DOMPurify.sanitize(value, { USE_PROFILES: { html: true, svg: true } })

export const emptyNotificationSettings = {
    campaign: {
        notificationVisualSettings: {
            roundness: 0,
        },
    },
    settings: {
        notificationVisualSettings: {
            backgroundColor: '',
            textColor: '',
            iconBackgroundColor: '',
            iconColor: '',
            icon: '',
            roundness: 0,
            linkTextColor: '',
            linkButtonBackgroundColor: '',
        },
    },
}

export const generateNotificationPreviews = async (
    t: TFunction<'translation', undefined, 'translation'>,
    languageString: string
) => {
    const language = convertStringToLanguage(languageString)

    let result = {
        lastOrders: '',
        lastRegistrations: '',
        actualVisitors: '',
        visitorSummary: '',
        registrationSummary: '',
        orderSummary: '',
        heureka: '',
        zboziCompanyRating: '',
        zboziProductRating: '',
        individual: '',
        shoptetLastOrders: '',
        shoptetCouponCode: '',
        spolehlivaRecenzeShopRating: '',
        spolehlivaRecenzeProductRating: '',
    }

    const lastOrders = await window.getNotificationPreview(
        emptyNotificationSettings,
        'lastOrders',
        t('campaign.notifications.lastOrders.previewText1') ?? '',
        t('campaign.notifications.lastOrders.previewText2') ?? '',
        undefined,
        language
    )

    const lastRegistrations = await window.getNotificationPreview(
        emptyNotificationSettings,
        'lastRegistrations',
        t('campaign.notifications.lastRegistrations.previewText1') ?? '',
        t('campaign.notifications.lastRegistrations.previewText2') ?? '',
        undefined,
        language
    )

    const actualVisitors = await window.getNotificationPreview(
        emptyNotificationSettings,
        'actualVisitors',
        undefined,
        t('campaign.notifications.actualVisitors.previewText1') ?? '',
        10,
        language
    )

    const visitorSummary = await window.getNotificationPreview(
        emptyNotificationSettings,
        'visitorSummary',
        undefined,
        t('campaign.notifications.visitorSummary.previewText1') ?? '',
        undefined,
        language,
        4,
        10
    )

    const registrationSummary = await window.getNotificationPreview(
        emptyNotificationSettings,
        'registrationSummary',
        undefined,
        t('campaign.notifications.visitorSummary.previewText2') ?? '',
        undefined,
        language,
        3,
        10
    )

    const orderSummary = await window.getNotificationPreview(
        emptyNotificationSettings,
        'orderSummary',
        undefined,
        t('campaign.notifications.visitorSummary.previewText2') ?? '',
        undefined,
        language,
        2,
        10
    )

    const heureka = await window.getNotificationPreview(
        emptyNotificationSettings,
        'heureka',
        undefined,
        undefined,
        undefined,
        language,
        undefined,
        undefined,
        t('campaign.notifications.heureka.previewText1') ?? '',
        4 * 20
    )

    const zboziCompanyRating = await window.getNotificationPreview(
        emptyNotificationSettings,
        'zboziCompanyRating',
        undefined,
        undefined,
        undefined,
        language,
        undefined,
        undefined,
        t('campaign.notifications.zbozi.previewText1') ?? '',
        4 * 20
    )

    const zboziProductRating = await window.getNotificationPreview(
        emptyNotificationSettings,
        'zboziProductRating',
        undefined,
        undefined,
        undefined,
        language,
        undefined,
        undefined,
        t('campaign.notifications.zbozi.previewText1') ?? '',
        4 * 20
    )

    const spolehlivaRecenzeShopRating = await window.getNotificationPreview(
        emptyNotificationSettings,
        'spolehlivaRecenzeShopRating',
        undefined,
        undefined,
        undefined,
        language,
        undefined,
        undefined,
        t('campaign.notifications.zbozi.previewText1') ?? '',
        4
    )

    const spolehlivaRecenzeProductRating = await window.getNotificationPreview(
        emptyNotificationSettings,
        'spolehlivaRecenzeProductRating',
        undefined,
        undefined,
        undefined,
        language,
        undefined,
        undefined,
        t('campaign.notifications.zbozi.previewText1') ?? '',
        4
    )

    const individual = await window.getNotificationPreview(
        emptyNotificationSettings,
        'individual',
        undefined,
        t('campaign.notifications.individual.previewText1') ?? '',
        undefined,
        language,
        undefined,
        undefined,
        undefined,
        undefined,
        true,
        t('campaign.notifications.individual.previewText2') ?? '',
        false
    )

    const shoptetLastOrders = await window.getNotificationPreview(
        emptyNotificationSettings,
        'shoptetLastOrders',
        t('campaign.notifications.lastOrders.previewText1') ?? '',
        t('campaign.notifications.shoptetLastOrders.screwdriver') ?? '',
        undefined,
        language,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        'https://creazilla-store.fra1.digitaloceanspaces.com/cliparts/3164156/screwdriver-clipart-md.png'
    )

    const shoptetCouponCode = await window.getNotificationPreview(
        emptyNotificationSettings,
        'shoptetCouponCode',
        undefined,
        undefined,
        undefined,
        language,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        t('campaign.notifications.shoptetCouponCode.sale10') ?? '',
        10,
        '%',
        t('campaign.notifications.shoptetCouponCode.wholeSale') ?? ''
    )

    result.lastOrders = getPureString(lastOrders)
    result.lastRegistrations = getPureString(lastRegistrations)
    result.actualVisitors = getPureString(actualVisitors)
    result.visitorSummary = getPureString(visitorSummary)
    result.registrationSummary = getPureString(registrationSummary)
    result.orderSummary = getPureString(orderSummary)
    result.heureka = getPureString(heureka)
    result.zboziCompanyRating = getPureString(zboziCompanyRating)
    result.zboziProductRating = getPureString(zboziProductRating)
    result.individual = getPureString(individual)
    result.shoptetLastOrders = getPureString(shoptetLastOrders)
    result.shoptetCouponCode = getPureString(shoptetCouponCode)
    result.spolehlivaRecenzeShopRating = getPureString(spolehlivaRecenzeShopRating)
    result.spolehlivaRecenzeProductRating = getPureString(spolehlivaRecenzeProductRating)

    return result
}

export const getScriptString = (websiteId: string) =>
    `<script type="text/javascript" website-id="${websiteId}" backend-url="${GlobalVars.SERVER_ENDPOINT}" id="notify-script" src="${GlobalVars.SERVER_ENDPOINT}/pixel/script"></script>`

export const stringIncludesSubstring = (str: string, substr: string) => {
    return str.includes(substr)
}

export const convertStringToLanguage = (language: string): Language => {
    if (language === 'cs' || language === 'sk' || language === 'hu' || language === 'pl') {
        return language
    } else {
        return 'cs'
    }
}

export type Language = 'cs' | 'sk' | 'hu' | 'pl'
export type Country = 'cz' | 'sk' | 'hu' | 'pl'

export interface WebsiteDto {
    id: string
    createdAt: Date
    lastModifiedAt: Date
    name: string
    url: string
    language: Language
    campaignCount: number

    //#region zbozi settings

    zboziName?: string
    zboziApiKey?: string
    zboziId?: string

    //#endregion

    //#region heureka settings

    heurekaName?: string
    heurekaApiKey?: string
    heurekaCountry?: Country

    //#endregion

    spolehlivaRecenzeShopId?: string
    spolehlivaRecenzeShopPassword?: string
}

export const getLanguageFromString = (language: string): Language => {
    switch (language) {
        case 'cs':
            return 'cs'
        case 'sk':
            return 'sk'
        case 'hu':
            return 'hu'
        case 'pl':
            return 'pl'
        default:
            return 'cs'
    }
}

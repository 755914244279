import { Box, Typography, Theme } from '@mui/material'
import { FC } from 'react'
import ModalBase from './ModalBase'
import CloseIcon from '@mui/icons-material/Close'
import TrashCan from '../assets/images/trashcan.png'
import CustomButton from './CustomButton'
import { makeStyles } from '@mui/styles'
import { useTranslation } from 'react-i18next'

interface DeleteWebModalProps {
    isOpen: boolean
    onClose: () => void
    isLoading: boolean
    onDelete: () => void
}

const DeleteWebModal: FC<DeleteWebModalProps> = ({ isOpen, onClose, isLoading, onDelete }) => {
    const classes = useStyles()
    const { t } = useTranslation()
    return (
        <ModalBase isOpen={isOpen} onClose={onClose}>
            <Box className={classes.container}>
                <Box className={classes.closeIconBox}>
                    <CloseIcon style={{ cursor: 'pointer' }} onClick={onClose} />
                </Box>
                <Box className={classes.imgContainer}>
                    <img style={{ marginTop: 12 }} src={TrashCan} alt='registration' width={174} height={192} />
                </Box>
                <Typography align='center' className={classes.primaryText}>
                    {t('deleteWeb.title')}
                </Typography>
                <Typography align='center' className={classes.secondaryText}>
                    {t('deleteWeb.description')}
                </Typography>

                <Box className={classes.buttonsContainer}>
                    <CustomButton
                        style={{ marginRight: 32, width: 112 }}
                        type='primary'
                        size='medium'
                        title={t('deleteWeb.delete')}
                        isDisabled={isLoading}
                        isLoading={isLoading}
                        onClick={onDelete}
                    />
                    <CustomButton
                        style={{ marginRight: 20, width: 100 }}
                        type='secondary'
                        size='medium'
                        title={t('deleteWeb.close')}
                        onClick={onClose}
                    />
                </Box>
            </Box>
        </ModalBase>
    )
}

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        flex: 1,
        width: 663,
        paddingTop: 2,
        paddingBottom: 2,
        backgroundColor: theme.palette.natural.t4,
        borderRadius: 8,
        boxShadow: theme.palette.main.shadow.secondary,
    },
    closeIconBox: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row-reverse',
        marginTop: 20,
        marginRight: 37,
    },
    imgContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    primaryText: {
        fontSize: 28,
        fontWeight: 'bold',
        color: theme.palette.main.alert.o,
        marginTop: 32,
        marginBottom: 24,
    },
    secondaryText: {
        fontSize: 16,
        color: theme.palette.natural.t,
    },
    buttonsContainer: {
        display: 'flex',
        flexDirection: 'row-reverse',
        marginTop: 32,
        marginBottom: 30,
    },
}))

export default DeleteWebModal

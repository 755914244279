import { Typography, Box, Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CustomButton from '../../components/CustomButton'
import PasswordField from '../../components/PasswordField'
import { showFlashMessageWithTimeout } from '../../services/actions/flashMessage-actions'
import { removeUserInfo } from '../../services/actions/user-actions'
import { UserManager } from '../../services/api/UserManager'
import { getUserInfo } from '../../services/selectors/user-selectors'
import { clearWebs } from '../../services/actions/web-actions'
import { useNavigate } from 'react-router-dom'
import { logoutUser } from '../../services/actions/auth-actions'
import { useTranslation } from 'react-i18next'

const ProfileConnection = () => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const user = useSelector(getUserInfo)
    const { t } = useTranslation()

    const [oldPassword, setOldPassword] = useState<string>('')
    const [newPassword, setNewPassword] = useState<string>('')
    const [secondNewPassword, setSecondNewPassword] = useState<string>('')
    const [isLoading, setIsLoading] = useState<boolean>(false)

    const handleOldPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setOldPassword(event.target.value)
    }

    const handleNewPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNewPassword(event.target.value)
    }

    const handleSecondNewPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSecondNewPassword(event.target.value)
    }

    const onPasswordUpdate = async () => {
        try {
            if (user) {
                setIsLoading(true)
                if (newPassword !== secondNewPassword) {
                    showFlashMessageWithTimeout(dispatch, 'Hesla se neshodují', 'error')
                    setIsLoading(false)
                    return
                } else {
                    const _userManager = UserManager.getManager()
                    const wasPasswordChanged = await _userManager.updateUserPasswordAsync(user.id, oldPassword, newPassword)
                    if (wasPasswordChanged) {
                        showFlashMessageWithTimeout(dispatch, 'Heslo bylo změněno, přihlaste se znovu.', 'success')
                        logoutUserAsync()
                    } else {
                        showFlashMessageWithTimeout(dispatch, 'Nepodařilo se změnit heslo', 'error')
                    }
                }
                setIsLoading(false)
            }
        } catch (e: any) {
            showFlashMessageWithTimeout(dispatch, e.toString(), 'error')
        }
    }

    const logoutUserAsync = async () => {
        try {
            dispatch(logoutUser())
            dispatch(removeUserInfo())
            dispatch(clearWebs())
            navigate('/')
        } catch (e: any) {
            showFlashMessageWithTimeout(dispatch, e.toString(), 'error')
        }
    }

    return (
        <Box style={{ flex: 1 }}>
            <Box
                style={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    marginTop: 24,
                    width: 480,
                }}
            >
                <Typography className={classes.sectionTitle}>{t('passwordChange.title')}</Typography>
                <PasswordField
                    label={t('passwordChange.oldPassword') ?? ''}
                    value={oldPassword}
                    onChange={handleOldPasswordChange}
                    fullWidth
                    isRequired
                />
                <Box style={{ height: 24 }} />
                <PasswordField
                    label={t('passwordChange.newPassword') ?? ''}
                    value={newPassword}
                    onChange={handleNewPasswordChange}
                    fullWidth
                    isRequired
                />
                <Box style={{ height: 24 }} />
                <PasswordField
                    label={t('passwordChange.confirmPassword') ?? ''}
                    value={secondNewPassword}
                    onChange={handleSecondNewPasswordChange}
                    fullWidth
                    isRequired
                />
                <Box className={classes.buttonContainer}>
                    <CustomButton
                        style={{ width: 88 }}
                        title={t('save')}
                        isLoading={isLoading}
                        size='medium'
                        onClick={onPasswordUpdate}
                    />
                </Box>
            </Box>
        </Box>
    )
}

const useStyles = makeStyles((theme: Theme) => ({
    sectionTitle: {
        fontSize: 18,
        color: theme.palette.main.secondary.u1,
        marginBottom: 24,
        fontWeight: 'bold',
    },
    buttonContainer: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row-reverse',
        maxWidth: 1008,
        marginTop: 32,
    },
}))

export default ProfileConnection

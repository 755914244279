import { Box, Typography, useTheme } from '@mui/material'
import { FC } from 'react'
import ModalBase from './ModalBase'
import CloseIcon from '@mui/icons-material/Close'
import CustomButton from './CustomButton'
import DeleteImage from '../assets/images/delete.png'
import { useTranslation } from 'react-i18next'

interface DeleteModalProps {
    isOpen: boolean
    onClose: () => void
    onDelete: () => void
    isLoading: boolean
    primaryText?: string
    secondaryText?: string
}

const DeleteModal: FC<DeleteModalProps> = ({ isOpen, onClose, onDelete, isLoading, primaryText, secondaryText }) => {
    const theme = useTheme()
    const { t } = useTranslation()
    return (
        <ModalBase center isOpen={isOpen} onClose={onClose}>
            <Box
                style={{
                    width: 663,
                    backgroundColor: '#FFFFFF',
                    borderRadius: 8,
                    boxShadow: '0px 2px 8px rgba(65, 32, 158, 0.08), 0px 20px 32px rgba(136, 100, 211, 0.24)',
                }}
            >
                <Box
                    style={{
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'row-reverse',
                        marginTop: 22,
                        marginRight: 37,
                    }}
                >
                    <CloseIcon style={{ cursor: 'pointer' }} onClick={onClose} />
                </Box>
                <Box
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <img style={{ marginTop: 12 }} src={DeleteImage} alt='registration' width={213} height={203} />
                </Box>
                <Typography
                    align='center'
                    style={{
                        fontSize: 28,
                        fontWeight: 'bold',
                        color: theme.palette.main.alert.o,
                        marginTop: 32,
                        marginBottom: 24,
                    }}
                >
                    {primaryText}
                </Typography>
                <Typography align='center' style={{ fontSize: 16, color: theme.palette.natural.t }}>
                    {secondaryText}
                </Typography>

                <Box
                    style={{
                        display: 'flex',
                        flexDirection: 'row-reverse',
                        marginTop: 32,
                        marginBottom: 32,
                    }}
                >
                    <CustomButton
                        style={{ marginRight: 32, width: 112 }}
                        type='primary'
                        size='medium'
                        title={t('delete')}
                        isDisabled={isLoading}
                        isLoading={isLoading}
                        onClick={onDelete}
                    />
                    <CustomButton
                        style={{ marginRight: 20, width: 100 }}
                        type='secondary'
                        size='medium'
                        title={t('close')}
                        onClick={onClose}
                    />
                </Box>
            </Box>
        </ModalBase>
    )
}

export default DeleteModal

import { Box, useTheme } from '@mui/material'
import { FC } from 'react'

interface ProgressIndicatorProps {
    progressInPercent: number
    width?: number
}

const ProgressIndicator: FC<ProgressIndicatorProps> = ({ progressInPercent, width }) => {
    const barWidth = width ? width : 200
    const progressWidth = (barWidth * progressInPercent) / 100
    const theme = useTheme()
    return (
        <Box style={{ width: barWidth, height: 12, backgroundColor: theme.palette.main.secondary.d2, borderRadius: 6 }}>
            <Box
                style={{ width: progressWidth, height: 12, borderRadius: 6, backgroundColor: theme.palette.main.primary.o }}
            ></Box>
        </Box>
    )
}

export default ProgressIndicator

export enum Routes {
    registration = '/registration',
    login = '/login',
    onboarding = '/onboarding',
    campaign = '/campaign',
    connections = '/connections',
    websites = '/websites',
    notification = '/notification',
    settings = '/settings',
    new = '/new',
    profile = '/profile',
    admin = '/admin',
    payment = '/payment',
    success = '/success',
    fail = '/fail',
    cancel = '/cancel',
    loggedOut = '/logged-out',
    shoptetLogin = '/shoptetLogin',
    webarealLogin = '/webarealLogin',
    upgatesLogin = '/upgatesLogin',
}

import { Box, Theme, Typography, useTheme } from '@mui/material'
import StatusChecker from '../../components/StatusChecker'
import { makeStyles } from '@mui/styles'
import { FC, useState } from 'react'
import CustomButton from '../../components/CustomButton'
import { WebsiteManager } from '../../services/api/WebsiteManager'
import { getActiveWebUrl } from '../../services/selectors/web-selectors'
import { useDispatch, useSelector } from 'react-redux'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { showFlashMessageWithTimeout } from '../../services/actions/flashMessage-actions'
import { getScriptString, stringIncludesSubstring } from '../../generic-utils'
import { useTranslation } from 'react-i18next'

interface AddPixelProps {
    goToNext: () => void
    goToPrevious: () => void
    createdWebId: string
    modalView?: boolean
    onPrimaryButtonClick?: () => void
}

const AddPixel: FC<AddPixelProps> = ({ goToNext, goToPrevious, createdWebId, modalView, onPrimaryButtonClick }) => {
    const classes = useStyles()
    const theme = useTheme()
    const dispatch = useDispatch()
    const { t } = useTranslation()

    const [isCheckLoading, setIsCheckLoading] = useState<boolean>(false)
    const [isActive, setIsActive] = useState<boolean>(false)

    const WEB_TO_CHECK = useSelector(getActiveWebUrl)
    const SCRIPT_STRING = getScriptString(createdWebId)

    const checkIfScriptAdded = async () => {
        if (WEB_TO_CHECK && createdWebId) {
            setIsCheckLoading(true)
            try {
                const _websiteManager = WebsiteManager.getManager()
                const result = await _websiteManager.getWebsiteHtmlAsync(WEB_TO_CHECK)
                if (stringIncludesSubstring(result.html, createdWebId)) {
                    setIsActive(true)
                } else {
                    setIsActive(false)
                }
                setIsCheckLoading(false)
            } catch (e: any) {
                setIsCheckLoading(false)
                showFlashMessageWithTimeout(
                    dispatch,
                    'Pixel se nepodařilo ověřit. Vložte celou URL adresu Vaší webové stránky.',
                    'error'
                )
            }
        }
    }

    return (
        <Box className={classes.container}>
            <Box className={classes.formContainer} style={{ marginTop: modalView ? 0 : 65 }}>
                <Typography textAlign='center' className={classes.title}>
                    {t('howToInstallPixel.title')}
                </Typography>
                <Box style={{ display: 'flex', flexDirection: 'row' }}>
                    <Typography className={classes.listItemNumber}>1.</Typography>
                    <Typography className={classes.listItemText}>{t('howToInstallPixel.first')}</Typography>
                </Box>

                <Box className={classes.scriptContainer}>
                    <Typography>{SCRIPT_STRING}</Typography>
                    <Box style={{ color: theme.palette.main.secondary.u1, width: 40 }}>
                        <ContentCopyIcon
                            style={{ fontSize: 26, cursor: 'pointer' }}
                            onClick={() => {
                                navigator.clipboard.writeText(SCRIPT_STRING)
                                showFlashMessageWithTimeout(dispatch, 'Script byl zkopírovan.', 'success')
                            }}
                        />
                    </Box>
                </Box>

                <Box style={{ display: 'flex', flexDirection: 'row', marginBottom: 24 }}>
                    <Typography className={classes.listItemNumber}>2.</Typography>
                    <Typography className={classes.listItemText}>{t('howToInstallPixel.second')}</Typography>
                </Box>

                <Box style={{ display: 'flex', flexDirection: 'row' }}>
                    <Typography className={classes.listItemNumber}>3.</Typography>
                    <Typography className={classes.listItemText}>{t('howToInstallPixel.third')}</Typography>
                </Box>
                <StatusChecker isActive={isActive} isLoading={isCheckLoading} onClick={checkIfScriptAdded} />
                <Box className={classes.buttonContainer}>
                    {!modalView && (
                        <>
                            <CustomButton
                                isDisabled={!isActive}
                                title={t('continue')}
                                onClick={goToNext}
                                style={{ width: 120, marginLeft: 20 }}
                            />
                            <CustomButton title={t('back')} type='secondary' onClick={goToPrevious} style={{ width: 100 }} />
                        </>
                    )}
                    {modalView && onPrimaryButtonClick && (
                        <CustomButton
                            title={t('close')}
                            type='primary'
                            size='medium'
                            onClick={onPrimaryButtonClick}
                            style={{ width: 87 }}
                        />
                    )}
                </Box>
            </Box>
        </Box>
    )
}

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    formContainer: {
        width: 600,
        display: 'flex',
        flexDirection: 'column',
    },
    buttonContainer: {
        height: 52,
        flex: 1,
        display: 'flex',
        flexDirection: 'row-reverse',
        marginTop: 30,
    },
    title: {
        color: theme.palette.main.secondary.u1,
        fontSize: 28,
        fontWeight: 'bold',
        marginBottom: 20,
    },
    scriptContainer: {
        flex: 1,
        backgroundColor: theme.palette.natural.t6,
        marginTop: 24,
        marginBottom: 24,
        padding: 12,
        border: `1px solid ${theme.palette.natural.t8}`,
        borderRadius: 4,
        display: 'flex',
    },
    listItemNumber: {
        color: theme.palette.main.secondary.o,
        marginRight: 8,
        fontSize: 16,
        fontWeight: 'bold',
    },
    listItemText: {
        fontSize: 16,
        color: theme.palette.natural.t,
    },
}))

export default AddPixel

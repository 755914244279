export const pl = {
    sidePanel: {
        campaign: 'Kampania',

        connection: 'Połączenia',

        settings: 'Ustawienia',

        infinite: 'Bez ograniczeń',
    },

    noTariffBanner: {
        title: 'Twoje konto nie ma żadnego aktywnego planu',

        description:
            'Bez aktywnego planu nie możemy wyświetlać notyfikacji na Twojej witrynie ani zwiększać jej współczynnika konwersji.',

        button1: 'Aktywuj plan',

        button2: 'na pierwsze 14 dni bezpłatnie',
    },

    apiKeys: 'Klucze API',
    addingApiKeys: 'Dodawanie kluczy API',
    optionalAddApiKey: 'Ten krok jest opcjonalny. Możesz dodać klucze API później',

    monthlyCredits: 'Punkty miesięczne',

    portableCredits: 'Punkty przenośne',

    help: 'Pomoc',

    reportProblem: 'Zgłoś błąd',

    saveChanges: 'Zapisz zmiany',

    reset: 'Reset',

    defaultSettings: 'Ustawienia domyślne',

    addKey: 'Dodaj klucz',

    login: 'Zaloguj się',

    back: 'Z powrotem',

    close: 'Zamknij',

    delete: 'Usuń',

    verified: 'Zweryfikowano',

    yes: 'Tak',

    notVerified: 'Brak weryfikacji',

    connected: 'Połączone',

    active: 'Aktywny',

    inactive: 'Nieaktywny',

    check: 'Sprawdź',

    add: 'Dodaj',

    edit: 'Modyfikuj',

    save: 'Zapisz',

    continue: 'Kontynuuj',

    userIsNotLoggedIn: 'Użytkownik nie jest zalogowany',

    deleteWebsite: 'Usuń witrynę',
    spolehliveRecenze: 'Złączony Zaufane',

    logout: 'Wyloguj się',

    paymentFailed: {
        title: ' Płatność została anulowana',
        description: 'Nie można ukończyć płatności.',
        continue: 'Kontynuować',
    },

    loggedOut: {
        title: 'Zostałeś wylogowany',

        description: 'Możesz zamknąć stronę.',

        close: 'Zamknij stronę',
    },

    passwordChange: {
        title: 'Zmiana hasła',
        oldPassword: 'Aktualne hasło',
        newPassword: 'Nowe hasło',
        confirmPassword: 'Potwierdź nowe hasło',
    },

    onBoarding: {
        title: 'Dodaj swoją pierwszą witrynę internetową',
        description:
            'Nie martw się, poprowadzimy Cię krok po kroku. Już niedługo będziesz mógł korzystać ze wszystkich korzyści jakie niesie ze sobą Zweryfikowana witryna.',
        start: 'Początek',
        logout: 'Wylogowanie',
    },

    resetPassword: {
        title: 'Do jakiego konta potrzebujesz, aby przywrócić dostęp?',
        reset: 'Resetowanie',
    },

    deleteWeb: {
        title: 'Usuń witrynę?',
        description: 'Wszystkie dane i kampanie zostaną usunięte.',
        delete: 'Usuń',
        close: 'Zamknij',
    },

    loginScreen: {
        title: 'Zaloguj sie',
        email: 'E-mail',
        password: 'Hasło',
        button: 'Zaloguj się',
        newUsers: 'Nowy użytkownik?',
        forgotPassword: 'Zapomniałeś hasła?',
        resetPassword: 'Resetowanie',
        signUp: 'Zarejestruj się',
    },
    registrationScreen: {
        title: {
            first: 'Tylko krok dzieli Cię od',
            second: 'zwiększenia współczynnika konwersji',
            third: 'Twojego e-sklepu  ',
            fourth: 'nawet o 20%!',
        },
        subTitle: 'Wypełnij poniższe informacje i dołącz do grona e-zakupów, które odniosły sukces',
        name: 'Imię',
        phone: 'Numer telefonu (opcjonalnie)',
        email: 'Email',
        password: 'Hasło',
        confirmPassword: 'Potwierdź hasło',
        accept: 'Zgadzam się z',
        terms: 'warunkami',
        button: 'Aktywuj 14 dni gratis',
        alreadyHaveAccount: 'Czy masz konto?',
        login: 'Zaloguj się',
    },
    addWebsite: {
        title: 'Dodaj witrynę internetową',
        name: 'Nazwa strony',
        warning: 'Wpisz pełny adres URL swojej witryny, łącznie z https.',
        includeHttps: 'łącznie z https',
        url: 'Adres URL witryny',
        notificationLanguage: 'Język powiadomień',
        czech: 'Čeština',
        slovak: 'Slovenština',
        polish: 'Polština',
    },
    howToInstallPixel: {
        title: 'Jak zainstalować piksel',
        first: 'Skopiuj kod Pixela.',
        second: 'Wklej kod w nagłówku swojej witryny na swojej stronie przed </head>.',
        third: 'Odwiedź swoją stronę, aby aktywować kod pikselowy. Następnie sprawdź za pomocą skanera, czy piksel jest aktywny.',
    },
    websites: {
        title: 'Pokaż wszystko',
        website: 'Witrynę',
        created: 'Utworzony',
        campaigns: 'Kampanie',
        addWebsite: 'Dodaj witrynę',
        connect: 'Łączyć',
        connected: 'Połączony z',
        yourWebsites: 'Twoje witrynę internetowe',
    },

    popups: {
        deletionWasSuccessful: 'Usunięcie zakończyło się pomyślnie.',

        saveWasSuccessful: 'Zapisano pomyślnie',

        visualChanged: 'Wygląd został ustawiony pomyślnie.',

        notificationAdded: 'Notyfikacja została dodana.',

        setButtonText: 'Ustaw tekst przycisku.',

        missingHttp: 'Url powinien rozpoczynać się od "http" lub "https".',

        addNameAndText: 'Dodaj nazwę notyfikacji i tekst.',

        textMustBeFilled: 'Tekst notyfikacji musi zostać wypełniony.',

        connectionMissing: 'Przynajmniej jedna pozycja musi być spełniona w "Parowaniu".',

        added: 'Dodano.',

        fillAllFields: 'Wypełnij wszystkie pola',

        fillApiKey: 'Wprowadź klucz API',

        notVerified: 'Nie zweryfikowano',

        changesSaved: 'Zmiany zostały zapisane pomyślnie',

        changeSubscriptionDisabled: 'Zmiana warunków abonamentu jest zabroniona',

        subscriptionChangedTo: 'Abonament zmieniono na: ',

        subscriptionCanceled: 'Rezygnacja z abonamentu była pomyślna',

        nameIsEmpty: 'Imię nie może być puste',

        cannotCreateNotification: 'Nie udało się utworzyć notyfikacji.',
    },

    campaign: {
        addFirstCampaign: 'Dodaj swoją pierwszą kampanię',

        setUpFirstCampaign: 'Skonfiguruj swoją pierwszą kampanię i zacznij zarabiać.',

        addCampaign: 'Dodaj kampanię',

        addNotification: 'Dodaj notyfikację',

        deleteCampaign: 'Czy usunąć kampanię?',

        table: {
            name: 'Imię',

            creationDate: 'Utworzona',

            notificationCount: 'Notyfikacja',

            status: 'Status',

            delete: 'Usuń',
        },

        settings: {
            title: 'Ustawienia',

            campaignName: 'Nazwa kampanii',

            newCampaign: 'Nowa kampania',

            whereWillCampaignBeShown: 'Kiedy kampania będzie wyświetlana',

            url: {
                startsWith: 'Zaczyna się od',

                includes: 'Zawiera',

                equals: 'Jest zgodny z',
            },

            includes: 'Obejmuje',

            exclude: 'Wyklucza (niewymagane)',

            addCondition: 'Dodaj warunek',
        },

        design: {
            title: 'Wygląd',

            notificationsDesign: 'Wygląd notyfikacji',

            designDescription:
                'Ustawienia dotyczą całej kampanii, wygląd konkretnej notyfikacji można zmienić w ustawieniach tej notyfikacji.',

            computer: 'Komputer',

            mobile: 'Komórka',

            position: 'Pozycja',

            downLeftCorner: 'Dolny lewy róg',

            downRightCorner: 'Dolny prawy róg',

            upLeftCorner: 'Górny lewy róg',

            upRightCorner: 'Górny prawy róg',

            down: 'U dołu',

            up: 'U góry',

            animations: 'Animacja',

            playAnimation: 'Uruchom animację',

            animationSettings: {
                showAfter: 'Notyfikacja pojawi się po',

                showDelay: 'Czas wyświetlenia',

                delayBetween: 'Przerwa między notyfikacjami',

                showSpeed: 'Prędkość wyświetlenia',

                second: 'sekund',

                seconds: 'sekundach',
            },
        },

        notifications: {
            title: 'Notyfikacja',

            noNotifications: 'Na razie nie masz żadnych notyfikacji',

            addNewNotification: 'Dodaj notyfikację',

            table: {
                type: 'Rodzaj',

                preview: 'Podgląd',

                status: 'Status',
            },

            settings: {
                displayRules: {
                    title: 'Zasady wyświetlania',

                    text: 'Tekst',

                    notificationText: 'Tekst notyfikacji',

                    variant: 'Wariant',

                    choseVariant: 'Wybierz wariant',

                    days: 'dni',

                    h: 'h',

                    show: 'Wyświetla',

                    notificationName: 'Nazwa notyfikacji',

                    nameNotification: 'Nadaj nazwę notyfikacji',

                    repeatNotification: 'Powtarzanie notyfikacji',

                    fillNotificationText: 'Wprowadź tekst notyfikacji',

                    actualVisitors: {
                        nameVisitors: 'Nadaj nazwę użytkownikom',

                        visitors: 'użytkowników',

                        maxVisitors: 'Minimalna liczba użytkowników do wyświetlenia',
                    },

                    lastOrders: {
                        maxLast: 'Maksymalnie ostatnie',

                        maxToShow: 'Minimalna liczba do wyświetlenia wynosi',

                        whenAtLeast: 'Notyfikacja tylko wtedy, gdy jest przynajmniej',

                        ordersLast: 'Zamówienia w ciągu ostatnich',

                        haveOrderedPlaceholder: 'już zamówił/a. Co z tobą?',

                        showOwnOrders: 'Pokaż użytkownikom własne zamówienia',

                        notRecoginzed: 'Nierozpoznane imiona jako "Gość"',

                        orders: 'zamówień',
                    },

                    lastRegistrations: {
                        registrations: 'rejestracji',

                        notRecoginzed: 'Nierozpoznane imiona jako "Użytkownik odwiedzający"',

                        registrationsLast: 'Rejestracja w ciągu ostatnich',

                        showOwnRegistrations: 'Pokaż użytkownikom własne rejestracje',

                        isRegistredPlaceholder: 'zakończył/a rejestrację',
                    },

                    visitorSummary: {
                        visitors: 'Użytkownicy odwiedzający',

                        registrations: 'Rejestracja',

                        orders: 'Zamówienia',

                        visitors2: 'użytkowników',

                        registrations2: 'rejestracji',

                        orders2: 'zamówień',

                        last: 'w ciągu ostatnich',

                        minNumber: 'Minimalna liczba',

                        toShow: 'do wyświetlenia',
                    },

                    individual: {
                        link: 'Link',

                        button: 'Przycisk',

                        buttonName: 'Nadaj nazwę',

                        url: 'URL',

                        buttonAsLink: 'Link jako przycisk',

                        textColor: 'Kolor tekstu',

                        openLinkInNewWindow: 'Otwór link w nowym oknie',

                        buttonColor: 'Kolor przycisku',

                        removedLinkDescription: 'Po usunięciu linku można wprowadzić dłuższy tekst notyfikacji.',
                        tooLongTextErrorMessage: 'Tekst przekracza maksymalną długość.',
                    },

                    webName: 'Nazwa witryny',

                    apiKey: 'Klucz API',

                    heureka: {
                        title: 'Propojení s Heurekou',

                        description: 'Do aktywacji tej notyfikacji wymagany jest klucz API Heureka. Czy dodać klucz API?',

                        heurekaApiKey: 'Klucz API Heureka',

                        country: 'Kraj',

                        stars: 'gwiazdkami',

                        reviewsWithRating: 'Opinie z ponad',

                        maxReviews: 'Minimalna liczba opinii do wyświetlenia',

                        howToAddApiKey: {
                            title: 'Jak dodać klucz Heureka',

                            first: 'W koncie Heureka w menu górnym wybierz opcję Administrace e-shopu (Administracja sklepu internetowego).',

                            second: 'W lewym górnym menu wybierz opcję Sprawdzone przez klientów (Sprawdzone przez klientów).',

                            third: 'Skopiuj tajny klucz i wklej go w pole klucz API.',

                            thirdHelp:
                                'Klucz jest dostępny, jeżeli masz odpowiednią ilość ocen i spełniasz warunki programu „Ověřeno zákazníky”.',

                            fourth: 'Sprawdź status',
                        },
                    },

                    zbozi: {
                        title: 'Połączyć się z Zboží.cz',

                        description: 'Do aktywacji tej notyfikacji wymagany jest klucz API Zboží.cz. Czy dodać klucz API?',

                        howToAddKey: 'Jak dodać klucz API Zboží.cz',

                        shopId: 'ID punktu',

                        storeReview: 'oceny sklepu',

                        productReview: 'oceny produkty',

                        maxReviews: 'Minimalna liczba opinii do wyświetlenia',

                        howToAddApiKey: {
                            title: 'Jak dodać klucz API Zboží.cz',

                            first: 'W koncie Zboží.cz w menu górnym wybierz zakładkę Provozovna (punkt).',

                            second: 'Skopiuj ID wybranego sklepu internetowego i wklej go w pole ID punktu.',

                            third: 'Na stronie Správa provozovny (Zarządzanie punktem) w lewym menu znajdź opcję Správa API (Zarządzanie API) i utwórz klucz API.',

                            thirdHelp:
                                'Będzie potrzebny ID punktu, który uzyskałeś w 2. kroku. Wklej wygenerowany klucz API w pole klucz API.',

                            fourth: 'Sprawdź status',
                        },
                    },

                    spolehlivaRecenze: {
                        title: 'Złączony Zaufane',

                        description: 'Aby aktywować to powiadomienie, musisz zalogować się do Zaufane. Czy chcesz się zalogować?',

                        howToAddApiKey: {
                            title: 'Jak zalogować się do Zaufane',

                            first: 'Skopiuj shopid i hasło ze swojego konta Zaufane.',

                            second: 'Wprowadź te informacje w odpowiednich polach powyżej.',

                            third: 'Sprawdź status',
                        },
                    },
                },

                pairing: {
                    title: 'Parowanie',

                    collectData: 'Pobór danych',

                    addCondition: 'Dodaj warunek',

                    cantEdit: 'Nie można poddawać edycji',

                    pairingCondition: 'Zasada parowania',

                    celectedDomain: 'Wybrana domena',

                    pageVisitors: 'Użytkownicy odwiedzający poszczególne strony',

                    domainVisitors: 'Podsumowania użytkowników odwiedzających całą domenę',

                    campaignCollects: 'Kampania będzie przechwytywać użytkowników odwiedzających każdą stronę oddzielnie.',
                },

                design: {
                    title: 'Wygląd',

                    description:
                        'Wygląd domyślny jest ustawiony dla całej kampanii, w tym miejscu można go dostosować do konkretnej notyfikacji',

                    backgroundColour: 'Kolor tła',

                    textColour: 'Kolor tekstu',

                    iconBackgroundColour: 'Kolor tła ikony',

                    iconColour: 'Kolor ikony',

                    roundedCorners: 'Zaokrąglenie krawędzi',

                    icon: 'Ikona',
                },
            },

            deleteModal: {
                title: 'Czy usunąć notyfikacje?',

                description: 'Wszystkie dane zostaną usunięte.',
            },

            lastOrders: {
                title: 'Ostatnie zamówienia',

                description: 'Zalecane dla sklepów internetowych obsługujących 10 lub więcej zamówień tygodniowo.',

                previewText1: 'Józef z Koszyc',

                previewText2: 'złożył/a zamówienie',
            },

            lastRegistrations: {
                title: 'Ostatnia rejestracja',

                description: 'Zalecane dla platform popytowych i witryn pozyskujących kontakty.',

                previewText1: 'Józef z Koszyc',

                previewText2: 'zakończył/a rejestrację',
            },

            actualVisitors: {
                title: 'Odwiedzalność - aktualne statystyki',

                description: 'Zalecane dla witryn, których minimalna dzienna odwiedzalność wynosi 1 000.',

                previewText1: 'użytkowników',
            },

            visitorSummary: {
                title: 'Odwiedzalność - statystyki sumaryczne',

                description: 'Zalecane dla witryn, których dzienna odwiedzalność jest niższa niż 1 000.',

                previewText1: 'użytkowników',

                previewText2: 'użytkownicy',
            },

            orderSummary: {
                title: 'Odwiedzalność - statystyki sumaryczne',
            },

            heureka: {
                title: 'Heuréka - opinie i oceny',

                description: 'Zalecane dla sklepów internetowych, które posiadają pozytywne opinie tekstowe na portalu Heuréka',

                previewText1:
                    'W porównaniu do 12 pro max doceniam wspaniałą kamerę (filmy 4k są top), a także zdjęcia - ogromna różnica i zmiana na lepsze. Znakomite makro. Szybki i niezawodny telefon. Ogromna satysfakcja. Kolor jest piękny.',

                previewText2: 'poleca',
            },

            zbozi: {
                title: 'Zboží - opinie i oceny',

                description: 'Zalecane dla sklepów internetowych, które posiadają pozytywne opinie tekstowe na portalu Zboží.cz',

                previewText1:
                    'W porównaniu do 12 pro max doceniam wspaniałą kamerę (filmy 4k są top), a także zdjęcia - ogromna różnica i zmiana na lepsze. Znakomite makro. Szybki i niezawodny telefon. Ogromna satysfakcja. Kolor jest piękny.',

                previewText2: 'poleca',
            },

            individual: {
                title: 'Własny',

                description:
                    'Wpisz własny tekst notyfikacji. Świetna notyfikacja do zakomunikowania zniżki, darmowej dostawy i innych benefitów dotyczących produktów i usług.',

                previewText1: 'Zarejestruj się w naszym sklepie internetowym i skorzystaj z rabatu 5% na zakupy.',

                previewText2: 'Otrzymaj prezent',
            },

            shoptetLastOrders: {
                title: 'Ostatnie zamówienia dla Shoptet',

                description: 'Ekskluzywne powiadomienia dla e-sklepów na platformie Shoptet.',

                timeRange: 'Zakres czasu',

                showRandom: 'Pokaż losowe zamówienia jako ostatnie ',

                twoMonths: '2 miesiące',

                screwdriver: 'Śrubokręt',
            },

            shoptetCouponCode: {
                title: 'Shoptet kod rabatowy',

                description: 'Ekskluzywne powiadomienia dla e-sklepów na platformie Shoptet.',

                info: 'Notyfikacja zakłada istnienie kuponu rabatowego na platformie Shoptet, tutaj należy podać informacje na jego temat',

                code: 'Kod rabatowy',

                saleType: 'Wartość rabatu',

                saleIn: 'Zniżka w',

                saleOn: 'Zniżka dla',

                wholeSale: 'Cały zakup',

                sale10: 'RABAT10',

                previewText: 'Użyj kodu rabatowego „RABAT10” i uzyskaj 10% rabatu na całe zakupy.',
            },

            spolehlivaRecenzeShopRating: {
                title: 'Zaufane - recenzje i oceny',

                description: 'Polecane dla e-sklepów, które mają pozytywne recenzje tekstowe zaufane.pl',

                previewText1: 'TODO',
            },

            spolehlivaRecenzeProductRating: {
                title: 'Zaufane - recenzje i oceny',

                description: 'Polecane dla e-sklepów, które mają pozytywne recenzje tekstowe zaufane.pl',

                previewText1: 'TODO',
            },
        },
    },

    subscriptions: {
        title: 'Abonament',

        successPayment: 'Płatność została poprawnie zrealizowana.',

        successPaymentDescription: 'Za moment punkty pojawią się na Twoim koncie.',

        wantToChangeSubscription: 'Czy na pewno chcesz zmienić plan',

        changeFrom: 'z ',

        changeTo: 'na ',

        subTitle: 'Twój abonament',

        portableCreditsDescription:
            'Punkty te nie tracą ważności. Zostaną wykorzystane po przekroczeniu wysokości miesięcznej puli punktów.',

        tarifs: {
            title: 'Plany',

            monthly: 'Miesięcznie',

            yearly: 'Rocznie',

            freeMonths: '2 miesiące gratis',

            viewsPerMonth: 'liczba wyświetleń w ciągu miesiąca',

            trial: 'wersja testowa - bez ograniczeń',

            creditsRefresh: 'Odnowienie punktów nastąpi: ',

            creditsExpire: 'Ważność punktów wygasa: ',

            subbscription: 'Abonament',

            trialVersion: 'wersja testowa',

            trialVersionExpires: 'Wersja testowa wygasa',

            cancelSubscription: 'Anuluj odnowienie abonamentu',

            nextPayment: 'Następna płatność zostanie zrealizowana automatycznie',

            unlimitedVersion: 'Aktywna nieograniczona wersja aplikacji',

            choseSubscription: 'Wybierz plan',

            to: 'Do',

            monthlyViews: 'wyświetleń stron miesięcznie',

            pricesAreFinal: 'Podane ceny są ostateczne.',

            moreViews: 'Czy Twoja witryna ma jeszcze więcej wyświetleń w ciągu miesiąca?',

            contactUsForCustom: 'Skontaktuj się z nami w celu uzgodnienia planu na miarę.',

            notificationsWithStatistics: 'Notyfikacja ze statystykami odwiedzalności',

            notificationsWithLastOrdersAndRegistrations: 'Notyfikacja ostatnich zamówień i rejestracji',

            notificationPersonalization: 'Personalizacja notyfikacji',

            zboziAndHeureka: 'Notyfikacja opinii z portalu Heureka i Zboží.cz',

            unlimitedNotifications: 'Nieograniczona liczba notyfikacji',

            unlimitedWebsites: 'Nieograniczona liczba witryn',

            onlyOneWebsite: '(tylko 1 witryna)',

            activateFreeTrial: 'Aktywuj 14 dni gratis',

            mostFavorite: 'Najpopularniejsze',

            selected: 'Wybrano',

            lite: {
                title: 'LITE',

                priceYearly: '39 Kč',

                priceMonthly: '47 Kč',
            },

            start: {
                title: 'START',

                priceYearly: '247 Kč',

                priceMonthly: '297 Kč',
            },

            standard: {
                title: 'STANDARD',

                priceYearly: '457 Kč',

                priceMonthly: '547 Kč',
            },

            premium: {
                title: 'PREMIUM',

                priceYearly: '833 Kč',

                priceMonthly: '997 Kč',
            },
        },
    },

    contact: {
        title: 'Dane kontaktowe',

        basic: {
            title: 'Základní informace',

            accountType: 'Typ konta',

            personal: 'Osobiste',

            business: 'Biznesowe',

            name: 'Imię',

            surname: 'Nazwisko',

            phone: 'Numer telefonu',

            dic: 'NIP',

            ico: 'REGON',

            companyName: 'Nazwa firmy',
        },

        address: {
            title: 'Adres',

            street: 'Ulica i nr',

            city: 'Miasto',

            zip: 'Kod',

            country: 'Kraj',

            czechRepublic: 'Republika Czeska',

            slovakia: 'Słowacja',

            hungary: 'Węgry',

            polish: 'Polska',
        },
    },

    shoptetFirstTouchScreen: {
        title: 'Instalacja wtyczki przebiegła pomyślnie.',

        description1:
            'Przygotowaliśmy dla Twojego sklepu internetowego zestaw inteligentnych notyfikacji, które według naszych doświadczeń z podobnymi sklepami internetowymi najefektywniej zwiększą liczbę zamówień w Twoim sklepie internetowym. Uruchomią się one automatycznie. Natychmiast po aktywacji Twojej darmowej jazdy testowej.',

        description2: 'Kliknij przycisk. Aktywuj inteligentne notyfikacje i obserwuj, jak codziennie wzrasta liczba zamówień.',

        button: 'Uruchom testowe 14 dni gratis',

        error: 'Logowanie nie powiodło się. Wyloguj się i spróbuj ponownie.',
    },
}

import { GlobalVars } from '../../global'
import {
    CreateNotificationSettingsRequest,
    CreateNotificationSettingsResponse,
    GetNotificationSettingsListResponse,
    GetNotificationSettingsResponse,
    UpdateNotificationSettingsRequest,
    UpdateNotificationSettingsResponse,
    UpdateNotificationSettingsStateRequest,
    UpdateNotificationSettingsStateResponse,
} from '../../shared/payloads/notificationSettingsPayloads'
import { NotificationType } from '../../shared/types/notificationSettingsTypes'
import { handleNetworkResponseAndGetJsonAsync, Network } from './Network'

export class NotificationManager {
    static _instance: NotificationManager | null = null

    static getManager(): NotificationManager {
        if (this._instance === null) {
            this._instance = new NotificationManager()
        }
        return this._instance
    }

    async getSingleNotificationSettingsAsync(id: string): Promise<GetNotificationSettingsResponse> {
        let response: Response | null = null
        const url = `${GlobalVars.SERVER_ENDPOINT}/notificationSettings/single?id=${id}`

        try {
            response = await Network.fetch(url, {
                method: 'GET',
                credentials: 'include',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
            })
        } catch (e) {
            throw new Error('Error executing fetch request.')
        }
        if (response) {
            const result: GetNotificationSettingsResponse = await handleNetworkResponseAndGetJsonAsync(response)
            return result
        } else {
            throw new Error('No response from server returned.')
        }
    }

    async getNotificationSettingsListAsync(campaignId: string): Promise<GetNotificationSettingsListResponse> {
        let response: Response | null = null
        const url = `${GlobalVars.SERVER_ENDPOINT}/notificationSettings?campaignId=${campaignId}`

        try {
            response = await Network.fetch(url, {
                method: 'GET',
                credentials: 'include',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
            })
        } catch (e) {
            throw new Error('Error executing fetch request.')
        }
        if (response) {
            const result: GetNotificationSettingsListResponse = await handleNetworkResponseAndGetJsonAsync(response)
            return result
        } else {
            throw new Error('No response from server returned.')
        }
    }

    async createNotificationSettingsAsync(
        campaignId: string,
        type: NotificationType,
        defaultSettings?: any
    ): Promise<CreateNotificationSettingsResponse> {
        let response: Response | null = null
        const url = `${GlobalVars.SERVER_ENDPOINT}/notificationSettings/create`

        const body: CreateNotificationSettingsRequest = {
            isActive: true,
            repeatNotification: true,
            fallbackToGenericCustomer: true,
            campaignId: campaignId,
            type: type,
            ...defaultSettings,
            notificationVisualSettings: {
                textColor: null,
                backgroundColor: null,
                iconBackgroundColor: null,
                iconColor: null,
                icon: null,
                linkButtonBackgroundColor: null,
                linkButtonTextColor: null,
                linkTextColor: null,
                roundness: null,
            },
        }

        try {
            response = await Network.fetch(url, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body),
            })
        } catch (e) {
            throw new Error('Error executing fetch request.')
        }
        if (response) {
            const result: CreateNotificationSettingsResponse = await handleNetworkResponseAndGetJsonAsync(response)
            return result
        } else {
            throw new Error('No response from server returned.')
        }
    }

    async updateNotificationSettingsAsync(body: UpdateNotificationSettingsRequest): Promise<UpdateNotificationSettingsResponse> {
        let response: Response | null = null
        const url = `${GlobalVars.SERVER_ENDPOINT}/notificationSettings/update`

        try {
            response = await Network.fetch(url, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body),
            })
        } catch (e) {
            throw new Error('Error executing fetch request.')
        }
        if (response) {
            const result: UpdateNotificationSettingsResponse = await handleNetworkResponseAndGetJsonAsync(response)
            return result
        } else {
            throw new Error('No response from server returned.')
        }
    }

    async updateNotificationSettingsStateAsync(id: string, isActive: boolean): Promise<UpdateNotificationSettingsStateResponse> {
        let response: Response | null = null
        const url = `${GlobalVars.SERVER_ENDPOINT}/notificationSettings/update/state`

        const body: UpdateNotificationSettingsStateRequest = {
            id: id,
            isActive: isActive,
        }

        try {
            response = await Network.fetch(url, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body),
            })
        } catch (e) {
            throw new Error('Error executing fetch request.')
        }
        if (response) {
            const result: UpdateNotificationSettingsStateResponse = await handleNetworkResponseAndGetJsonAsync(response)
            return result
        } else {
            throw new Error('No response from server returned.')
        }
    }

    async deleteNotificationSettings(id: string): Promise<boolean> {
        let response: Response | null = null
        const url = `${GlobalVars.SERVER_ENDPOINT}/notificationSettings?id=${id}`

        try {
            response = await Network.fetch(url, {
                method: 'DELETE',
                credentials: 'include',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
            })
        } catch (e) {
            throw new Error('Error executing fetch request.')
        }
        if (response && response.status === 200) {
            return true
        } else {
            return false
        }
    }
}

import { Box, Typography, useTheme } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import PaymentFailIcon from '../../assets/images/paymentFailIcon.png'
import CustomButton from '../../components/CustomButton'
import { Routes } from '../../navigation/navigation-types'
import { useTranslation } from 'react-i18next'

const PaymentCancel = () => {
    const theme = useTheme()
    const navigate = useNavigate()
    const { t} = useTranslation()

    return (
        <Box style={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <img style={{ marginTop: 144 }} src={PaymentFailIcon} alt='success icon' height={190} width={275} />
            <Typography
                style={{ fontSize: 28, color: theme.palette.main.alert.o, fontWeight: '700', marginTop: 50, marginBottom: 10 }}
            >
                {t('paymentFailed.title')}
            </Typography>
            <Typography style={{ fontSize: 16, fontWeight: '400', color: theme.palette.natural.t, marginBottom: 36 }}>
                {t('paymentFailed.description')}
            </Typography>
            <CustomButton
                title={t('paymentFailed.continue')}
                size='large'
                style={{ width: 140 }}
                onClick={() => navigate(`${Routes.profile}`, { state: { preselectedTab: 'subscription' } })}
            />
        </Box>
    )
}

export default PaymentCancel

import { FC, useEffect, useState } from 'react'
import Navigation from './Navigation'
import Campaigns from '../screens/Campaigns/Campaigns'
import Connections from '../screens/Connections'
import Login from '../screens/login/Login'
import Websites from '../screens/Websites/Websites'
import OnBoarding from '../screens/OnBoarding/OnBoarding'
import { Routes as RoutesWrapper, Route, Navigate } from 'react-router-dom'
import { Routes } from './navigation-types'
import ProtectedRoute from './ProtectedRoute'
import AddCampaign from '../screens/Campaigns/AddCampaign/AddCampaign'
import AddNotification from '../screens/Notifications/AddNotification'
import EditNotification from '../screens/Notifications/EditNotification'
import Settings from '../screens/Settings/Settings'
import Profile from '../screens/Profile/Profile'
import AdminPanel from '../screens/Admin/AdminPanel'
import PaymentSuccess from '../screens/Payment/PaymentSuccess'
import PaymentFail from '../screens/Payment/PaymentFail'
import NotFound from '../screens/NotFound'
import Registration from '../screens/login/Registration'
import { AUTH_LOCAL_STORAGE_KEY, parseJwtEncodedTokenAndGetReduxState } from '../services/types/auth-types'
import { setAuthState, logoutUser } from '../services/actions/auth-actions'
import { useDispatch } from 'react-redux'
import PaymentCancel from '../screens/Payment/PaymentCancel'
import ShoptetLogin from '../screens/login/ShoptetLogin'
import LoggedOut from '../screens/login/LoggedOut'
import WebarealLogin from '../screens/login/WebarealLogin'
import UpgatesLogin from '../screens/login/UpgatesLogin'

const MainNavigator: FC = () => {
    const dispatch = useDispatch()
    const [finishedAuthTokenRetrieval, setFinishedAuthTokenRetrieval] = useState<boolean>(false)

    useEffect(() => {
        console.debug('Initializing token from storage...')
        const token = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY)
        if (token) {
            console.debug('Token found, parsing...')
            const state = parseJwtEncodedTokenAndGetReduxState(token)
            dispatch(setAuthState(state))
        } else {
            console.debug('No token found...')
            dispatch(logoutUser())
        }
        setFinishedAuthTokenRetrieval(true)
    }, [dispatch])

    if (!finishedAuthTokenRetrieval) return <div>Loading token . . .</div>

    return (
        <>
            <Navigation>
                <RoutesWrapper>
                    <Route index element={<Navigate to={`${Routes.login}`} />} />
                    <Route path={`${Routes.login}`} element={<Login />} />
                    <Route path={`${Routes.shoptetLogin}`} element={<ShoptetLogin />} />
                    <Route path={`${Routes.upgatesLogin}`} element={<UpgatesLogin />} />
                    <Route path={`${Routes.webarealLogin}`} element={<WebarealLogin />} />
                    <Route path={`${Routes.registration}`} element={<Registration />} />
                    <Route path={`${Routes.loggedOut}`} element={<LoggedOut />} />

                    <Route path={`${Routes.campaign}`} element={<ProtectedRoute component={Campaigns} />} />
                    <Route path={`${Routes.campaign}/:id`} element={<ProtectedRoute component={AddCampaign} />} />
                    <Route path={`${Routes.connections}`} element={<ProtectedRoute component={Connections} />} />
                    <Route path={`${Routes.onboarding}`} element={<ProtectedRoute component={OnBoarding} />} />
                    <Route path={`${Routes.websites}`} element={<ProtectedRoute component={Websites} />} />
                    <Route path={`${Routes.settings}`} element={<ProtectedRoute component={Settings} />} />
                    <Route path={`${Routes.profile}`} element={<ProtectedRoute component={Profile} />} />
                    <Route path={`${Routes.admin}`} element={<ProtectedRoute component={AdminPanel} />} />
                    <Route
                        path={`${Routes.campaign}/:id${Routes.notification}`}
                        element={<ProtectedRoute component={AddNotification} />}
                    />
                    <Route
                        path={`${Routes.campaign}/:id${Routes.notification}/:notificationId`}
                        element={<ProtectedRoute component={EditNotification} />}
                    />
                    <Route path={`${Routes.payment}${Routes.success}`} element={<ProtectedRoute component={PaymentSuccess} />} />
                    <Route path={`${Routes.payment}${Routes.fail}`} element={<ProtectedRoute component={PaymentFail} />} />
                    <Route path={`${Routes.payment}${Routes.cancel}`} element={<ProtectedRoute component={PaymentCancel} />} />
                    <Route path='*' element={<NotFound />} />
                </RoutesWrapper>
            </Navigation>
        </>
    )
}

export default MainNavigator

import { Box, Theme, useTheme } from '@mui/material'
import { FC } from 'react'
import ModalBase from './ModalBase'
import { makeStyles } from '@mui/styles'
import CloseIcon from '@mui/icons-material/Close'
import { useSelector } from 'react-redux'
import { getActiveWebId } from '../services/selectors/web-selectors'
import AddPixel from '../screens/OnBoarding/AddPixel'

interface PixelConnectionModalProps {
    isOpen: boolean
    onClose: () => void
    openedForId?: string
}

const PixelConnectionModal: FC<PixelConnectionModalProps> = ({ isOpen, onClose, openedForId }) => {
    const classes = useStyles()
    const theme = useTheme()
    const activeWebId = useSelector(getActiveWebId)

    const webId = openedForId ? openedForId : activeWebId

    return (
        <ModalBase isOpen={isOpen} onClose={onClose}>
            <Box className={classes.container}>
                <Box className={classes.iconContainer}>
                    <CloseIcon style={{ color: theme.palette.natural.t2, cursor: 'pointer' }} onClick={onClose} />
                </Box>
                <Box>
                    {webId && (
                        <AddPixel
                            createdWebId={webId}
                            goToNext={() => {}}
                            goToPrevious={() => {}}
                            modalView
                            onPrimaryButtonClick={onClose}
                        />
                    )}
                </Box>
            </Box>
        </ModalBase>
    )
}

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        flex: 1,
        width: 695,
        backgroundColor: theme.palette.natural.t4,
        paddingTop: 21,
        paddingBottom: 32,
        borderRadius: 8,
    },
    iconContainer: {
        display: 'flex',
        flexDirection: 'row-reverse',
        marginRight: 32,
    },
}))

export default PixelConnectionModal

import { Box, Typography, Theme, useTheme } from '@mui/material'
import { FC } from 'react'
import CustomButton from '../../components/CustomButton'
import WelcomePng from '../../assets/images/welcome.png'
import { makeStyles } from '@mui/styles'
import { useDispatch } from 'react-redux'
import { removeUserInfo } from '../../services/actions/user-actions'
import { clearWebs } from '../../services/actions/web-actions'
import { useNavigate } from 'react-router-dom'
import { logoutUser } from '../../services/actions/auth-actions'
import { UserManager } from '../../services/api/UserManager'
import { AUTH_LOCAL_STORAGE_KEY } from '../../services/types/auth-types'
import { useTranslation } from 'react-i18next'

interface WelcomeProps {
    goToNext: () => void
}

const Welcome: FC<WelcomeProps> = ({ goToNext }) => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const theme = useTheme()
    const navigate = useNavigate()
    const { t } = useTranslation()

    const logoutUserAsync = async () => {
        try {
            const userManager = UserManager.getManager()
            await userManager.logoutUserAsync()
        } catch (e: any) {
            console.log(e.toString())
            console.log('Something wrong with server call. Token removed, user logged out.')
        }
        dispatch(logoutUser())
        dispatch(removeUserInfo())
        dispatch(clearWebs())
        localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY)
        navigate('/')
    }

    return (
        <Box className={classes.container}>
            <img src={WelcomePng} alt='welcome' height={183} width={213} style={{ marginTop: 144 }} />
            <Typography className={classes.title}>{t('onBoarding.title')}</Typography>
            <Box className={classes.textContainer}>
                <Typography className={classes.text}>{t('onBoarding.description')}</Typography>
            </Box>
            <CustomButton title={t('onBoarding.start')} onClick={goToNext} style={{ width: 198 }} />
            <Typography marginTop={1} sx={{ color: theme.palette.natural.t3, cursor: 'pointer' }} onClick={logoutUserAsync}>
                {t('onBoarding.logout')}
            </Typography>
        </Box>
    )
}

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
    },
    title: {
        marginTop: 38,
        color: theme.palette.main.secondary.u1,
        fontWeight: 'bold',
        fontSize: 36,
    },
    textContainer: {
        width: 516,
        marginTop: 28,
        marginBottom: 40,
    },
    text: {
        color: theme.palette.natural.t1,
        fontSize: 16,
        textAlign: 'center',
    },
}))

export default Welcome

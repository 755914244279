import { Language, WebsiteDto } from '../shared/types/websiteTypes'

export const getActiveWebLanguage = (activeWebId: string, webs: WebsiteDto[]): Language => {
    for (const web of webs) {
        if (web.id === activeWebId) return web.language
    }

    // fallback
    return 'cs'
}

export const getUserWebsNames = (webs: WebsiteDto[]) => {
    const result: string[] = []
    webs.forEach((web) => result.push(web.name))
    return result
}

export const getOrdersAndRegistrationsInitialText = (forOrder: boolean, language: Language) => {
    if (language === 'sk') {
        return forOrder ? 'už má objednané. A čo Vy?' : 'má dokončenú registráciu'
    } else {
        return forOrder
            ? 'campaign.notifications.settings.displayRules.lastOrders.haveOrderedPlaceholder'
            : 'campaign.notifications.settings.displayRules.lastRegistrations.isRegistredPlaceholder'
    }
}

export const getActualVisitorsModalInitialText = (language: Language) => {
    if (language === 'sk') {
        return 'návštěvníkov'
    } else return 'campaign.notifications.settings.displayRules.actualVisitors.visitors'
}

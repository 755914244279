import { SvgIcon, useTheme } from '@mui/material'
import React from 'react'

interface IconComponentProps {
    iconPath: string
    isSelected?: boolean
    onClick?: () => void
}

const IconComponent: React.FC<IconComponentProps> = ({ iconPath, isSelected, onClick }: IconComponentProps) => {
    const theme = useTheme()
    return (
        <div
            onClick={onClick}
            style={{
                height: '40px',
                width: '40px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer',
            }}
        >
            <SvgIcon
                fontSize='large'
                style={{
                    color: isSelected ? theme.palette.main.primary.o : theme.palette.natural.t3,
                }}
            >
                <path d={iconPath} />
            </SvgIcon>
        </div>
    )
}

export default IconComponent

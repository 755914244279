import { FormControl, InputLabel } from '@mui/material'
import { FC, useState } from 'react'
import InputAdornment from '@mui/material/InputAdornment'
import IconButton from '@mui/material/IconButton'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import OutlinedInput from '@mui/material/OutlinedInput'
import { makeStyles } from '@mui/styles'

interface PasswordFieldProps {
    value: string
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
    label?: string
    style?: React.CSSProperties
    fullWidth?: boolean
    isRequired?: boolean
}

const useStyles = makeStyles({
    input: {
        height: 44,
    },
    label: {
        marginTop: -5,
        '&.Mui-focused': {
            marginTop: 0,
        },
        '&.Mui-disabled': {
            marginTop: 0,
        },
    },
})

const PasswordField: FC<PasswordFieldProps> = ({ value, onChange, label, style, fullWidth, isRequired }) => {
    const classes = useStyles()
    const [showPassword, setShowPassword] = useState<boolean>(false)
    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault()
    }

    return (
        <form>
            {/* This is here to remove wired DOM warning for password input  */}
            <input type='text' autoComplete='username' style={{ display: 'none' }} />
            <FormControl variant='outlined' fullWidth required={isRequired}>
                <InputLabel htmlFor='outlined-adornment-password' className={value.length === 0 ? classes.label : undefined}>
                    {label}
                </InputLabel>
                <OutlinedInput
                    autoComplete='new-password'
                    style={{ ...style, height: 44 }}
                    fullWidth={fullWidth}
                    label={label}
                    type={showPassword ? 'text' : 'password'}
                    value={value}
                    onChange={onChange}
                    endAdornment={
                        <InputAdornment position='end'>
                            <IconButton
                                onClick={() => setShowPassword(!showPassword)}
                                onMouseDown={handleMouseDownPassword}
                                edge='end'
                            >
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                    }
                />
            </FormControl>
        </form>
    )
}

export default PasswordField

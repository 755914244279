import { AccountType, RegistrationSource } from '../../shared/types/userTypes'
import { Language } from '../../shared/types/websiteTypes'

export interface UserReduxState {
    info: UserInfo
    adminInfo: UserInfo
}

export interface UserInfo {
    id: string
    createdAt: Date | null
    lastModifiedAt: Date | null
    name: string
    email: string
    isAdmin: boolean
    accountType: AccountType
    remainingExtraCredits?: number
    remainingPricingPlanCredits: number
    maxPricingPlanCredits?: number
    isInTrial: boolean
    pricingPlanName?: string
    lastPricingPlanName?: string
    lastPricingPlanPeriodEndsAt?: Date
    lastCreditsChargedAt?: Date
    nextCreditsChargedAt?: Date
    isActivationConfirmed?: boolean
    hasUnlimitedAccess: boolean
    language: Language
    registeredFrom: RegistrationSource
}

export enum UserActionTypes {
    SET_USER_INFO = 'SET_USER_INFO',
    REMOVE_USER_INFO = 'REMOVE_USER_INFO',
    SET_ADMIN_INFO = 'SET_ADMIN_INFO',
    REMOVE_ADMIN_INFO = 'REMOVE_ADMIN_INFO',
}

export interface SetUserInfo {
    type: typeof UserActionTypes.SET_USER_INFO
    payload: {
        id: string
        createdAt: Date
        lastModifiedAt: Date
        name: string
        email: string
        isAdmin: boolean
        accountType: AccountType
        remainingExtraCredits?: number
        remainingPricingPlanCredits: number
        isInTrial: boolean
        pricingPlanName?: string
        lastPricingPlanName?: string
        lastPricingPlanPeriodEndsAt?: Date
        lastCreditsChargedAt?: Date
        nextCreditsChargedAt?: Date
        isActivationConfirmed?: boolean
        hasUnlimitedAccess: boolean
        language: Language
        registeredFrom: RegistrationSource
    }
}

export interface SetAdminInfo {
    type: typeof UserActionTypes.SET_ADMIN_INFO
    payload: {
        id: string
        createdAt: Date
        lastModifiedAt: Date
        name: string
        email: string
        isAdmin: boolean
        accountType: AccountType
        remainingExtraCredits?: number
        remainingPricingPlanCredits: number
        isInTrial: boolean
        pricingPlanName?: string
        lastPricingPlanName?: string
        lastPricingPlanPeriodEndsAt?: Date
        lastCreditsChargedAt?: Date
        nextCreditsChargedAt?: Date
        hasUnlimitedAccess: boolean
        language: Language
        registeredFrom: RegistrationSource
    }
}

export interface RemoveUserInfo {
    type: typeof UserActionTypes.REMOVE_USER_INFO
}

export interface RemoveAdminInfo {
    type: typeof UserActionTypes.REMOVE_ADMIN_INFO
}

export type UserActions = SetUserInfo | RemoveUserInfo | SetAdminInfo | RemoveAdminInfo

import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive'
import DoneIcon from '@mui/icons-material/Done'
import PriorityHighIcon from '@mui/icons-material/PriorityHigh'
import { Box, Typography, useTheme } from '@mui/material'
import { FC, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import CloseIcon from '@mui/icons-material/Close'
import { Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { selectFlashMessages } from '../services/selectors/flashMessages-selectors'
import { FlashMessageProps, FlashMessageType } from '../services/types/flash-messages-types'
import { removeFlashMessage } from '../services/actions/flashMessage-actions'

const FlashMessages: FC = () => {
    const flashMessagesToDisplay = useSelector(selectFlashMessages)
    const styles = flashMessageRootStyles()

    return (
        <>
            {flashMessagesToDisplay && flashMessagesToDisplay.length > 0 && (
                <Box className={styles.fashMessageRoot}>
                    {flashMessagesToDisplay.map((fm) => (
                        <FlashMessage key={fm.id} id={fm.id} message={fm.message || ''} type={fm.type} />
                    ))}
                </Box>
            )}
        </>
    )
}

const truncate = (str: string) => (str.length > 90 ? `${str.substring(0, 90)}...` : str)

const FlashMessage = ({ id, message, type }: FlashMessageProps) => {
    const [shouldMessageBeTruncated, setShouldMessageBeTruncated] = useState<boolean>(message.length > 5)
    const classes = flashMessageStyles({ type })
    const dispatch = useDispatch()
    const theme = useTheme()
    const truncatedMessage = truncate(message)
    const borderColor =
        type === 'success'
            ? theme.palette.main.accent.u1
            : type === 'error'
            ? theme.palette.main.alert.u1
            : theme.palette.main.secondary.u1

    return (
        <Box
            className={classes.flashMessage}
            style={{
                border: `1px solid ${borderColor}`,
                boxShadow: `2px 1px 1px ${borderColor}`,
            }}
        >
            <Box className={classes.iconTextContainer}>
                <Box className={classes.leftIcon}>
                    {type === 'info' ? (
                        <NotificationsActiveIcon style={{ color: theme.palette.main.secondary.u1 }} />
                    ) : type === 'success' ? (
                        <DoneIcon style={{ color: theme.palette.main.accent.o }} />
                    ) : (
                        <PriorityHighIcon style={{ color: theme.palette.main.alert.o }} />
                    )}
                </Box>
                <Typography
                    onClick={() => setShouldMessageBeTruncated(false)}
                    className={classes.flashMessageText}
                    style={{ cursor: shouldMessageBeTruncated ? 'pointer' : 'default' }}
                >
                    {shouldMessageBeTruncated ? truncatedMessage : message}
                </Typography>
            </Box>

            <Box onClick={() => dispatch(removeFlashMessage(id))} className={classes.icon}>
                <CloseIcon
                    style={{
                        color:
                            type === 'success'
                                ? theme.palette.main.accent.u1
                                : type === 'error'
                                ? theme.palette.main.alert.u1
                                : theme.palette.main.secondary.u1,
                    }}
                />
            </Box>
        </Box>
    )
}

export default FlashMessages

interface StyleProps {
    type: FlashMessageType
}

export const flashMessageRootStyles = makeStyles({
    fashMessageRoot: {
        display: 'flex',
        width: '95vw',
        maxWidth: 730,
        position: 'fixed',
        left: '50%',
        transform: 'translate(-50%, 0%)',
        top: 20,
        flexDirection: 'column-reverse',
        alignItems: 'center',
        zIndex: 15000,
    },
})

export const flashMessageStyles = makeStyles((theme: Theme) => ({
    flashMessage: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        height: 50,
        flex: 1,
        backgroundColor: (props: StyleProps) =>
            props.type === 'success'
                ? theme.palette.main.accent.d3
                : props.type === 'info'
                ? theme.palette.main.secondary.d3
                : theme.palette.main.alert.d3,
        justifyContent: 'space-between',
        alignItems: 'center',
        zIndex: 100,
        marginBottom: 10,
        borderRadius: 8,
    },
    flashMessageText: {
        color: (props: StyleProps) =>
            props.type === 'success'
                ? theme.palette.main.accent.u1
                : props.type === 'info'
                ? theme.palette.main.secondary.u2
                : theme.palette.main.alert.u1,
        overflowWrap: 'break-word',
        marginLeft: 10,
    },
    icon: {
        display: 'flex',
        cursor: 'pointer',
        marginRight: 10,
    },
    leftIcon: {
        display: 'flex',
        flexShrink: 0,
    },
    iconTextContainer: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: 10,
        marginRight: 10,
        maxWidth: '85%',
        overflow: 'hidden',
        height: 48,
    },
}))

import { GlobalVars } from '../../global'
import {
    CreateWebsiteResponse,
    CreateWebsiteRequest,
    GetWebsiteHtmlResponse,
    UpdateHeurekaApiRequest,
    UpdateWebsiteResponse,
    GetWebsiteResponse,
    UpdateZboziApiRequest,
    GetWebsitesResponse,
    UpdateWebsiteRequest,
} from '../../shared/payloads/websitePayloads'
import { Language } from '../../shared/types/websiteTypes'
import { handleNetworkResponseAndGetJsonAsync, handleNetworkResponseWithNoPayloadAsync, Network } from './Network'

export class WebsiteManager {
    static _instance: WebsiteManager | null = null

    static getManager(): WebsiteManager {
        if (this._instance === null) {
            this._instance = new WebsiteManager()
        }
        return this._instance
    }

    async createWebsiteAsync(userId: string, name: string, webUrl: string, language: Language): Promise<CreateWebsiteResponse> {
        let response: Response | null = null
        const url = `${GlobalVars.SERVER_ENDPOINT}/website/create`

        const body: CreateWebsiteRequest = {
            userId: userId,
            name: name,
            url: webUrl,
            language: language,
        }

        try {
            response = await Network.fetch(url, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body),
            })
        } catch (e) {
            throw new Error('Error executing fetch request.')
        }
        if (response) {
            const result: CreateWebsiteResponse = await handleNetworkResponseAndGetJsonAsync(response)
            return result
        } else {
            throw new Error('No response from server returned.')
        }
    }

    async getWebsitesForUserAsync(userId: string): Promise<GetWebsitesResponse> {
        if (!userId) new Error('Param userId is empty')
        let response: Response | null = null
        const url = `${GlobalVars.SERVER_ENDPOINT}/website?userId=${userId}`

        try {
            response = await Network.fetch(url, {
                method: 'GET',
                credentials: 'include',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
            })
        } catch (e) {
            throw new Error('Error executing fetch request.')
        }

        if (response) {
            const result: GetWebsitesResponse = await handleNetworkResponseAndGetJsonAsync(response)
            return result
        } else {
            throw new Error('No response from server returned.')
        }
    }

    async getWebsiteHtmlAsync(websiteUrl: string): Promise<GetWebsiteHtmlResponse> {
        let response: Response | null = null
        const url = `${GlobalVars.SERVER_ENDPOINT}/website/html?url=${websiteUrl}`

        try {
            response = await Network.fetch(url, {
                method: 'GET',
                credentials: 'include',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
            })
        } catch (e) {
            throw new Error('Error executing fetch request.')
        }
        if (response) {
            const result: GetWebsiteHtmlResponse = await handleNetworkResponseAndGetJsonAsync(response)
            return result
        } else {
            throw new Error('No response from server returned.')
        }
    }

    async getWebsiteSingleAsync(id: string): Promise<GetWebsiteResponse> {
        let response: Response | null = null
        const url = `${GlobalVars.SERVER_ENDPOINT}/website/single?id=${id}`
        try {
            response = await Network.fetch(url, {
                method: 'GET',
                credentials: 'include',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
            })
        } catch (e) {
            throw new Error('Error executing fetch request.')
        }
        if (response) {
            const result: GetWebsiteResponse = await handleNetworkResponseAndGetJsonAsync(response)
            return result
        } else {
            throw new Error('No response from server returned.')
        }
    }

    async updateHeurekaAsync(websiteId: string, country: string, name: string, apiKey: string): Promise<UpdateWebsiteResponse> {
        let response: Response | null = null
        const url = `${GlobalVars.SERVER_ENDPOINT}/website/heureka/update`
        const body: UpdateHeurekaApiRequest = {
            websiteId: websiteId,
            country: country,
            name: name,
            apiKey: apiKey,
        }

        try {
            response = await Network.fetch(url, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body),
            })
        } catch (e) {
            throw new Error('Error executing fetch request.')
        }
        if (response) {
            const result: UpdateWebsiteResponse = await handleNetworkResponseAndGetJsonAsync(response)
            return result
        } else {
            throw new Error('No response from server returned.')
        }
    }

    async updateZboziAsync(websiteId: string, apiId: string, name: string, apiKey: string): Promise<UpdateWebsiteResponse> {
        let response: Response | null = null
        const url = `${GlobalVars.SERVER_ENDPOINT}/website/zbozi/update`
        const body: UpdateZboziApiRequest = {
            websiteId: websiteId,
            name: name,
            apiKey: apiKey,
            apiId: apiId,
        }

        try {
            response = await Network.fetch(url, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body),
            })
        } catch (e) {
            throw new Error('Error executing fetch request.')
        }
        if (response) {
            const result: UpdateWebsiteResponse = await handleNetworkResponseAndGetJsonAsync(response)
            return result
        } else {
            throw new Error('No response from server returned.')
        }
    }

    async updateSpolehliveRecenzeAsync(websiteId: string, shopId: string, password: string): Promise<UpdateWebsiteResponse> {
        let response: Response | null = null
        const url = `${GlobalVars.SERVER_ENDPOINT}/website/spolehlivaRecenze/update`
        const body = {
            websiteId: websiteId,
            shopId: shopId,
            shopPassword: password,
        }

        try {
            response = await Network.fetch(url, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body),
            })
        } catch (e) {
            throw new Error('Error executing fetch request.')
        }
        if (response) {
            const result: UpdateWebsiteResponse = await handleNetworkResponseAndGetJsonAsync(response)
            return result
        } else {
            throw new Error('No response from server returned.')
        }
    }

    async updateWebsiteAsync(id: string, name: string, webUrl: string, language: Language): Promise<UpdateWebsiteResponse> {
        let response: Response | null = null
        const url = `${GlobalVars.SERVER_ENDPOINT}/website/update`

        const body: UpdateWebsiteRequest = {
            id: id,
            name: name,
            url: webUrl,
            language: language,
        }
        try {
            response = await Network.fetch(url, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body),
            })
        } catch (e) {
            throw new Error('Error executing fetch request.')
        }
        if (response) {
            const result: UpdateWebsiteResponse = await handleNetworkResponseAndGetJsonAsync(response)
            return result
        } else {
            throw new Error('No response from server returned.')
        }
    }

    async deleteWebsiteAsync(id: string) {
        let response: Response | null = null
        const url = `${GlobalVars.SERVER_ENDPOINT}/website?id=${id}`

        try {
            response = await Network.fetch(url, {
                method: 'DELETE',
                credentials: 'include',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
            })
        } catch (e) {
            throw new Error('Error executing fetch request.')
        }
        if (response) {
            await handleNetworkResponseWithNoPayloadAsync(response)
        } else {
            throw new Error('No response from server returned.')
        }
    }
}

import { Box, SelectChangeEvent, Theme, Typography, useTheme } from '@mui/material'
import { FC, useEffect, useState } from 'react'
import ModalBase from '../../components/ModalBase'
import CloseIcon from '@mui/icons-material/Close'
import { makeStyles } from '@mui/styles'
import { Language } from '../../shared/types/websiteTypes'
import CustomTextField from '../../components/CustomTextField'
import CustomDropdown from '../../components/CustomDropdown'
import CustomButton from '../../components/CustomButton'
import { useDispatch, useSelector } from 'react-redux'
import { WebsiteManager } from '../../services/api/WebsiteManager'
import { CreateWebsiteResponse, UpdateWebsiteResponse } from '../../shared/payloads/websitePayloads'
import { showFlashMessageWithTimeout } from '../../services/actions/flashMessage-actions'
import { UserInfo } from '../../services/types/user-types'
import { addWeb, setActiveWeb, updateWeb } from '../../services/actions/web-actions'
import { getActiveWebId } from '../../services/selectors/web-selectors'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import { useTranslation } from 'react-i18next'

interface AddWebModalProps {
    isOpen: boolean
    onClose: () => void
    userInfo: UserInfo
    title?: string
    // if true, updates web. If false, create new
    update?: boolean
    primaryButtonTitle?: string
    secondaryButtonTitle?: string
    completedWebName?: string
    completedWebUrl?: string
    completedLanguage?: Language
}

const AddWebModal: FC<AddWebModalProps> = ({
    isOpen,
    onClose,
    userInfo,
    title,
    update,
    primaryButtonTitle,
    secondaryButtonTitle,
    completedWebName,
    completedWebUrl,
    completedLanguage,
}) => {
    const theme = useTheme()
    const classes = useStyles()
    const dispatch = useDispatch()
    const activeWebId = useSelector(getActiveWebId)
    const { t } = useTranslation()

    const [webName, setWebName] = useState<string>(completedWebName ? completedWebName : '')
    const [webUrl, setWebUrl] = useState<string>(completedWebUrl ? completedWebUrl : '')
    const [selectedLanguage, setSelectedLanguage] = useState<Language>(completedLanguage ? completedLanguage : 'cs')
    const [isLoading, setIsLoading] = useState<boolean>(false)

    useEffect(() => {
        if (completedWebName !== undefined && completedWebUrl !== undefined && completedLanguage !== undefined) {
            setWebName(completedWebName)
            setWebUrl(completedWebUrl)
            setSelectedLanguage(completedLanguage)
        }
    }, [completedWebName, completedWebUrl, completedLanguage, isOpen])

    const handleWebNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setWebName(event.target.value)
    }

    const handleWebUrlChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setWebUrl(event.target.value)
    }

    const onLanguageChange = (event: SelectChangeEvent) => {
        setSelectedLanguage(event.target.value as Language)
    }

    const onAddWeb = async () => {
        if (webName.length === 0 || webUrl.length === 0) {
            showFlashMessageWithTimeout(dispatch, 'Vyplňte všechna pole', 'info')
            return
        }

        try {
            setIsLoading(true)
            const _websiteManager = WebsiteManager.getManager()
            const result: CreateWebsiteResponse = await _websiteManager.createWebsiteAsync(
                userInfo.id,
                webName,
                webUrl,
                selectedLanguage
            )
            if (result) {
                dispatch(addWeb(result))
                showFlashMessageWithTimeout(dispatch, 'Web byl úspěšně vytvořen.', 'success')
            }
            setIsLoading(false)
            onModalClose()
        } catch (e: any) {
            setIsLoading(false)
            showFlashMessageWithTimeout(dispatch, e.toString(), 'error')
        }
    }

    const onUpdateWeb = async () => {
        if (activeWebId && webName && webUrl && selectedLanguage) {
            try {
                setIsLoading(true)
                const _websiteManager = WebsiteManager.getManager()
                const result: UpdateWebsiteResponse = await _websiteManager.updateWebsiteAsync(
                    activeWebId,
                    webName,
                    webUrl,
                    selectedLanguage
                )
                if (result) {
                    dispatch(updateWeb(activeWebId, webName, webUrl, selectedLanguage))
                    dispatch(setActiveWeb(webUrl, activeWebId))
                    showFlashMessageWithTimeout(dispatch, t('popups.changesSaved'), 'success')
                }
                setIsLoading(false)
                onModalClose()
            } catch (e: any) {
                setIsLoading(false)
                showFlashMessageWithTimeout(dispatch, e.toString(), 'error')
            }
        }
    }

    const clearForms = () => {
        setWebName('')
        setWebUrl('')
    }

    const onModalClose = () => {
        clearForms()
        onClose()
    }

    return (
        <ModalBase isOpen={isOpen} onClose={onModalClose}>
            <Box className={classes.container}>
                <Box className={classes.iconContainer}>
                    <CloseIcon style={{ color: theme.palette.natural.t2, cursor: 'pointer' }} onClick={onModalClose} />
                </Box>
                <Typography className={classes.title}>{title ? title : 'Přidejte web'}</Typography>
                <Box style={{ flex: 1 }}>
                    <CustomTextField
                        style={{ marginTop: 46 }}
                        fullWidth
                        label={t('addWebsite.name') ?? ''}
                        value={webName}
                        onChange={handleWebNameChange}
                    />
                    <Box display='flex' flexDirection='row' alignItems='center'>
                        <ErrorOutlineIcon style={{ fontSize: 20, marginRight: 10, color: theme.palette.main.secondary.u1 }} />
                        <Typography style={{ color: theme.palette.natural.t1, fontSize: 14, marginTop: 23, marginBottom: 23 }}>
                            {t('addWebsite.warning')}
                        </Typography>
                    </Box>
                    <CustomTextField
                        style={{ marginBottom: 46 }}
                        fullWidth
                        label={`${t('addWebsite.url')} (${t('addWebsite.includeHttps')})`}
                        value={webUrl}
                        onChange={handleWebUrlChange}
                    />

                    <CustomDropdown
                        id='language-dropdown'
                        label={t('addWebsite.notificationLanguage')}
                        items={[
                            { title: t('contact.address.slovakia'), value: 'sk' },
                            { title: t('contact.address.czechRepublic'), value: 'cs' },
                            { title: t('contact.address.polish'), value: 'pl' },
                        ]}
                        onChange={onLanguageChange}
                        value={selectedLanguage}
                    />
                </Box>
                <Box style={{ display: 'flex', flexDirection: 'row-reverse' }}>
                    <CustomButton
                        size='medium'
                        title={primaryButtonTitle ? primaryButtonTitle : 'Přidat'}
                        isLoading={isLoading}
                        onClick={update ? onUpdateWeb : onAddWeb}
                        style={{ width: 90 }}
                    />
                    <CustomButton
                        size='medium'
                        title={secondaryButtonTitle ? secondaryButtonTitle : 'Zpět'}
                        type='secondary'
                        onClick={onModalClose}
                        style={{ width: 78, marginRight: 24 }}
                    />
                </Box>
            </Box>
        </ModalBase>
    )
}

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        width: 408,
        height: 441,
        backgroundColor: '#FFFFFF',
        display: 'flex',
        flexDirection: 'column',
        borderRadius: 8,
        padding: '20px 32px 32px 32px',
        filter: 'drop-shadow(0px 2px 8px rgba(65, 32, 158, 0.08)) drop-shadow(0px 20px 32px rgba(136, 100, 211, 0.24))',
    },
    iconContainer: {
        display: 'flex',
        flexDirection: 'row-reverse',
    },
    title: {
        color: theme.palette.main.secondary.u1,
        textAlign: 'center',
        fontSize: 28,
        fontWeight: 700,
    },
    textUnderline: {
        fontWeight: 700,
        textDecoration: 'underline',
        cursor: 'pointer',
        fontSize: 14,
    },
}))

export default AddWebModal

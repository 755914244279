import { useTheme } from '@mui/material'
import React from 'react'
import { BorderType } from './BorderSelect'

interface BorderComponentProps {
    borderValue: BorderType
    isSelected?: boolean
    onClick?: () => void
}

const BorderComponent: React.FC<BorderComponentProps> = ({ borderValue, isSelected, onClick }: BorderComponentProps) => {
    const theme = useTheme()

    return (
        <div
            style={{
                height: '45px',
                width: '60px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer',
            }}
            onClick={onClick}
        >
            <div
                style={{
                    width: '90%',
                    height: '90%',
                    border: `3px solid ${isSelected ? theme.palette.main.primary.o : theme.palette.natural.t3}`,
                    backgroundColor: isSelected ? '#FFF9F1' : '#FFFFFF',
                    borderRadius: borderValue + 'px',
                }}
            />
        </div>
    )
}

export default BorderComponent

import { Box, Typography, useTheme } from '@mui/material'
import { FC, useState } from 'react'
import ModalBase from './ModalBase'
import CloseIcon from '@mui/icons-material/Close'
import CustomTextField from './CustomTextField'
import CustomButton from './CustomButton'
import { showFlashMessageWithTimeout } from '../services/actions/flashMessage-actions'
import { useDispatch } from 'react-redux'
import { UserManager } from '../services/api/UserManager'
import { useTranslation } from 'react-i18next'

interface ResetPasswordModalProps {
    isOpen: boolean
    onClose: () => void
}

const ResetPasswordModal: FC<ResetPasswordModalProps> = ({ isOpen, onClose }) => {
    const theme = useTheme()
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const [email, setEmail] = useState<string>('')
    const [isEmailValid, setIsEmailValid] = useState<boolean>(false)
    const [isLoading, setIsLoading] = useState<boolean>(false)

    const onModalClose = () => {
        setEmail('')
        onClose()
    }

    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = event.target.value,
            pattern = new RegExp(
                /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
            )

        if (pattern.test(newValue)) {
            setIsEmailValid(true)
        } else {
            setIsEmailValid(false)
        }

        setEmail(newValue)
    }

    const onPasswordReset = async () => {
        try {
            setIsLoading(true)
            const _userManager = UserManager.getManager()
            await _userManager.resetUserPasswordAsync(email, 'cs')
            setIsLoading(false)
            showFlashMessageWithTimeout(
                dispatch,
                'Zkontrolujte vaši e - mailovou adresu, poslali jsme vám e-mail',
                'success',
                5000
            )
            setEmail('')
            onModalClose()
        } catch (e: any) {
            setIsLoading(false)
            showFlashMessageWithTimeout(dispatch, e.toString(), 'error')
        }
    }

    return (
        <ModalBase center isOpen={isOpen} onClose={onModalClose}>
            <Box
                style={{
                    width: 400,
                    backgroundColor: theme.palette.natural.t4,
                    borderRadius: 8,
                    flex: 1,
                    boxShadow: theme.palette.main.shadow.secondary,
                    marginRight: 16,
                    marginLeft: 16,
                }}
            >
                <Box
                    style={{
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'row-reverse',
                        marginTop: 22,
                        marginRight: 37,
                    }}
                >
                    <CloseIcon style={{ cursor: 'pointer' }} onClick={onModalClose} />
                </Box>

                <Box
                    style={{
                        marginLeft: 16,
                        marginRight: 16,
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <Typography
                        style={{
                            fontSize: 16,
                            color: theme.palette.natural.t,
                            marginBottom: 16,
                        }}
                    >
                        {t('resetPassword.title')}
                    </Typography>
                    <CustomTextField label='Email' fullWidth value={email} onChange={handleEmailChange} isRequired />
                </Box>
                <Box
                    style={{
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'row-reverse',
                        margin: 16,
                        marginTop: 32,
                    }}
                >
                    <CustomButton
                        isLoading={isLoading}
                        style={{ width: 100 }}
                        title={t('resetPassword.reset')}
                        size='medium'
                        onClick={onPasswordReset}
                        isDisabled={!isEmailValid || email.length === 0}
                    />
                </Box>
            </Box>
        </ModalBase>
    )
}

export default ResetPasswordModal

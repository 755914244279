import { FormControl, InputLabel, MenuItem } from '@mui/material'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import { FC } from 'react'
import { DropdownMenuItem } from './types'

interface CustomDropdownProps {
    items: DropdownMenuItem[]
    value?: string
    onChange: (event: SelectChangeEvent) => void
    label: string
    id?: string
    width?: string | number
    hiddenValues?: string[]
    disabled?: boolean
}

const CustomDropdown: FC<CustomDropdownProps> = ({ items, value, onChange, label, width, id, hiddenValues, disabled }) => {
    return (
        <FormControl fullWidth style={{ maxWidth: width }}>
            <InputLabel id={id}>{label}</InputLabel>
            <Select
                labelId={id}
                id={`custom-dropdown-${id}`}
                value={value}
                label={label}
                disabled={disabled}
                onChange={onChange}
                inputProps={{ style: { height: 44 } }}
                style={{ height: 44 }}
            >
                {items.length > 0 &&
                    items.map((item, index) => {
                        return (
                            <MenuItem key={`${index}-value`} disabled={hiddenValues?.includes(item.value)} value={item.value}>
                                {item.title}
                            </MenuItem>
                        )
                    })}
            </Select>
        </FormControl>
    )
}

export default CustomDropdown

import { Box, Typography, useTheme, Theme } from '@mui/material'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { showFlashMessage, showFlashMessageWithTimeout } from '../../services/actions/flashMessage-actions'
import { CampaignManager } from '../../services/api/CampaignManager'
import { WebsiteManager } from '../../services/api/WebsiteManager'
import { getUserInfo } from '../../services/selectors/user-selectors'
import { getActiveWebId, getActiveWebUrl } from '../../services/selectors/web-selectors'
import { CampaignDto, CampaignPosition } from '../../shared/types/campaignTypes'
import CampaignsTable from './CampaignsTable'
import CircularProgress from '@mui/material/CircularProgress'
import { makeStyles } from '@mui/styles'
import CustomButton from '../../components/CustomButton'
import { useNavigate } from 'react-router-dom'
import { Routes } from '../../navigation/navigation-types'
import DeleteModal from '../../components/DeleteModal'
import FirstCampaign from '../../assets/images/firstCampaign.png'
import { setActiveWeb } from '../../services/actions/web-actions'
import { useTranslation } from 'react-i18next'

const Campaigns: React.FC = () => {
    const theme = useTheme()
    const dispatch = useDispatch()
    const classes = useStyles()
    const navigate = useNavigate()
    const { t } = useTranslation();
    const user = useSelector(getUserInfo)
    const webUrl = useSelector(getActiveWebUrl)
    const activeWebId = useSelector(getActiveWebId)
    const [campaigns, setCampaigns] = useState<CampaignDto[]>([])
    const [webId, setWebId] = useState<string | undefined>(undefined)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [isDeleting, setIsDeleting] = useState<boolean>(false)
    const [isAdding, setIsAdding] = useState<boolean>(false)
    const [isTableDisabled, setIsTableDisabled] = useState<boolean>(false)
    const [deleteModalOpenForId, setDeleteModalOpenForId] = useState<string | undefined>(undefined)

    useEffect(() => {
        const fetchWebsiteCampaigns = async () => {
            try {
                setIsLoading(true)
                const _websiteManager = WebsiteManager.getManager()
                const _campaignManager = CampaignManager.getManager()
                const websitesResponse = await _websiteManager.getWebsitesForUserAsync(user.id)
                if (webUrl) {
                    setWebId(activeWebId)
                    if (activeWebId) {
                        const result = await _campaignManager.getCampaignsAsync(activeWebId)
                        setCampaigns(result.campaigns)
                    }
                } else {
                    if (websitesResponse.websites.length > 0) {
                        const webId = websitesResponse.websites[0].id
                        const webUrl = websitesResponse.websites[0].url
                        const result = await _campaignManager.getCampaignsAsync(webId)
                        setCampaigns(result.campaigns)
                        dispatch(setActiveWeb(webUrl, webId))
                    }
                }
                setIsLoading(false)
            } catch (e: any) {
                setIsLoading(false)
                dispatch(showFlashMessage(e.toString(), 'error'))
            }
        }
        fetchWebsiteCampaigns()
    }, [webUrl, dispatch, user.id, activeWebId])

    const onStatusChange = async (id: string) => {
        setIsTableDisabled(true)
        const copy: CampaignDto[] = JSON.parse(JSON.stringify(campaigns))
        let isActive = false
        copy.forEach((camp) => {
            if (camp.id === id) {
                camp.isActive = !camp.isActive
                isActive = camp.isActive
            }
        })
        try {
            const _campaignManager = CampaignManager.getManager()
            await _campaignManager.updateCampaignStateAsync(id, isActive)
            setCampaigns(copy)
            setIsTableDisabled(false)
        } catch (e: any) {
            setIsTableDisabled(false)
            setCampaigns(campaigns)
            dispatch(showFlashMessage(e.toString(), 'error'))
        }
    }

    const onAddCampaign = async () => {
        if (webId) {
            setIsAdding(true)
            try {
                const _campaignManager = CampaignManager.getManager()
                const result = await _campaignManager.createCampaignAsync(webId)
                if (result) {
                    let pcSettings = {
                        isActive: true,
                        position: 'bottomLeft' as CampaignPosition,
                        showAfterSeconds: 5,
                        showDurationSeconds: 5,
                        nextNotificationDelaySeconds: 10,
                        animationSpeed: 0,
                    }
                    let phoneSettings = {
                        isActive: true,
                        position: 'bottom' as CampaignPosition,
                        showAfterSeconds: 5,
                        showDurationSeconds: 5,
                        nextNotificationDelaySeconds: 10,
                        animationSpeed: 0,
                    }
                    await _campaignManager.updateCampaignVisualAsync(result.id, pcSettings, phoneSettings)
                }
                setIsAdding(false)
                sessionStorage.setItem('selectedTab', 'settings')
                navigate(`${Routes.campaign}/${result.id}`)
            } catch (e: any) {
                setIsAdding(false)
                dispatch(showFlashMessage(e.toString(), 'error'))
            }
        }
    }

    const onDeleteCampaign = async () => {
        if (deleteModalOpenForId) {
            try {
                setIsDeleting(true)
                const _campaignManager = CampaignManager.getManager()
                await _campaignManager.deleteCampaignAsync(deleteModalOpenForId)
                setCampaigns((state) => {
                    const copy: CampaignDto[] = JSON.parse(JSON.stringify(state))
                    const result: CampaignDto[] = copy.filter((item) => item.id !== deleteModalOpenForId)
                    return result
                })
                setIsDeleting(false)
                setDeleteModalOpenForId(undefined)
                showFlashMessageWithTimeout(dispatch, 'Odstranění proběhlo úspěšně.', 'success')
            } catch (e: any) {
                setIsDeleting(false)
                dispatch(showFlashMessage(e.toString(), 'error'))
            }
        }
    }

    return (
        <Box style={{ flex: 1 }}>
            {isLoading && (
                <Box className={classes.loadingContainer}>
                    <CircularProgress style={{ color: theme.palette.main.primary.o }} />
                </Box>
            )}
            {!isLoading && (
                <Box className={classes.buttonContainer}>
                    <CustomButton
                        size='medium'
                        title={'+ ' + t('campaign.addCampaign')}
                        type='primary'
                        style={{ width: 205 }}
                        isLoading={isAdding}
                        onClick={onAddCampaign}
                    />
                </Box>
            )}
            <Box style={{ marginTop: 32, marginLeft: 24, marginRight: 78 }}>
                {!isLoading && campaigns.length > 0 && (
                    <CampaignsTable
                        isDisabled={isTableDisabled}
                        campaigns={campaigns}
                        onStatusChange={onStatusChange}
                        onOpenDeleteModal={(id) => setDeleteModalOpenForId(id)}
                        onCampaignSelect={(id) => navigate(`${Routes.campaign}/${id}`)}
                    />
                )}
            </Box>
            {!isLoading && campaigns.length === 0 && (
                <Box className={classes.imgContainer}>
                    <img src={FirstCampaign} alt='first campaign' width={401} height={272} />
                    <Typography textAlign='center' className={classes.primaryText}>
                        {t('campaign.addFirstCampaign')}
                    </Typography>
                    <Typography className={classes.secondaryText}>{t('campaign.setUpFirstCampaign')}</Typography>
                    <CustomButton isLoading={isAdding} title={t('campaign.addCampaign')} onClick={onAddCampaign} style={{ width: 162 }} />
                </Box>
            )}
            <DeleteModal
                isLoading={isDeleting}
                onDelete={onDeleteCampaign}
                isOpen={!!deleteModalOpenForId}
                onClose={() => setDeleteModalOpenForId(undefined)}
                primaryText={t('campaign.deleteCampaign') ?? ''}
                secondaryText={t('campaign.notifications.deleteModal.description') ?? ''}
            />
        </Box>
    )
}

const useStyles = makeStyles((theme: Theme) => ({
    loadingContainer: {
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 32,
    },
    buttonContainer: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row-reverse',
        marginTop: 16,
        marginRight: 24,
    },
    imgContainer: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 90,
    },
    primaryText: {
        fontSize: 28,
        fontWeight: 'bold',
        color: theme.palette.natural.t,
        marginTop: 30,
    },
    secondaryText: {
        marginTop: 20,
        marginBottom: 32,
        fontSize: 16,
        color: theme.palette.natural.t1,
    },
}))

export default Campaigns

import { Box, Theme, Typography, useTheme } from '@mui/material'
import { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CustomButton from '../../components/CustomButton'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import moment from 'moment'
import { makeStyles, withStyles } from '@mui/styles'
import { getUserWebs } from '../../services/selectors/web-selectors'
import { useNavigate } from 'react-router-dom'
import { Routes } from '../../navigation/navigation-types'
import PixelConnectionModal from '../../components/PixelConnectionModal'
import PixelStatus from '../../components/PixelStatus'
import { showFlashMessageWithTimeout } from '../../services/actions/flashMessage-actions'
import { stringIncludesSubstring } from '../../generic-utils'
import { useTranslation } from 'react-i18next'

const Websites: FC = () => {
    const theme = useTheme()
    const classes = useStyles()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const webs = useSelector(getUserWebs)
    const [pixelModalOpenFor, setPixelModalOpenFor] = useState<string>('')
    const [webIdsWithConnectedPixel, setWebIdsWithConnectedPixel] = useState<string[]>([])
    const [isLoading, setIsLoading] = useState<boolean>(false)

    const StyledTableRow = withStyles(() => ({
        root: {
            height: 78,
            backgroundColor: theme.palette.natural.t6,
        },
    }))(TableRow)

    useEffect(() => {
        const checkAllPixelStatusForAllWebs = async () => {
            try {
                setIsLoading(true)
                for (let i = 0; i < webs.length; i++) {
                    const web = webs[i]
                    try {
                        const result = await fetch(web.url, { method: 'GET' })
                        if (result) {
                            const webHtml = await result.text()
                            if (stringIncludesSubstring(webHtml, web.id)) {
                                setWebIdsWithConnectedPixel((state) => [...state, web.id])
                            }
                        }
                    } catch (e) {
                        console.log(`Nepodařilo se ověřit Pixel pro ${web.url}, webId: ${web.id}`)
                    }
                }
                setIsLoading(false)
            } catch (e: any) {
                showFlashMessageWithTimeout(dispatch, e.toString(), 'error')
            }
        }

        checkAllPixelStatusForAllWebs()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [webs])

    return (
        <Box style={{ flex: 1, marginTop: 24, marginLeft: 24, marginRight: 24 }}>
            <Box
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    marginBottom: 34,
                }}
            >
                <Typography style={{ fontSize: 28, color: theme.palette.main.secondary.u1 }}>
                    {t('websites.yourWebsites')}
                </Typography>
                <Box style={{ flex: 1 }} />
                <CustomButton
                    style={{ width: 143 }}
                    size='medium'
                    title={`+ ${t('websites.addWebsite')}`}
                    onClick={() =>
                        navigate(Routes.onboarding, {
                            state: { preselectedScreenIndex: 1 },
                        })
                    }
                />
            </Box>

            <TableContainer component={Box}>
                <Table className={classes.table} sx={{ minWidth: 650 }} aria-label='simple table'>
                    <TableHead classes={{ root: classes.thead }}>
                        <TableRow
                            style={{
                                backgroundColor: theme.palette.main.primary.d2,
                                height: 52,
                                borderRadius: 4,
                            }}
                        >
                            <TableCell className={classes.tableCell} style={{ color: theme.palette.natural.t }} align='left'>
                                {t('websites.website')}
                            </TableCell>
                            <TableCell className={classes.tableCell} style={{ color: theme.palette.natural.t }} align='left'>
                                {t('websites.created')}
                            </TableCell>
                            <TableCell className={classes.tableCell} style={{ color: theme.palette.natural.t }} align='left'>
                                {t('websites.campaigns')}
                            </TableCell>
                            <TableCell
                                className={classes.tableCell}
                                style={{ color: theme.palette.natural.t, width: 214 }}
                                align='left'
                            >
                                Pixel
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {webs.map((web) => {
                            return (
                                <StyledTableRow
                                    key={web.id}
                                    sx={{
                                        '&:last-child td, &:last-child th': { border: 0 },
                                    }}
                                >
                                    <TableCell
                                        className={classes.tableCell}
                                        component='th'
                                        scope='row'
                                        style={{ color: theme.palette.natural.t1 }}
                                    >
                                        <Typography>{web.name}</Typography>
                                        <Typography>{web.url}</Typography>
                                    </TableCell>
                                    <TableCell
                                        className={classes.tableCell}
                                        style={{ color: theme.palette.natural.t1 }}
                                        align='left'
                                    >
                                        {moment(web.createdAt).format('DD. MM. YYYY')}
                                    </TableCell>
                                    <TableCell
                                        className={classes.tableCell}
                                        style={{ color: theme.palette.natural.t1 }}
                                        align='left'
                                    >
                                        {web.campaignCount}
                                    </TableCell>
                                    <TableCell
                                        className={classes.tableCell}
                                        style={{
                                            color: theme.palette.natural.t1,
                                            width: 214,
                                        }}
                                        align='center'
                                    >
                                        <PixelStatus
                                            isConnected={webIdsWithConnectedPixel.includes(web.id)}
                                            isLoading={isLoading}
                                            onClick={() => setPixelModalOpenFor(web.id)}
                                        />
                                    </TableCell>
                                </StyledTableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            {/* Hidden for now */}
            {/* <AddWebModal
        userInfo={user}
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      /> */}
            <PixelConnectionModal
                isOpen={!!pixelModalOpenFor}
                openedForId={pixelModalOpenFor}
                onClose={() => setPixelModalOpenFor('')}
            />
        </Box>
    )
}

const useStyles = makeStyles((theme: Theme) => ({
    table: {
        minWidth: 550,
    },
    tableCell: {
        padding: '0px 24px',
    },
    thead: {
        backgroundColor: theme.palette.natural.t2,
        '& th:first-child': {
            borderRadius: '4px 0 0 0px',
        },
        '& th:last-child': {
            borderRadius: '0 4px 0 0',
        },
    },
}))

export default Websites

import { FormControl, InputLabel, MenuItem, Typography, useTheme } from '@mui/material'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import { FC } from 'react'
import { DropdownMenuItem } from './types'
import { useTranslation } from 'react-i18next'

interface WebDropdownProps {
    items: DropdownMenuItem[]
    value?: string
    onChange: (event: SelectChangeEvent) => void
    label: string
    id: string
    width?: number
    disabled?: boolean
}

const WebDropdown: FC<WebDropdownProps> = ({ items, value, onChange, label, width, id, disabled }) => {
    const theme = useTheme()
    const { t } = useTranslation()

    const defaultColor = theme.palette.main.primary.d2
    const hoverColor = theme.palette.main.primary.d1
    const selectedColor = theme.palette.main.primary.o
    const textDefault = theme.palette.natural.t1
    return (
        <FormControl fullWidth style={{ width: width }}>
            <InputLabel id={id}>{label}</InputLabel>
            <Select
                style={{ height: 40 }}
                defaultValue=''
                labelId={id}
                id={`custom-dropdown-${id}`}
                value={value}
                label={label}
                onChange={onChange}
                disabled={disabled}
                sx={{
                    '&& fieldset': {
                        border: `1px solid ${defaultColor}`,
                    },
                    '&:hover': {
                        '&& fieldset': {
                            border: `1px solid ${hoverColor}`,
                        },
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        border: `1px solid ${selectedColor}`,
                    },
                }}
                MenuProps={{
                    PaperProps: {
                        sx: {
                            padding: 0,
                            color: textDefault,
                            '& .MuiMenuItem-root.Mui-selected': {
                                backgroundColor: theme.palette.main.primary.d3,
                                color: theme.palette.main.primary.o,
                            },
                            '& .MuiMenuItem-root:hover': {
                                backgroundColor: theme.palette.main.primary.d3,
                                color: theme.palette.main.primary.o,
                            },
                            '& .MuiMenuItem-root.Mui-selected:hover': {
                                backgroundColor: theme.palette.main.primary.d3,
                                color: theme.palette.main.primary.o,
                            },
                        },
                    },
                }}
            >
                {items.length > 0 &&
                    items.map((item, index) => (
                        <MenuItem
                            key={`${index}-value`}
                            value={item.value}
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                height: 44,
                                justifyContent: 'center',
                                alignItems: 'flex-start',
                            }}
                        >
                            <Typography style={{ fontSize: 16 }}>
                                {item.value === 'redirect' ? t('websites.title') : item.title}
                            </Typography>
                        </MenuItem>
                    ))}
            </Select>
        </FormControl>
    )
}

export default WebDropdown

import { Box, Stack, Theme, Typography, useTheme } from '@mui/material'
import { FC } from 'react'
import ModalBase from './ModalBase'
import { makeStyles } from '@mui/styles'
import AddHeurekaKey from '../assets/images/AddHeurekaKey.png'
import CloseIcon from '@mui/icons-material/Close'
import CustomButton from './CustomButton'
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined'
import { useTranslation } from 'react-i18next'

interface ApiKeyModalProps {
    type?: 'heureka' | 'zbozi' | 'spolehlivaRecenze'
    isOpen: boolean
    onClose: () => void
    onOpenModal: () => void
}

const textMap = {
    heureka: {
        title: 'campaign.notifications.settings.displayRules.heureka.title',
        helpUrl: 'https://www.overenyweb.cz/napoveda/nastaveni-notifikaci/heureka-recenze-a-hodnoceni/',
        notificationText: 'campaign.notifications.settings.displayRules.heureka.description',
        addButtonTitle: 'addKey',
    },
    zbozi: {
        title: 'campaign.notifications.settings.displayRules.zbozi.title',
        helpUrl: 'https://www.overenyweb.cz/napoveda/nastaveni-notifikaci/zbozi-cz-recenze-a-hodnoceni/',
        notificationText: 'campaign.notifications.settings.displayRules.zbozi.description',
        addButtonTitle: 'addKey',
    },
    spolehlivaRecenze: {
        title: 'campaign.notifications.settings.displayRules.spolehlivaRecenze.title',
        helpUrl: 'https://www.overenyweb.cz/napoveda/nastaveni-notifikaci/spolehlive-recenze-a-hodnoceni/', // Update this URL
        notificationText: 'campaign.notifications.settings.displayRules.spolehlivaRecenze.description',
        addButtonTitle: 'login',
    },
}

const ApiKeyModal: FC<ApiKeyModalProps> = ({ isOpen, onClose, onOpenModal, type }) => {
    const theme = useTheme()
    const classes = useStyles()
    const { t } = useTranslation()

    const { title, helpUrl, notificationText, addButtonTitle } = textMap[type || 'heureka']

    return (
        <ModalBase center onClose={onClose} isOpen={isOpen}>
            <Box className={classes.container}>
                <Box className={classes.closeIconBox}>
                    <CloseIcon style={{ cursor: 'pointer' }} onClick={onClose} />
                </Box>
                <Box className={classes.imgContainer}>
                    <img style={{ marginTop: 12 }} src={AddHeurekaKey} alt='registration' width={301} height={209} />
                </Box>
                <Stack justifyContent='center' direction='row'>
                    <Typography align='center' className={classes.primaryText}>
                        {t(title)}
                    </Typography>
                    <Typography align='center' className={classes.primaryText}>
                        <HelpOutlineOutlinedIcon
                            titleAccess={t('help') ?? 'Nápověda'}
                            sx={{ color: theme.palette.natural.t2, marginTop: 1.4, marginLeft: 1, cursor: 'pointer' }}
                            onClick={() => window.open(helpUrl)}
                        />
                    </Typography>
                </Stack>
                <Typography align='center' style={{ fontSize: 16, color: theme.palette.natural.t }}>
                    {t(notificationText)}
                </Typography>
                <Box className={classes.buttonsContainer}>
                    <CustomButton
                        style={{ marginRight: 32, width: 112 }}
                        type='primary'
                        size='medium'
                        title={addButtonTitle}
                        onClick={onOpenModal}
                    />
                    <CustomButton
                        style={{ marginRight: 20, width: 100 }}
                        type='secondary'
                        size='medium'
                        title={t('close')}
                        onClick={onClose}
                    />
                </Box>
            </Box>
        </ModalBase>
    )
}

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        width: 663,
        backgroundColor: theme.palette.natural.t4,
        borderRadius: 8,
        boxShadow: theme.palette.main.shadow.secondary,
    },
    closeIconBox: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row-reverse',
        marginTop: 22,
        marginRight: 37,
    },
    imgContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    primaryText: {
        fontSize: 28,
        fontWeight: 'bold',
        color: theme.palette.main.secondary.u1,
        marginTop: 32,
        marginBottom: 24,
    },
    buttonsContainer: {
        display: 'flex',
        flexDirection: 'row-reverse',
        marginTop: 32,
        marginBottom: 32,
    },
}))

export default ApiKeyModal

import { Box, useTheme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React, { useEffect, useState } from 'react'
import Tab from '@mui/material/Tab'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import CircularProgressBox from '../../../components/CircularProgressBox'
import { showFlashMessageWithTimeout } from '../../../services/actions/flashMessage-actions'
import { CampaignManager } from '../../../services/api/CampaignManager'
import { OrderRecordDto, RegistrationRecordDto } from '../../../shared/types/activityStorageTypes'
import CampaignAnalyticsTable from './CampaignAnalyticsTable'
import { Theme } from '@mui/system'
import CustomButton from '../../../components/CustomButton'

type TabType = 'orders' | 'registrations'

const CampaignAnalytics = () => {
    const dispatch = useDispatch()
    const theme = useTheme()
    const { id } = useParams()
    const classes = useStyles()

    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [ordersAnalytics, setOrdersAnalytics] = useState<OrderRecordDto[]>()
    const [registrationsAnalytics, setRegistrationsAnalytics] = useState<RegistrationRecordDto[]>()
    const [selectedTab, setSelectedTab] = useState<TabType>('orders')

    const activeColor = theme.palette.natural.t1
    const notActiveColor = theme.palette.natural.t2

    const fetchCampaignAnalytics = async () => {
        if (!id) return
        try {
            setIsLoading(true)
            const ordersAnalyticsRes = await CampaignManager.getManager().getCampaignOrdersAnalyticsAsync(id)
            const registrationsAnalyticsRes = await CampaignManager.getManager().getCampaignRegistrationsAnalyticsAsync(id)
            if (ordersAnalyticsRes) {
                setOrdersAnalytics(ordersAnalyticsRes.orders)
            }
            if (registrationsAnalyticsRes) {
                setRegistrationsAnalytics(registrationsAnalyticsRes.registrations)
            }
            setIsLoading(false)
        } catch (e: any) {
            setIsLoading(false)
            showFlashMessageWithTimeout(dispatch, e.toString(), 'error')
        }
    }

    useEffect(() => {
        fetchCampaignAnalytics()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    if (isLoading) return <CircularProgressBox />

    return (
        <div>
            <Box sx={{ width: '100%' }}>
                <TabContext value={selectedTab}>
                    <TabList
                        TabIndicatorProps={{
                            style: {
                                background: theme.palette.main.primary.o,
                                height: 3,
                            },
                        }}
                        onChange={(_, newValue) => setSelectedTab(newValue as TabType)}
                    >
                        <Tab
                            disableRipple
                            className={classes.tab}
                            style={{
                                color: selectedTab === 'orders' ? activeColor : notActiveColor,
                            }}
                            label='Poslední objednávky'
                            value='orders'
                        />
                        <Tab
                            disableRipple
                            className={classes.tab}
                            style={{
                                color: selectedTab === 'registrations' ? activeColor : notActiveColor,
                            }}
                            label='Poslední registrace'
                            value='registrations'
                        />
                        <Box style={{ flex: 1 }} />
                        <CustomButton
                            title='Aktulizovat data'
                            onClick={() => fetchCampaignAnalytics()}
                            type='primary'
                            size='medium'
                            style={{ width: 184, marginBottom: 8, marginTop: 8 }}
                        />
                    </TabList>
                    <TabPanel value='orders' style={{ padding: 0 }}>
                        <CampaignAnalyticsTable analytics={ordersAnalytics ?? []} />
                    </TabPanel>
                    <TabPanel value='registrations' style={{ padding: 0 }}>
                        <CampaignAnalyticsTable analytics={registrationsAnalytics ?? []} />
                    </TabPanel>
                </TabContext>
            </Box>
        </div>
    )
}

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        flex: 1,
        marginLeft: 24,
        marginRight: 24,
        marginTop: 24,
    },
    title: {
        fontSize: 28,
        fontWeight: 'bold',
        color: theme.palette.main.secondary.u1,
        marginBottom: 20,
        height: 42,
    },
    tabsDivider: {
        width: '100%',
        height: 3,
        backgroundColor: theme.palette.natural.t3,
        position: 'absolute',
        bottom: 0,
    },
    tab: {
        textTransform: 'none',
        fontSize: 16,
    },
}))

export default CampaignAnalytics

import { Box } from '@mui/material'
import { useSelector } from 'react-redux'
import { getActiveWebId } from '../services/selectors/web-selectors'
import AddApiKeys from './OnBoarding/AddApiKeys'

const Connections = () => {
    const activeWebId = useSelector(getActiveWebId)

    if (activeWebId) {
        return (
            <Box style={{ flex: 1, marginLeft: 24, marginRight: 24 }}>
                <AddApiKeys isConnectionsScreen createdWebId={activeWebId} />
            </Box>
        )
    }
    return <></>
}

export default Connections

import { ReduxState } from './main-reducer-types'
import { getEmptyAuthState } from '../../services/types/auth-types'

const initState: ReduxState = {
    flashMessages: {
        flashMessages: [],
    },
    user: {
        info: {
            id: '',
            createdAt: null,
            lastModifiedAt: null,
            name: '',
            email: '',
            isAdmin: false,
            accountType: 'personal',
            remainingPricingPlanCredits: 0,
            isInTrial: false,
            pricingPlanName: undefined,
            hasUnlimitedAccess: false,
            language: 'cs',
            registeredFrom: 'internal',
        },
        adminInfo: {
            id: '',
            createdAt: null,
            lastModifiedAt: null,
            name: '',
            email: '',
            isAdmin: false,
            accountType: 'personal',
            remainingPricingPlanCredits: 0,
            isInTrial: false,
            pricingPlanName: undefined,
            hasUnlimitedAccess: false,
            language: 'cs',
            registeredFrom: 'internal',
        },
    },
    web: {
        active: undefined,
        activeId: undefined,
        webs: [],
    },
    auth: getEmptyAuthState(),
}

export default initState

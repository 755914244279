export interface FlashMessagesReduxState {
    flashMessages: FlashMessageProps[]
}

export interface FlashMessageProps {
    id: number
    message: string
    type: FlashMessageType
}

export type FlashMessageType = 'success' | 'info' | 'error'

export enum FlashMessagesActionTypes {
    SHOW_FLASH_MESSAGE = 'SHOW_FLASH_MESSAGE',
    REMOVE_FLASH_MESSAGE = 'REMOVE_FLASH_MESSAGE',
    CLEAR_ALL_FLASH_MESSAGES = 'CLEAR_ALL_FLASH_MESSAGES',
}

export interface ShowFlashMessage {
    type: typeof FlashMessagesActionTypes.SHOW_FLASH_MESSAGE
    payload: {
        id?: number
        message: string
        type: FlashMessageType
    }
}

export interface RemoveFlashMessage {
    type: typeof FlashMessagesActionTypes.REMOVE_FLASH_MESSAGE
    payload: {
        flashMessageId: number
    }
}

export interface ClearAllFlashMessages {
    type: typeof FlashMessagesActionTypes.CLEAR_ALL_FLASH_MESSAGES
}

export type FlashMessageActions = ShowFlashMessage | RemoveFlashMessage | ClearAllFlashMessages

import { WebsiteDto } from '../../shared/types/websiteTypes'
import { ReduxAction } from '../types/main-reducer-types'
import { WebActionTypes, WebReduxState } from '../types/web-types'

export default function webReducer(state: WebReduxState, action: ReduxAction): WebReduxState {
    switch (action.type) {
        case WebActionTypes.SET_ACTIVE_WEB: {
            return {
                ...state,
                active: action.payload.active,
                activeId: action.payload.activeId,
            }
        }
        case WebActionTypes.SET_WEBS: {
            return {
                ...state,
                webs: action.payload,
            }
        }
        case WebActionTypes.ADD_WEB: {
            return {
                ...state,
                webs: [...state.webs, action.payload],
            }
        }
        case WebActionTypes.UPDATE_WEB: {
            const copy: WebsiteDto[] = [...state.webs]
            const { id, name, url, language } = action.payload
            const web = copy.find((item) => item.id === id)
            if (web) {
                web.name = name
                web.url = url
                web.language = language
            }
            return {
                ...state,
                webs: copy,
            }
        }

        case WebActionTypes.CLEAR_WEBS: {
            return {
                active: undefined,
                activeId: undefined,
                webs: [],
            }
        }

        default:
            return state
    }
}

import { Box, CircularProgress, Theme, Typography, useTheme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import PaymentSuccessIcon from '../../assets/images/paymentSuccessIcon.png'
import CustomButton from '../../components/CustomButton'
import { Routes } from '../../navigation/navigation-types'
import { showFlashMessageWithTimeout } from '../../services/actions/flashMessage-actions'
import { setUserInfo } from '../../services/actions/user-actions'
import { UserManager } from '../../services/api/UserManager'
import { getUserInfo } from '../../services/selectors/user-selectors'
import { useTranslation } from 'react-i18next'

const PaymentSuccess = () => {
    const theme = useTheme()
    const navigate = useNavigate()
    const classes = useStyles()
    const dispatch = useDispatch()
    const user = useSelector(getUserInfo)
    const { t } = useTranslation()

    const [isLoading, setIsLoading] = useState<boolean>(false)

    useEffect(() => {
        const fetchUserFullInfo = async () => {
            try {
                setIsLoading(true)
                const _userManager = UserManager.getManager()
                const result = await _userManager.getUserSingleAsync()
                dispatch(setUserInfo(result))
                if (!user.isActivationConfirmed) {
                    UserManager.getManager().confirmActivationAsync(user.id)
                }
                setIsLoading(false)
            } catch (e: any) {
                setIsLoading(false)
                showFlashMessageWithTimeout(dispatch, e.toString(), 'error')
            }
        }
        fetchUserFullInfo()
    }, [dispatch])

    isLoading && (
        <Box className={classes.loadingContainer}>
            <CircularProgress style={{ color: theme.palette.main.primary.o }} />
        </Box>
    )

    return (
        <Box style={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <img style={{ marginTop: 144 }} src={PaymentSuccessIcon} alt='success icon' height={190} width={110} />
            <Typography
                style={{ fontSize: 28, color: theme.palette.natural.t, fontWeight: '700', marginTop: 50, marginBottom: 10 }}
            >
                {t('subscriptions.successPayment')}
            </Typography>
            <Typography style={{ fontSize: 16, fontWeight: '400', color: theme.palette.natural.t, marginBottom: 36 }}>
                {t('subscriptions.successPaymentDescription')}
            </Typography>
            <CustomButton
                title={t('continue')}
                size='large'
                style={{ width: 140 }}
                onClick={() => navigate(`${Routes.profile}`, { state: { preselectedTab: 'subscription' } })}
            />
        </Box>
    )
}

const useStyles = makeStyles((theme: Theme) => ({
    loadingContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 32,
    },
}))

export default PaymentSuccess

import { Box, useTheme } from '@mui/material'
import { FC } from 'react'
import { CampaignPosition } from '../../../shared/types/campaignTypes'

interface PositionPreviewProps {
    position: CampaignPosition
    isActive: boolean
    index: number
}

const PositionPreview: FC<PositionPreviewProps> = ({ position, isActive, index }) => {
    const theme = useTheme()
    let positionStyle: React.CSSProperties = {}

    if (position === 'bottomLeft') {
        positionStyle = { alignItems: 'end', justifyContent: 'left' }
    }

    if (position === 'topLeft') {
        positionStyle = { alignItems: 'start', justifyContent: 'left' }
    }

    if (position === 'topRight') {
        positionStyle = { alignItems: 'start', justifyContent: 'right' }
    }

    if (position === 'bottomRight') {
        positionStyle = { alignItems: 'end', justifyContent: 'right' }
    }
    if (position === 'top') {
        positionStyle = { justifyContent: 'center' }
    }
    if (position === 'bottom') {
        positionStyle = { flexDirection: 'column-reverse' }
    }

    if (position === 'top' || position === 'bottom') {
        return (
            <Box
                style={{
                    width: 58,
                    height: 100,
                    borderWidth: 4,
                    borderRadius: 4,
                    borderStyle: 'solid',
                    display: 'flex',
                    borderColor: isActive ? theme.palette.main.primary.o : theme.palette.natural.t3,
                    backgroundColor: isActive ? '#FFF9F1' : '#FFFFFF',
                    ...positionStyle,
                }}
            >
                <Box
                    style={{
                        width: 42,
                        height: 17,
                        borderRadius: 1,
                        margin: 8,
                        display: 'flex',
                        backgroundColor: isActive ? theme.palette.main.secondary.o : theme.palette.natural.t3,
                    }}
                />
            </Box>
        )
    } else {
        return (
            <Box
                style={{
                    width: 146,
                    height: 100,
                    borderWidth: 4,
                    borderRadius: 4,
                    borderStyle: 'solid',
                    display: 'flex',
                    borderColor: isActive ? theme.palette.main.primary.o : theme.palette.natural.t3,
                    backgroundColor: isActive ? '#FFF9F1' : '#FFFFFF',
                    ...positionStyle,
                }}
            >
                <Box
                    style={{
                        width: 42,
                        height: 17,
                        borderRadius: 1,
                        margin: 8,
                        display: 'flex',
                        backgroundColor: isActive ? theme.palette.main.secondary.o : theme.palette.natural.t3,
                    }}
                />
            </Box>
        )
    }
}

export default PositionPreview

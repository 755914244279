export const cs = {
    sidePanel: {
        campaign: 'Kampaň',
        connection: 'Propojení',
        settings: 'Nastavení',
        infinite: 'Bez omezení',
    },
    noTariffBanner: {
        title: 'Váš účet nemá aktivní žádný tarif',
        description:
            'Bez aktivního tarifu nemůžeme zobrazit notifikace na Vašich stránkách a zvyšovat tak jejich konverzní poměr.',
        button1: 'Aktivovat tarif',
        button2: 'na prvních 14 dnů zdarma',
    },
    apiKeys: 'API klíče',
    addingApiKeys: 'Přidání API klíčů',
    optionalAddApiKey: 'Tento krok je dobrovolný. API klíče můžete přidat později',
    monthlyCredits: 'Měsíční kredity',
    portableCredits: 'Přenosné kredity',
    help: 'Nápověda',
    reportProblem: 'Nahlásit chybu',
    saveChanges: 'Uložit změny',
    reset: 'Reset',
    defaultSettings: 'Výchozí nastavení',
    addKey: 'Přidat klíč',
    login: 'Přihlásit se',
    back: 'Zpět',
    close: 'Zavřít',
    delete: 'Vymazat',
    verified: 'Ověřeno',
    yes: 'Ano',
    notVerified: 'Neověřeno',
    connected: 'Propojeno',
    active: 'Aktivní',
    inactive: 'Neaktivní',
    check: 'Zkontrolovat',
    add: 'Přidat',
    edit: 'Upravit',
    save: 'Uložit',
    continue: 'Pokračovat',
    userIsNotLoggedIn: 'Uživatel není přihlášen',
    deleteWebsite: 'Vymazat web',
    spolehliveRecenze: 'Spolehlive Recenze',
    logout: 'Odhlásit',
    paymentFailed: {
        title: 'Platba byla zrušena',
        description: 'Platbu se nepodařilo dokončit.',
        continue: 'Pokračovat',
    },
    loggedOut: {
        title: 'Byl jste odhlášen',
        description: 'Stránku můžete zavřít.',
        close: 'Zavřít stránku',
    },
    passwordChange: {
        title: 'Změna hesla',
        oldPassword: 'Současné heslo',
        newPassword: 'Nové heslo',
        confirmPassword: 'Potvrďte nové heslo',
    },
    onBoarding: {
        title: 'Přidejte svůj první web',
        description: 'Nebojte se, provedeme vás krok po kroku. Brzo budete moct využívat všech výhod, které Ověřený web přínáší.',
        start: 'Začít',
        logout: 'Odhlásit se',
    },
    resetPassword: {
        title: 'K jakému účtu potřebujete obnovit přístup?',
        reset: 'Resetovat',
    },
    deleteWeb: {
        title: 'Vymazat web?',
        description: 'Veškerá data a kampaně budou vymazány.',
        delete: 'Vymazat',
        close: 'Zavřít',
    },
    loginScreen: {
        title: 'Přihlášení',
        email: 'Email',
        password: 'Heslo',
        button: 'Přihlásit se',
        newUsers: 'Nový uživatel?',
        forgotPassword: 'Zapomněli jste heslo?',
        resetPassword: 'Resetovat',
        signUp: 'Registrujte se',
    },
    registrationScreen: {
        title: {
            first: 'Jste jen jeden krok od',
            second: 'zvýšení konverzního poměru',
            third: 'Vašeho e-shopu ',
            fourth: 'až o 20 %!',
        },
        subTitle: 'Vyplňte následující údaje a připojte se k úspěšným e-shopařům',
        name: 'Jméno',
        phone: 'Telefonní číslo (nepovinné)',
        email: 'Email',
        password: 'Heslo',
        confirmPassword: 'Potvrďte heslo',
        accept: 'Souhlasím s',
        terms: 'podmínkami',
        button: 'AKTIVOVAT 14 DNÍ ZDARMA',
        alreadyHaveAccount: 'Máte účet?',
        login: 'Přihlásit se',
    },
    addWebsite: {
        title: 'Přidejte web',
        name: 'Název webu',
        warning: 'Vložte celou URL adresu Vaší webové stránky včetně https.',
        includeHttps: 'včetně https',
        url: 'URL webu',
        notificationLanguage: 'Jazyk notifikací',
        czech: 'Čeština',
        slovak: 'Slovenština',
        polish: 'Polština',
    },
    howToInstallPixel: {
        title: 'Jak nainstalovat pixel',
        first: 'Zkopírujte kód Pixelu.',
        second: 'Vložte kód do hlavičky Vašeho webu na Váš web před </head>.',
        third: 'Navštivte Vaší stránku, tím aktivujete pixel kód. Následně zkontrojte pomocí skeneru jestli je pixel aktivní.',
    },
    websites: {
        title: 'Zobrazit všechny',
        website: 'Web',
        created: 'Vytvořena',
        campaigns: 'Kampaně',
        addWebsite: 'Přidat web',
        connect: 'Propojit',
        connected: 'Propojeno',
        yourWebsites: 'Vaše weby',
    },
    popups: {
        deletionWasSuccessful: 'Odstranění proběhlo úspěšně.',
        saveWasSuccessful: 'Úspěšně uloženo',
        visualChanged: 'Vzhled byl nastaven úspěšně.',
        notificationAdded: 'Notifikace byla přidána.',
        setButtonText: 'Nastavte text tlačítka.',
        missingHttp: 'Url musí mít "http" a nebo "https" na začátku.',
        addNameAndText: 'Přidejte název notifikace a text.',
        textMustBeFilled: 'Text notifikace musí být vyplněný.',
        connectionMissing: 'Musíte splnit alespoň jednu položku ve "Spárování".',
        added: 'Přidáno.',
        fillAllFields: 'Vyplňte všechna pole',
        fillApiKey: 'Zadejte API klič',
        notVerified: 'Není ověřeno',
        changesSaved: 'Změny byly úspěšně uloženy',
        changeSubscriptionDisabled: 'Změna podmínek předplatného je zakázána',
        subscriptionChangedTo: 'Předplatné změněno na: ',
        subscriptionCanceled: 'Předplatné úspěšně zrušeno',
        nameIsEmpty: 'Jméno nemůže být prázdné',
        cannotCreateNotification: 'Nepodařilo se vytvořit notifikaci.',
    },
    campaign: {
        addFirstCampaign: 'Přidejte svojí první kampaň',
        setUpFirstCampaign: 'Nastavte svojí první kampaň a začněte vydělávat.',
        addCampaign: 'Přidat kampaň',
        addNotification: 'Přidat notifikaci',
        deleteCampaign: 'Vymazat kampaň?',
        table: {
            name: 'Jméno',
            creationDate: 'Vytvořena',
            notificationCount: 'Notifikace',
            status: 'Status',
            delete: 'Vymazat',
        },
        settings: {
            title: 'Nastavení',
            campaignName: 'Název kampaně',
            newCampaign: 'Nová kampaň',
            whereWillCampaignBeShown: 'Kde se kampaň bude zobrazovat',
            url: {
                startsWith: 'Začíná na',
                includes: 'Obsahuje',
                equals: 'Shoduje se',
            },
            includes: 'Zahrnuje',
            exclude: 'Vylučuje (nepovinné)',
            addCondition: 'Přidat podmínku',
        },
        design: {
            title: 'Vzhled',
            notificationsDesign: 'Vzhled notifikací',
            designDescription:
                'Nastavení pro celou kampaň, vzhled pro konkrétní notifikaci můžete měnit v nastavení samotné notifikace.',
            computer: 'Počítač',
            mobile: 'Mobil',
            position: 'Pozice',
            downLeftCorner: 'Dolní levý roh',
            downRightCorner: 'Dolní pravý roh',
            upLeftCorner: 'Horní levý roh',
            upRightCorner: 'Horní pravý roh',
            down: 'Dole',
            up: 'Nahoře',
            animations: 'Animace',
            playAnimation: 'Přehrát animaci',
            animationSettings: {
                showAfter: 'Notifkace se zobrazí po',
                showDelay: 'Doba zobrazení',
                delayBetween: 'Prodleva mezi notifikacemi',
                showSpeed: 'Rychlost zobrazení',
                second: 'vteřin',
                seconds: 'vteřinách',
            },
        },
        notifications: {
            title: 'Notifikace',
            noNotifications: 'Nemáte zatím žádné notifikace',
            addNewNotification: 'Přidejte notifikaci',
            table: {
                type: 'Typ',
                preview: 'Náhled',
                status: 'Status',
            },
            settings: {
                displayRules: {
                    title: 'Pravidla zobrazení',
                    text: 'Text',
                    notificationText: 'Text notifikace',
                    variant: 'Varianta',
                    choseVariant: 'Vyberte variantu',
                    days: 'dní',
                    h: 'h',
                    show: 'Zobrazí',
                    notificationName: 'Název notifikace',
                    nameNotification: 'Pojmenujte notifikaci',
                    repeatNotification: 'Opakování notifikací',
                    fillNotificationText: 'Zadejte text notifikace',
                    actualVisitors: {
                        nameVisitors: 'Pojmenujte návštěvníky',
                        visitors: 'návštěvníků',
                        maxVisitors: 'Minimální počet návštěvníků pro zobrazení',
                    },
                    lastOrders: {
                        maxLast: 'Maximálně posledních',
                        maxToShow: 'Minimální počet pro zobrazení je',
                        whenAtLeast: 'Notifikace pouze pokud je alespoň',
                        ordersLast: 'Objednávky za posledních',
                        haveOrderedPlaceholder: 'už má objednáno. A co vy?',
                        showOwnOrders: 'Ukázat návštěvníkům vlastní objednávky',
                        notRecoginzed: 'Nerozpoznaná jména jako "Zákazník"',
                        orders: 'objednávek',
                    },
                    lastRegistrations: {
                        registrations: 'registrací',
                        notRecoginzed: 'Nerozpoznaná jména jako "Návštěvník"',
                        registrationsLast: 'Registrace za posledních',
                        showOwnRegistrations: 'Ukázat návštěvníkům vlastní regisrace',
                        isRegistredPlaceholder: 'má dokončenou registraci',
                    },
                    visitorSummary: {
                        visitors: 'Návštěvníci',
                        registrations: 'Registrace',
                        orders: 'Objednávky',
                        visitors2: 'návštěvníků',
                        registrations2: 'registrací',
                        orders2: 'objednávek',
                        last: 'za posledních',
                        minNumber: 'Minimální počet',
                        toShow: 'pro zobrazení',
                    },
                    individual: {
                        link: 'Odkaz',
                        button: 'Tlačítko',
                        buttonName: 'Pojmenujte',
                        url: 'URL',
                        buttonAsLink: 'Odkaz jako tlačítko',
                        textColor: 'Barva textu',
                        openLinkInNewWindow: 'Otevřít odkaz v novém okně',
                        buttonColor: 'Barva tlačítka',
                        removedLinkDescription: 'Odebrání odkazu umožní delší text notifikace.',
                        tooLongTextErrorMessage: 'Text přesahuje maximální délku.',
                    },
                    webName: 'Název webu',
                    apiKey: 'API klíč',
                    heureka: {
                        title: 'Propojení s Heurekou',
                        description: 'Pro aktivaci této notifikace je nutný Heureka API klíč. Chcete API klíč přidat?',
                        heurekaApiKey: 'Heureka API klíč',
                        country: 'Země',
                        stars: 'hvězdičky',
                        reviewsWithRating: 'Recenze více než s',
                        maxReviews: 'Minimální počet recenzí pro zobrazení',
                        howToAddApiKey: {
                            title: 'Jak přidat Heureka klíč',
                            first: 'V Heurece účtu v horním menu vyberte Administrace e-shopu.',
                            second: 'V levém menu vyberte Ověřeno zákazníky.',
                            third: 'Zkopírujte tajný klíč a vložte ho do API klíč pole.',
                            thirdHelp:
                                'Klíč je k dispozici pokud máte dostatek hodnocení a splňujete “Oveřeno zákazníky” podmínky.',
                            fourth: 'Ověřte status',
                        },
                    },
                    zbozi: {
                        title: 'Propojení s Zboží.cz',
                        description: 'Pro aktivaci této notifikace je nutný Zboží.cz API klíč. Chcete API klíč přidat?',
                        howToAddKey: 'Jak přidat Zboží.cz API klíč',
                        shopId: 'ID provozovny',
                        storeReview: 'hodnocení obchodu',
                        productReview: 'hodnocení produktu',
                        maxReviews: 'Minimální počet recenzí pro zobrazení',
                        howToAddApiKey: {
                            title: 'Jak přidat Zboží.cz klíč',
                            first: 'V Zboží.cz účtu v hlavním menu vyberte záložku Provozovna.',
                            second: 'Zkopírujte ID vybraného eshopu a vlože to ho do ID provozovny pole.',
                            third: 'Na stránce Správa provozovny v levém menu vyhledejte položku Správa API a vytvořte API klíč.',
                            thirdHelp:
                                'Budete zde potřebovat ID provozovny, které jste získali v 2. kroku. Vygenerovaný API klíč vložte do pole API klíč.',
                            fourth: 'Ověřte status',
                        },
                    },
                    spolehlivaRecenze: {
                        title: 'Propojení se spolehlivéRecenze',
                        description:
                            'Pro aktivaci této notifikace je nutný se přihlásit k Spolehlivým Recenzím. Chcete se přihlásit?',
                        howToAddApiKey: {
                            title: 'Jak se přihlásit k Spolehlivé Recenze',
                            first: 'Zkopírujte shopid a heslo z Vašeho účtu u Spolehlivé Recenze.',
                            second: 'Vložte tyto údaje do příslušných políček výše.',
                            third: 'Ověřte status.',
                        },
                    },
                },
                pairing: {
                    title: 'Spárování',
                    collectData: 'Zachycení dat',
                    addCondition: 'Přidat podmínku',
                    cantEdit: 'Nelze editovat',
                    pairingCondition: 'Pravidlo párování',
                    celectedDomain: 'Vybraná doména',
                    pageVisitors: 'Návštěvníci jednotlivých stránek',
                    domainVisitors: 'Souhrny návštěvníků celé domény',
                    campaignCollects: 'Kampaň bude zachytávat návštěvníky každé stránky samostatně.',
                },
                design: {
                    title: 'Vzhled',
                    description: 'Výchozí vzhled je nastaven pro celou kampaň, zde ho můžete upravit pro konkrétní notifikaci',
                    backgroundColour: 'Barva pozadí',
                    textColour: 'Barva textu',
                    iconBackgroundColour: 'Barva pozadí ikony',
                    iconColour: 'Barva ikony',
                    roundedCorners: 'Zaoblení rohů',
                    icon: 'Ikona',
                },
            },
            deleteModal: {
                title: 'Vymazat notifikace?',
                description: 'Veškerá data budou vymazána.',
            },
            lastOrders: {
                title: 'Poslední objednávky',
                description: 'Doporučeno pro e-shopy s 10 a více objednávkami týdně.',
                previewText1: 'Josef z Košic',
                previewText2: 'má dokončenou objednávku',
            },
            lastRegistrations: {
                title: 'Poslední registrace',
                description: 'Doporučeno pro poptávkové weby a weby sbírající kontakty.',
                previewText1: 'Josef z Košic',
                previewText2: 'má dokončenou registrací',
            },
            actualVisitors: {
                title: 'Návštěvnost - aktuální statistiky',
                description: 'Doporučeno pro weby s minimální denní návštěvností 1 000.',
                previewText1: 'návštěvníků',
            },
            visitorSummary: {
                title: 'Návštěvnost - souhrné statistiky',
                description: 'Doporučeno pro weby s denní návštěvností pod 1 000.',
                previewText1: 'návštěvníků',
                previewText2: 'uživatelé',
            },
            orderSummary: {
                title: 'Návštěvnost - souhrné statistiky',
            },
            heureka: {
                title: 'Heuréka - recenze a hodnocení',
                description: 'Doporučeno pro e-shopy, které mají kladné textové recenze na Heuréce',
                previewText1:
                    'V porovnaní s 12 pro max oceňujem úžasnú kameru (4k videá sú top) a tiež fotografie - obrovský rozdiel a posun. Výborne makro. Rýchly a spoľahlivý telefón. Veľká spokojnosť. Farba je krásna.',
                previewText2: 'doporučuje',
            },
            zbozi: {
                title: 'Zboží - recenze a hodnocení',
                description: 'Doporučeno pro e-shopy, které mají kladné textové recenze na Zboží.cz',
                previewText1:
                    'V porovnaní s 12 pro max oceňujem úžasnú kameru (4k videá sú top) a tiež fotografie - obrovský rozdiel a posun. Výborne makro. Rýchly a spoľahlivý telefón. Veľká spokojnosť. Farba je krásna.',
                previewText2: 'doporučuje',
            },
            individual: {
                title: 'Vlastní',
                description:
                    'Otextujte si notifikaci dle libosti. Skvělá notifikace pro komunikaci slevy, dopravy zdarma a dalších benefitů vašich produktů a služeb.',
                previewText1: 'Zaregistrujte se do našeho e-shopu a získejte 5 % slevu na Váš nákup.',
                previewText2: 'Získejte dárek',
            },
            shoptetLastOrders: {
                title: 'Poslední objednávky pro Shoptet',
                description: 'Exkluzivní notifikace pro e-shopy na platformě Shoptet.',
                timeRange: 'Časové rozmezí',
                showRandom: 'Zobrazovat náhodné objednávky za poslední ',
                twoMonths: '2 měsíce',
                screwdriver: 'Šroubovák',
            },
            shoptetCouponCode: {
                title: 'Shoptet slevový kód',
                description: 'Exkluzivní notifikace pro e-shopy na platformě Shoptet.',
                info: 'Notifikace předpokládá že na platformě shoptet máte vytvořený slevový kupón o kterém zde vyplníte informace',
                code: 'Slevový kód',
                saleType: 'Hodnota slevy',
                saleIn: 'Sleva v',
                saleOn: 'Sleva na',
                wholeSale: 'Celý nákup',
                sale10: 'SLEVA10',
                previewText: 'Použijte slevový kód "SLEVA10" a získejte slevu 10 % na celý nákup.',
            },
            spolehlivaRecenzeShopRating: {
                title: 'Spolehlivé recenze - recenze a hodnocení',
                description: 'Doporučeno pro e-shopy, které mají kladné textové recenze na spolehliverecenze.cz',
                previewText1: 'TODO',
            },
            spolehlivaRecenzeProductRating: {
                title: 'Spolehlivé recenze - recenze a hodnocení',
                description: 'Doporučeno pro e-shopy, které mají kladné textové recenze na spolehliverecenze.cz',
                previewText1: 'TODO',
            },
        },
    },
    subscriptions: {
        title: 'Předplatné',
        successPayment: 'Platba proběhla v pořádku.',
        successPaymentDescription: 'Během okamžiku se vám kredity objeví na účtě.',
        wantToChangeSubscription: 'Opravdu si přejete změnit předplatné',
        changeFrom: 'ze ',
        changeTo: 'na ',
        subTitle: 'Vaše předplatné',
        portableCreditsDescription: 'Tyto kredity nemají exipraci. Použijí se pokud jste přesáhli výši měsíčního kreditu.',
        tarifs: {
            title: 'Tarify',
            monthly: 'Měsíčně',
            yearly: 'Ročně',
            freeMonths: '2 měsíce zdarma',
            viewsPerMonth: 'počet zobrazení za měsíc',
            trial: 'zkušební verze - bez omezení',
            creditsRefresh: 'Obnova kreditů proběhne: ',
            creditsExpire: 'Kredity expirují: ',
            subbscription: 'Předplatné',
            trialVersion: 'zkušební verze',
            trialVersionExpires: 'Zkušební verze vyprší',
            cancelSubscription: 'Zrušit obnovu předplatného',
            nextPayment: 'Další platba bude automaticky provedena',
            unlimitedVersion: 'Je aktivní neomezená verze aplikace',
            choseSubscription: 'Vybrat tarif',
            to: 'Do',
            monthlyViews: 'zobrazení stránek měsíčně',
            pricesAreFinal: 'Uvedené ceny jsou finální.',
            moreViews: 'Mají vaše stránky ještě více zobrazení měsíčně?',
            contactUsForCustom: 'Kontaktujte nás pro tarif na míru.',
            notificationsWithStatistics: 'Notifikace se statistikami návštěvnosti',
            notificationsWithLastOrdersAndRegistrations: 'Notifikace posledních objednávek a registrací',
            notificationPersonalization: 'Personalizace notifikací',
            zboziAndHeureka: 'Notifikace recenzí z Heureky a Zboží.cz',
            unlimitedNotifications: 'Neomezeně notifikací',
            unlimitedWebsites: 'Neomezený počet webů',
            onlyOneWebsite: '(pouze 1 web)',
            activateFreeTrial: 'Aktivovat 14 dní zdarma',
            mostFavorite: 'Nejoblíbenější',
            selected: 'Vybráno',
            lite: {
                title: 'LITE',
                priceYearly: '39 Kč',
                priceMonthly: '47 Kč',
            },
            start: {
                title: 'START',
                priceYearly: '247 Kč',
                priceMonthly: '297 Kč',
            },
            standard: {
                title: 'STANDARD',
                priceYearly: '457 Kč',
                priceMonthly: '547 Kč',
            },
            premium: {
                title: 'PREMIUM',
                priceYearly: '833 Kč',
                priceMonthly: '997 Kč',
            },
        },
    },
    contact: {
        title: 'Kontaktní údaje',
        basic: {
            title: 'Základní informace',
            accountType: 'Typ účtu',
            personal: 'Osobní',
            business: 'Podnikatelský',
            name: 'Jméno',
            surname: 'Příjmení',
            phone: 'Telefoní číslo',
            dic: 'DIČ',
            ico: 'IČO',
            companyName: 'Název společnosti',
        },
        address: {
            title: 'Adresa',
            street: 'Ulice a č. p.',
            city: 'Město',
            zip: 'PSČ',
            country: 'Země',
            czechRepublic: 'Česká republika',
            slovakia: 'Slovensko',
            hungary: 'Maďarsko',
            polish: 'Polsko',
        },
    },
    shoptetFirstTouchScreen: {
        title: 'Instalace doplňku byla úspěšná.',
        description1:
            'Připravili jsme pro Váš e-shop sadu chytrých notifikací, které dle našich zkušeností s podobnými e-shopy nejefektivněji zvýší počet objednávek Vašeho e-shopu. Ty se spustí automaticky. Ihned po aktivaci Vaší zkušební jízdy zdarma.',
        description2:
            'Klikněte na tlačítko. Aktivujte si chytré notifikace a už jen pozorujte, jak každý den roste počet objednávek.',
        button: 'Spustit testovacích 14 dní zdarma',
        error: 'Přihlášení se nezdařilo odhlašte se a zkuste to znovu.',
    },
    webarealFirstTouchScreen: {
        title: 'Instalace doplňku byla úspěšná.',
        description1:
            'Připravili jsme pro Váš e-shop sadu chytrých notifikací, které dle našich zkušeností s podobnými e-shopy nejefektivněji zvýší počet objednávek Vašeho e-shopu. Ty se spustí automaticky. Ihned po aktivaci Vaší zkušební jízdy zdarma.',
        description2:
            'Klikněte na tlačítko. Aktivujte si chytré notifikace a už jen pozorujte, jak každý den roste počet objednávek.',
        button: 'Spustit testovacích 14 dní zdarma',
        error: 'Přihlášení se nezdařilo odhlašte se a zkuste to znovu.',
    },
    upgatesFirstTouchScreen: {
        title: 'Instalace doplňku byla úspěšná.',
        description1:
            'Připravili jsme pro Váš e-shop sadu chytrých notifikací, které dle našich zkušeností s podobnými e-shopy nejefektivněji zvýší počet objednávek Vašeho e-shopu. Ty se spustí automaticky. Ihned po aktivaci Vaší zkušební jízdy zdarma.',
        description2:
            'Klikněte na tlačítko. Aktivujte si chytré notifikace a už jen pozorujte, jak každý den roste počet objednávek.',
        button: 'Spustit testovacích 14 dní zdarma',
        error: 'Přihlášení se nezdařilo odhlašte se a zkuste to znovu.',
    },
}

import { Box, Typography, useTheme } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import CustomButton from '../components/CustomButton'

const NotFound = () => {
    const navigate = useNavigate()
    const theme = useTheme()
    return (
        <Box style={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Typography
                    style={{ fontSize: 50, color: theme.palette.main.primary.o, fontWeight: 'bold', textAlign: 'center' }}
                >
                    404
                </Typography>
                <Typography style={{ marginBottom: 36, fontSize: 16, fontWeight: '400', color: theme.palette.natural.t1 }}>
                    Nenašli jsme požadovanou stránku
                </Typography>
                <CustomButton style={{ width: 68 }} size='medium' title='Zpět' onClick={() => navigate(-1)} />
            </Box>
        </Box>
    )
}

export default NotFound

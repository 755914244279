import { Box, useTheme } from '@mui/material'
import { FC } from 'react'
import parse from 'html-react-parser'

interface NotificationPreviewProps {
    notificationHtml: string
    backgroundColor?: string
    height?: number
}

const NotificationPreview: FC<NotificationPreviewProps> = ({ notificationHtml, backgroundColor, height }) => {
    const theme = useTheme()
    return (
        <Box
            id='notification-preview'
            style={{
                height: height ? height : 160,
                width: '100%',
                backgroundColor: backgroundColor ? backgroundColor : theme.palette.natural.t9,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            {parse(notificationHtml)}
        </Box>
    )
}

export default NotificationPreview

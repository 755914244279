import { Box, Typography, useTheme, Theme } from '@mui/material'
import ProgressIndicator from '../../components/ProgressIndicator'
import { makeStyles } from '@mui/styles'
import { useState } from 'react'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import StarIcon from '@mui/icons-material/Star'
import DoneRoundedIcon from '@mui/icons-material/DoneRounded'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import CustomButton from '../../components/CustomButton'
import { showFlashMessageWithTimeout } from '../../services/actions/flashMessage-actions'
import { UserManager } from '../../services/api/UserManager'
import { useDispatch, useSelector } from 'react-redux'
import { UserDto } from '../../shared/types/userTypes'
import { translatePeriodOfTime } from '../../utils/subscrtiption-utils'
import { setUserInfo } from '../../services/actions/user-actions'
import CancelSubscriptionModal from '../../components/CancelSubscriptionModal'
import ChangeSubscriptionModal from '../../components/ChangeSubscriptionModal'
import { PricingPlanPeriod } from '../../shared/types/pricingPlanTypes'
import { getAdminInfo } from '../../services/selectors/user-selectors'
import { useTranslation } from 'react-i18next'

type PricingPlanType = 'lite' | 'start' | 'standard' | 'premium'

interface PricingPlan {
    type: PricingPlanType
    periodOfTime: PricingPlanPeriod
    benefits: string[]
    disadvantages?: string[]
    numberOfNotifications: number
    price: number
    huPrice: number
    skPrice: number
    plPrice: number
}

const yearPricingPlans: PricingPlan[] = [
    {
        type: 'lite',
        periodOfTime: 'year',
        benefits: [
            'notificationsWithStatistics',
            'notificationsWithLastOrdersAndRegistrations',
            'notificationPersonalization',
            'zboziAndHeureka',
            'unlimitedNotifications',
        ],
        disadvantages: ['unlimitedWebsites'],
        numberOfNotifications: 6000,
        price: 39,
        huPrice: 610,
        skPrice: 1.59,
        plPrice: 6.92,
    },
    {
        type: 'start',
        periodOfTime: 'year',
        benefits: [
            'notificationsWithStatistics',
            'notificationsWithLastOrdersAndRegistrations',
            'notificationPersonalization',
            'zboziAndHeureka',
            'unlimitedNotifications',
            'unlimitedWebsites',
        ],

        numberOfNotifications: 30000,
        price: 247,
        huPrice: 3867,
        skPrice: 10.09,
        plPrice: 43.71,
    },
    {
        type: 'standard',
        periodOfTime: 'year',
        benefits: [
            'notificationsWithStatistics',
            'notificationsWithLastOrdersAndRegistrations',
            'notificationPersonalization',
            'zboziAndHeureka',
            'unlimitedNotifications',
            'unlimitedWebsites',
        ],
        numberOfNotifications: 70000,
        price: 457,
        huPrice: 7154,
        skPrice: 18.58,
        plPrice: 80.5,
    },
    {
        type: 'premium',
        periodOfTime: 'year',
        benefits: [
            'notificationsWithStatistics',
            'notificationsWithLastOrdersAndRegistrations',
            'notificationPersonalization',
            'zboziAndHeureka',
            'unlimitedNotifications',
            'unlimitedWebsites',
        ],
        numberOfNotifications: 150000,
        price: 833,
        huPrice: 13040,
        skPrice: 33.86,
        plPrice: 146.72,
    },
]

const monthPricingPlans: PricingPlan[] = [
    {
        type: 'lite',
        periodOfTime: 'month',
        benefits: [
            'notificationsWithStatistics',
            'notificationsWithLastOrdersAndRegistrations',
            'notificationPersonalization',
            'zboziAndHeureka',
            'unlimitedNotifications',
        ],
        disadvantages: ['unlimitedWebsites'],
        numberOfNotifications: 6000,
        price: 47,
        huPrice: 736,
        skPrice: 1.91,
        plPrice: 8.29,
    },
    {
        type: 'start',
        periodOfTime: 'month',
        benefits: [
            'notificationsWithStatistics',
            'notificationsWithLastOrdersAndRegistrations',
            'notificationPersonalization',
            'zboziAndHeureka',
            'unlimitedNotifications',
            'unlimitedWebsites',
        ],
        numberOfNotifications: 30000,
        price: 297,
        huPrice: 4650,
        skPrice: 12.07,
        plPrice: 52.45,
    },
    {
        type: 'standard',
        periodOfTime: 'month',
        benefits: [
            'notificationsWithStatistics',
            'notificationsWithLastOrdersAndRegistrations',
            'notificationPersonalization',
            'zboziAndHeureka',
            'unlimitedNotifications',
            'unlimitedWebsites',
        ],
        numberOfNotifications: 70000,
        price: 547,
        huPrice: 8563,
        skPrice: 22.23,
        plPrice: 96.61,
    },
    {
        type: 'premium',
        periodOfTime: 'month',
        benefits: [
            'notificationsWithStatistics',
            'notificationsWithLastOrdersAndRegistrations',
            'notificationPersonalization',
            'zboziAndHeureka',
            'unlimitedNotifications',
            'unlimitedWebsites',
        ],
        numberOfNotifications: 150000,
        price: 997,
        huPrice: 15607,
        skPrice: 40.51,
        plPrice: 176.07,
    },
]

interface ProfileSubscriptionProps {
    user: UserDto | undefined
    onUpdateUser: (user: UserDto) => void
}

const ProfileSubscription: React.FC<ProfileSubscriptionProps> = ({ user, onUpdateUser }: ProfileSubscriptionProps) => {
    const theme = useTheme()
    const classes = useStyles()
    const dispatch = useDispatch()
    const { t, i18n } = useTranslation()

    const [newSelectedPlan, setNewSelectedPlan] = useState<PricingPlan>()

    const [selectedSubscriptionType, setSelectedSubscriptionType] = useState<PricingPlanPeriod>(
        user?.pricingPlanPeriod ? user.pricingPlanPeriod : 'year'
    )
    const [isSubscriptionCanceling, setIsSubscriptionCanceling] = useState<boolean>(false)
    const [isSubscriptionLoading, setIsSubscriptionLoading] = useState<boolean>(false)

    const [isCancelSubscriptionModalOpen, setIsCancelSubscriptionModalOpen] = useState<boolean>(false)
    const [isChangeSubscriptionModalOpen, setIsChangeSubscriptionModalOpen] = useState<boolean>(false)

    const planList = selectedSubscriptionType === 'month' ? monthPricingPlans : yearPricingPlans

    const adminInfo = useSelector(getAdminInfo)
    const disableActions = adminInfo.id.length > 0

    const CreateStripeSubscriptionCheckout = async (
        pricingPlanName: PricingPlanType,
        period: PricingPlanPeriod,
        numberOfNotifications: number
    ) => {
        try {
            if (disableActions) {
                showFlashMessageWithTimeout(dispatch, t('popups.changeSubscriptionDisabled'), 'error')
                return
            }
            setIsSubscriptionLoading(true)
            if (!user) throw new Error('Uživatel nenalezen')
            const checkoutRes = await UserManager.getManager().createStripeSubscriptionCheckout({
                pricingPlanName: pricingPlanName,
                period: period,
            })
            if (checkoutRes.sessionUrl) {
                window.location.href = checkoutRes.sessionUrl
            }
            if (checkoutRes === 200) {
                onUpdateUser({
                    ...user,
                    lastPricingPlanName: user.pricingPlanName,
                    isInTrial: false,
                    pricingPlanName: pricingPlanName,
                    pricingPlanPeriod: period,
                    maxPricingPlanCredits: numberOfNotifications,
                    remainingPricingPlanCredits: numberOfNotifications,
                    lastPricingPlanPeriodStartsAt: new Date(),
                    lastPricingPlanPeriodEndsAt:
                        period === 'month'
                            ? new Date(new Date().setMonth(new Date().getMonth() + 1))
                            : new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
                })
                setIsChangeSubscriptionModalOpen(false)
                showFlashMessageWithTimeout(
                    dispatch,
                    t('popups.subscriptionChangedTo') + pricingPlanName.toUpperCase(),
                    'success'
                )
            }
        } catch (err: any) {
            setIsSubscriptionLoading(false)
            return showFlashMessageWithTimeout(dispatch, err.toString(), 'error')
        }
        setIsSubscriptionLoading(false)
        dispatch(setUserInfo(user))
    }

    const CancelStripeSubscription = async () => {
        try {
            if (disableActions) {
                showFlashMessageWithTimeout(dispatch, t('popups.changeSubscriptionDisabled'), 'error')
                return
            }
            setIsSubscriptionCanceling(true)
            if (!user) throw new Error('Uživatel nenalezen')
            await UserManager.getManager().cancelStripeSubscription()
            onUpdateUser({
                ...user,
                lastPricingPlanName: user?.pricingPlanName,
                pricingPlanName: undefined,
            })
        } catch (err: any) {
            setIsSubscriptionCanceling(false)
            return showFlashMessageWithTimeout(dispatch, err.toString(), 'error')
        }
        setIsSubscriptionCanceling(false)
        setIsCancelSubscriptionModalOpen(false)
        dispatch(setUserInfo(user))
        showFlashMessageWithTimeout(dispatch, t('popups.subscriptionCanceled'), 'success')
    }

    const HandleOpenChangeSubscriptionModal = (
        periodOfTime: PricingPlanPeriod,
        type: PricingPlanType,
        numberOfNotifications: number
    ) => {
        setNewSelectedPlan({
            periodOfTime,
            type,
            benefits: [],
            disadvantages: [],
            numberOfNotifications: numberOfNotifications,
            price: 0,
            huPrice: 0,
            skPrice: 0,
            plPrice: 0,
        })
        setIsChangeSubscriptionModalOpen(true)
    }

    if (!user) {
        return <Typography variant='h5'>{t('userIsNotLoggedIn')}</Typography>
    }

    return (
        <Box style={{ flex: 1 }}>
            <Box className={classes.container}>
                <Typography className={classes.titleText}>{t('subscriptions.subTitle')}</Typography>
                <Box className={classes.sectionContainer} style={{ marginTop: 24 }}>
                    <Box className={classes.upperSectionContainer}>
                        <Typography className={classes.mainText}>{t('monthlyCredits')}</Typography>
                        <Box className={classes.indicatorTextContainer}>
                            <Typography className={user.isInTrial ? classes.italicIndicatorText : classes.indicatorText}>
                                {user.isInTrial
                                    ? t('subscriptions.tarifs.trial')
                                    : `${user?.remainingPricingPlanCredits} / ${user?.maxPricingPlanCredits ?? 0}`}
                            </Typography>
                            {!user.isInTrial && (
                                <ProgressIndicator
                                    progressInPercent={
                                        user?.maxPricingPlanCredits
                                            ? (user?.remainingPricingPlanCredits / user?.maxPricingPlanCredits) * 100
                                            : 0
                                    }
                                />
                            )}
                        </Box>
                    </Box>
                    <Box className={classes.sectionDivider} />
                    <Typography className={classes.secondaryText}>
                        {!user.isInTrial &&
                            user.nextCreditsChargedAt &&
                            ' ' +
                                t('subscriptions.tarifs.creditsRefresh') +
                                ' ' +
                                new Date(user.nextCreditsChargedAt).toLocaleDateString('cs-CS')}
                        {!user.nextCreditsChargedAt &&
                            user.lastPricingPlanPeriodEndsAt &&
                            t('subscriptions.tarifs.creditsExpire') +
                                ' ' +
                                new Date(user.lastPricingPlanPeriodEndsAt).toLocaleDateString('cs-CS') +
                                ' (' +
                                user.lastPricingPlanName +
                                ')'}
                    </Typography>
                </Box>
                <Box className={classes.sectionContainer}>
                    <Box className={classes.upperSectionContainer}>
                        <Typography className={classes.mainText}>{t('portableCredits')}</Typography>
                        <Box className={classes.indicatorTextContainer}>
                            <Typography className={classes.indicatorText}>{user?.remainingExtraCredits}</Typography>
                        </Box>
                    </Box>
                    <Box className={classes.sectionDivider} />
                    <Typography className={classes.secondaryText}>{t('subscriptions.portableCreditsDescription')}</Typography>
                </Box>
                {user.pricingPlanName && (
                    <Box className={classes.sectionContainer}>
                        <Box className={classes.upperSectionContainer}>
                            <Typography className={classes.mainText}>
                                {t('subscriptions.tarifs.subbscription')}{' '}
                                {user?.isInTrial && t('subscriptions.tarifs.trialVersion') + ' '}
                                {user.pricingPlanName &&
                                    user.pricingPlanPeriod &&
                                    user.pricingPlanName + ' ' + translatePeriodOfTime(user.pricingPlanPeriod)}
                            </Typography>
                            <Typography className={classes.coloredText} onClick={() => setIsCancelSubscriptionModalOpen(true)}>
                                {t('subscriptions.tarifs.cancelSubscription')}
                            </Typography>
                            <CancelSubscriptionModal
                                isOpen={isCancelSubscriptionModalOpen}
                                onClose={() => setIsCancelSubscriptionModalOpen(false)}
                                onCancelSubscription={CancelStripeSubscription}
                                isLoading={isSubscriptionCanceling}
                            />
                        </Box>
                        <Box className={classes.sectionDivider} />
                        {!user.hasUnlimitedAccess && (
                            <Typography className={classes.secondaryText}>
                                {user?.isInTrial
                                    ? `${t('subscriptions.tarifs.creditsExpire')} `
                                    : `${t('subscriptions.tarifs.creditsExpire')} `}
                                {new Date(user?.lastPricingPlanPeriodEndsAt ?? '').toLocaleDateString('cs-CS')}
                            </Typography>
                        )}
                        {user.hasUnlimitedAccess && (
                            <Typography className={classes.secondaryText}>
                                {t('subscriptions.tarifs.unlimitedVersion')}
                            </Typography>
                        )}
                    </Box>
                )}
                <Typography className={classes.titleText} style={{ marginTop: 40 }}>
                    {t('subscriptions.tarifs.title')}
                </Typography>
                <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <Box className={classes.buttonsContainer}>
                        <Box
                            className={classes.leftButton}
                            style={{
                                backgroundColor:
                                    selectedSubscriptionType === 'month'
                                        ? theme.palette.main.secondary.o
                                        : theme.palette.natural.t7,
                            }}
                            onClick={() => setSelectedSubscriptionType('month')}
                        >
                            <Typography
                                style={{
                                    fontSize: 16,
                                    fontWeight: selectedSubscriptionType === 'month' ? '700' : '500',
                                    color:
                                        selectedSubscriptionType === 'month'
                                            ? theme.palette.natural.t4
                                            : theme.palette.natural.t2,
                                }}
                            >
                                {t('subscriptions.tarifs.monthly')}
                            </Typography>
                        </Box>
                        <Box
                            className={classes.rightButton}
                            style={{
                                backgroundColor:
                                    selectedSubscriptionType === 'year'
                                        ? theme.palette.main.secondary.o
                                        : theme.palette.natural.t7,
                            }}
                            onClick={() => setSelectedSubscriptionType('year')}
                        >
                            <Typography
                                style={{
                                    fontSize: 16,
                                    fontWeight: selectedSubscriptionType === 'year' ? '700' : '500',
                                    color:
                                        selectedSubscriptionType === 'year' ? theme.palette.natural.t4 : theme.palette.natural.t2,
                                }}
                            >
                                {t('subscriptions.tarifs.yearly')}
                            </Typography>
                        </Box>
                    </Box>
                    {selectedSubscriptionType === 'year' && (
                        <Box className={classes.bonusCard}>
                            <Box className={classes.bonusCardIconContainer}>
                                <AttachMoneyIcon className={classes.bonusCardIcon} />
                            </Box>
                            <Typography className={classes.bonusCardText}>{t('subscriptions.tarifs.freeMonths')}</Typography>
                        </Box>
                    )}
                </Box>
                <Box style={{ flex: 1, display: 'flex', flexDirection: 'row', marginTop: 32, marginBottom: 24 }}>
                    {planList.map((plan, index) => (
                        <Box className={classes.planCardContainer} key={index}>
                            <Box style={{ height: 17, marginTop: 16, marginBottom: 3 }}>
                                {plan.type === 'standard' && (
                                    <Typography className={classes.favoriteText}>
                                        {t('subscriptions.tarifs.mostFavorite')}
                                    </Typography>
                                )}
                            </Box>
                            <Box className={classes.planTypeContainer}>
                                <Box className={classes.starIconBox}>
                                    {plan.type === 'standard' && (
                                        <StarIcon style={{ color: theme.palette.main.secondary.o, fontSize: 24 }} />
                                    )}
                                </Box>
                                <Typography className={classes.planTypeText}>
                                    {t('subscriptions.tarifs.' + plan.type + '.title')}
                                </Typography>
                                <Box className={classes.starIconBox}></Box>
                            </Box>
                            <Typography
                                style={{
                                    textAlign: 'center',
                                    fontSize: 35,
                                    fontWeight: '700',
                                    color: theme.palette.natural.t,
                                }}
                            >
                                {t(
                                    'subscriptions.tarifs.' +
                                        plan.type +
                                        (selectedSubscriptionType === 'year' ? '.priceYearly' : '.priceMonthly')
                                )}
                            </Typography>
                            {i18n.language === 'hu' && (
                                <Typography
                                    style={{
                                        textAlign: 'center',
                                        fontSize: 16,
                                        fontWeight: '400',
                                        color: theme.palette.natural.t,
                                    }}
                                >
                                    ≈ {plan.huPrice.toLocaleString()} Ft
                                </Typography>
                            )}
                            {i18n.language === 'sk' && (
                                <Typography
                                    style={{
                                        textAlign: 'center',
                                        fontSize: 16,
                                        fontWeight: '400',
                                        color: theme.palette.natural.t,
                                    }}
                                >
                                    ≈ {plan.skPrice.toFixed(2).replace('.', ',')} €
                                </Typography>
                            )}
                            {i18n.language === 'pl' && (
                                <Typography
                                    style={{
                                        textAlign: 'center',
                                        fontSize: 16,
                                        fontWeight: '400',
                                        color: theme.palette.natural.t,
                                    }}
                                >
                                    ≈ {plan.plPrice.toFixed(2).replace('.', ',')} zł
                                </Typography>
                            )}
                            <Typography
                                style={{
                                    textAlign: 'center',
                                    fontSize: 16,
                                    fontWeight: '400',
                                    color: theme.palette.natural.t2,
                                }}
                            >
                                {t('subscriptions.tarifs.monthly')}
                            </Typography>
                            <Typography className={classes.numberOfShowsText}>
                                {t('subscriptions.tarifs.viewsPerMonth')}
                            </Typography>
                            <Typography className={classes.numberOfNotificationText}>{plan.numberOfNotifications}</Typography>
                            <Box className={classes.listContainer} style={{ marginTop: 20 }}>
                                <Box className={classes.listItem}>
                                    <Box className={classes.iconContainer}>
                                        <DoneRoundedIcon style={{ color: theme.palette.main.accent.o }} />
                                    </Box>
                                    <Typography className={classes.listItemText}>{`${t(
                                        'subscriptions.tarifs.to'
                                    )} ${plan.numberOfNotifications.toLocaleString()} ${t(
                                        'subscriptions.tarifs.monthlyViews'
                                    )} `}</Typography>
                                </Box>
                                {plan.benefits.map((b, index) => (
                                    <Box key={index} className={classes.listItem}>
                                        <Box className={classes.iconContainer}>
                                            <DoneRoundedIcon style={{ color: theme.palette.main.accent.o }} />
                                        </Box>
                                        <Typography className={classes.listItemText}>{t('subscriptions.tarifs.' + b)}</Typography>
                                    </Box>
                                ))}
                            </Box>
                            {plan.disadvantages?.length !== 0 && (
                                <Box className={classes.listContainer}>
                                    {plan.disadvantages?.map((d, index) => (
                                        <Box className={classes.listItem} key={index}>
                                            <Box className={classes.closeIconContainer}>
                                                <CloseRoundedIcon style={{ color: theme.palette.natural.t3 }} />
                                            </Box>
                                            <Typography className={classes.listItemText}>
                                                {t('subscriptions.tarifs.' + d) +
                                                    (d === 'unlimitedWebsites'
                                                        ? ' ' + t('subscriptions.tarifs.onlyOneWebsite')
                                                        : '')}
                                            </Typography>
                                        </Box>
                                    ))}
                                </Box>
                            )}
                            <Box style={{ flex: 1 }} />
                            <Box className={classes.planButtonContainer}>
                                {user.pricingPlanName?.toLowerCase() === plan.type &&
                                user.pricingPlanPeriod?.toLowerCase() === plan.periodOfTime ? (
                                    <Box
                                        style={{
                                            height: 44,
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <DoneRoundedIcon style={{ color: theme.palette.main.primary.o }} />
                                        <Typography style={{ color: theme.palette.main.primary.o, fontSize: 16, marginLeft: 10 }}>
                                            {t('subscriptions.tarifs.selected')}
                                        </Typography>
                                    </Box>
                                ) : (
                                    <>
                                        <CustomButton
                                            size='medium'
                                            style={{ paddingLeft: theme.spacing(1), paddingRight: theme.spacing(1) }}
                                            title={
                                                user.lastPricingPlanName || user.pricingPlanName
                                                    ? t('subscriptions.tarifs.choseSubscription')
                                                    : t('subscriptions.tarifs.activateFreeTrial')
                                            }
                                            isLoading={isSubscriptionLoading}
                                            isDisabled={isSubscriptionLoading}
                                            onClick={() =>
                                                user.pricingPlanName
                                                    ? HandleOpenChangeSubscriptionModal(
                                                          plan.periodOfTime,
                                                          plan.type,
                                                          plan.numberOfNotifications
                                                      )
                                                    : CreateStripeSubscriptionCheckout(
                                                          plan.type,
                                                          plan.periodOfTime,
                                                          plan.numberOfNotifications
                                                      )
                                            }
                                        />
                                        <ChangeSubscriptionModal
                                            isOpen={isChangeSubscriptionModalOpen}
                                            onClose={() => setIsChangeSubscriptionModalOpen(false)}
                                            oldSubscription={user.pricingPlanName}
                                            newSubscription={newSelectedPlan?.type}
                                            onChangeSubscription={() =>
                                                newSelectedPlan &&
                                                CreateStripeSubscriptionCheckout(
                                                    newSelectedPlan.type,
                                                    newSelectedPlan.periodOfTime,
                                                    newSelectedPlan.numberOfNotifications
                                                )
                                            }
                                            isLoading={isSubscriptionLoading}
                                        />
                                    </>
                                )}
                            </Box>
                        </Box>
                    ))}
                </Box>
                <Box className={classes.footerTextContainer}>
                    <Typography className={classes.footerTopText}>{t('subscriptions.tarifs.pricesAreFinal')}</Typography>
                    <Box className={classes.footerHintContainer}>
                        <Typography className={classes.footerTopText}>{t('subscriptions.tarifs.moreViews')}</Typography>
                        <a href='javascript:void(Tawk_API.toggle())'>
                            <Typography className={classes.footerBottomText}>
                                {t('subscriptions.tarifs.contactUsForCustom')}
                            </Typography>
                        </a>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        marginTop: 24,
    },
    sectionDivider: {
        height: 2,
        width: '100%',
        backgroundColor: theme.palette.natural.t8,
        marginTop: 8,
        marginBottom: 8,
    },
    upperSectionContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    sectionContainer: {
        display: 'flex',
        flexDirection: 'column',
        maxWidth: 564,
        marginTop: 40,
    },
    indicatorTextContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    indicatorText: {
        fontSize: 14,
        color: theme.palette.natural.t1,
        marginRight: 8,
    },
    italicIndicatorText: {
        fontSize: 14,
        color: theme.palette.natural.t1,
        marginRight: 8,
        fontStyle: 'italic',
    },
    coloredText: {
        color: theme.palette.main.primary.o,
        fontSize: 14,
        fontWeight: 'bold',
        cursor: 'pointer',
    },
    mainText: {
        fontSize: 16,
        fontWeight: 'bold',
        color: theme.palette.natural.t1,
    },
    secondaryText: {
        fontSize: 14,
        color: theme.palette.natural.t1,
    },
    errorText: {
        fontSize: 22,
        color: theme.palette.error.main,
    },
    titleText: {
        fontSize: 22,
        fontWeight: 'bold',
        color: theme.palette.main.secondary.u1,
    },
    leftButton: {
        height: 48,
        width: 235,
        borderTopLeftRadius: 4,
        borderBottomLeftRadius: 4,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
    },
    rightButton: {
        height: 48,
        width: 235,
        borderTopRightRadius: 4,
        borderBottomRightRadius: 4,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
    },
    buttonsContainer: {
        height: 48,
        width: 470,
        borderRadius: 4,
        display: 'flex',
        flexDirection: 'row',
    },
    bonusCard: {
        height: 28,
        width: 163,
        backgroundColor: theme.palette.main.primary.o,
        borderRadius: 4,
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        marginLeft: 8,
    },
    bonusCardIconContainer: {
        height: 16,
        width: 16,
        borderRadius: 8,
        backgroundColor: theme.palette.main.secondary.o,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginLeft: 10,
    },
    bonusCardIcon: {
        color: theme.palette.main.primary.o,
        fontSize: 16,
    },
    bonusCardText: {
        color: theme.palette.natural.t4,
        fontSize: 14,
        fontWeight: '400',
        marginLeft: 10,
    },
    planTypeContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
    },
    starIconBox: {
        height: 42,
        width: 42,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    listContainer: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: 24,
    },
    listItem: {
        display: 'flex',
        flexDirection: 'row',
        marginBottom: 16,
        alignItems: 'center',
    },
    iconContainer: {
        height: 24,
        width: 24,
        borderRadius: 12,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.palette.main.accent.d2,
        marginRight: 16,
    },
    listItemText: {
        fontSize: 16,
        fontWeight: '400',
        color: theme.palette.natural.t1,
        lineHeight: 1.2,
        marginRight: 24,
    },
    footerTextContainer: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
    },
    footerTopText: {
        textAlign: 'center',
        fontSize: 16,
        fontWeight: '400',
        color: theme.palette.natural.t1,
        marginTop: 2,
    },
    footerHintContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        marginTop: 20,
        marginBottom: 40,
    },
    footerFirstText: {
        fontSize: 18,
        fontWeight: '700',
        color: theme.palette.natural.t1,
    },
    footerBottomText: {
        fontSize: 18,
        fontWeight: '700',
        color: theme.palette.main.primary.o,
        marginLeft: 4,
    },
    planButtonContainer: {
        marginTop: 42,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: 36,
        height: 44,
    },
    closeIconContainer: {
        height: 24,
        width: 24,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: 16,
    },
    planCardContainer: {
        width: 312,
        marginRight: 32,
        display: 'flex',
        flexDirection: 'column',
        boxShadow: theme.palette.main.shadow.primary,
        borderRadius: 4,
        backgroundColor: theme.palette.natural.t4,
        paddingLeft: 10,
        paddingRight: 10,
    },
    favoriteText: {
        fontSize: 14,
        fontWeight: '400',
        color: theme.palette.natural.t2,
        textAlign: 'center',
    },
    planTypeText: {
        textAlign: 'center',
        fontSize: 28,
        textTransform: 'uppercase',
        color: theme.palette.natural.t1,
        fontWeight: 'bold',
        marginBottom: 8,
    },
    numberOfShowsText: {
        marginTop: 32,
        textAlign: 'center',
        fontSize: 16,
        fontWeight: '400',
        color: theme.palette.natural.t1,
    },
    numberOfNotificationText: {
        marginTop: 8,
        textAlign: 'center',
        fontSize: 16,
        fontWeight: '700',
        color: theme.palette.natural.t,
    },
    loadingContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 32,
    },
}))

export default ProfileSubscription

import { Box, Typography, useTheme, Theme } from '@mui/material'
import { useEffect, useState } from 'react'
import Tab from '@mui/material/Tab'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import CampaignSettings from './CampaignSettings'
import { makeStyles } from '@mui/styles'
import CampaignNotifications from './CampaignNotifications'
import CampaignView from './CampaignView'
import CustomButton from '../../../components/CustomButton'
import { useNavigate, useParams } from 'react-router-dom'
import { Routes } from '../../../navigation/navigation-types'
import { CampaignManager } from '../../../services/api/CampaignManager'
import { useDispatch, useSelector } from 'react-redux'
import { showFlashMessageWithTimeout } from '../../../services/actions/flashMessage-actions'
import CampaignAnalytics from './CampaignAnalytics'
import { getAdminInfo } from '../../../services/selectors/user-selectors'
import { useTranslation } from 'react-i18next'

type TabType = 'settings' | 'view' | 'notifications' | 'analytics'

const AddCampaign = () => {
    const theme = useTheme()
    const classes = useStyles()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { id } = useParams()
    const { t } = useTranslation();
    const preselectedTab = sessionStorage.getItem('selectedTab') as TabType
    const admin = useSelector(getAdminInfo)

    const [selectedTab, setSelectedTab] = useState<TabType>(preselectedTab ? preselectedTab : 'settings')
    const [campaignName, setCampaignName] = useState<string>('')

    useEffect(() => {
        const fetchCampaignAsync = async () => {
            if (id) {
                try {
                    const _campaignManager = CampaignManager.getManager()
                    const result = await _campaignManager.getSingleCampaignAsync(id)
                    setCampaignName(result.name)
                } catch (e: any) {
                    showFlashMessageWithTimeout(dispatch, e.toString(), 'error')
                }
            }
        }
        fetchCampaignAsync()
    })

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setSelectedTab(newValue as TabType)
        sessionStorage.setItem('selectedTab', newValue)
    }

    const activeColor = theme.palette.natural.t1
    const notActiveColor = theme.palette.natural.t2

    return (
        <Box className={classes.container}>
            <Typography className={classes.title}>{campaignName}</Typography>

            <Box sx={{ width: '100%' }}>
                <TabContext value={selectedTab}>
                    <TabList
                        TabIndicatorProps={{
                            style: {
                                background: theme.palette.main.primary.o,
                                height: 3,
                            },
                        }}
                        onChange={handleChange}
                    >
                        <Tab
                            disableRipple
                            className={classes.tab}
                            style={{
                                color: selectedTab === 'settings' ? activeColor : notActiveColor,
                            }}
                            label={t('campaign.settings.title')}
                            value='settings'
                        />
                        <Tab
                            disableRipple
                            className={classes.tab}
                            style={{
                                color: selectedTab === 'view' ? activeColor : notActiveColor,
                            }}
                            label={t('campaign.design.title')}
                            value='view'
                        />
                        <Tab
                            disableRipple
                            className={classes.tab}
                            style={{
                                color: selectedTab === 'notifications' ? activeColor : notActiveColor,
                            }}
                            label={t('campaign.notifications.title')}
                            value='notifications'
                        />
                        {admin.isAdmin && (
                            <Tab
                                disableRipple
                                className={classes.tab}
                                style={{
                                    color: selectedTab === 'analytics' ? activeColor : notActiveColor,
                                }}
                                label='Datová analytika'
                                value='analytics'
                            />
                        )}
                        <Box style={{ flex: 1 }} />
                        <CustomButton
                            title={t('campaign.addNotification')}
                            onClick={() => navigate(`${Routes.campaign}/${id}${Routes.notification}`)}
                            type='primary'
                            size='medium'
                            style={{ width: 184, marginBottom: 8 }}
                        />
                        <Box className={classes.tabsDivider} />
                    </TabList>
                    <TabPanel value='settings' style={{ padding: 0 }}>
                        <CampaignSettings setCampaignNameInParent={(name) => setCampaignName(name)} />
                    </TabPanel>
                    <TabPanel value='view' style={{ padding: 0 }}>
                        <CampaignView />
                    </TabPanel>
                    <TabPanel value='notifications' style={{ padding: 0 }}>
                        <CampaignNotifications />
                    </TabPanel>
                    <TabPanel value='analytics' style={{ padding: 0 }}>
                        <CampaignAnalytics />
                    </TabPanel>
                </TabContext>
            </Box>
        </Box>
    )
}

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        flex: 1,
        marginLeft: 24,
        marginRight: 24,
        marginTop: 24,
    },
    title: {
        fontSize: 28,
        fontWeight: 'bold',
        color: theme.palette.main.secondary.u1,
        marginBottom: 20,
        height: 42,
    },
    tabsDivider: {
        width: '100%',
        height: 3,
        backgroundColor: theme.palette.natural.t3,
        position: 'absolute',
        bottom: 0,
    },
    tab: {
        textTransform: 'none',
        fontSize: 16,
    },
}))

export default AddCampaign

import { Typography, Box, SelectChangeEvent, useTheme, Theme, CircularProgress } from '@mui/material'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CustomButton from '../../components/CustomButton'
import CustomDropdown from '../../components/CustomDropdown'
import CustomTextField from '../../components/CustomTextField'
import { showFlashMessageWithTimeout } from '../../services/actions/flashMessage-actions'
import { WebsiteManager } from '../../services/api/WebsiteManager'
import { getActiveWebId, getActiveWebUrl, getUserWebs } from '../../services/selectors/web-selectors'
import { Language } from '../../shared/types/websiteTypes'
import { makeStyles } from '@mui/styles'
import PixelStatus from '../../components/PixelStatus'
import PixelConnectionModal from '../../components/PixelConnectionModal'
import AddWebModal from '../Websites/AddWebModal'
import { getUserInfo } from '../../services/selectors/user-selectors'
import DeleteWebModal from '../../components/DeleteWebModal'
import { useNavigate } from 'react-router-dom'
import { Routes } from '../../navigation/navigation-types'
import { setActiveWeb, setWebs } from '../../services/actions/web-actions'
import { useTranslation } from 'react-i18next'

const stringIncludesSubstring = (str: string, substr: string) => {
    return str.includes(substr)
}

const Settings = () => {
    const theme = useTheme()
    const dispatch = useDispatch()
    const classes = useStyles()
    const navigate = useNavigate()
    const activeWebId = useSelector(getActiveWebId)
    const activeWebUrl = useSelector(getActiveWebUrl)
    const user = useSelector(getUserInfo)
    const webs = useSelector(getUserWebs)
    const { t } = useTranslation()

    const [webName, setWebName] = useState<string>('')
    const [webUrl, setWebUrl] = useState<string>('')
    const [selectedLanguage, setSelectedLanguage] = useState<Language>('cs')
    const [isFetching, setIsFetching] = useState<boolean>(false)
    const [isPixelModalOpen, setIsPixelModalOpen] = useState<boolean>(false)
    const [isAddWebModalOpen, setIsAddWebModalOpen] = useState<boolean>(false)
    const [isCheckLoading, setIsCheckLoading] = useState<boolean>(false)
    const [isConnected, setIsConnected] = useState<boolean>(false)
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false)
    const [isDeleting, setIsDeleting] = useState<boolean>(false)

    useEffect(() => {
        const checkPixelStatus = async () => {
            if (activeWebUrl && activeWebId && webUrl) {
                setIsCheckLoading(true)
                try {
                    const _websiteManager = WebsiteManager.getManager()
                    const result = await _websiteManager.getWebsiteHtmlAsync(activeWebUrl)
                    if (stringIncludesSubstring(result.html, activeWebId)) {
                        setIsConnected(true)
                    } else {
                        setIsConnected(false)
                    }
                    setIsCheckLoading(false)
                } catch (e: any) {
                    setIsCheckLoading(false)
                    setIsConnected(false)
                    showFlashMessageWithTimeout(dispatch, 'Automatická kontrola Pixelu nebyla úspěšná.', 'error')
                }
            }
        }

        checkPixelStatus()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [webUrl])

    useEffect(() => {
        const fetchWebInfo = async () => {
            try {
                if (activeWebId) {
                    setIsFetching(true)
                    const _websiteManager = WebsiteManager.getManager()
                    const result = await _websiteManager.getWebsiteSingleAsync(activeWebId)
                    setWebName(result.name)
                    setWebUrl(result.url)
                    setSelectedLanguage(result.language)
                    setIsFetching(false)
                }
            } catch (e: any) {
                showFlashMessageWithTimeout(dispatch, e.toString(), 'error')
                setIsFetching(false)
            }
        }

        fetchWebInfo()
    }, [webs, dispatch, activeWebId])

    const handleWebNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setWebName(event.target.value)
    }

    const handleWebUrlChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setWebUrl(event.target.value)
    }

    const onLanguageChange = (event: SelectChangeEvent) => {
        setSelectedLanguage(event.target.value as Language)
    }

    const handleDeleteWeb = async () => {
        if (activeWebId) {
            try {
                setIsDeleting(true)
                const _websiteManager = WebsiteManager.getManager()
                await _websiteManager.deleteWebsiteAsync(activeWebId)
                const filteredWebs = webs.filter((web) => web.id !== activeWebId)
                if (filteredWebs.length >= 1) {
                    dispatch(setActiveWeb(filteredWebs[0].url, filteredWebs[0].id))
                    dispatch(setWebs(filteredWebs))
                    navigate(Routes.websites)
                } else {
                    navigate(Routes.onboarding)
                }
                setIsDeleting(false)
            } catch (e: any) {
                showFlashMessageWithTimeout(dispatch, e.toString(), 'error')
                setIsDeleting(false)
            }
        }
    }

    if (isFetching) {
        return (
            <Box style={{ flex: 1 }}>
                <Box className={classes.loadingContainer}>
                    <CircularProgress style={{ color: theme.palette.main.primary.o }} />
                </Box>
            </Box>
        )
    }

    return (
        <Box style={{ flex: 1 }}>
            <Box className={classes.container}>
                <Box className={classes.topBarContainer}>
                    <CustomButton
                        size='medium'
                        style={{ width: 100 }}
                        type='primary'
                        onClick={() => setIsAddWebModalOpen(true)}
                        title={t('edit')}
                    />
                    <Typography className={classes.redText} onClick={() => setIsDeleteModalOpen(true)}>
                        {t('deleteWebsite')}
                    </Typography>
                </Box>
                <Box style={{ width: 376 }}>
                    <Typography className={classes.webName}>{webName}</Typography>
                    <CustomTextField
                        style={{ marginTop: 46 }}
                        fullWidth
                        disabled
                        label={t('addWebsite.name') ?? ''}
                        value={webName}
                        onChange={handleWebNameChange}
                    />

                    <CustomTextField
                        style={{ marginTop: 46, marginBottom: 46 }}
                        fullWidth
                        disabled
                        label={`${t('addWebsite.url')} (${t('addWebsite.includeHttps')})`}
                        value={webUrl}
                        onChange={handleWebUrlChange}
                    />

                    <CustomDropdown
                        disabled
                        id='language-dropdown'
                        label={t('addWebsite.notificationLanguage')}
                        items={[
                            { title: t('contact.address.slovakia'), value: 'sk' },
                            { title: t('contact.address.czechRepublic'), value: 'cs' },
                            { title: t('contact.address.polish'), value: 'pl' },
                        ]}
                        onChange={onLanguageChange}
                        value={selectedLanguage}
                    />
                    <Box className={classes.statusContainer}>
                        <Box className={classes.statusRow}>
                            <Typography className={classes.statusTitle}>Pixel</Typography>
                            <PixelStatus
                                isConnected={isConnected}
                                isLoading={isCheckLoading}
                                onClick={() => setIsPixelModalOpen(true)}
                            />
                        </Box>
                    </Box>
                </Box>
            </Box>
            <PixelConnectionModal isOpen={isPixelModalOpen} onClose={() => setIsPixelModalOpen(false)} />
            <AddWebModal
                userInfo={user}
                update
                isOpen={isAddWebModalOpen}
                title={t('sidePanel.settings') ?? ''}
                primaryButtonTitle={t('save') ?? ''}
                secondaryButtonTitle={t('close') ?? ''}
                onClose={() => setIsAddWebModalOpen(false)}
                completedWebName={webName}
                completedWebUrl={webUrl}
                completedLanguage={selectedLanguage}
            />
            <DeleteWebModal
                isOpen={isDeleteModalOpen}
                onClose={() => setIsDeleteModalOpen(false)}
                onDelete={handleDeleteWeb}
                isLoading={isDeleting}
            />
        </Box>
    )
}

const useStyles = makeStyles((theme: Theme) => ({
    loadingContainer: {
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 32,
    },
    container: {
        marginTop: 16,
        marginLeft: 24,
        marginRight: 24,
    },
    topBarContainer: {
        display: 'flex',
        flexDirection: 'row-reverse',
        alignItems: 'center',
    },
    redText: {
        fontSize: 16,
        color: theme.palette.main.alert.o,
        marginRight: 44,
        cursor: 'pointer',
    },
    webName: {
        fontSize: 28,
        fontWeight: 'bold',
        color: theme.palette.main.secondary.u1,
        height: 42,
    },
    statusContainer: {
        height: 76,
        flex: 1,
        backgroundColor: theme.palette.natural.t6,
        border: `1px solid ${theme.palette.natural.t8}`,
        display: 'flex',
        alignItems: 'center',
        marginTop: 24,
        borderRadius: 4,
    },
    statusRow: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    statusTitle: {
        fontSize: 16,
        color: theme.palette.natural.t1,
        marginLeft: 16,
    },
}))

export default Settings

import { Stack, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import logo from '../../assets/images/paymentFailIcon.png'
import { logoutUser } from '../../services/actions/auth-actions'
import { UserManager } from '../../services/api/UserManager'
import { removeUserInfo } from '../../services/actions/user-actions'
import { clearWebs } from '../../services/actions/web-actions'
import { useDispatch } from 'react-redux'
import { showFlashMessageWithTimeout } from '../../services/actions/flashMessage-actions'
import CustomButton from '../../components/CustomButton'

const LoggedOut: React.FC = () => {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    useEffect(() => {
        const logout = async () => {
            try {
                const userManager = UserManager.getManager()
                await userManager.logoutUserAsync()
                dispatch(logoutUser())
                dispatch(removeUserInfo())
                dispatch(clearWebs())
            } catch (e: any) {
                dispatch(removeUserInfo())
                dispatch(clearWebs())
                showFlashMessageWithTimeout(dispatch, e.toString(), 'error')
            }
        }
        logout()
    }, [dispatch])

    const handleCloseWindow = () => {
        window.close();
    };

    return (
        <Stack height='100vh' justifyContent='center' alignItems='center'>
            <img width={300} src={logo} alt='Logout' />
            <Typography fontSize={32}>{t('loggedOut.title')}</Typography>
            <Typography fontSize={20}>{`${t('loggedOut.description')}`}</Typography>
            <CustomButton title={t('loggedOut.close')} size='small' onClick={handleCloseWindow} style={{
                padding: '5px 20px',
            }} />
        </Stack>
    )
}

export default LoggedOut
import { Box, CircularProgress, Typography, useTheme } from '@mui/material'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Routes } from '../../navigation/navigation-types'
import { setAuthState } from '../../services/actions/auth-actions'
import { showFlashMessageWithTimeout } from '../../services/actions/flashMessage-actions'
import { setAdminInfo, setUserInfo } from '../../services/actions/user-actions'
import { clearWebs } from '../../services/actions/web-actions'
import { UserManager } from '../../services/api/UserManager'
import { getUserInfo } from '../../services/selectors/user-selectors'
import {
    AUTH_LOCAL_STORAGE_ADMIN_KEY,
    AUTH_LOCAL_STORAGE_KEY,
    parseJwtEncodedTokenAndGetReduxState,
} from '../../services/types/auth-types'
import { UserBriefDto } from '../../shared/types/userTypes'
import UsersTable from './UsersTable'

const AdminPanel = () => {
    const theme = useTheme()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const user = useSelector(getUserInfo)

    const [allUsers, setAllUsers] = useState<UserBriefDto[]>([])
    const [isLoading, setIsLoading] = useState<boolean>(false)

    useEffect(() => {
        const fetchAllUsersList = async () => {
            setIsLoading(true)
            try {
                const _userManager = UserManager.getManager()
                const result = await _userManager.getAllUsersAsync()
                if (result) {
                    setAllUsers(result)
                }
                setIsLoading(false)
            } catch (e: any) {
                showFlashMessageWithTimeout(dispatch, e.toString(), 'error')
                setIsLoading(false)
            }
        }

        fetchAllUsersList()
    }, [dispatch])

    const takeControl = async (id: string) => {
        const jwt = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY)
        if (jwt) localStorage.setItem(AUTH_LOCAL_STORAGE_ADMIN_KEY, jwt)
        try {
            if (user.createdAt && user.lastModifiedAt && user.remainingExtraCredits !== undefined) {
                dispatch(
                    setAdminInfo({
                        id: user.id,
                        createdAt: user.createdAt,
                        lastModifiedAt: user.lastModifiedAt,
                        name: user.name,
                        email: user.email,
                        isAdmin: user.isAdmin,
                        accountType: user.accountType,
                        remainingPricingPlanCredits: user.remainingPricingPlanCredits,
                        remainingExtraCredits: user.remainingExtraCredits,
                        maxPricingPlanCredits: user.maxPricingPlanCredits,
                        hasUnlimitedAccess: user.hasUnlimitedAccess,
                        language: 'cs',
                        registeredFrom: 'internal',
                    })
                )
            }
            const _userManager = UserManager.getManager()
            const result = await _userManager.takeUserControlAsync(id)
            // Object "controlledUser" has all props from "loggedUserInfo" object, except "token" prop
            const { token, ...controlledUser } = result
            if (controlledUser) dispatch(setUserInfo(controlledUser))
            const state = parseJwtEncodedTokenAndGetReduxState(token)
            dispatch(setAuthState(state))
            navigate(Routes.campaign)
            dispatch(clearWebs())
        } catch (e: any) {
            showFlashMessageWithTimeout(dispatch, e.toString(), 'error')
        }
    }

    if (isLoading) {
        return (
            <Box
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginTop: 32,
                }}
            >
                <CircularProgress style={{ color: theme.palette.main.primary.o }} />
            </Box>
        )
    }

    return (
        <Box style={{ flex: 1, marginLeft: 24, marginRight: 24, marginTop: 32 }}>
            <Typography
                style={{
                    fontSize: 22,
                    color: theme.palette.main.secondary.u1,
                    fontWeight: '700',
                    marginBottom: 24,
                }}
            >
                Klienti
            </Typography>
            <UsersTable users={allUsers} onTakeControl={(id) => takeControl(id)} />
        </Box>
    )
}

export default AdminPanel

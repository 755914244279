import { Box, Theme, Typography, useTheme } from '@mui/material'
import { FC, useEffect, useState } from 'react'
import ModalBase from '../../components/ModalBase'
import StatusChecker from '../../components/StatusChecker'
import { makeStyles } from '@mui/styles'
import CustomButton from '../../components/CustomButton'
import { WebsiteDto } from '../../shared/types/websiteTypes'
import { WebsiteManager } from '../../services/api/WebsiteManager'
import CustomTextField from '../../components/CustomTextField'
import CloseIcon from '@mui/icons-material/Close'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { ExternalApiManager } from '../../services/api/ExternalApiManager'
import { showFlashMessageWithTimeout } from '../../services/actions/flashMessage-actions'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

interface ApiKeyModalProps {
    isOpen: boolean
    onClose: () => void
    createdWebId: string
    websiteData?: WebsiteDto
    onAddKey?: (apiKey: string) => void
}

const ZboziApiKeyModal: FC<ApiKeyModalProps> = ({ isOpen, onClose, createdWebId, websiteData, onAddKey }) => {
    const classes = useStyles()
    const theme = useTheme()
    const dispatch = useDispatch()
    const { t } = useTranslation();

    const [webName, setWebName] = useState<string>(websiteData?.zboziName || '')
    const [shopId, setShopId] = useState<string>(websiteData?.zboziId || '')
    const [apiKey, setApiKey] = useState<string>(websiteData?.zboziApiKey || '')
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [isCheckLoading, setIsCheckLoading] = useState<boolean>(false)
    const [isVerified, setIsVerified] = useState<boolean>(websiteData?.zboziApiKey ? true : false)

    useEffect(() => {
        setWebName(websiteData?.zboziName || '')
        setShopId(websiteData?.zboziId || '')
        setApiKey(websiteData?.zboziApiKey || '')
    }, [isOpen, websiteData?.zboziApiKey, websiteData?.zboziId, websiteData?.zboziName])

    const handleWebNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setWebName(event.target.value)
    }

    const handleApiIdChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setShopId(event.target.value)
    }

    const handleApiKeyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setApiKey(event.target.value)
    }

    const onAdd = async () => {
        if (createdWebId && webName && apiKey && isVerified) {
            try {
                setIsLoading(true)
                const _websiteManager = WebsiteManager.getManager()
                const result = await _websiteManager.updateZboziAsync(createdWebId, shopId, webName, apiKey)
                if (result) {
                    showFlashMessageWithTimeout(dispatch, 'Přidáno.', 'success')
                }
                setIsLoading(false)
                if (onAddKey) onAddKey(apiKey)
                onClose()
            } catch (e: any) {
                setIsLoading(false)
                showFlashMessageWithTimeout(dispatch, e.toString(), 'success')
            }
        } else {
            showFlashMessageWithTimeout(dispatch, 'Vyplňte všechna pole', 'info')
        }
    }

    const onCheck = async () => {
        if (apiKey.length === 0) {
            showFlashMessageWithTimeout(dispatch, 'Zadejte API klič', 'info')
        }

        try {
            setIsCheckLoading(true)
            const _externalApiManager = ExternalApiManager.getManager()
            const result = await _externalApiManager.verifyZboziExternalApi(apiKey, shopId)
            if (result.isVerified) {
                setIsVerified(true)
            } else {
                setIsVerified(false)
                showFlashMessageWithTimeout(dispatch, 'Není ověřeno', 'error')
            }
            setIsCheckLoading(false)
        } catch (e: any) {
            setIsCheckLoading(false)
            showFlashMessageWithTimeout(dispatch, e.toString(), 'error')
        }
    }

    return (
        <ModalBase isOpen={isOpen} onClose={onClose} center>
            <Box className={classes.body}>
                <Box className={classes.iconContainer}>
                    <CloseIcon style={{ color: theme.palette.natural.t2, cursor: 'pointer' }} onClick={onClose} />
                </Box>
                <Typography className={classes.title}>{t('campaign.notifications.settings.displayRules.zbozi.howToAddApiKey.title')}</Typography>
                <Box className={classes.inputsContainer}>
                    <CustomTextField
                        style={{ marginRight: 24 }}
                        fullWidth
                        label={t('campaign.notifications.settings.displayRules.webName') ?? ''}
                        isRequired
                        value={webName}
                        onChange={handleWebNameChange}
                    />
                    <CustomTextField fullWidth label={t('campaign.notifications.settings.displayRules.zbozi.shopId') ?? ''} isRequired value={shopId} onChange={handleApiIdChange} />
                </Box>
                <Box style={{ marginLeft: 32, marginRight: 32 }}>
                    <CustomTextField
                        style={{ marginRight: 24, marginTop: 49, marginBottom: 32 }}
                        fullWidth
                        label={t('campaign.notifications.settings.displayRules.apiKey') ?? ''}
                        isRequired
                        value={apiKey}
                        onChange={handleApiKeyChange}
                    />
                    <Typography className={classes.listTitle}>{t('campaign.notifications.settings.displayRules.zbozi.howToAddApiKey.title')}</Typography>

                    <Box className={classes.rowWithMargin}>
                        <Typography className={classes.listItemNumber}>1.</Typography>
                        <Typography className={classes.listItemText}>
                            {t('campaign.notifications.settings.displayRules.zbozi.howToAddApiKey.first')}
                        </Typography>
                    </Box>
                    <Box className={classes.rowWithMargin}>
                        <Typography className={classes.listItemNumber}>2.</Typography>
                        <Typography className={classes.listItemText}>{t('campaign.notifications.settings.displayRules.zbozi.howToAddApiKey.second')}</Typography>
                    </Box>
                    <Box style={{ display: 'flex', flexDirection: 'row' }}>
                        <Typography className={classes.listItemNumber}>3.</Typography>
                        <Typography className={classes.listItemText}>
                            {t('campaign.notifications.settings.displayRules.zbozi.howToAddApiKey.third')}
                        </Typography>
                    </Box>
                    <Box className={classes.infoRow}>
                        <Box className={classes.infoIconContainer}>
                            <InfoOutlinedIcon style={{ color: theme.palette.natural.t2 }} />
                        </Box>
                        <Typography style={{ fontSize: 14, color: theme.palette.natural.t1 }}>
                            {t('campaign.notifications.settings.displayRules.zbozi.howToAddApiKey.thirdHelp')}.
                        </Typography>
                    </Box>
                    <Box style={{ display: 'flex', flexDirection: 'row' }}>
                        <Typography className={classes.listItemNumber}>4.</Typography>
                        <Typography className={classes.listItemText}>{t('campaign.notifications.settings.displayRules.zbozi.howToAddApiKey.fourth')}</Typography>
                    </Box>
                    <StatusChecker isActive={isVerified} isLoading={isCheckLoading} onClick={onCheck} />
                    <Box className={classes.buttonContainer}>
                        <CustomButton
                            isLoading={isLoading}
                            title={websiteData?.zboziApiKey || isVerified ? t('saveChanges') : t('add')}
                            onClick={onAdd}
                            isDisabled={!isVerified}
                            style={{ width: 120, marginLeft: 20 }}
                        />
                        <CustomButton
                            isLoading={false}
                            title={t('close')}
                            type='secondary'
                            onClick={onClose}
                            style={{ width: 120 }}
                        />
                    </Box>
                </Box>
            </Box>
        </ModalBase>
    )
}

const useStyles = makeStyles((theme: Theme) => ({
    buttonContainer: {
        height: 52,
        flex: 1,
        display: 'flex',
        flexDirection: 'row-reverse',
        marginTop: 32,
        marginBottom: 32,
    },
    body: {
        width: 698,
        backgroundColor: 'white',
        display: 'flex',
        flexDirection: 'column',
        borderRadius: 8,
        boxShadow: theme.palette.main.shadow.secondary,
    },
    title: {
        fontSize: 28,
        color: theme.palette.main.secondary.u1,
        textAlign: 'center',
        marginBottom: 30,
    },
    iconContainer: {
        display: 'flex',
        flexDirection: 'row-reverse',
        marginTop: 20,
        marginRight: 37,
        marginBottom: 4,
    },
    listItemNumber: {
        color: theme.palette.main.secondary.o,
        marginRight: 8,
        fontSize: 16,
        fontWeight: 'bold',
    },
    listItemText: {
        fontSize: 16,
        color: theme.palette.natural.t,
    },
    inputsContainer: {
        display: 'flex',
        flexDirection: 'row',
        marginLeft: 32,
        marginRight: 32,
    },
    listTitle: {
        fontSize: 18,
        color: theme.palette.natural.t,
        marginBottom: 16,
        fontWeight: 'bold',
    },
    rowWithMargin: {
        display: 'flex',
        flexDirection: 'row',
        marginBottom: 24,
    },
    infoRow: {
        display: 'flex',
        flexDirection: 'row',
        height: 34,
        alignItems: 'center',
        marginBottom: 24,
        marginTop: 20,
    },
    infoIconContainer: {
        height: 34,
        width: 34,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
}))

export default ZboziApiKeyModal

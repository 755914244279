import { Box, Typography, useTheme } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import PaymentFailIcon from '../../assets/images/paymentFailIcon.png'
import CustomButton from '../../components/CustomButton'
import { Routes } from '../../navigation/navigation-types'

const PaymentFail = () => {
    const theme = useTheme()
    const navigate = useNavigate()
    return (
        <Box style={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <img style={{ marginTop: 144 }} src={PaymentFailIcon} alt='success icon' height={190} width={275} />
            <Typography
                style={{ fontSize: 28, color: theme.palette.main.alert.o, fontWeight: '700', marginTop: 50, marginBottom: 10 }}
            >
                Platba byla zamítnuta
            </Typography>
            <Typography style={{ fontSize: 16, fontWeight: '400', color: theme.palette.natural.t, marginBottom: 36 }}>
                Platba nebyla přijata. Zkuste to prosím znova.
            </Typography>
            <CustomButton
                title='Pokračovat'
                size='large'
                style={{ width: 140 }}
                onClick={() => navigate(`${Routes.profile}`, { state: { preselectedTab: 'subscription' } })}
            />
        </Box>
    )
}

export default PaymentFail

import { Box, Typography, Theme } from '@mui/material'
import ModalBase from './ModalBase'
import CloseIcon from '@mui/icons-material/Close'
import Icon from '../assets/images/registration.png'
import CustomButton from './CustomButton'
import { makeStyles } from '@mui/styles'
import { useTranslation } from 'react-i18next'

interface ChangeSubscriptionModalProps {
    isOpen: boolean
    onClose: () => void
    isLoading: boolean
    onChangeSubscription: () => void
    oldSubscription?: string
    newSubscription?: string
}

const ChangeSubscriptionModal: React.FC<ChangeSubscriptionModalProps> = ({
    isOpen,
    onClose,
    isLoading,
    onChangeSubscription,
    oldSubscription,
    newSubscription,
}: ChangeSubscriptionModalProps) => {
    const classes = useStyles()
    const { t, i18n } = useTranslation()

    return (
        <ModalBase isOpen={isOpen} onClose={onClose}>
            <Box className={classes.container}>
                <Box className={classes.closeIconBox}>
                    <CloseIcon style={{ cursor: 'pointer' }} onClick={onClose} />
                </Box>
                <Box className={classes.imgContainer}>
                    <img style={{ marginTop: 12 }} src={Icon} alt='buy subscription' width={174} height={192} />
                </Box>
                <Typography align='center' className={classes.primaryText}>
                    {
                        i18n.language === 'hu' ?
                            (t('subscriptions.wantToChangeSubscription') + ' ' + oldSubscription?.toUpperCase() + t('subscriptions.changeFrom') + ' ' + newSubscription?.toUpperCase() + t('subscriptions.changeTo') + '?')
                            : (t('subscriptions.wantToChangeSubscription') + ' ' + t('subscriptions.changeFrom') + oldSubscription?.toUpperCase() + t('subscriptions.changeTo') + newSubscription?.toUpperCase() + '?')
                    }
                    Opravdu si přejete změnit předplatné ze {oldSubscription?.toUpperCase()} na {newSubscription?.toUpperCase()}?
                </Typography>
                Valóban meg kívánja változtatni az előfizetését TSET-ről TEST2-re?

                <Box className={classes.buttonsContainer}>
                    <CustomButton
                        style={{ marginRight: 32, width: 112 }}
                        type='primary'
                        size='medium'
                        title={t('yes')}
                        isDisabled={isLoading}
                        isLoading={isLoading}
                        onClick={onChangeSubscription}
                    />
                    <CustomButton
                        style={{ marginRight: 20, width: 100 }}
                        type='secondary'
                        size='medium'
                        title={t('back')}
                        onClick={onClose}
                    />
                </Box>
            </Box>
        </ModalBase>
    )
}

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        flex: 1,
        width: 663,
        paddingTop: 2,
        paddingBottom: 2,
        backgroundColor: theme.palette.natural.t4,
        borderRadius: 8,
        boxShadow: theme.palette.main.shadow.secondary,
    },
    closeIconBox: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row-reverse',
        marginTop: 20,
        marginRight: 37,
    },
    imgContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    primaryText: {
        fontSize: 20,
        fontWeight: 'bold',
        color: theme.palette.main.alert.o,
        marginTop: 32,
        marginBottom: 24,
    },
    secondaryText: {
        fontSize: 16,
        color: theme.palette.natural.t,
    },
    buttonsContainer: {
        display: 'flex',
        flexDirection: 'row-reverse',
        marginTop: 32,
        marginBottom: 30,
    },
}))

export default ChangeSubscriptionModal

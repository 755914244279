import { AuthActionTypes, AuthReduxState } from '../../services/types/auth-types'
import initState from '../types/initState'
import { ReduxAction } from '../types/main-reducer-types'

export default function authReducer(state: AuthReduxState = initState.auth, action: ReduxAction): AuthReduxState {
    switch (action.type) {
        case AuthActionTypes.SET_AUTH_STATE: {
            return action.payload
        }
        default:
            return state
    }
}

import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import {
    Box,
    Tab,
    useTheme,
    Theme,
    Typography,
    TextField,
    SelectChangeEvent,
    Autocomplete,
    Chip,
    Stack,
    Grid,
} from '@mui/material'
import { FC, useLayoutEffect, useState } from 'react'
import ModalBase from '../../components/ModalBase'
import { makeStyles } from '@mui/styles'
import CloseIcon from '@mui/icons-material/Close'
import TabPanel from '@mui/lab/TabPanel'
import CustomTextField from '../../components/CustomTextField'
import CustomSwitch from '../../components/CustomSwitch'
import CustomButton from '../../components/CustomButton'
import { useDispatch } from 'react-redux'
import { NotificationManager } from '../../services/api/NotificationManager'
import { showFlashMessageWithTimeout } from '../../services/actions/flashMessage-actions'
import CustomDropdown from '../../components/CustomDropdown'
import { ControlType, DropdownMenuItem, FormType } from '../../components/types'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import { fireEvent } from '@testing-library/react'
import {
    GetNotificationSettingsResponse,
    UpdateNotificationSettingsRequest,
} from '../../shared/payloads/notificationSettingsPayloads'
import { transformFormsToUrlFilterDto, transformUrlFilterToForms } from '../../generic-utils'
import DOMPurify from 'dompurify'
import NotificationPreview from '../../components/NotificationPreview'
import { Language } from '../../shared/types/websiteTypes'
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined'
import { getOrdersAndRegistrationsInitialText } from '../../utils/web-utils'
import IconSelect from '../../components/NotificationEditComponents/IconSelect'
import { NotificationDefaultSettings } from '../../types/notification-default-settings'
import BorderSelect, { BorderType } from '../../components/NotificationEditComponents/BorderSelect'
import CustomColorPicker from '../../components/NotificationEditComponents/CustomColorPicker'
import { useTranslation } from 'react-i18next'
import { v4 } from 'uuid'

interface OrdersAndRegistrationsModalProps {
    isOpen: boolean
    onClose: () => void
    createdNotificationId: string
    forOrders: boolean
    language: Language
    data?: GetNotificationSettingsResponse
}

type TabType = 'rules' | 'pairing' | 'design'

const notificationStyleId = v4()

const menuItems: DropdownMenuItem[] = [
    {
        title: 'Začíná na',
        value: 'beginsWith',
    },
    {
        title: 'Obsahuje',
        value: 'contains',
    },
    {
        title: 'Shoduje se',
        value: 'isEqual',
    },
]

const getAvailableControls = (forms: FormType[]) => {
    const unavailableControls: ControlType[] = []
    const allControls: ControlType[] = ['beginsWith', 'contains', 'isEqual']
    forms.forEach((form) => {
        unavailableControls.push(form.control)
    })
    const result = allControls.filter((ac) => !unavailableControls.includes(ac))
    return result
}

const getMax = (data: GetNotificationSettingsResponse): number => {
    if (data.type === 'lastOrders') {
        if (data.maxLastOrders) return data.maxLastOrders
    }
    if (data.type === 'lastRegistrations') {
        if (data.maxLastRegistrations) return data.maxLastRegistrations
    }
    return 0
}

const getMin = (data: GetNotificationSettingsResponse): number => {
    if (data.type === 'lastOrders') {
        if (data.minOrders) return data.minOrders
    }
    if (data.type === 'lastRegistrations') {
        if (data.minRegistrations) return data.minRegistrations
    }
    return 0
}

const OrdersAndRegistrationsModal: FC<OrdersAndRegistrationsModalProps> = ({
    isOpen,
    onClose,
    createdNotificationId,
    forOrders,
    language,
    data,
}) => {
    const theme = useTheme()
    const classes = useStyles()
    const dispatch = useDispatch()
    const { t } = useTranslation()

    const [selectedTab, setSelectedTab] = useState<TabType>('rules')
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [text, setText] = useState<string>(
        data?.text ? data.text : t(getOrdersAndRegistrationsInitialText(forOrders, language)) ?? ''
    )
    const [max, setMax] = useState<number>(data ? getMax(data) : 10)
    const [min, setMin] = useState<number>(data ? getMin(data) : 5)
    const [days, setDays] = useState<number>(data?.maxLastDays !== undefined ? data?.maxLastDays : 5)
    const [fallbackToGenericCustomer, setFallbackToGenericCustomer] = useState<boolean>(
        data?.fallbackToGenericCustomer !== undefined ? data?.fallbackToGenericCustomer : true
    )
    const [withRepeat, setWithRepeat] = useState<boolean>(
        data?.repeatNotification !== undefined ? data?.repeatNotification : true
    )
    const [forms, setForms] = useState<FormType[]>(
        data?.urlFilter ? transformUrlFilterToForms(data.urlFilter) : [{ control: 'beginsWith', include: [], excludes: [] }]
    )

    const [notificationHtml, setNotificationHtml] = useState<string>('')
    const [showCustomerOwnOrders, setShowCustomerOwnOrders] = useState<boolean>(
        data?.showCustomerOwnOrders !== undefined ? data.showCustomerOwnOrders : true
    )
    const [showCustomerOwnRegistrations, setShowCustomerOwnRegistrations] = useState<boolean>(
        data?.showCustomerOwnRegistrations !== undefined ? data?.showCustomerOwnRegistrations : true
    )

    const [backgroundColor, setBackgroundColor] = useState<string | null>(
        data?.notificationVisualSettings?.backgroundColor || null
    )
    const [textColor, setTextColor] = useState<string | null>(data?.notificationVisualSettings?.textColor || null)
    const [iconBackgroundColor, setIconBackgroundColor] = useState<string | null>(
        data?.notificationVisualSettings?.iconBackgroundColor || null
    )
    const [iconColor, setIconColor] = useState<string | null>(data?.notificationVisualSettings?.iconColor || null)
    const [borderRadius, setBorderRadius] = useState<BorderType | null>(data?.notificationVisualSettings?.roundness || null)
    const [icon, setIcon] = useState<string | null>(data?.notificationVisualSettings?.icon || null)

    useLayoutEffect(() => {
        const createNotificationPreviewHtml = async () => {
            const result = await window.getNotificationPreview(
                {
                    campaign: {
                        notificationVisualSettings: {
                            roundness: 1,
                        },
                    },
                    settings: {
                        type: forOrders ? 'lastOrders' : 'lastRegistrations',
                        notificationVisualSettings: {
                            backgroundColor: backgroundColor ?? '',
                            textColor: textColor ?? '',
                            iconBackgroundColor: iconBackgroundColor ?? '',
                            iconColor: iconColor ?? '',
                            icon: icon ?? '',
                            roundness: borderRadius ?? 0,
                        },
                    },
                },
                forOrders ? 'lastOrders' : 'lastRegistrations',
                t('campaign.notifications.lastOrders.previewText1') ?? '',
                text,
                undefined,
                language,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                notificationStyleId
            )

            // Transforms object htmldivelement to pure string
            const cleanHtmlString = DOMPurify.sanitize(result, {
                USE_PROFILES: { html: true, svg: true },
            })
            setNotificationHtml(cleanHtmlString)
        }

        createNotificationPreviewHtml()
    })

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setSelectedTab(newValue as TabType)
    }

    const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setText(event.target.value)
    }

    const handleMaxRegistrationsChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setMax(parseInt(event.target.value, 10))
    }

    const handleMinRegistrationsChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setMin(parseInt(event.target.value, 10))
    }

    const handleDaysChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setDays(parseInt(event.target.value, 10))
    }

    const toggleWithRepeat = () => {
        setWithRepeat(!withRepeat)
    }

    const toggleFallbackToGenericCustomer = () => {
        setFallbackToGenericCustomer(!fallbackToGenericCustomer)
    }

    const toggleShowCustomerOwnOrders = () => {
        setShowCustomerOwnOrders(!showCustomerOwnOrders)
    }

    const toggleShowCustomerOwnRegistrations = () => {
        setShowCustomerOwnRegistrations(!showCustomerOwnRegistrations)
    }

    const getValuesToHide = (forms: FormType[]): string[] => {
        let unavailableControls: string[] = []
        forms.forEach((form) => unavailableControls.push(form.control))
        return unavailableControls
    }

    const handleControlChange = (event: SelectChangeEvent, index: number) => {
        setForms((state) => {
            const copy: FormType[] = [...state]
            const formToUpdate = copy[index]
            formToUpdate.control = event.target.value as ControlType
            return copy
        })
    }

    const onDeleteForm = (index: number) => {
        setForms((state) => {
            const copy: FormType[] = [...state]
            copy.splice(index, 1)
            return copy
        })
    }

    const onAddIncludeChip = (formIndex: number, values: string[]) => {
        setForms((state) => {
            const copy: FormType[] = [...state]
            copy[formIndex].include = values
            return copy
        })
    }

    const onAddExcludeChip = (formIndex: number, values: string[]) => {
        setForms((state) => {
            const copy: FormType[] = [...state]
            copy[formIndex].excludes = values
            return copy
        })
    }

    const addEmptyForm = () => {
        if (forms.length < 3) {
            const availableControls = getAvailableControls(forms)
            setForms((state) => {
                const result = [
                    ...state,
                    {
                        control: availableControls[0],
                        include: [],
                        excludes: [],
                    },
                ]
                return result
            })
        }
    }

    const activeColor = theme.palette.natural.t1
    const notActiveColor = theme.palette.natural.t2

    const isFormsFilled = () => {
        for (let i = 0; i < forms.length; i++) {
            const form = forms[i]
            if (form.excludes.length > 0 || form.include.length > 0) {
                return true
            }
        }
        return false
    }

    const onSave = async () => {
        if (text.length < 1) {
            setSelectedTab('rules')
            showFlashMessageWithTimeout(dispatch, t('popups.textMustBeFilled') ?? '', 'error')
            return
        }
        if (!isFormsFilled()) {
            showFlashMessageWithTimeout(dispatch, t('popups.connectionMissing') ?? '', 'error')
            return
        }
        try {
            setIsLoading(true)
            const _notificationManager = NotificationManager.getManager()
            let body = {}
            if (!forOrders) {
                body = {
                    id: createdNotificationId,
                    isActive: true,
                    repeatNotification: withRepeat,
                    text: text,
                    maxLastDays: days,
                    minRegistrations: min,
                    maxLastRegistrations: max,
                    fallbackToGenericCustomer: fallbackToGenericCustomer,
                    urlFilter: transformFormsToUrlFilterDto(forms),
                    showCustomerOwnRegistrations: showCustomerOwnRegistrations,

                    notificationVisualSettings: {
                        backgroundColor: backgroundColor ?? '',
                        textColor: textColor ?? '',
                        iconBackgroundColor: iconBackgroundColor ?? '',
                        iconColor: iconColor ?? '',
                        icon: icon ?? '',
                        roundness: borderRadius ?? 0,
                    },
                }
            } else {
                body = {
                    id: createdNotificationId,
                    isActive: true,
                    repeatNotification: withRepeat,
                    text: text,
                    maxLastDays: days,
                    minOrders: min,
                    maxLastOrders: max,
                    fallbackToGenericCustomer: fallbackToGenericCustomer,
                    urlFilter: transformFormsToUrlFilterDto(forms),
                    showCustomerOwnOrders: showCustomerOwnOrders,

                    notificationVisualSettings: {
                        backgroundColor: backgroundColor ?? '',
                        textColor: textColor ?? '',
                        iconBackgroundColor: iconBackgroundColor ?? '',
                        iconColor: iconColor ?? '',
                        icon: icon ?? '',
                        roundness: borderRadius ?? 0,
                    },
                }
            }

            await _notificationManager.updateNotificationSettingsAsync(body as UpdateNotificationSettingsRequest)
            setIsLoading(false)
            clearForms()
            onClose()
        } catch (e: any) {
            showFlashMessageWithTimeout(dispatch, e.toString(), 'error')
            setIsLoading(false)
        }
    }

    const clearForms = () => {
        setForms([{ control: 'contains', include: [], excludes: [] }])
        setSelectedTab('rules')
        setMin(0)
        setMax(0)
        setText('')
        setDays(0)
        setFallbackToGenericCustomer(true)
        setWithRepeat(true)
    }

    return (
        <ModalBase isOpen={isOpen} onClose={onClose} style={{ overflow: 'auto' }} center>
            <Stack
                direction='column'
                bgcolor={theme.palette.natural.t4}
                p={3}
                overflow='auto'
                sx={{
                    width: { xs: '80%', md: 900 },
                    maxHeight: '90vh',
                }}
                boxShadow={theme.palette.main.shadow.secondary}
                borderRadius={2}
            >
                <Box className={classes.titleContainer}>
                    <Typography className={classes.title}>
                        {forOrders
                            ? t('campaign.notifications.lastOrders.title')
                            : t('campaign.notifications.lastRegistrations.title')}
                    </Typography>
                    <CloseIcon style={{ color: theme.palette.natural.t2, cursor: 'pointer' }} onClick={onClose} />
                </Box>
                <TabContext value={selectedTab}>
                    <TabList
                        TabIndicatorProps={{
                            style: {
                                background: theme.palette.main.primary.o,
                                height: 3,
                            },
                        }}
                        onChange={handleChange}
                    >
                        <Tab
                            disableRipple
                            className={classes.tab}
                            style={{
                                color: selectedTab === 'rules' ? activeColor : notActiveColor,
                            }}
                            label={t('campaign.notifications.settings.displayRules.title')}
                            value='rules'
                        />
                        <Tab
                            disableRipple
                            className={classes.tab}
                            style={{
                                color: selectedTab === 'pairing' ? activeColor : notActiveColor,
                            }}
                            label={t('campaign.notifications.settings.pairing.title')}
                            value='pairing'
                        />
                        <Tab
                            disableRipple
                            className={classes.tab}
                            style={{
                                color: selectedTab === 'design' ? activeColor : notActiveColor,
                            }}
                            label={t('campaign.notifications.settings.design.title')}
                            value='design'
                        />
                        <Box className={classes.tabsDivider} />
                    </TabList>
                    <TabPanel value='rules' style={{ padding: 0 }}>
                        <NotificationPreview notificationHtml={notificationHtml} />

                        <Grid container rowSpacing={2} columnSpacing={2}>
                            <Grid item xs={12} md={6}>
                                <Typography className={classes.sectionTitle}>
                                    {t('campaign.notifications.settings.displayRules.text')}
                                </Typography>
                                <CustomTextField
                                    fullWidth
                                    label={t('campaign.notifications.settings.displayRules.notificationText') ?? ''}
                                    value={text}
                                    onChange={handleTextChange}
                                />
                            </Grid>
                            <Grid item xs={12} md={6} />
                            <Grid item xs={12}>
                                <Typography className={classes.sectionTitle} style={{ marginTop: 24 }}>
                                    {t('campaign.notifications.settings.displayRules.show')}
                                </Typography>
                                <Box style={{ display: 'flex', flex: 1, marginTop: 16 }}>
                                    <Box className={classes.rulesInputSectionContainer}>
                                        <Box className={classes.rowBox}>
                                            <Typography className={classes.inputLabel}>
                                                {' '}
                                                {t('campaign.notifications.settings.displayRules.lastOrders.maxLast')}
                                            </Typography>
                                            <Box style={{ flex: 1 }} />
                                            <TextField
                                                style={{ width: 100, marginRight: 12 }}
                                                type='number'
                                                variant='outlined'
                                                value={max}
                                                onChange={handleMaxRegistrationsChange}
                                                InputProps={{
                                                    inputProps: { min: 0 },
                                                    style: { height: 44 },
                                                }}
                                            />
                                            <Typography style={{ color: theme.palette.natural.t1, fontSize: 16 }}>
                                                {forOrders
                                                    ? t('campaign.notifications.settings.displayRules.lastOrders.orders')
                                                    : t(
                                                          'campaign.notifications.settings.displayRules.lastRegistrations.registrations'
                                                      )}
                                            </Typography>
                                        </Box>
                                        <Box className={classes.rowBox}>
                                            <Typography className={classes.inputLabel}>
                                                {forOrders
                                                    ? t('campaign.notifications.settings.displayRules.lastOrders.maxToShow')
                                                    : t('campaign.notifications.settings.displayRules.lastOrders.whenAtLeast')}
                                            </Typography>
                                            <Box style={{ flex: 1 }} />
                                            <TextField
                                                style={{ width: 100, marginRight: 12 }}
                                                type='number'
                                                variant='outlined'
                                                value={min}
                                                onChange={handleMinRegistrationsChange}
                                                InputProps={{
                                                    inputProps: { min: 0 },
                                                    style: { height: 44 },
                                                }}
                                            />
                                            <Typography style={{ color: theme.palette.natural.t1, fontSize: 16 }}>
                                                {forOrders
                                                    ? t('campaign.notifications.settings.displayRules.lastOrders.orders')
                                                    : t(
                                                          'campaign.notifications.settings.displayRules.lastRegistrations.registrations'
                                                      )}
                                            </Typography>
                                        </Box>
                                        <Box className={classes.rowBox}>
                                            <Typography className={classes.inputLabel}>
                                                {forOrders
                                                    ? t('campaign.notifications.settings.displayRules.lastOrders.ordersLast')
                                                    : t(
                                                          'campaign.notifications.settings.displayRules.lastRegistrations.registrationsLast'
                                                      )}
                                            </Typography>
                                            <Box style={{ flex: 1 }} />
                                            <TextField
                                                style={{ width: 100, marginRight: 12 }}
                                                type='number'
                                                variant='outlined'
                                                value={days}
                                                onChange={handleDaysChange}
                                                InputProps={{
                                                    inputProps: { min: 0 },
                                                    style: { height: 44 },
                                                }}
                                            />
                                            <Typography
                                                style={{
                                                    color: theme.palette.natural.t1,
                                                    fontSize: 16,
                                                    marginRight: forOrders ? 60 : 45,
                                                }}
                                            >
                                                {t('campaign.notifications.settings.displayRules.days')}
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Box className={classes.rulesSwitchesSectionContainer}>
                                        <Box className={classes.buttonsContainer}>
                                            <Typography className={classes.switchLabel}>
                                                {forOrders
                                                    ? t('campaign.notifications.settings.displayRules.lastOrders.notRecoginzed')
                                                    : t(
                                                          'campaign.notifications.settings.displayRules.lastRegistrations.notRecoginzed'
                                                      )}
                                            </Typography>
                                            <CustomSwitch
                                                checked={fallbackToGenericCustomer}
                                                onSwitch={() => toggleFallbackToGenericCustomer()}
                                                disabled={false}
                                            />
                                        </Box>
                                        <Box className={classes.buttonsContainer} style={{ marginTop: 16 }}>
                                            <Typography className={classes.switchLabel}>
                                                {forOrders
                                                    ? t('campaign.notifications.settings.displayRules.lastOrders.showOwnOrders')
                                                    : t(
                                                          'campaign.notifications.settings.displayRules.lastRegistrations.showOwnRegistrations'
                                                      )}
                                            </Typography>
                                            {forOrders && (
                                                <CustomSwitch
                                                    checked={showCustomerOwnOrders}
                                                    onSwitch={() => toggleShowCustomerOwnOrders()}
                                                    disabled={false}
                                                />
                                            )}
                                            {!forOrders && (
                                                <CustomSwitch
                                                    checked={showCustomerOwnRegistrations}
                                                    onSwitch={() => toggleShowCustomerOwnRegistrations()}
                                                    disabled={false}
                                                />
                                            )}
                                        </Box>
                                        <Box className={classes.buttonsContainer} style={{ marginTop: 16 }}>
                                            <Typography className={classes.switchLabel}>
                                                {t('campaign.notifications.settings.displayRules.repeatNotification')}
                                            </Typography>
                                            <CustomSwitch
                                                checked={withRepeat}
                                                onSwitch={() => toggleWithRepeat()}
                                                disabled={false}
                                            />
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                        {/* <Box className={classes.rulesContainer}>
                            <Typography className={classes.sectionTitle} style={{ marginBottom: 16 }}>
                                Text
                            </Typography>
                            <CustomTextField
                                style={{ width: 417 }}
                                label='Text notifikace'
                                value={text}
                                onChange={handleTextChange}
                            />
                            <Typography className={classes.sectionTitle} style={{ marginTop: 24 }}>
                                Zobrazí
                            </Typography>
                            <Box style={{ display: 'flex', flex: 1, marginTop: 16 }}>
                                <Box className={classes.rulesInputSectionContainer}>
                                    <Box className={classes.rowBox}>
                                        <Typography className={classes.inputLabel}>Maximálně posledních</Typography>
                                        <Box style={{ flex: 1 }} />
                                        <TextField
                                            style={{ width: 100, marginRight: 12 }}
                                            type='number'
                                            variant='outlined'
                                            value={max}
                                            onChange={handleMaxRegistrationsChange}
                                            InputProps={{
                                                inputProps: { min: 0 },
                                                style: { height: 44 },
                                            }}
                                        />
                                        <Typography style={{ color: theme.palette.natural.t1, fontSize: 16 }}>
                                            {forOrders ? 'objednávek' : 'registrací'}
                                        </Typography>
                                    </Box>
                                    <Box className={classes.rowBox}>
                                        <Typography className={classes.inputLabel}>
                                            {forOrders ? 'Minimální počet pro zobrazení je' : 'Notifikace pouze pokud je alespoň'}
                                        </Typography>
                                        <Box style={{ flex: 1 }} />
                                        <TextField
                                            style={{ width: 100, marginRight: 12 }}
                                            type='number'
                                            variant='outlined'
                                            value={min}
                                            onChange={handleMinRegistrationsChange}
                                            InputProps={{
                                                inputProps: { min: 0 },
                                                style: { height: 44 },
                                            }}
                                        />
                                        <Typography style={{ color: theme.palette.natural.t1, fontSize: 16 }}>
                                            {forOrders ? 'objednávek' : 'registrací'}
                                        </Typography>
                                    </Box>
                                    <Box className={classes.rowBox}>
                                        <Typography className={classes.inputLabel}>
                                            {forOrders ? 'Objednávky za posledních' : 'Regisrace za posledních'}
                                        </Typography>
                                        <Box style={{ flex: 1 }} />
                                        <TextField
                                            style={{ width: 100, marginRight: 12 }}
                                            type='number'
                                            variant='outlined'
                                            value={days}
                                            onChange={handleDaysChange}
                                            InputProps={{
                                                inputProps: { min: 0 },
                                                style: { height: 44 },
                                            }}
                                        />
                                        <Typography
                                            style={{
                                                color: theme.palette.natural.t1,
                                                fontSize: 16,
                                                marginRight: forOrders ? 60 : 45,
                                            }}
                                        >
                                            dní
                                        </Typography>
                                    </Box>
                                </Box>
                                <Box className={classes.rulesSwitchesSectionContainer}>
                                    <Box className={classes.buttonsContainer}>
                                        <Typography className={classes.switchLabel}>
                                            {`Nerozpoznaná jména jako "${forOrders ? 'Zákazník' : 'Návštěvník'}"`}
                                        </Typography>
                                        <CustomSwitch
                                            checked={fallbackToGenericCustomer}
                                            onSwitch={() => toggleFallbackToGenericCustomer()}
                                            disabled={false}
                                        />
                                    </Box>
                                    <Box className={classes.buttonsContainer} style={{ marginTop: 16 }}>
                                        <Typography className={classes.switchLabel}>
                                            {forOrders
                                                ? 'Ukázat návštěvníkům vlastní objednávky'
                                                : 'Ukázat návštěvníkům vlastní regisrace'}
                                        </Typography>
                                        {forOrders && (
                                            <CustomSwitch
                                                checked={showCustomerOwnOrders}
                                                onSwitch={() => toggleShowCustomerOwnOrders()}
                                                disabled={false}
                                            />
                                        )}
                                        {!forOrders && (
                                            <CustomSwitch
                                                checked={showCustomerOwnRegistrations}
                                                onSwitch={() => toggleShowCustomerOwnRegistrations()}
                                                disabled={false}
                                            />
                                        )}
                                    </Box>
                                    <Box className={classes.buttonsContainer} style={{ marginTop: 16 }}>
                                        <Typography className={classes.switchLabel}>{t('campaign.notifications.settings.displayRules.repeatNotification')}</Typography>
                                        <CustomSwitch checked={withRepeat} onSwitch={() => toggleWithRepeat()} disabled={false} />
                                    </Box>
                                </Box>
                            </Box>
                        </Box> */}

                        <Box className={classes.firstButtonsContainer}>
                            <CustomButton
                                size='medium'
                                style={{ width: 132 }}
                                title={t('continue')}
                                onClick={() => setSelectedTab('pairing')}
                            />
                        </Box>
                    </TabPanel>
                    <TabPanel value='pairing' style={{ padding: 0 }}>
                        <Box
                            style={{
                                display: 'flex',
                                flex: 1,
                                flexDirection: 'column',
                                height: 502,
                                overflow: 'auto',
                            }}
                        >
                            <Stack direction='row' alignItems='center'>
                                <Typography className={classes.secondTitle}>
                                    {t('campaign.notifications.settings.pairing.collectData')}
                                </Typography>
                                <HelpOutlineOutlinedIcon
                                    titleAccess='Nápověda'
                                    sx={{ color: theme.palette.natural.t2, marginLeft: 1, paddingTop: 1, cursor: 'pointer' }}
                                    onClick={() =>
                                        window.open(
                                            forOrders
                                                ? 'https://www.overenyweb.cz/napoveda/nastaveni-notifikaci/posledni-objednavky/'
                                                : 'https://www.overenyweb.cz/napoveda/nastaveni-notifikaci/posledni-registrace/'
                                        )
                                    }
                                />
                            </Stack>
                            <Box>
                                {forms.map((form, index) => {
                                    return (
                                        <Box key={`dropdown-key-${index}`} className={classes.formContainer}>
                                            <Box className={classes.urlContainer}>
                                                <Box style={{ width: 280, marginBottom: 40 }}>
                                                    <CustomDropdown
                                                        label='URL'
                                                        id={`${index}-form-dropdown`}
                                                        value={form.control}
                                                        items={menuItems.map((item) => {
                                                            return {
                                                                value: item.value,
                                                                title:
                                                                    item.value === 'beginsWith'
                                                                        ? t('campaign.settings.url.startsWith')
                                                                        : item.value === 'contains'
                                                                        ? t('campaign.settings.url.includes')
                                                                        : t('campaign.settings.url.equals') ?? '',
                                                            }
                                                        })}
                                                        hiddenValues={getValuesToHide(forms)}
                                                        onChange={(event) => handleControlChange(event, index)}
                                                    />
                                                </Box>
                                                <Box>
                                                    <DeleteOutlineIcon
                                                        onClick={() => onDeleteForm(index)}
                                                        className={classes.icon}
                                                    />
                                                </Box>
                                            </Box>

                                            <Autocomplete
                                                id={`include-${index}`}
                                                style={{ marginBottom: 40 }}
                                                multiple
                                                options={[]}
                                                freeSolo
                                                onChange={(event: any, value: any) => {
                                                    onAddIncludeChip(index, value)
                                                }}
                                                value={form.include || []}
                                                renderTags={(value: readonly string[], getTagProps) =>
                                                    value.map((option: string, index: number) => (
                                                        <Chip variant='outlined' label={option} {...getTagProps({ index })} />
                                                    ))
                                                }
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        variant='outlined'
                                                        label={t('campaign.settings.includes')}
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            endAdornment: (
                                                                <Stack direction='row' spacing={1}>
                                                                    <CustomButton
                                                                        size='micro'
                                                                        style={{ width: 40 }}
                                                                        title='+'
                                                                        onClick={() => {
                                                                            const element = document.getElementById(
                                                                                `include-${index}`
                                                                            )
                                                                            if (element) {
                                                                                fireEvent.keyDown(element, {
                                                                                    key: 'Enter',
                                                                                })
                                                                            }
                                                                        }}
                                                                    />
                                                                    {params.InputProps.endAdornment}
                                                                </Stack>
                                                            ),
                                                        }}
                                                    />
                                                )}
                                            />
                                            <Autocomplete
                                                id={`excludes-${index}`}
                                                multiple
                                                options={[]}
                                                freeSolo
                                                onChange={(event: any, value: any, reason) => {
                                                    onAddExcludeChip(index, value)
                                                }}
                                                value={form.excludes || []}
                                                renderTags={(value: readonly string[], getTagProps) =>
                                                    value.map((option: string, index: number) => (
                                                        <Chip variant='outlined' label={option} {...getTagProps({ index })} />
                                                    ))
                                                }
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        variant='outlined'
                                                        label={t('campaign.settings.exclude')}
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            endAdornment: (
                                                                <Stack direction='row' spacing={1}>
                                                                    <CustomButton
                                                                        size='micro'
                                                                        style={{ width: 40 }}
                                                                        title='+'
                                                                        onClick={() => {
                                                                            const element = document.getElementById(
                                                                                `excludes-${index}`
                                                                            )
                                                                            if (element) {
                                                                                fireEvent.keyDown(element, {
                                                                                    key: 'Enter',
                                                                                })
                                                                            }
                                                                        }}
                                                                    />
                                                                    {params.InputProps.endAdornment}
                                                                </Stack>
                                                            ),
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Box>
                                    )
                                })}
                                {forms.length < 3 && (
                                    <CustomButton
                                        size='small'
                                        style={{ width: 180 }}
                                        type='secondary'
                                        title={t('campaign.notifications.settings.pairing.addCondition')}
                                        onClick={addEmptyForm}
                                    />
                                )}
                            </Box>
                        </Box>
                        <Box className={classes.secondButtonsContainer}>
                            <CustomButton
                                style={{ width: 132, marginLeft: 24 }}
                                size='medium'
                                type='primary'
                                title='Uložit'
                                isLoading={isLoading}
                                onClick={onSave}
                            />
                            <CustomButton
                                style={{ width: 132 }}
                                size='medium'
                                type='secondary'
                                title='Zpět'
                                onClick={() => setSelectedTab('rules')}
                            />
                        </Box>
                    </TabPanel>
                    <TabPanel value='design' style={{ padding: 0 }}>
                        <NotificationPreview notificationHtml={notificationHtml} />

                        <Typography style={{ marginTop: 16 }} mb={1} color={theme.palette.natural.t1}>
                            {t('campaign.notifications.settings.design.description')}
                        </Typography>
                        <Grid container columnSpacing={3}>
                            <Grid item xs={12} md={6}>
                                <Stack direction='row' alignItems='center'>
                                    <Typography className={classes.sectionTitle} width={300}>
                                        {t('campaign.notifications.settings.design.backgroundColour')}
                                    </Typography>

                                    <CustomColorPicker
                                        color={backgroundColor}
                                        onChange={setBackgroundColor}
                                        defaultColor={NotificationDefaultSettings.BackgroundColor}
                                    />
                                </Stack>
                                <Stack direction='row' alignItems='center'>
                                    <Typography className={classes.sectionTitle} width={300}>
                                        {t('campaign.notifications.settings.design.textColour')}
                                    </Typography>

                                    <CustomColorPicker
                                        color={textColor}
                                        onChange={setTextColor}
                                        defaultColor={NotificationDefaultSettings.TextColor}
                                    />
                                </Stack>
                                <Stack direction='row' alignItems='center'>
                                    <Typography className={classes.sectionTitle} width={300}>
                                        {t('campaign.notifications.settings.design.iconBackgroundColour')}
                                    </Typography>

                                    <CustomColorPicker
                                        color={iconBackgroundColor}
                                        onChange={setIconBackgroundColor}
                                        defaultColor={NotificationDefaultSettings.IconBackgroundColor}
                                    />
                                </Stack>
                                <Stack direction='row' alignItems='center'>
                                    <Typography className={classes.sectionTitle} width={300}>
                                        {t('campaign.notifications.settings.design.iconColour')}
                                    </Typography>

                                    <CustomColorPicker
                                        color={iconColor}
                                        onChange={setIconColor}
                                        defaultColor={NotificationDefaultSettings.IconColor}
                                    />
                                </Stack>
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <Typography className={classes.sectionTitle}>
                                    {t('campaign.notifications.settings.design.roundedCorners')}
                                </Typography>

                                <BorderSelect
                                    selectedBorderValue={borderRadius}
                                    onChange={setBorderRadius}
                                    defaultBorderValue={Number(NotificationDefaultSettings.BorderRoundness)}
                                />

                                <Typography className={classes.sectionTitle} mt={1}>
                                    {t('campaign.notifications.settings.design.icon')}
                                </Typography>

                                <IconSelect
                                    selectedIcon={icon}
                                    onChange={setIcon}
                                    defaultIcon={
                                        forOrders
                                            ? NotificationDefaultSettings.OrdersIcon
                                            : NotificationDefaultSettings.RegisterIcon
                                    }
                                />

                                <Stack direction='row' justifyContent='flex-end' mt={1}>
                                    <CustomButton
                                        style={{ width: 132 }}
                                        size='medium'
                                        type='secondary'
                                        title={t('back')}
                                        onClick={() => setSelectedTab('rules')}
                                    />
                                    <CustomButton
                                        style={{ width: 132, marginLeft: 24 }}
                                        size='medium'
                                        type='primary'
                                        title={t('save')}
                                        isLoading={isLoading}
                                        onClick={onSave}
                                    />
                                </Stack>
                            </Grid>
                        </Grid>
                    </TabPanel>
                </TabContext>
            </Stack>
        </ModalBase>
    )
}

const useStyles = makeStyles((theme: Theme) => ({
    tabsDivider: {
        width: 370,
        height: 3,
        backgroundColor: theme.palette.natural.t3,
        position: 'absolute',
        bottom: 0,
    },
    tab: {
        textTransform: 'none',
        fontSize: 16,
    },
    container: {
        flex: 1,
        width: 996,
        height: 660,
        backgroundColor: theme.palette.natural.t4,
        paddingLeft: 32,
        paddingRight: 32,
        paddingTop: 24,
        paddingBottom: 32,
        borderRadius: 8,
        boxShadow: theme.palette.main.shadow.secondary,
    },
    titleContainer: {
        height: 34,
        flex: 1,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 16,
    },
    title: {
        fontSize: 28,
        fontWeight: 'bold',
        color: theme.palette.main.secondary.u1,
    },
    rulesContainer: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: 24,
    },
    sectionTitle: {
        fontSize: 18,
        color: theme.palette.main.primary.o,
        fontWeight: 'bold',
    },
    rulesInputSectionContainer: {
        display: 'flex',
        height: 50,
        width: 528,
        flexDirection: 'column',
    },
    inputLabel: {
        width: 300,
        color: theme.palette.natural.t1,
        fontSize: 16,
    },
    rulesSwitchesSectionContainer: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
    },
    switchLabel: {
        marginLeft: 60,
        color: theme.palette.natural.t1,
        fontSize: 16,
    },
    buttonsContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: 44,
    },
    firstButtonsContainer: {
        display: 'flex',
        flex: 1,
        marginTop: 16,
        flexDirection: 'row-reverse',
    },
    secondTitle: {
        marginTop: 24,
        color: theme.palette.main.secondary.u1,
        fontSize: 18,
        fontWeight: 'bold',
        marginBottom: 16,
    },
    secondButtonsContainer: {
        flex: 1,
        height: 44,
        display: 'flex',
        flexDirection: 'row-reverse',
        marginTop: 16,
    },
    rowBox: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: 16,
        height: 44,
    },
    formContainer: {
        flex: 1,
        padding: 24,
        backgroundColor: theme.palette.natural.t8,
        marginBottom: 16,
        borderRadius: 8,
        maxWidth: 735,
    },
    urlContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    icon: {
        color: theme.palette.main.alert.o,
        fontSize: 24,
        cursor: 'pointer',
    },
    doneIcon: {
        color: theme.palette.natural.t2,
        marginRight: 20,
        fontSize: 26,
    },
}))

export default OrdersAndRegistrationsModal

import { Box, Typography, useTheme, Theme } from '@mui/material'
import { UserManager } from '../services/api/UserManager'
import { useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { removeAdminInfo, removeUserInfo, setUserInfo } from '../services/actions/user-actions'
import { getAdminInfo, getUserInfo } from '../services/selectors/user-selectors'
import { makeStyles } from '@mui/styles'
import { getActiveWebId, getActiveWebUrl, getUserWebs } from '../services/selectors/web-selectors'
import { useEffect, useState } from 'react'
import { showFlashMessageWithTimeout } from '../services/actions/flashMessage-actions'
import { WebsiteManager } from '../services/api/WebsiteManager'
import { WebsiteDto } from '../shared/types/websiteTypes'
import WebDropdown from '../components/WebDropdown'
import { SelectChangeEvent } from '@mui/material/Select'
import { clearWebs, setActiveWeb, setWebs } from '../services/actions/web-actions'
import { Routes } from './navigation-types'
import { DropdownMenuItem } from '../components/types'
import PersonIcon from '@mui/icons-material/Person'
import DropdownMenu from '../components/DropdownMenu'
import LogoutIcon from '@mui/icons-material/Logout'
import LogoPng from '../assets/images/logo.png'
import { logoutUser, setAuthState } from '../services/actions/auth-actions'
import {
    AUTH_LOCAL_STORAGE_ADMIN_KEY,
    AUTH_LOCAL_STORAGE_KEY,
    parseJwtEncodedTokenAndGetReduxState,
} from '../services/types/auth-types'
import { useTranslation } from 'react-i18next'

const getDropdownItems = (webs: WebsiteDto[]) => {
    const result: DropdownMenuItem[] = []
    webs.forEach((w) => {
        result.push({ title: w.name, value: w.id })
    })
    result.push({ title: 'Zobrazit všechny', value: 'redirect' })
    return result
}

const dropdownItemBorderRadius = 6

const TopPanel = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const location = useLocation()
    const theme = useTheme()
    const user = useSelector(getUserInfo)
    const admin = useSelector(getAdminInfo)
    const classes = useStyles()
    const activeWebUrl = useSelector(getActiveWebUrl)
    const activeWebId = useSelector(getActiveWebId)
    const webs = useSelector(getUserWebs)
    const { t } = useTranslation()
    const shouldHideDropdown = location.pathname.includes(Routes.onboarding)
    const shouldHideProfileDropdown = location.pathname.includes(Routes.onboarding)
    const dropdownItems = getDropdownItems(webs)

    const [selectedWebId, setSelectedWebId] = useState<string>(activeWebId ? activeWebId : webs[0] ? webs[0].id : '')
    const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false)

    useEffect(() => {
        const fetchUserWebs = async () => {
            try {
                const _websiteManager = WebsiteManager.getManager()
                const result = await _websiteManager.getWebsitesForUserAsync(user.id)
                dispatch(setWebs(result.websites))
                if (!(activeWebUrl && activeWebId) && result.websites.length > 0) {
                    setSelectedWebId(result.websites[0].id)
                }
            } catch (e: any) {
                showFlashMessageWithTimeout(dispatch, e.toString(), 'error')
            }
        }

        if (user.id) {
            fetchUserWebs()
        }

        if (activeWebUrl && activeWebId) {
            setSelectedWebId(activeWebId)
        }
    }, [dispatch, activeWebUrl, activeWebId, user, user.id])

    const logoutUserAsync = async () => {
        if (user.registeredFrom !== 'internal' && !user.isAdmin) {
            navigate(Routes.loggedOut)
            return
        }
        try {
            const adminJwt = localStorage.getItem(AUTH_LOCAL_STORAGE_ADMIN_KEY)
            if (adminJwt) {
                localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, adminJwt)
                localStorage.removeItem(AUTH_LOCAL_STORAGE_ADMIN_KEY)
            } else {
                const userManager = UserManager.getManager()
                await userManager.logoutUserAsync()
                dispatch(logoutUser())
            }
            dispatch(removeUserInfo())
            dispatch(clearWebs())

            if (
                admin &&
                admin.id &&
                admin.createdAt &&
                admin.lastModifiedAt &&
                adminJwt &&
                user.remainingExtraCredits !== undefined
            ) {
                dispatch(
                    setUserInfo({
                        id: admin.id,
                        createdAt: admin.createdAt,
                        lastModifiedAt: admin.lastModifiedAt,
                        name: admin.name,
                        email: admin.email,
                        isAdmin: admin.isAdmin,
                        accountType: admin.accountType,
                        remainingPricingPlanCredits: user.remainingPricingPlanCredits,
                        remainingExtraCredits: user.remainingExtraCredits,
                        maxPricingPlanCredits: user.maxPricingPlanCredits,
                        hasUnlimitedAccess: user.hasUnlimitedAccess,
                        language: 'cs',
                        registeredFrom: 'internal',
                    })
                )
                showFlashMessageWithTimeout(dispatch, 'Nyní jste opět administrátor', 'success')
                navigate(Routes.admin)
                dispatch(removeAdminInfo())
                const state = parseJwtEncodedTokenAndGetReduxState(adminJwt)
                dispatch(setAuthState(state))
            } else {
                navigate('/')
            }
            setIsDropdownOpen(false)
        } catch (e: any) {
            dispatch(removeUserInfo())
            dispatch(clearWebs())
            showFlashMessageWithTimeout(dispatch, e.toString(), 'error')
            setIsDropdownOpen(false)
            navigate('/')
        }
    }

    const handleChange = (event: SelectChangeEvent) => {
        if (event.target.value === 'redirect') {
            navigate(Routes.websites)
        } else {
            setSelectedWebId(event.target.value)
            let webUrl = ''
            for (let i = 0; i < webs.length; i++) {
                const web = webs[i]
                if (web.id === event.target.value) {
                    webUrl = web.url
                    break
                }
            }
            if (webUrl) {
                dispatch(setActiveWeb(webUrl, event.target.value))
                navigate(`${Routes.campaign}`)
            }
        }
    }

    const profileDropdownItems: DropdownMenuItem[] = [
        { title: 'contact.title', value: 'info' },
        { title: 'passwordChange.title', value: 'connection' },
        { title: 'subscriptions.title', value: 'subscription' },
    ]

    return (
        <Box className={classes.container}>
            <Box>
                <img style={{ marginLeft: 16 }} src={LogoPng} alt='logo' />
            </Box>
            {!shouldHideDropdown && (
                <Box
                    style={{
                        marginLeft: 36,
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <Box style={{ height: 40 }}>
                        <WebDropdown
                            id='webs-dropdown'
                            label=''
                            items={dropdownItems}
                            onChange={handleChange}
                            value={selectedWebId}
                            disabled={user.registeredFrom !== 'internal'}
                        />
                    </Box>
                </Box>
            )}
            <Box style={{ flex: 1 }} />
            {!shouldHideProfileDropdown && (
                <DropdownMenu
                    placement='bottom-start'
                    open={isDropdownOpen}
                    onClose={() => setIsDropdownOpen(false)}
                    content={
                        <Box className={classes.dropdownContainer}>
                            {user && (
                                <>
                                    <Box
                                        style={{
                                            height: 56,
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Typography className={classes.userNameItem}>{user.email}</Typography>
                                    </Box>
                                    <Box className={classes.divider} />
                                </>
                            )}
                            {profileDropdownItems
                                .filter((row) => {
                                    // filter value connection if user is not internal
                                    if (row.value === 'connection' && user.registeredFrom !== 'internal') {
                                        return false
                                    }
                                    return true
                                })
                                .map((di, index) => (
                                    <Box
                                        key={index}
                                        className={classes.dropdownItem}
                                        onClick={() => {
                                            setIsDropdownOpen(false)
                                            navigate(Routes.profile, {
                                                state: { preselectedTab: di.value },
                                            })
                                        }}
                                    >
                                        <Typography className={classes.dropdownItemText}>{t(di.title)}</Typography>
                                    </Box>
                                ))}
                            <Box className={classes.logoutItem} onClick={logoutUserAsync}>
                                <LogoutIcon className={classes.logoutIcon} />
                                <Typography className={classes.logoutText}>{t('logout')}</Typography>
                            </Box>
                        </Box>
                    }
                >
                    <Box className={classes.profile} onClick={() => setIsDropdownOpen(true)}>
                        <PersonIcon style={{ color: theme.palette.main.secondary.o }} />
                    </Box>
                </DropdownMenu>
            )}
        </Box>
    )
}

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        position: 'fixed',
        width: '100%',
        height: 56,
        backgroundColor: theme.palette.natural.t7,
        zIndex: 1001,
        borderBottom: `1px solid ${theme.palette.natural.t11}`,
    },
    button: {
        height: 56,
        width: 100,
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    profile: {
        height: 32,
        width: 32,
        borderRadius: 16,
        border: `1px solid ${theme.palette.main.secondary.o}`,
        backgroundColor: theme.palette.main.secondary.d3,
        marginRight: 24,
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    dropdownItemText: {
        height: 44,
        fontSize: 16,
        display: 'flex',
        alignItems: 'center',
        color: theme.palette.natural.t1,
        marginLeft: 16,
        width: '100%',
        '&:hover': {
            color: theme.palette.main.primary.o,
        },
    },
    dropdownItem: {
        height: 44,
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: theme.palette.main.primary.d3,
        },
    },
    logoutItem: {
        height: 48,
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: theme.palette.main.primary.d3,
            borderBottomRightRadius: dropdownItemBorderRadius,
            borderBottomLeftRadius: dropdownItemBorderRadius,
        },
    },
    logoutText: {
        fontSize: 16,
        height: '100%',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        color: theme.palette.natural.t1,
        '&:hover': {
            color: theme.palette.main.primary.o,
        },
    },
    logoutIcon: {
        color: theme.palette.main.alert.o,
        marginLeft: 16,
        marginRight: 16,
    },
    userNameItem: {
        fontWeight: 'bold',
        marginLeft: 16,
        color: theme.palette.natural.t1,
    },
    dropdownContainer: {
        width: 280,
        backgroundColor: 'white',
        boxShadow: theme.palette.main.shadow.secondary,
        borderRadius: dropdownItemBorderRadius,
        display: 'flex',
        flexDirection: 'column',
    },
    divider: {
        height: 1,
        width: '100%',
        backgroundColor: theme.palette.natural.t10,
    },
}))

export default TopPanel

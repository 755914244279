import { Stack, Typography, useTheme } from '@mui/material'
import React from 'react'
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined'
import { useTranslation } from 'react-i18next'

interface HelpButtonProps {
    link: string
}

const HelpButton: React.FC<HelpButtonProps> = ({ link }: HelpButtonProps) => {
    const theme = useTheme()
    const { t, i18n } = useTranslation();

    const onClick = () => {
        i18n.language === 'hu' ?
            window.open('https://www.overenyweb.cz/hu/')
            : window.open(link)
    }

    return (
        <Stack direction='row' sx={{ cursor: 'pointer' }} onClick={onClick}>
            <HelpOutlineOutlinedIcon fontSize='small' sx={{ color: theme.palette.natural.t1, marginLeft: 1 }} />
            <Typography
                variant='body2'
                style={{
                    marginLeft: 5,
                    color: theme.palette.natural.t1,
                    fontSize: 12,
                    marginTop: 3,
                }}
            >
                {t('help')}
            </Typography>
        </Stack>
    )
}

export default HelpButton

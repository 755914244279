import { Box, Typography, useTheme } from '@mui/material'
import { FC } from 'react'
import DoneIcon from '@mui/icons-material/Done'
import { useTranslation } from 'react-i18next'

interface PixelStatusProps {
    isConnected?: boolean
    isLoading?: boolean
    onClick: () => void
}

const PixelStatus: FC<PixelStatusProps> = ({ isConnected, onClick, isLoading }) => {
    const theme = useTheme()
    const { t } = useTranslation()

    return (
        <Box
            style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
            }}
            onClick={onClick}
        >
            {isConnected && !isLoading && (
                <Box
                    style={{
                        height: 32,
                        width: 32,
                        borderRadius: 16,
                        marginRight: 24,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: isConnected ? theme.palette.main.accent.o : theme.palette.main.alert.o,
                    }}
                >
                    <DoneIcon style={{ color: theme.palette.natural.t7 }} />
                </Box>
            )}
            {!isLoading && (
                <Typography
                    style={{
                        color: isConnected ? theme.palette.natural.t1 : theme.palette.main.primary.o,
                        fontSize: 16,
                        marginRight: 24,
                        cursor: 'pointer',
                    }}
                >
                    {isConnected ? t('websites.connected') : t('websites.connect')}
                </Typography>
            )}
            {isLoading && (
                <Typography
                    style={{
                        color: theme.palette.natural.t1,
                        fontSize: 16,
                        marginRight: 24,
                    }}
                >
                    Verifikace...
                </Typography>
            )}
        </Box>
    )
}

export default PixelStatus

import { FC, useState } from 'react'
import { Box, CircularProgress, Typography, useTheme } from '@mui/material'

interface CustomButtonProps {
    title: string
    onClick: () => void
    isLoading?: boolean
    isDisabled?: boolean
    style?: React.CSSProperties
    type?: 'primary' | 'secondary'
    size?: 'micro' | 'small' | 'medium' | 'large'
    color?: string
    fontSize?: number
}

const CustomButton: FC<CustomButtonProps> = ({
    title,
    isLoading,
    onClick,
    style,
    isDisabled,
    size,
    type,
    color,
    fontSize = 16,
}) => {
    const [pressed, setPressed] = useState<boolean>(false)
    const height = getHeight(size)
    const theme = useTheme()

    const buttonColor = color || theme.palette.main.primary.o

    const primaryStyle = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: isDisabled ? undefined : 'pointer',
        background: buttonColor,
        borderRadius: 6,
        border: '1px solid transparent',
        opacity: isDisabled || pressed ? 0.5 : 1,
        '&:hover': {
            background: color || theme.palette.main.primary.d1,
        },
    }

    const secondaryStyle = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: isDisabled ? undefined : 'pointer',
        border: `1px solid ${theme.palette.main.primary.o}`,
        borderRadius: 6,
        backgroundColor: '#FFFFFF',
        '&:hover': {
            background: theme.palette.main.primary.d3,
        },
    }

    if (type === 'secondary') {
        return (
            <Box
                style={{
                    ...secondaryStyle,
                    ...style,
                    height: height,
                }}
                onMouseDown={() => setPressed(true)}
                onMouseUp={() => setPressed(false)}
                onClick={isLoading || isDisabled ? undefined : onClick}
            >
                {!isLoading && <Typography style={{ color: theme.palette.main.primary.o, fontSize }}>{title}</Typography>}
                {isLoading && <CircularProgress size={height / 1.5} style={{ color: theme.palette.main.secondary.o }} />}
            </Box>
        )
    }

    return (
        <Box
            style={{
                ...primaryStyle,
                ...style,
                height: height,
            }}
            onMouseDown={() => (isDisabled ? undefined : setPressed(true))}
            onMouseUp={() => (isDisabled ? undefined : setPressed(false))}
            onClick={isLoading || isDisabled ? undefined : onClick}
        >
            {!isLoading && <Typography style={{ color: '#FFFFFF', fontSize }}>{title}</Typography>}
            {isLoading && <CircularProgress size={height / 1.5} style={{ color: theme.palette.main.secondary.o }} />}
        </Box>
    )
}

const getHeight = (size: 'micro' | 'small' | 'medium' | 'large' | undefined) => {
    if (size === 'micro') return 26
    if (size === 'small') return 34
    if (size === 'medium') return 42
    return 50
}

export default CustomButton

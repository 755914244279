import React from 'react'
import Slider from 'react-slick'
import Review1 from '../assets/images/reviews/Review1.png'
import Review2 from '../assets/images/reviews/Review2.png'
import { Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'

const ReviewsSlider: React.FC = () => {
    const classes = useStyles()

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 2,
        autoplay: true,
        autoplaySpeed: 3000,
        responsive: [
            {
                breakpoint: 1210,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: true,
                    dots: true,
                },
            },
            {
                breakpoint: 775,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1,
                },
            },
        ],
    }

    return (
        <div style={{ width: '100%', maxWidth: 1220 }}>
            <Slider {...settings}>
                <div>
                    <div className={classes.item}>
                        <img src={Review1} alt='Review 1' />
                    </div>
                </div>
                <div>
                    <div className={classes.item}>
                        <img src={Review2} alt='Review 2' />
                    </div>
                </div>
            </Slider>
        </div>
    )
}

const useStyles = makeStyles((theme: Theme) => ({
    item: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
    },
}))

export default ReviewsSlider

import { Box, Typography, Theme } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import { makeStyles } from '@mui/styles'
import HeurekaNotificationModal from './HeurekaNotificationModal'
import { useEffect, useLayoutEffect, useState } from 'react'
import { showFlashMessageWithTimeout } from '../../services/actions/flashMessage-actions'
import { NotificationManager } from '../../services/api/NotificationManager'
import { NotificationType } from '../../shared/types/notificationSettingsTypes'
import { CreateNotificationSettingsResponse } from '../../shared/payloads/notificationSettingsPayloads'
import { WebsiteManager } from '../../services/api/WebsiteManager'
import { Language, WebsiteDto } from '../../shared/types/websiteTypes'
import { useDispatch, useSelector } from 'react-redux'
import { getActiveWebId } from '../../services/selectors/web-selectors'
import ApiKeyModal from '../../components/ApiKeyModal'
import HeurekaApiKeyModal from '../OnBoarding/HeurekaApiKeyModal'
import ActualVisitorsModal from './ActualVisitorsModal'
import IndividualNotificationModal from './IndividualNotificationModal'
import SummaryModal from './SummaryModal'
import ShoptetLastOrdersNotificationModal from './ShoptetLastOrdersNotificationModal'
import OrdersAndRegistrationsModal from './OrdersAndRegistrationsModal'
import ZboziApiKeyModal from '../OnBoarding/ZboziApiKeyModal'
import ZboziNotificationModal from './ZboziNotificationModal'
import { NotificationsPreviewsStringsProps } from '../../components/types'
import NotificationPreview from '../../components/NotificationPreview'
import { convertStringToLanguage, generateNotificationPreviews } from '../../generic-utils'
import { getOrdersAndRegistrationsInitialText, getActualVisitorsModalInitialText } from '../../utils/web-utils'
import { useTranslation } from 'react-i18next'
import { TFunction } from 'i18next'
import ShoptetCouponCodeNotificationModal from './ShoptetCouponCodeNotificationModal'
import { getUserInfo } from '../../services/selectors/user-selectors'
import { RegistrationSource } from '../../shared/types/userTypes'
import SpolehliveRecenzeNotificationModal from './SpolehliveRecenzeNotificationModal'
import SpolehliveRecenzeLoginModal from '../OnBoarding/SpolehliveRecenzeLoginModal'

interface OptionType {
    name: string
    hint: string
}

const options: OptionType[] = [
    {
        name: 'campaign.notifications.lastOrders.title',
        hint: 'campaign.notifications.lastOrders.description',
    },
    {
        name: 'campaign.notifications.lastRegistrations.title',
        hint: 'campaign.notifications.lastRegistrations.description',
    },
    {
        name: 'campaign.notifications.actualVisitors.title',
        hint: 'campaign.notifications.actualVisitors.description',
    },
    {
        name: 'campaign.notifications.visitorSummary.title',
        hint: 'campaign.notifications.visitorSummary.description',
    },
    {
        name: 'campaign.notifications.heureka.title',
        hint: 'campaign.notifications.heureka.description',
    },
    {
        name: 'campaign.notifications.zbozi.title',
        hint: 'campaign.notifications.zbozi.description',
    },
    {
        name: 'campaign.notifications.spolehlivaRecenzeShopRating.title',
        hint: 'campaign.notifications.spolehlivaRecenzeShopRating.description',
    },
    {
        name: 'campaign.notifications.individual.title',
        hint: 'campaign.notifications.individual.description',
    },
    {
        name: 'campaign.notifications.shoptetLastOrders.title',
        hint: 'campaign.notifications.shoptetLastOrders.description',
    },
    {
        name: 'campaign.notifications.shoptetCouponCode.title',
        hint: 'campaign.notifications.shoptetCouponCode.description',
    },
]

const getDefaultSettings = (language: Language, t: TFunction<'translation', undefined, 'translation'>) => {
    return {
        lastOrders: {
            text: t(getOrdersAndRegistrationsInitialText(true, language)),
            maxLastDays: 5,
            minOrders: 5,
            maxLastOrders: 10,
            showCustomerOwnOrders: true,
        },
        lastRegistrations: {
            text: t(getOrdersAndRegistrationsInitialText(false, language)),
            maxLastDays: 5,
            minRegistrations: 5,
            maxLastRegistrations: 10,
            showCustomerOwnRegistrations: true,
        },
        actualVisitors: {
            minVisitors: 10,
            text: t(getActualVisitorsModalInitialText(language)),
        },
        visitorSummary: {
            text: t(getActualVisitorsModalInitialText(language)),
            maxLastDays: 7,
            minVisitors: 20,
        },
        heureka: {
            minReviews: 1,
            minReviewStars: 4,
        },
        zboziCompanyRating: {
            minReviews: 1,
        },
        individual: {
            addLink: false,
            name: '',
            text: '',
            linkAsButton: false,
            openLinkInNewWindow: false,
        },
        shoptetLastOrders: {},
        shoptetCouponCode: {},
        spolehlivaRecenzeShopRating: {
            minReviews: 1,
            minReviewStars: 4,
        },
    }
}

const AddNotification = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const classes = useStyles()
    const { id } = useParams()
    const { t, i18n } = useTranslation()
    const user = useSelector(getUserInfo)
    const webId = useSelector(getActiveWebId)
    const language = convertStringToLanguage(i18n.language)
    const defaultSettings = getDefaultSettings(language, t)
    const [createdNotificationId, setCreatedNotificationId] = useState<string>('')
    const [websiteDto, setWebsiteDto] = useState<WebsiteDto | undefined>()
    const [isNotificationAdded, setIsNotificationAdded] = useState<boolean>(false)

    // API KEYS MODALS:
    const [apiKeyModalOpenFor, setApiKeyModalOpenFor] = useState<'zbozi' | 'heureka' | 'spolehlivaRecenze' | undefined>()
    const [isAddZboziApiKeyModalOpen, setIsAddZboziApiKeyModalOpen] = useState<boolean>(false)
    const [addZboziApiKeyModal, setAddZboziApiKeyModal] = useState<boolean>(false)
    const [isSpolehliveRecenzeLoginModalOpen, setIsSpolehliveRecenzeLoginModalOpen] = useState<boolean>(false)

    // NOTIFICATION MODALS:
    const [isActualVisitorsModalOpen, setIsActualVisitorsModalOpen] = useState<boolean>(false)
    const [isIndividualNotificationModalOpen, setIsIndividualNotificationModalOpen] = useState<boolean>(false)
    const [isSummaryModalOpen, setIsSummaryModalOpen] = useState<boolean>(false)
    const [isRegistrationNotificationModalOpen, setIsRegistrationNotificationModalOpen] = useState<boolean>(false)
    const [isLastOrdersNotificationModalOpen, setIsLastOrdersNotificationModalOpen] = useState<boolean>(false)
    const [isHeurekaNotificationModalOpen, setIsHeurekaNotificationModalOpen] = useState<boolean>(false)
    const [isZboziNotificationModalOpen, setIsZboziNotificationModalOpen] = useState<boolean>(false)
    const [isShoptetLastOrdersNotificationModalOpen, setIsShoptetLastOrdersNotificationModalOpen] = useState<boolean>(false)
    const [isShoptetCouponNotificationModalOpen, setIsShoptetCouponNotificationModalOpen] = useState<boolean>(false)
    const [isSpolehliveRecenzeNotificationModalOpen, setIsSpolehliveRecenzeNotificationModalOpen] = useState<boolean>(false)

    const [notificationsPreviewsStrings, setNotificationsPreviewsStrings] = useState<NotificationsPreviewsStringsProps>({
        lastOrders: '',
        lastRegistrations: '',
        actualVisitors: '',
        visitorSummary: '',
        registrationSummary: '',
        orderSummary: '',
        heureka: '',
        zboziCompanyRating: '',
        zboziProductRating: '',
        individual: '',
        shoptetLastOrders: '',
        shoptetCouponCode: '',
        spolehlivaRecenzeShopRating: '',
        spolehlivaRecenzeProductRating: '',
    })

    type RegistrationKeyMap = {
        [key in NotificationType]: RegistrationSource[]
    }

    let registrationKeyMap: RegistrationKeyMap = {
        lastOrders: ['internal', 'webareal', 'upgates'],
        lastRegistrations: ['internal', 'shoptet', 'webareal', 'upgates'],
        actualVisitors: ['internal', 'shoptet', 'webareal', 'upgates'],
        visitorSummary: ['internal', 'shoptet', 'webareal', 'upgates'],
        registrationSummary: ['internal', 'shoptet', 'webareal', 'upgates'],
        orderSummary: ['internal', 'shoptet', 'webareal', 'upgates'],
        heureka: ['internal', 'shoptet', 'webareal', 'upgates'],
        zboziCompanyRating: ['internal', 'shoptet', 'webareal', 'upgates'],
        zboziProductRating: ['internal', 'shoptet', 'webareal', 'upgates'],
        individual: ['internal', 'shoptet', 'webareal', 'upgates'],
        shoptetLastOrders: ['shoptet'],
        shoptetCouponCode: ['shoptet'],
        spolehlivaRecenzeShopRating: ['internal', 'shoptet', 'webareal', 'upgates'],
        spolehlivaRecenzeProductRating: ['internal', 'shoptet', 'webareal', 'upgates'],
    }

    useEffect(() => {
        const fetchSiteData = async () => {
            if (webId) {
                try {
                    const _websiteManager = WebsiteManager.getManager()
                    const result: WebsiteDto = await _websiteManager.getWebsiteSingleAsync(webId)
                    setWebsiteDto(result)
                } catch (e: any) {
                    showFlashMessageWithTimeout(dispatch, e.toString(), 'error')
                }
            }
        }

        fetchSiteData()
    }, [id, webId, dispatch, apiKeyModalOpenFor])

    useLayoutEffect(() => {
        const getReview = async () => {
            const result = await generateNotificationPreviews(t, i18n.language)
            setNotificationsPreviewsStrings(result)
        }
        getReview()
    }, [t])

    const onNotificationCreate = async (type: NotificationType) => {
        if (id) {
            let defaultSet
            switch (type) {
                case 'lastOrders':
                    defaultSet = defaultSettings.lastOrders
                    break
                case 'lastRegistrations':
                    defaultSet = defaultSettings.lastRegistrations
                    break
                case 'actualVisitors':
                    defaultSet = defaultSettings.actualVisitors
                    break
                case 'visitorSummary':
                    defaultSet = defaultSettings.visitorSummary
                    break
                case 'heureka':
                    defaultSet = defaultSettings.heureka
                    break
                case 'zboziCompanyRating':
                    defaultSet = defaultSettings.zboziCompanyRating
                    break
                case 'spolehlivaRecenzeShopRating':
                    defaultSet = defaultSettings.spolehlivaRecenzeShopRating
                    break
                case 'individual':
                    defaultSet = defaultSettings.individual
                    break
                case 'shoptetLastOrders':
                    defaultSet = defaultSettings.shoptetLastOrders
                    break
                case 'shoptetCouponCode':
                    defaultSet = defaultSettings.shoptetCouponCode
                    break
                default:
                    throw new Error(`Unexpected type of notification: ${type}`)
            }
            try {
                const _notificationManager = NotificationManager.getManager()
                const result: CreateNotificationSettingsResponse = await _notificationManager.createNotificationSettingsAsync(
                    id,
                    type,
                    defaultSet
                )
                if (result.id) {
                    setCreatedNotificationId(result.id)
                    openModalForNotificationType(type)
                    setIsNotificationAdded(true)
                } else {
                    throw new Error(t('popups.cannotCreateNotification') ?? '')
                }
            } catch (e: any) {
                showFlashMessageWithTimeout(dispatch, e.toString(), 'error')
            }
        }
    }

    const openModalForNotificationType = (type: NotificationType) => {
        switch (type) {
            case 'heureka':
                setIsHeurekaNotificationModalOpen(true)
                break
            case 'actualVisitors':
                setIsActualVisitorsModalOpen(true)
                break
            case 'individual':
                setIsIndividualNotificationModalOpen(true)
                break
            case 'lastRegistrations':
                setIsRegistrationNotificationModalOpen(true)
                break
            case 'lastOrders':
                setIsLastOrdersNotificationModalOpen(true)
                break
            case 'visitorSummary':
                setIsSummaryModalOpen(true)
                break
            case 'zboziCompanyRating':
                setIsZboziNotificationModalOpen(true)
                break
            case 'spolehlivaRecenzeShopRating':
                setIsSpolehliveRecenzeNotificationModalOpen(true)
                break
            case 'shoptetLastOrders':
                setIsShoptetLastOrdersNotificationModalOpen(true)
                break
            case 'shoptetCouponCode':
                setIsShoptetCouponNotificationModalOpen(true)
                break
            default:
                break
        }
    }

    const getTypeFromIndex = (index: number): NotificationType => {
        switch (index) {
            case 0:
                return 'lastOrders'
            case 1:
                return 'lastRegistrations'
            case 2:
                return 'actualVisitors'
            case 3:
                return 'visitorSummary'
            case 4:
                return 'heureka'
            case 5:
                return 'zboziCompanyRating'
            case 6:
                return 'spolehlivaRecenzeShopRating'
            case 7:
                return 'individual'
            case 8:
                return 'shoptetLastOrders'
            case 9:
                return 'shoptetCouponCode'
            default:
                return 'lastOrders'
        }
    }

    const onAddHeurekaApiKey = (apiKey: string) => {
        if (websiteDto) {
            setWebsiteDto({ ...websiteDto, heurekaApiKey: apiKey })
            onNotificationCreate('heureka')
        }
    }

    const onAddZboziApiKey = (apiKey: string) => {
        if (websiteDto) {
            setWebsiteDto({ ...websiteDto, zboziApiKey: apiKey })
            onNotificationCreate('zboziCompanyRating')
        }
    }

    const onSpolehliveRecenzeLogin = (shopId: string, password: string) => {
        if (websiteDto) {
            setWebsiteDto({ ...websiteDto, spolehlivaRecenzeShopId: shopId, spolehlivaRecenzeShopPassword: password })
            onNotificationCreate('spolehlivaRecenzeShopRating')
        }
    }

    return (
        <Box style={{ flex: 1 }}>
            <Typography className={classes.goBackText} onClick={() => navigate(-1)}>
                {t('back')}
            </Typography>
            <Box className={classes.optionsContainer}>
                {options.map((option, index) => {
                    const notifType = getTypeFromIndex(index)
                    if (!registrationKeyMap[notifType].includes(user.registeredFrom) && !user.isAdmin) {
                        return null
                    }
                    return (
                        <Box
                            key={index}
                            className={classes.optionCard}
                            onClick={() => {
                                // this logic will be refactored later
                                if (index === 0) onNotificationCreate('lastOrders')
                                if (index === 1) onNotificationCreate('lastRegistrations')
                                if (index === 2) onNotificationCreate('actualVisitors')
                                if (index === 3) onNotificationCreate('visitorSummary')
                                if (index === 4) {
                                    if (websiteDto?.heurekaApiKey !== null) {
                                        onNotificationCreate('heureka')
                                    } else {
                                        setApiKeyModalOpenFor('heureka')
                                    }
                                }
                                if (index === 5) {
                                    if (websiteDto?.zboziApiKey !== null) {
                                        onNotificationCreate('zboziCompanyRating')
                                    } else {
                                        setApiKeyModalOpenFor('zbozi')
                                    }
                                }
                                if (index === 6) {
                                    if (websiteDto?.spolehlivaRecenzeShopId !== null) {
                                        onNotificationCreate('spolehlivaRecenzeShopRating')
                                    } else {
                                        setApiKeyModalOpenFor('spolehlivaRecenze')
                                    }
                                }
                                if (index === 7) onNotificationCreate('individual')
                                if (index === 8) onNotificationCreate('shoptetLastOrders')
                                if (index === 9) onNotificationCreate('shoptetCouponCode')
                            }}
                        >
                            <Box className={classes.cardBody}>
                                <Typography className={classes.title}>{t(option.name)}</Typography>
                                <Typography className={classes.hint}>{t(option.hint)}</Typography>
                                <NotificationPreview
                                    height={130}
                                    backgroundColor='transparent'
                                    notificationHtml={notificationsPreviewsStrings[notifType]}
                                />
                            </Box>
                        </Box>
                    )
                })}
            </Box>

            {websiteDto?.id && (
                <HeurekaApiKeyModal
                    websiteData={websiteDto}
                    createdWebId={websiteDto?.id}
                    isOpen={isAddZboziApiKeyModalOpen}
                    onClose={() => {
                        setIsAddZboziApiKeyModalOpen(false)
                        isNotificationAdded && showFlashMessageWithTimeout(dispatch, t('popups.notificationAdded'), 'success')
                        setIsNotificationAdded(false)
                    }}
                    onAddKey={onAddHeurekaApiKey}
                />
            )}

            {websiteDto?.id && (
                <ZboziApiKeyModal
                    websiteData={websiteDto}
                    createdWebId={websiteDto?.id}
                    isOpen={addZboziApiKeyModal}
                    onClose={() => {
                        setAddZboziApiKeyModal(false)
                        isNotificationAdded && showFlashMessageWithTimeout(dispatch, t('popups.notificationAdded'), 'success')
                        setIsNotificationAdded(false)
                    }}
                    onAddKey={onAddZboziApiKey}
                />
            )}

            {websiteDto?.id && (
                <SpolehliveRecenzeLoginModal
                    websiteData={websiteDto}
                    createdWebId={websiteDto?.id}
                    isOpen={isSpolehliveRecenzeLoginModalOpen}
                    onClose={() => {
                        setIsSpolehliveRecenzeLoginModalOpen(false)
                        isNotificationAdded && showFlashMessageWithTimeout(dispatch, t('popups.notificationAdded'), 'success')
                        setIsNotificationAdded(false)
                    }}
                    onAddKey={onSpolehliveRecenzeLogin}
                />
            )}

            <HeurekaNotificationModal
                createdNotificationId={createdNotificationId}
                isOpen={isHeurekaNotificationModalOpen}
                onClose={() => {
                    setIsHeurekaNotificationModalOpen(false)
                    isNotificationAdded && showFlashMessageWithTimeout(dispatch, t('popups.notificationAdded'), 'success')
                    setIsNotificationAdded(false)
                }}
            />

            <ZboziNotificationModal
                createdNotificationId={createdNotificationId}
                isOpen={isZboziNotificationModalOpen}
                onClose={() => {
                    setIsZboziNotificationModalOpen(false)
                    isNotificationAdded && showFlashMessageWithTimeout(dispatch, t('popups.notificationAdded'), 'success')
                    setIsNotificationAdded(false)
                }}
            />

            <SpolehliveRecenzeNotificationModal
                createdNotificationId={createdNotificationId}
                isOpen={isSpolehliveRecenzeNotificationModalOpen}
                onClose={() => {
                    setIsSpolehliveRecenzeNotificationModalOpen(false)
                    isNotificationAdded && showFlashMessageWithTimeout(dispatch, t('popups.notificationAdded'), 'success')
                    setIsNotificationAdded(false)
                }}
            />

            <ApiKeyModal
                type={apiKeyModalOpenFor}
                isOpen={!!apiKeyModalOpenFor}
                onClose={() => {
                    setApiKeyModalOpenFor(undefined)
                    isNotificationAdded && showFlashMessageWithTimeout(dispatch, t('popups.notificationAdded'), 'success')
                    setIsNotificationAdded(false)
                }}
                onOpenModal={() => {
                    if (apiKeyModalOpenFor === 'heureka') {
                        setIsAddZboziApiKeyModalOpen(true)
                    } else if (apiKeyModalOpenFor === 'spolehlivaRecenze') {
                        setIsSpolehliveRecenzeLoginModalOpen(true)
                    } else {
                        setAddZboziApiKeyModal(true)
                    }
                    setApiKeyModalOpenFor(undefined)
                }}
            />

            <ActualVisitorsModal
                createdNotificationId={createdNotificationId}
                isOpen={isActualVisitorsModalOpen}
                onClose={() => {
                    setIsActualVisitorsModalOpen(false)
                    isNotificationAdded && showFlashMessageWithTimeout(dispatch, t('popups.notificationAdded'), 'success')
                    setIsNotificationAdded(false)
                }}
                language={language}
            />

            <IndividualNotificationModal
                createdNotificationId={createdNotificationId}
                isOpen={isIndividualNotificationModalOpen}
                onClose={() => {
                    setIsIndividualNotificationModalOpen(false)
                    isNotificationAdded && showFlashMessageWithTimeout(dispatch, t('popups.notificationAdded'), 'success')
                    setIsNotificationAdded(false)
                }}
            />

            <SummaryModal
                createdNotificationId={createdNotificationId}
                isOpen={isSummaryModalOpen}
                onClose={() => {
                    setIsSummaryModalOpen(false)
                    isNotificationAdded && showFlashMessageWithTimeout(dispatch, t('popups.notificationAdded'), 'success')
                    setIsNotificationAdded(false)
                }}
                language={language}
            />

            <ShoptetLastOrdersNotificationModal
                createdNotificationId={createdNotificationId}
                isOpen={isShoptetLastOrdersNotificationModalOpen}
                onClose={() => {
                    setIsShoptetLastOrdersNotificationModalOpen(false)
                    isNotificationAdded && showFlashMessageWithTimeout(dispatch, 'Notifikace byla úspěšně přidána.', 'success')
                    setIsNotificationAdded(false)
                }}
                language={language}
            />

            <ShoptetCouponCodeNotificationModal
                createdNotificationId={createdNotificationId}
                isOpen={isShoptetCouponNotificationModalOpen}
                onClose={() => {
                    setIsShoptetCouponNotificationModalOpen(false)
                    isNotificationAdded && showFlashMessageWithTimeout(dispatch, 'Notifikace byla úspěšně přidána.', 'success')
                    setIsNotificationAdded(false)
                }}
                language={language}
            />

            {(isRegistrationNotificationModalOpen || isLastOrdersNotificationModalOpen) && (
                <OrdersAndRegistrationsModal
                    forOrders={isLastOrdersNotificationModalOpen}
                    createdNotificationId={createdNotificationId}
                    isOpen={isRegistrationNotificationModalOpen || isLastOrdersNotificationModalOpen}
                    onClose={() => {
                        setIsRegistrationNotificationModalOpen(false)
                        setIsLastOrdersNotificationModalOpen(false)
                        isNotificationAdded && showFlashMessageWithTimeout(dispatch, t('popups.notificationAdded'), 'success')
                        setIsNotificationAdded(false)
                    }}
                    language={language}
                />
            )}
        </Box>
    )
}

const useStyles = makeStyles((theme: Theme) => ({
    goBackText: {
        color: theme.palette.main.primary.o,
        fontSize: 16,
        marginTop: 32,
        marginLeft: 28,
        cursor: 'pointer',
        userSelect: 'none',
    },
    optionsContainer: {
        flex: 1,
        display: 'flex',
        flexWrap: 'wrap',
        marginTop: 28,
        marginLeft: 28,
    },
    optionCard: {
        width: 390,
        border: `1px solid ${theme.palette.natural.t3}`,
        borderRadius: 4,
        marginRight: 24,
        marginBottom: 20,
        cursor: 'pointer',
    },
    cardBody: {
        flex: 1,
        margin: 16,
        display: 'flex',
        flexDirection: 'column',
    },
    title: {
        fontSize: 16,
        color: theme.palette.main.secondary.u1,
        fontWeight: 'bold',
        marginBottom: 9,
    },
    hint: {
        fontSize: 14,
        color: theme.palette.natural.t1,
        marginBottom: 16,
        minHeight: 42,
    },
}))

export default AddNotification

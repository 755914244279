import Tab from '@mui/material/Tab'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import { Box, useTheme, Theme } from '@mui/material'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { ProfileTabType } from '../../components/types'
import { UserManager } from '../../services/api/UserManager'
import { makeStyles } from '@mui/styles'
import ProfileInfo from './ProfileInfo'
import ProfileConnection from './ProfileConnection'
import ProfileSubscription from './ProfileSubscription'
import { UserDto } from '../../shared/types/userTypes'
import { useDispatch } from 'react-redux'
import { showFlashMessageWithTimeout } from '../../services/actions/flashMessage-actions'
import CircularProgress from '@mui/material/CircularProgress'
import { useTranslation } from 'react-i18next'

const Profile = () => {
    const location = useLocation()
    const { preselectedTab } = (location.state as any) ?? {}
    const theme = useTheme()
    const classes = useStyles()
    const dispatch = useDispatch()
    const { t } = useTranslation()

    const [selectedTab, setSelectedTab] = useState<ProfileTabType>(preselectedTab ? preselectedTab : 'subscription')
    const [userDto, setUserDto] = useState<UserDto>()
    const [isLoading, setIsLoading] = useState<boolean>(false)

    useEffect(() => {
        const fetchUserFullInfo = async () => {
            try {
                setIsLoading(true)
                const _userManager = UserManager.getManager()
                const result = await _userManager.getUserSingleAsync()
                setUserDto(result as UserDto)
                setIsLoading(false)
            } catch (e: any) {
                setIsLoading(false)
                showFlashMessageWithTimeout(dispatch, e.toString(), 'error')
            }
        }
        fetchUserFullInfo()
    }, [dispatch])

    useEffect(() => {
        setSelectedTab(preselectedTab ? preselectedTab : 'subscription')
    }, [preselectedTab, dispatch])

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setSelectedTab(newValue as ProfileTabType)
    }

    const activeColor = theme.palette.natural.t1
    const notActiveColor = theme.palette.natural.t2

    if (isLoading) {
        return (
            <Box className={classes.loadingContainer}>
                <CircularProgress style={{ color: theme.palette.main.primary.o }} />
            </Box>
        )
    }

    return (
        <Box style={{ flex: 1, marginLeft: 24, marginRight: 24, marginTop: 16 }}>
            <TabContext value={selectedTab}>
                <TabList
                    TabIndicatorProps={{
                        style: {
                            background: theme.palette.main.primary.o,
                            height: 3,
                        },
                    }}
                    onChange={handleChange}
                >
                    <Tab
                        disableRipple
                        className={classes.tab}
                        style={{
                            color: selectedTab === 'info' ? activeColor : notActiveColor,
                        }}
                        label={t('contact.title')}
                        value='info'
                    />
                    {userDto?.registeredFrom === 'internal' && (
                        <Tab
                            disableRipple
                            className={classes.tab}
                            style={{
                                color: selectedTab === 'connection' ? activeColor : notActiveColor,
                            }}
                            label={t('passwordChange.title')}
                            value='connection'
                        />
                    )}
                    <Tab
                        disableRipple
                        className={classes.tab}
                        style={{
                            color: selectedTab === 'subscription' ? activeColor : notActiveColor,
                        }}
                        label={t('subscriptions.title')}
                        value='subscription'
                    />
                    <Box className={classes.tabsDivider} />
                </TabList>
                <TabPanel value='info' style={{ padding: 0 }}>
                    {userDto && <ProfileInfo userDto={userDto} onChangeUserDto={setUserDto} />}
                </TabPanel>
                <TabPanel value='connection' style={{ padding: 0 }}>
                    <ProfileConnection />
                </TabPanel>
                <TabPanel value='subscription' style={{ padding: 0 }}>
                    <ProfileSubscription user={userDto} onUpdateUser={setUserDto} />
                </TabPanel>
            </TabContext>
        </Box>
    )
}

const useStyles = makeStyles((theme: Theme) => ({
    tab: {
        textTransform: 'none',
        fontSize: 16,
    },
    tabsDivider: {
        width: 393,
        height: 3,
        backgroundColor: theme.palette.natural.t3,
        position: 'absolute',
        bottom: 0,
    },
    loadingContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 32,
    },
}))

export default Profile

import { GlobalVars } from '../../global'
import { VerifyExternalApiResponse } from '../../shared/payloads/externalApiPayloads'
import { Country } from '../../shared/types/websiteTypes'
import { handleNetworkResponseAndGetJsonAsync, Network } from './Network'

export class ExternalApiManager {
    static _instance: ExternalApiManager | null = null

    static getManager(): ExternalApiManager {
        if (this._instance === null) {
            this._instance = new ExternalApiManager()
        }
        return this._instance
    }

    async verifyHeurekaExternalApi(apiKey: string, country: Country): Promise<VerifyExternalApiResponse> {
        let response: Response | null = null
        const url = `${GlobalVars.SERVER_ENDPOINT}/heureka/verify?apiKey=${apiKey}&country=${country}`
        try {
            response = await Network.fetch(url, {
                method: 'GET',
                credentials: 'include',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
            })
        } catch (e) {
            throw new Error('Error executing fetch request.')
        }
        if (response) {
            const result: VerifyExternalApiResponse = await handleNetworkResponseAndGetJsonAsync(response)
            return result
        } else {
            throw new Error('No response from server returned.')
        }
    }

    async verifyZboziExternalApi(apiKey: string, shopId: string): Promise<VerifyExternalApiResponse> {
        let response: Response | null = null
        const url = `${GlobalVars.SERVER_ENDPOINT}/zbozi/verify?apiKey=${apiKey}&shopId=${shopId}`
        try {
            response = await Network.fetch(url, {
                method: 'GET',
                credentials: 'include',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
            })
        } catch (e) {
            throw new Error('Error executing fetch request.')
        }
        if (response) {
            const result: VerifyExternalApiResponse = await handleNetworkResponseAndGetJsonAsync(response)
            return result
        } else {
            throw new Error('No response from server returned.')
        }
    }

    async verifySpolehliveRecenzeExternalApi(shopId: string, password: string): Promise<VerifyExternalApiResponse> {
        let response: Response | null = null
        const url = `${GlobalVars.SERVER_ENDPOINT}/spolehlivaRecenze/verify?shopId=${shopId}&password=${password}`
        try {
            response = await Network.fetch(url, {
                method: 'GET',
                credentials: 'include',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
            })
        } catch (e) {
            throw new Error('Error executing fetch request.')
        }
        if (response) {
            const result: VerifyExternalApiResponse = await handleNetworkResponseAndGetJsonAsync(response)
            return result
        } else {
            throw new Error('No response from server returned.')
        }
    }
}

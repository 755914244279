import { useEffect, useState } from 'react'
import { Box, Grid, Stack, Theme, Typography, useTheme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useNavigate, useLocation } from 'react-router-dom'
import { Routes } from './navigation-types'
import { useDispatch, useSelector } from 'react-redux'
import { UserManager } from '../services/api/UserManager'
import { showFlashMessageWithTimeout } from '../services/actions/flashMessage-actions'
import { setUserInfo } from '../services/actions/user-actions'
import CircularProgressBox from '../components/CircularProgressBox'
import { getUserInfo } from '../services/selectors/user-selectors'
import HelpButton from '../components/HelpButton'
import { useTranslation } from 'react-i18next'

const SidePanel: React.FC = () => {
    const classes = useStyles()
    const theme = useTheme()
    const navigate = useNavigate()
    const user = useSelector(getUserInfo)
    const location = useLocation()
    const [selectedTab, setSelectedTab] = useState<Routes>(Routes.campaign)
    const dispatch = useDispatch()
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const { t, i18n } = useTranslation()

    // Set userInfo every minute
    useEffect(() => {
        const fetchUserFullInfo = async () => {
            try {
                setIsLoading(true)
                const _userManager = UserManager.getManager()
                const result = await _userManager.getUserSingleAsync()
                dispatch(setUserInfo(result))
                i18n.changeLanguage(result.isAdmin ? 'cs' : result.language)
                setIsLoading(false)
            } catch (e: any) {
                setIsLoading(false)
                showFlashMessageWithTimeout(dispatch, e.toString(), 'error')
            }
        }
        const getCreditsEveryMinute = setInterval(() => {
            fetchUserFullInfo()
        }, 60000)

        fetchUserFullInfo()

        return () => clearInterval(getCreditsEveryMinute)
    }, [dispatch])

    useEffect(() => {
        if (Object.values(Routes).includes(location.pathname as Routes)) {
            setSelectedTab(location.pathname as Routes)
        }
    }, [selectedTab, location.pathname])

    return (
        <Box className={classes.sidePanel}>
            <Stack direction='column' height='100%'>
                <Box
                    onClick={() => navigate(`${Routes.campaign}`)}
                    className={classes.menuItem}
                    style={{
                        marginTop: 56,
                        backgroundColor: selectedTab === Routes.campaign ? theme.palette.main.primary.d3 : undefined,
                        borderRight: selectedTab === Routes.campaign ? `3px solid ${theme.palette.main.primary.o}` : undefined,
                    }}
                >
                    <Typography
                        style={{
                            marginLeft: 20,
                            color: selectedTab === Routes.campaign ? theme.palette.main.primary.o : theme.palette.natural.t1,
                        }}
                    >
                        {t('sidePanel.campaign')}
                    </Typography>
                </Box>
                <Box
                    onClick={() => navigate(`${Routes.connections}`)}
                    className={classes.menuItem}
                    style={{
                        backgroundColor: selectedTab === Routes.connections ? theme.palette.main.primary.d3 : undefined,
                        borderRight: selectedTab === Routes.connections ? `3px solid ${theme.palette.main.primary.o}` : undefined,
                    }}
                >
                    <Typography
                        style={{
                            marginLeft: 20,
                            color: selectedTab === Routes.connections ? theme.palette.main.primary.o : theme.palette.natural.t1,
                        }}
                    >
                        {t('sidePanel.connection')}
                    </Typography>
                </Box>
                {user?.registeredFrom === 'internal' && (
                    <Box
                        onClick={() => navigate(`${Routes.settings}`)}
                        className={classes.menuItem}
                        style={{
                            backgroundColor: selectedTab === Routes.settings ? theme.palette.main.primary.d3 : undefined,
                            borderRight:
                                selectedTab === Routes.settings ? `3px solid ${theme.palette.main.primary.o}` : undefined,
                        }}
                    >
                        <Typography
                            style={{
                                marginLeft: 20,
                                color: selectedTab === Routes.settings ? theme.palette.main.primary.o : theme.palette.natural.t1,
                            }}
                        >
                            {t('sidePanel.settings')}
                        </Typography>
                    </Box>
                )}
                {user?.isAdmin && (
                    <Box
                        onClick={() => navigate(`${Routes.admin}`)}
                        className={classes.menuItem}
                        style={{
                            backgroundColor: selectedTab === Routes.admin ? theme.palette.main.primary.d3 : undefined,
                            borderRight: selectedTab === Routes.admin ? `3px solid ${theme.palette.main.primary.o}` : undefined,
                        }}
                    >
                        <Typography
                            style={{
                                marginLeft: 20,
                                color: selectedTab === Routes.admin ? theme.palette.main.primary.o : theme.palette.natural.t1,
                            }}
                        >
                            Admin
                        </Typography>
                    </Box>
                )}

                <Box sx={{ marginTop: 'auto' }}>
                    {isLoading ? (
                        <CircularProgressBox />
                    ) : (
                        user && (
                            <Grid container>
                                <Grid item xs={8}>
                                    <Typography
                                        variant='caption'
                                        style={{
                                            marginLeft: 10,
                                            marginBottom: 5,
                                            color: theme.palette.natural.t1,
                                        }}
                                    >
                                        {t('monthlyCredits')}:
                                    </Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography variant='caption' style={{ color: theme.palette.natural.t1 }}>
                                        {user?.isInTrial ? t('sidePanel.infinite') : user?.remainingPricingPlanCredits ?? 0}
                                    </Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    <Typography
                                        variant='caption'
                                        style={{
                                            marginLeft: 10,
                                            marginBottom: 5,
                                            color: theme.palette.natural.t1,
                                        }}
                                    >
                                        {t('portableCredits')}:
                                    </Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography variant='caption' style={{ color: theme.palette.natural.t1 }}>
                                        {user?.remainingExtraCredits ?? 0}
                                    </Typography>
                                </Grid>
                            </Grid>
                        )
                    )}

                    <Stack direction='row' alignItems='center' my={2}>
                        <HelpButton link='https://www.overenyweb.cz/napoveda/' />
                        <a
                            href='javascript:void(Tawk_API.toggle())'
                            style={{
                                textDecoration: 'none',
                            }}
                        >
                            <Typography
                                variant='body2'
                                style={{
                                    marginLeft: 10,
                                    color: theme.palette.natural.t1,
                                    fontSize: 12,
                                    marginTop: 3,
                                }}
                            >
                                {t('reportProblem')}
                            </Typography>
                        </a>
                    </Stack>
                </Box>
            </Stack>
        </Box>
    )
}

const useStyles = makeStyles((theme: Theme) => ({
    sidePanel: {
        position: 'fixed',
        backgroundColor: '#FBFAFC',
        borderTop: `1px solid black`,
        width: 200,
        height: '100vh',
        zIndex: 1,
        borderRight: '1px solid #DCDCDC',
        // Hide scrollbar
        '&::-webkit-scrollbar': {
            width: 0,
        },
    },
    menuItem: {
        height: 48,
        width: 197,
        display: 'flex',
        alignItems: 'center',
        '&:hover': {
            backgroundColor: theme.palette.main.primary.d3,
            borderRight: '3px solid #EDECF0',
        },
        cursor: 'pointer',
    },
}))

export default SidePanel

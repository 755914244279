import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import { cs } from './resources.cs'
import { hu } from './resources.hu'
import { pl } from './resources.pl'

i18n
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        lng: navigator.language || navigator.userAgent,
        debug: true,
        fallbackLng: 'cs',
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        resources: {
            cs: {
                translation: cs,
            },
            sk: {
                translation: cs,
            },
            hu: {
                translation: hu,
            },
            pl: {
                translation: pl,
            },
        },
    })

export default i18n

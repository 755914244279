import { Typography, Box, Theme, SelectChangeEvent } from '@mui/material'
import { FC, useState } from 'react'
import { makeStyles } from '@mui/styles'
import { AccountType, UserDto } from '../../shared/types/userTypes'
import CustomTextField from '../../components/CustomTextField'
import { DropdownMenuItem } from '../../components/types'
import WebDropdown from '../../components/WebDropdown'
import CustomButton from '../../components/CustomButton'
import { showFlashMessageWithTimeout } from '../../services/actions/flashMessage-actions'
import { useDispatch } from 'react-redux'
import { UserManager } from '../../services/api/UserManager'
import { UpdateUserRequest } from '../../shared/payloads/userPayloads'
import { useTranslation } from 'react-i18next'

interface ProfileInfoType {
    userDto?: UserDto
    onChangeUserDto: (userDto: UserDto) => void
}

type CountryType = 'sk' | 'cz' | 'hu' | 'pl'

const dropdownItems: DropdownMenuItem[] = [
    { title: 'contact.basic.personal', value: 'personal' },
    { title: 'contact.basic.business', value: 'business' },
]

const ProfileInfo: FC<ProfileInfoType> = ({ userDto, onChangeUserDto }) => {
    const dispatch = useDispatch()
    const classes = useStyles()
    const { t } = useTranslation()
    const [accountType, setAccountType] = useState<AccountType>(userDto?.accountType ? userDto?.accountType : 'personal')
    const [name, setName] = useState<string>(userDto?.name ? userDto.name : '')
    const [surname, setSurname] = useState<string>(userDto?.surname ? userDto?.surname : '')
    const [phoneNumber, setPhoneNumber] = useState<string>(userDto?.phoneNumber ? userDto?.phoneNumber : '')
    const [companyName, setCompanyName] = useState<string>(userDto?.companyName ? userDto?.companyName : '')
    const [ico, setIco] = useState<string>(userDto?.companyRegistrationNumber ? userDto?.companyRegistrationNumber : '')
    const [dic, setDic] = useState<string>(
        userDto?.valueAddedTaxIdentificationNumber ? userDto?.valueAddedTaxIdentificationNumber : ''
    )

    const [streetName, setStreetName] = useState<string>(userDto?.streetAndHouseNumber ? userDto?.streetAndHouseNumber : '')
    const [townName, setTownName] = useState<string>(userDto?.town ? userDto?.town : '')
    const [psc, setPsc] = useState<string>(userDto?.postcode ? userDto?.postcode : '')
    const [selectedCountry, setSelectedCountry] = useState<CountryType>(userDto?.country ? userDto?.country : 'cz')
    const [isLoading, setIsLoading] = useState<boolean>(false)

    const onAccountTypeSelect = (event: SelectChangeEvent) => {
        setAccountType(event.target.value as AccountType)
    }

    const onCountrySelect = (event: SelectChangeEvent) => {
        setSelectedCountry(event.target.value as CountryType)
    }

    const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setName(event.target.value)
    }

    const handleSurnameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSurname(event.target.value)
    }

    const handlePhoneNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPhoneNumber(event.target.value)
    }
    const handleCompanyNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCompanyName(event.target.value)
    }

    const handleIcoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIco(event.target.value)
    }

    const handleDicChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDic(event.target.value)
    }

    const handleStreetNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setStreetName(event.target.value)
    }

    const handleTownNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTownName(event.target.value)
    }

    const handlePscChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPsc(event.target.value)
    }

    const onSave = async () => {
        if (name.length === 0) {
            showFlashMessageWithTimeout(dispatch, t('popups.nameIsEmpty'), 'error')
            return
        }
        try {
            setIsLoading(true)
            const _userManager = UserManager.getManager()

            const body: UpdateUserRequest = {
                name: name,
                accountType: accountType,
                phoneNumber: phoneNumber ? phoneNumber : undefined,
                surname: surname ? surname : undefined,
                streetAndHouseNumber: streetName,
                town: townName,
                postcode: psc,
                country: selectedCountry,
                companyRegistrationNumber: ico,
                valueAddedTaxIdentificationNumber: dic,
                companyName: companyName,
            }

            const result = await _userManager.updateUserAsync(body)
            if (result) {
                onChangeUserDto(result)
                showFlashMessageWithTimeout(dispatch, t('popups.changesSaved'), 'success')
            }
            setIsLoading(false)
        } catch (e: any) {
            setIsLoading(false)
            showFlashMessageWithTimeout(dispatch, e.toString(), 'error')
        }
    }

    return (
        <Box
            style={{
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <Box
                style={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'row',
                    marginTop: 24,
                    maxWidth: 1008,
                }}
            >
                <Box
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: 480,
                        marginRight: 56,
                    }}
                >
                    <Typography className={classes.sectionTitle}>{t('contact.basic.title')}</Typography>

                    <WebDropdown
                        id='profile-settings-options'
                        label={t('contact.basic.accountType')}
                        items={dropdownItems.map((item) => {
                            return {
                                title: t(item.title),
                                value: item.value,
                            }
                        })}
                        value={accountType}
                        onChange={onAccountTypeSelect}
                    />
                    <Box
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            marginTop: 24,
                            marginBottom: 24,
                        }}
                    >
                        <CustomTextField
                            label={t('contact.basic.name') ?? ''}
                            value={name}
                            isRequired
                            onChange={handleNameChange}
                            fullWidth
                        />
                        <Box style={{ width: 48 }} />
                        <CustomTextField
                            label={t('contact.basic.surname') ?? ''}
                            value={surname}
                            onChange={handleSurnameChange}
                            fullWidth
                        />
                    </Box>
                    {accountType === 'business' && (
                        <>
                            <CustomTextField
                                style={{ marginBottom: 24 }}
                                label={t('contact.basic.companyName') ?? ''}
                                value={companyName}
                                onChange={handleCompanyNameChange}
                            />
                            <CustomTextField
                                style={{ marginBottom: 24 }}
                                label={t('contact.basic.ico') ?? ''}
                                value={ico}
                                onChange={handleIcoChange}
                            />
                            <CustomTextField
                                style={{ marginBottom: 24 }}
                                label={t('contact.basic.dic') ?? ''}
                                value={dic}
                                onChange={handleDicChange}
                            />
                        </>
                    )}

                    <CustomTextField
                        label={t('contact.basic.phone') ?? ''}
                        value={phoneNumber}
                        onChange={handlePhoneNumberChange}
                    />
                </Box>

                <Box
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: 480,
                    }}
                >
                    <Typography className={classes.sectionTitle}>{t('contact.address.title')}</Typography>

                    <CustomTextField
                        style={{ marginBottom: 24 }}
                        label={t('contact.address.street') ?? ''}
                        value={streetName}
                        onChange={handleStreetNameChange}
                        fullWidth
                    />
                    <CustomTextField
                        style={{ marginBottom: 24 }}
                        label={t('contact.address.city') ?? ''}
                        value={townName}
                        onChange={handleTownNameChange}
                        fullWidth
                    />
                    <CustomTextField
                        style={{ marginBottom: 24 }}
                        label={t('contact.address.zip') ?? ''}
                        value={psc}
                        onChange={handlePscChange}
                        fullWidth
                    />
                    <WebDropdown
                        id='profile-settings-country'
                        label={t('contact.address.country') ?? ''}
                        items={[
                            { title: t('contact.address.slovakia'), value: 'sk' },
                            { title: t('contact.address.czechRepublic'), value: 'cz' },
                            { title: t('contact.address.hungary'), value: 'hu' },
                            { title: t('contact.address.polish'), value: 'pl' },
                        ]}
                        value={selectedCountry}
                        onChange={onCountrySelect}
                    />
                </Box>
            </Box>
            <Box className={classes.buttonContainer}>
                <CustomButton isLoading={isLoading} style={{ width: 88 }} title={t('save')} size='medium' onClick={onSave} />
            </Box>
        </Box>
    )
}

const useStyles = makeStyles((theme: Theme) => ({
    sectionTitle: {
        fontSize: 18,
        color: theme.palette.main.secondary.u1,
        marginBottom: 24,
        fontWeight: 'bold',
    },
    buttonContainer: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row-reverse',
        maxWidth: 1008,
        marginTop: 32,
    },
}))

export default ProfileInfo

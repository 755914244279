import { Box } from '@mui/material'
import { useState } from 'react'
import AddWeb from './AddWeb'
import Welcome from './Welcome'
import AddPixel from './AddPixel'
import AddApiKeys from './AddApiKeys'
import { useLocation, useNavigate } from 'react-router-dom'
import { Routes } from '../../navigation/navigation-types'

interface LocationState {
    preselectedScreenIndex?: number
}

const OnBoarding = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const locationState: LocationState = location.state as LocationState

    const [currentScreenIndex, setCurrentScreenIndex] = useState<number>(
        locationState && locationState.preselectedScreenIndex ? locationState.preselectedScreenIndex : 0
    )
    const [createdWebId, setCreatedWebId] = useState<string>('')

    const goToNextScreen = () => setCurrentScreenIndex(currentScreenIndex + 1)
    const goToPreviousScreen = () => {
        if (currentScreenIndex > 0) {
            setCurrentScreenIndex(currentScreenIndex - 1)
        } else return
    }

    return (
        <Box style={{ flex: 1 }}>
            {currentScreenIndex === 0 && <Welcome goToNext={goToNextScreen} />}
            {currentScreenIndex === 1 && (
                <AddWeb
                    goToNext={goToNextScreen}
                    goToPrevious={
                        locationState && locationState.preselectedScreenIndex
                            ? () => navigate(Routes.websites)
                            : goToPreviousScreen
                    }
                    setCreatedWebId={(id) => setCreatedWebId(id)}
                />
            )}
            {currentScreenIndex === 2 && (
                <AddPixel createdWebId={createdWebId} goToNext={goToNextScreen} goToPrevious={goToPreviousScreen} />
            )}
            {currentScreenIndex === 3 && (
                <AddApiKeys createdWebId={createdWebId} goToNext={goToNextScreen} goToPrevious={goToPreviousScreen} />
            )}
        </Box>
    )
}

export default OnBoarding

import { Input, Stack } from '@mui/material'
import React from 'react'
import CustomButton from '../CustomButton'

interface CustomColorPickerProps {
    color: string | null
    defaultColor: string
    onChange: (color: string | null) => void
    justifyContent?: 'flex-end' | 'space-between' | 'flex-start'
}

const CustomColorPicker: React.FC<CustomColorPickerProps> = ({
    color,
    onChange,
    defaultColor,
    justifyContent,
}: CustomColorPickerProps) => {
    return (
        <Stack
            direction='row'
            spacing={2}
            alignItems='center'
            pt={1}
            justifyContent={justifyContent ?? color ? 'space-between' : 'flex-end'}
            width='100%'
        >
            <Input
                disableUnderline
                type='color'
                inputProps={{
                    style: {
                        width: 150,
                        height: 40,
                        cursor: 'pointer',
                        color: 'blue',
                    },
                }}
                value={color || defaultColor}
                onChange={(event) => onChange(event.target.value)}
            />
            {color && (
                <CustomButton
                    onClick={() => onChange(null)}
                    title='Reset'
                    size='small'
                    type='secondary'
                    style={{
                        width: 100,
                    }}
                />
            )}
        </Stack>
    )
}

export default CustomColorPicker

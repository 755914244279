import { Box, Typography, useTheme, Theme, TextField, Tab, Grid, Stack } from '@mui/material'
import { FC, useLayoutEffect, useState } from 'react'
import ModalBase from '../../components/ModalBase'
import CloseIcon from '@mui/icons-material/Close'
import { makeStyles } from '@mui/styles'
import CustomButton from '../../components/CustomButton'
import CustomSwitch from '../../components/CustomSwitch'
import { useDispatch } from 'react-redux'
import { showFlashMessageWithTimeout } from '../../services/actions/flashMessage-actions'
import { NotificationManager } from '../../services/api/NotificationManager'
import {
    CreateNotificationSettingsResponse,
    GetNotificationSettingsResponse,
} from '../../shared/payloads/notificationSettingsPayloads'
import { useParams } from 'react-router-dom'
import CustomDropdown from '../../components/CustomDropdown'
import { NotificationType } from '../../shared/types/notificationSettingsTypes'
import DOMPurify from 'dompurify'
import NotificationPreview from '../../components/NotificationPreview'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import CustomColorPicker from '../../components/NotificationEditComponents/CustomColorPicker'
import { NotificationDefaultSettings } from '../../types/notification-default-settings'
import BorderSelect, { BorderType } from '../../components/NotificationEditComponents/BorderSelect'
import { useTranslation } from 'react-i18next'
import { v4 } from 'uuid'

interface SpolehliveRecenzeModalProps {
    createdNotificationId: string
    isOpen: boolean
    onClose: () => void
    data?: GetNotificationSettingsResponse
}

type VariantType = 'spolehlivaRecenzeShopRating' | 'spolehlivaRecenzeProductRating'

const dropdownItems = [
    { title: 'storeReview', value: 'spolehlivaRecenzeShopRating' },
    { title: 'productReview', value: 'spolehlivaRecenzeProductRating' },
]

type TabType = 'rules' | 'design'

const notificationStyleId = v4()

const SpolehliveRecenzeNotificationModal: FC<SpolehliveRecenzeModalProps> = ({
    createdNotificationId,
    isOpen,
    onClose,
    data,
}) => {
    const theme = useTheme()
    const classes = useStyles()
    const dispatch = useDispatch()
    const { id } = useParams()
    const { t } = useTranslation()

    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [minReviewStars, setMinReviewStars] = useState<number>(data?.minReviewStars !== undefined ? data?.minReviewStars : 4)
    const [minReviews, setMinReviews] = useState<number>(data?.minReviews !== undefined ? data?.minReviews : 1)
    const [withRepeat, setWithRepeat] = useState<boolean>(
        data?.repeatNotification !== undefined ? data?.repeatNotification : true
    )

    const [selectedVariant, setSelectedVariant] = useState<VariantType>(
        data?.type ? (data?.type as VariantType) : 'spolehlivaRecenzeShopRating'
    )
    const [selectedTab, setSelectedTab] = useState<TabType>('rules')

    const [notificationHtml, setNotificationHtml] = useState<string>('')

    const [backgroundColor, setBackgroundColor] = useState<string | null>(
        data?.notificationVisualSettings?.backgroundColor || null
    )
    const [textColor, setTextColor] = useState<string | null>(data?.notificationVisualSettings?.textColor || null)
    const [iconBackgroundColor, setIconBackgroundColor] = useState<string | null>(
        data?.notificationVisualSettings?.iconBackgroundColor || null
    )
    const [borderRadius, setBorderRadius] = useState<BorderType | null>(data?.notificationVisualSettings?.roundness || null)

    const onCloseAndClearInputs = () => {
        setMinReviewStars(4)
        setMinReviews(1)
        setWithRepeat(true)
        setSelectedVariant('spolehlivaRecenzeShopRating')
        onClose()
    }

    useLayoutEffect(() => {
        const createNotificationPreviewHtml = async () => {
            const result = await window.getNotificationPreview(
                {
                    campaign: {
                        notificationVisualSettings: {
                            roundness: 1,
                        },
                    },
                    settings: {
                        type: selectedVariant,
                        notificationVisualSettings: {
                            backgroundColor: backgroundColor ?? '',
                            textColor: textColor ?? '',
                            iconBackgroundColor: iconBackgroundColor ?? '',
                            roundness: borderRadius ?? 0,
                        },
                    },
                },
                selectedVariant,
                undefined,
                undefined,
                undefined,
                'cs',
                undefined,
                undefined,
                t('campaign.notifications.zbozi.previewText1') ?? '',
                minReviewStars ?? 4,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                notificationStyleId
            )
            // Transforms object htmldivelement to pure string
            const cleanHtmlString = DOMPurify.sanitize(result, {
                USE_PROFILES: { html: true, svg: true },
            })
            setNotificationHtml(cleanHtmlString)
        }

        createNotificationPreviewHtml()
    })

    const handleMinReviewStarsChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const result = parseInt(event.target.value, 10)
        if (result >= 5) {
            setMinReviewStars(5)
        } else {
            setMinReviewStars(result)
        }
    }

    const handleMinNumberOfReviewsChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setMinReviews(parseInt(event.target.value, 10))
    }

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setSelectedTab(newValue as TabType)
    }

    const toggleWithRepeat = () => {
        setWithRepeat(!withRepeat)
    }

    const onNotificationCreate = async (type: NotificationType): Promise<string | undefined> => {
        if (id) {
            try {
                const _notificationManager = NotificationManager.getManager()
                const result: CreateNotificationSettingsResponse = await _notificationManager.createNotificationSettingsAsync(
                    id,
                    type
                )
                if (result.id) {
                    return result.id
                } else {
                    throw new Error(t('popups.cannotCreateNotification') ?? '')
                }
            } catch (e: any) {
                showFlashMessageWithTimeout(dispatch, e.toString(), 'error')
            }
        }
    }

    const onSave = async () => {
        try {
            setIsLoading(true)
            const _notificationManager = NotificationManager.getManager()
            let newCreatedNotificationId = undefined
            if (selectedVariant !== data?.type) {
                await _notificationManager.deleteNotificationSettings(createdNotificationId)
                newCreatedNotificationId = await onNotificationCreate(selectedVariant)
            }

            await _notificationManager.updateNotificationSettingsAsync({
                id: newCreatedNotificationId ? newCreatedNotificationId : createdNotificationId,
                isActive: true,
                repeatNotification: withRepeat,
                minReviews: minReviews,
                minReviewStars: minReviewStars,

                notificationVisualSettings: {
                    backgroundColor: backgroundColor ?? '',
                    textColor: textColor ?? '',
                    iconBackgroundColor: iconBackgroundColor ?? '',
                    roundness: borderRadius ?? 0,
                },
            })
            setIsLoading(false)
            onCloseAndClearInputs()
        } catch (e: any) {
            setIsLoading(false)
            showFlashMessageWithTimeout(dispatch, e.toString(), 'error')
        }
    }

    const activeColor = theme.palette.natural.t1
    const notActiveColor = theme.palette.natural.t2

    return (
        <ModalBase center isOpen={isOpen} onClose={onCloseAndClearInputs}>
            <Stack
                direction='column'
                bgcolor={theme.palette.natural.t4}
                p={3}
                overflow='auto'
                sx={{
                    width: { xs: '80%', md: 900 },
                    maxHeight: '90vh',
                }}
                boxShadow={theme.palette.main.shadow.secondary}
                borderRadius={2}
            >
                <Box className={classes.titleContainer}>
                    <Typography className={classes.title}>
                        {t('campaign.notifications.spolehlivaRecenzeProductRating.title')}
                    </Typography>
                    <CloseIcon style={{ cursor: 'pointer' }} onClick={onCloseAndClearInputs} />
                </Box>
                <NotificationPreview notificationHtml={notificationHtml} />

                <TabContext value={selectedTab}>
                    <TabList
                        TabIndicatorProps={{
                            style: {
                                background: theme.palette.main.primary.o,
                                height: 3,
                            },
                        }}
                        onChange={handleChange}
                    >
                        <Tab
                            disableRipple
                            className={classes.tab}
                            style={{
                                color: selectedTab === 'rules' ? activeColor : notActiveColor,
                            }}
                            label={t('campaign.notifications.settings.displayRules.title')}
                            value='rules'
                        />
                        <Tab
                            disableRipple
                            className={classes.tab}
                            style={{
                                color: selectedTab === 'design' ? activeColor : notActiveColor,
                            }}
                            label={t('campaign.notifications.settings.design.title')}
                            value='design'
                        />
                        <Box className={classes.tabsDivider} />
                    </TabList>
                    <TabPanel value='rules'>
                        <Typography
                            style={{
                                color: theme.palette.main.primary.o,
                                fontSize: 18,
                                marginTop: 16,
                                marginBottom: 16,
                                fontWeight: 'bold',
                            }}
                        >
                            {t('campaign.notifications.settings.displayRules.variant')}
                        </Typography>
                        <CustomDropdown
                            width={400}
                            value={selectedVariant}
                            label={t('campaign.notifications.settings.displayRules.choseVariant')}
                            items={dropdownItems.map((item) => ({
                                title: t(`campaign.notifications.settings.displayRules.zbozi.${item.title}`),
                                value: item.value,
                            }))}
                            onChange={(event) => setSelectedVariant(event.target.value as VariantType)}
                        />
                        <Typography
                            style={{
                                color: theme.palette.main.primary.o,
                                fontSize: 18,
                                marginTop: 24,
                                marginBottom: 16,
                                fontWeight: 'bold',
                            }}
                        >
                            {t('campaign.notifications.settings.displayRules.show')}
                        </Typography>
                        <Box style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                            <Box
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    flex: 1,
                                    height: 104,
                                }}
                            >
                                <Box className={classes.row} style={{ marginBottom: 16 }}>
                                    <Box className={classes.textContainer}>
                                        <Typography
                                            style={{
                                                flex: 1,
                                                color: theme.palette.natural.t1,
                                                fontSize: 16,
                                            }}
                                        >
                                            {t('campaign.notifications.settings.displayRules.zbozi.maxReviews')}
                                        </Typography>
                                        <TextField
                                            style={{ width: 108, marginRight: 12 }}
                                            type='number'
                                            variant='outlined'
                                            value={minReviews}
                                            onChange={handleMinNumberOfReviewsChange}
                                            InputProps={{
                                                inputProps: { min: 0 },
                                                style: { height: 44 },
                                            }}
                                        />
                                        <Box style={{ width: 72 }} />
                                    </Box>
                                </Box>
                                <Box className={classes.row}>
                                    <Box className={classes.textContainer}>
                                        <Typography
                                            style={{
                                                flex: 1,
                                                color: theme.palette.natural.t1,
                                                fontSize: 16,
                                            }}
                                        >
                                            {t('campaign.notifications.settings.displayRules.heureka.reviewsWithRating')}
                                        </Typography>
                                        <TextField
                                            style={{ width: 108, marginRight: 12 }}
                                            type='number'
                                            variant='outlined'
                                            value={minReviewStars}
                                            onChange={handleMinReviewStarsChange}
                                            InputProps={{
                                                inputProps: { min: 0 },
                                                style: { height: 44 },
                                            }}
                                        />
                                        <Typography
                                            style={{
                                                color: theme.palette.natural.t1,
                                                fontSize: 16,
                                                width: 72,
                                            }}
                                        >
                                            {t('campaign.notifications.settings.displayRules.heureka.stars')}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                            <Box style={{ flex: 1 }}>
                                <Box
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        marginLeft: 80,
                                        height: 52,
                                    }}
                                >
                                    <Typography style={{ color: theme.palette.natural.t1, fontSize: 16 }}>
                                        {t('campaign.notifications.settings.displayRules.repeatNotification')}
                                    </Typography>
                                    <CustomSwitch checked={withRepeat} onSwitch={() => toggleWithRepeat()} disabled={false} />
                                </Box>
                            </Box>
                        </Box>
                        <Box
                            style={{
                                height: 44,
                                display: 'flex',
                                flexDirection: 'row-reverse',
                                marginTop: 44,
                            }}
                        >
                            <CustomButton
                                size='medium'
                                style={{ width: 87 }}
                                title={t('save')}
                                isLoading={isLoading}
                                onClick={onSave}
                            />
                        </Box>
                    </TabPanel>
                    <TabPanel value='design' style={{ padding: 0 }}>
                        <Typography style={{ marginTop: 16 }} mb={1}>
                            {t('campaign.notifications.settings.design.description')}
                        </Typography>
                        <Grid container columnSpacing={3}>
                            <Grid item xs={12} md={6}>
                                <Stack direction='row' alignItems='center'>
                                    <Typography fontSize={18} color={theme.palette.main.primary.o} fontWeight='bold' width={300}>
                                        {t('campaign.notifications.settings.design.backgroundColour')}
                                    </Typography>

                                    <CustomColorPicker
                                        color={backgroundColor}
                                        onChange={setBackgroundColor}
                                        defaultColor={NotificationDefaultSettings.BackgroundColor}
                                    />
                                </Stack>
                                <Stack direction='row' alignItems='center'>
                                    <Typography fontSize={18} color={theme.palette.main.primary.o} fontWeight='bold' width={300}>
                                        {t('campaign.notifications.settings.design.textColour')}
                                    </Typography>

                                    <CustomColorPicker
                                        color={textColor}
                                        onChange={setTextColor}
                                        defaultColor={NotificationDefaultSettings.TextColor}
                                    />
                                </Stack>
                                <Stack direction='row' alignItems='center'>
                                    <Typography fontSize={18} color={theme.palette.main.primary.o} fontWeight='bold' width={300}>
                                        {t('campaign.notifications.settings.design.iconBackgroundColour')}
                                    </Typography>

                                    <CustomColorPicker
                                        color={iconBackgroundColor}
                                        onChange={setIconBackgroundColor}
                                        defaultColor={NotificationDefaultSettings.IconBackgroundColor}
                                    />
                                </Stack>
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <Typography fontSize={18} color={theme.palette.main.primary.o} fontWeight='bold'>
                                    {t('campaign.notifications.settings.design.roundedCorners')}
                                </Typography>

                                <BorderSelect
                                    selectedBorderValue={borderRadius}
                                    onChange={setBorderRadius}
                                    defaultBorderValue={Number(NotificationDefaultSettings.BorderRoundness)}
                                />

                                <Stack
                                    direction='row'
                                    justifyContent='flex-end'
                                    mt={{
                                        xs: 2,
                                        md: 18,
                                    }}
                                >
                                    <CustomButton
                                        style={{ width: 132 }}
                                        size='medium'
                                        type='secondary'
                                        title={t('back')}
                                        onClick={() => setSelectedTab('rules')}
                                    />
                                    <CustomButton
                                        style={{ width: 132, marginLeft: 24 }}
                                        size='medium'
                                        type='primary'
                                        title={t('save')}
                                        isLoading={isLoading}
                                        onClick={onSave}
                                    />
                                </Stack>
                            </Grid>
                        </Grid>
                    </TabPanel>
                </TabContext>
            </Stack>
        </ModalBase>
    )
}

const useStyles = makeStyles((theme: Theme) => ({
    tabsDivider: {
        width: 262,
        height: 3,
        backgroundColor: theme.palette.natural.t3,
        position: 'absolute',
        bottom: 0,
    },
    tab: {
        textTransform: 'none',
        fontSize: 16,
    },
    container: {
        minWidth: 1000,
        flex: 1,
        paddingTop: 32,
        paddingBottom: 32,
        paddingLeft: 24,
        paddingRight: 24,
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: theme.palette.natural.t4,
        boxShadow: theme.palette.main.shadow.secondary,
        borderRadius: 8,
    },
    titleContainer: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginRight: 13,
    },
    title: {
        fontSize: 28,
        fontWeight: 'bold',
        color: theme.palette.main.secondary.u1,
    },
    row: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    textContainer: {
        display: 'flex',
        flexDirection: 'row',
        width: 417,
        flex: 1,
        alignItems: 'center',
    },
}))

export default SpolehliveRecenzeNotificationModal

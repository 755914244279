import { useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom'
import { getAuthenticationStatus } from '../services/selectors/user-selectors'

interface Props {
    component: React.ComponentType
    path?: string
}

export const ProtectedRoute: React.FC<Props> = ({ component: RouteComponent }) => {
    const isAuthenticated = useSelector(getAuthenticationStatus)

    if (isAuthenticated) {
        return <RouteComponent />
    }

    return <Navigate to='/' />
}

export default ProtectedRoute

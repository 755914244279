import { Box, Theme, Typography, useTheme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { FC, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import CustomButton from '../../components/CustomButton'
import { Routes } from '../../navigation/navigation-types'
import { WebsiteManager } from '../../services/api/WebsiteManager'
import { WebsiteDto } from '../../shared/types/websiteTypes'
import HeurekaApiKeyModal from './HeurekaApiKeyModal'
import ZboziApiKeyModal from './ZboziApiKeyModal'
import DoneIcon from '@mui/icons-material/Done'
import { showFlashMessageWithTimeout } from '../../services/actions/flashMessage-actions'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import SpolehliveRecenzeLoginModal from './SpolehliveRecenzeLoginModal'

const API_KEYS: { name: string; type: string }[] = [
    { name: 'Heureka', type: 'heureka' },
    { name: 'Zboží.cz', type: 'zbozi' },
    { name: 'Spolehlive Recenze', type: 'spolehliveRecenze' },
]
interface AddApiKeysProps {
    goToNext?: () => void
    goToPrevious?: () => void
    createdWebId: string
    // Includes different styling for connection screen
    isConnectionsScreen?: boolean
}

const AddApiKeys: FC<AddApiKeysProps> = ({ goToNext, goToPrevious, createdWebId, isConnectionsScreen }) => {
    const classes = useStyles()
    const theme = useTheme()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const [heurekaModalOpen, setHeurekaModalOpen] = useState<boolean>(false)
    const [zboziModalOpen, setZboziModalOpen] = useState<boolean>(false)
    const [spolehliveRecenzeModalOpen, setSpolehliveRecenzeModalOpen] = useState<boolean>(false)
    const [websiteData, setWebsiteData] = useState<WebsiteDto | undefined>()
    const [isWebsiteLoading, setIsWebsiteLoading] = useState<boolean>(false)

    useEffect(() => {
        const fetchSiteData = async () => {
            setIsWebsiteLoading(true)
            try {
                const _websiteManager = WebsiteManager.getManager()
                const result: WebsiteDto = await _websiteManager.getWebsiteSingleAsync(createdWebId)
                setWebsiteData(result)
                setIsWebsiteLoading(false)
            } catch (e: any) {
                setIsWebsiteLoading(false)
                showFlashMessageWithTimeout(dispatch, e.toString(), 'error')
            }
        }
        if (createdWebId) {
            fetchSiteData()
        }
    }, [heurekaModalOpen, zboziModalOpen, createdWebId, dispatch])

    const getStringStatus = (type: string, websiteData?: WebsiteDto) => {
        if (type === 'heureka') {
            if (websiteData?.heurekaApiKey) return t('popups.added')
            return t('add')
        }
        if (type === 'zbozi') {
            if (websiteData?.zboziApiKey) return t('popups.added')
            return t('add')
        }
        if (type === 'spolehliveRecenze') {
            if (websiteData?.spolehlivaRecenzeShopId) return t('popups.added')
            return t('add')
        }
    }

    const statusSuccess = (
        <Box className={classes.statusContainer}>
            <Box className={classes.iconContainer}>
                <DoneIcon style={{ color: '#FBFAFC' }} />
            </Box>
            <Typography className={classes.statusTitle}>{t('connected')}</Typography>
        </Box>
    )

    return (
        <Box className={classes.container} style={{ justifyContent: isConnectionsScreen ? 'flex-start' : 'center' }}>
            <Box
                className={classes.formContainer}
                style={{
                    alignItems: isConnectionsScreen ? 'stretch' : 'center',
                    marginTop: isConnectionsScreen ? 24 : 65,
                }}
            >
                <Typography className={classes.title} style={{ marginBottom: isConnectionsScreen ? 0 : 20 }}>
                    {isConnectionsScreen ? t('apiKeys') : t('addingApiKeys')}
                </Typography>
                {!isConnectionsScreen && (
                    <Typography style={{ fontSize: 16, color: theme.palette.natural.t1 }}>
                        {t('optionalAddApiKey')}
                    </Typography>
                )}
                {API_KEYS.map((apiKey, index) => {
                    const wasHeurekaKeyAdded = !!websiteData?.heurekaApiKey
                    const wasZboziKeyAdded = !!websiteData?.zboziApiKey
                    const wasSpolehliveRecenzeKeyAdded = !!websiteData?.spolehlivaRecenzeShopId

                    return (
                        <Box key={index} className={classes.apiKeyRow}>
                            <Typography style={{ marginLeft: 35 }}>
                                {apiKey.type === 'spolehliveRecenze' ? t('spolehliveRecenze') : apiKey.name}
                            </Typography>
                            <Box style={{ flex: 1 }} />
                            {wasHeurekaKeyAdded && apiKey.type === 'heureka' && (
                                <Box onClick={() => setHeurekaModalOpen(true)}>{statusSuccess}</Box>
                            )}
                            {!wasHeurekaKeyAdded && apiKey.type === 'heureka' && (
                                <CustomButton
                                    size='medium'
                                    title={getStringStatus(apiKey.type, websiteData) || 'Přidat'}
                                    onClick={() => setHeurekaModalOpen(true)}
                                    style={{ marginRight: 24, width: 90 }}
                                    isLoading={isWebsiteLoading}
                                />
                            )}
                            {wasZboziKeyAdded && apiKey.type === 'zbozi' && (
                                <Box onClick={() => setZboziModalOpen(true)}>{statusSuccess}</Box>
                            )}
                            {!wasZboziKeyAdded && apiKey.type === 'zbozi' && (
                                <CustomButton
                                    size='medium'
                                    title={getStringStatus(apiKey.type, websiteData) || 'Přidat'}
                                    onClick={() => setZboziModalOpen(true)}
                                    style={{ marginRight: 24, width: 90 }}
                                    isLoading={isWebsiteLoading}
                                />
                            )}
                            {wasSpolehliveRecenzeKeyAdded && apiKey.type === 'spolehliveRecenze' && (
                                <Box onClick={() => setSpolehliveRecenzeModalOpen(true)}>{statusSuccess}</Box>
                            )}
                            {!wasSpolehliveRecenzeKeyAdded && apiKey.type === 'spolehliveRecenze' && (
                                <CustomButton
                                    size='medium'
                                    title={getStringStatus(apiKey.type, websiteData) || 'Přidat'}
                                    onClick={() => setSpolehliveRecenzeModalOpen(true)}
                                    style={{ marginRight: 24, width: 90 }}
                                    isLoading={isWebsiteLoading}
                                />
                            )}
                        </Box>
                    )
                })}
                {!isConnectionsScreen && (
                    <Box className={classes.buttonContainer}>
                        <CustomButton
                            isLoading={false}
                            title={t('continue')}
                            onClick={() => navigate(Routes.campaign)}
                            style={{ width: 120, marginLeft: 20 }}
                        />
                        <CustomButton
                            title={t('back')}
                            type='secondary'
                            onClick={goToPrevious ? goToPrevious : () => {}}
                            size='large'
                            style={{ width: 100 }}
                        />
                    </Box>
                )}
            </Box>
            <HeurekaApiKeyModal
                websiteData={websiteData}
                createdWebId={createdWebId}
                isOpen={heurekaModalOpen}
                onClose={() => setHeurekaModalOpen(false)}
            />
            <ZboziApiKeyModal
                websiteData={websiteData}
                createdWebId={createdWebId}
                isOpen={zboziModalOpen}
                onClose={() => setZboziModalOpen(false)}
            />
            <SpolehliveRecenzeLoginModal
                websiteData={websiteData}
                createdWebId={createdWebId}
                isOpen={spolehliveRecenzeModalOpen}
                onClose={() => setSpolehliveRecenzeModalOpen(false)}
            />
        </Box>
    )
}

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        display: 'flex',
        alignItems: 'center',
    },
    formContainer: {
        width: 660,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    buttonContainer: {
        height: 52,
        flex: 1,
        display: 'flex',
        flexDirection: 'row-reverse',
        marginTop: 30,
        width: '100%',
    },
    apiKeyRow: {
        height: 86,
        backgroundColor: theme.palette.natural.t10,
        width: 660,
        marginTop: 24,
        display: 'flex',
        alignItems: 'center',
        borderRadius: 4,
    },
    title: {
        fontSize: 28,
        fontWeight: 'bold',
        color: theme.palette.main.secondary.u1,
    },
    iconContainer: {
        height: 32,
        width: 32,
        borderRadius: 16,
        marginLeft: 16,
        marginRight: 16,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.palette.main.accent.o,
    },
    statusContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginRight: 24,
        cursor: 'pointer',
    },
    statusTitle: {
        fontWeight: '500',
        color: theme.palette.natural.t1,
        fontSize: 16,
    },
}))

export default AddApiKeys

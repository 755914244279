import React, { useEffect } from 'react'
import { AUTH_LOCAL_STORAGE_KEY, parseJwtEncodedTokenAndGetReduxState } from '../../services/types/auth-types'
import { logoutUser, setAuthState } from '../../services/actions/auth-actions'
import { useDispatch } from 'react-redux'
import { removeUserInfo } from '../../services/actions/user-actions'
import { clearWebs } from '../../services/actions/web-actions'
import { useNavigate } from 'react-router-dom'
import { Routes } from '../../navigation/navigation-types'
import WelcomePng from '../../assets/images/welcome.png'
import DeletePng from '../../assets/images/delete.png'
import { Stack, Typography } from '@mui/material'
import CustomButton from '../../components/CustomButton'
import { useTranslation } from 'react-i18next'

const ShoptetLogin: React.FC = () => {
  const url = window.location.href
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { t, i18n } = useTranslation()

  const [screenText, setScreenText] = React.useState<string>('')
  const [isError, setIsError] = React.useState<boolean>(false)

  useEffect(() => {
    const removeOldToken = async () => {
      dispatch(removeUserInfo())
      dispatch(clearWebs())
      dispatch(logoutUser())
      localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY)
    }

    const login = async () => {
      setScreenText('')
      setIsError(false)
      try {
        await removeOldToken()

        const accessToken = url.split('externalToken=')[1].split('&')[0]
        const language = url.split('language=')[1].split('&')[0]

        localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, accessToken)
        const state = parseJwtEncodedTokenAndGetReduxState(accessToken)

        i18n.changeLanguage(language)

        dispatch(setAuthState(state))
        setScreenText(t('shoptetFirstTouchScreen.title') ?? '')

        if (state.parsedToken.isActivationConfirmed && state.parsedToken.registeredFrom === 'shoptet') {
          navigate(Routes.campaign)
        }
      } catch (error) {
        await removeOldToken()

        setIsError(true)
        setScreenText(t('shoptetFirstTouchScreen.error') ?? '')
      }
    }

    login()
  }, [dispatch, url, navigate])

  const handleButtonClick = async () => {
    navigate(Routes.profile, {
      state: { preselectedTab: 'subscription' },
    })
  }

  return (
    <div style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Stack px={20} justifyContent='center' alignItems='center'>
        <img src={isError ? DeletePng : WelcomePng} alt='welcome' />
        <Stack maxWidth={800} spacing={1} justifyContent='center' alignItems='center' textAlign='center' my={1}>
          <Typography fontSize={32} fontWeight={500}>{screenText}</Typography>
          {
            !isError && (<>
              <Typography>{t('shoptetFirstTouchScreen.description1')}</Typography>
              <Typography>{t('shoptetFirstTouchScreen.description2')}</Typography>
            </>)
          }
        </Stack>
        {
          !isError && <CustomButton title={t('shoptetFirstTouchScreen.button')} onClick={handleButtonClick} size='medium' style={{
            paddingLeft: 20,
            paddingRight: 20,
          }} />
        }
      </Stack>
    </div>
  )
}

export default ShoptetLogin
import { Box, Theme, useMediaQuery, useTheme } from '@mui/material'
import RegistrationForm from './RegistrationForm'
import { makeStyles } from '@mui/styles'
import ReviewsSlider from '../../components/ReviewsSlider'

const Registration = () => {
    const classes = useStyles()
    const theme = useTheme()
    const isTablet = useMediaQuery(theme.breakpoints.down('md'))

    return (
        <Box className={classes.container}>
            <Box
                style={{
                    width: isTablet ? 'auto' : 876,
                    backgroundColor: theme.palette.natural.t6,
                    boxShadow: theme.palette.main.shadow.primary,
                    borderRadius: 16,
                    marginBottom: 32,
                }}
            >
                <RegistrationForm />
            </Box>
            <ReviewsSlider />
        </Box>
    )
}

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        paddingBottom: 32,
        paddingTop: 32,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.palette.main.primary.d2,
        overflow: 'scroll',
    },
}))

export default Registration

import { Dispatch } from 'redux'
import { AuthActionTypes, AuthReduxState, AUTH_LOCAL_STORAGE_KEY, SetAuthState } from '../types/auth-types'
import { removeUserInfo } from './user-actions'
import { clearWebs } from './web-actions'

export const setAuthState = (state: AuthReduxState): SetAuthState => ({
    type: AuthActionTypes.SET_AUTH_STATE,
    payload: state,
})

export const logoutUser = () => {
    return async (dispatch: Dispatch): Promise<void> => {
        localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY)
        dispatch(
            setAuthState({
                isAuthenticated: false,
                userEmail: '',
                token: '',
                parsedToken: {
                    userId: '',
                    createdTimestamp: 0,
                    userEmail: '',
                    expiration: 0,
                    integrityHash: '',
                    refreshIntervalInSeconds: 0,
                    refreshTimestamp: 0,
                    isAdmin: false,
                    isActivationConfirmed: false,
                    registeredFrom: 'internal',
                },
            })
        )

        dispatch(removeUserInfo())
        dispatch(clearWebs())
    }
}

import { Box, Typography, useTheme, Theme } from '@mui/material'
import { FC } from 'react'
import CustomButton from './CustomButton'
import { makeStyles } from '@mui/styles'
import CloseIcon from '@mui/icons-material/Close'
import DoneIcon from '@mui/icons-material/Done'
import { useTranslation } from 'react-i18next'

interface StatusCheckerProps {
    isActive?: boolean
    onClick: () => void
    isLoading?: boolean
}

const StatusChecker: FC<StatusCheckerProps> = ({ isActive, onClick, isLoading }) => {
    const classes = useStyles()
    const theme = useTheme()
    const { t } = useTranslation();

    return (
        <Box className={classes.container}>
            <Box className={classes.body}>
                <Box className={classes.statusBox}>
                    {isActive !== undefined && (
                        <Box
                            className={classes.iconContainer}
                            style={{
                                backgroundColor: isActive ? theme.palette.main.accent.o : theme.palette.main.alert.o,
                            }}
                        >
                            {isActive === true && <DoneIcon className={classes.icon} />}
                            {isActive === false && <CloseIcon className={classes.icon} />}
                        </Box>
                    )}
                    {isActive === undefined && <Typography className={classes.nonCheckedText}>{t('notVerified')}</Typography>}
                    {isActive !== undefined && (
                        <Typography style={{ fontSize: 16, color: theme.palette.natural.t1 }}>
                            {isActive ? t('active') : t('inactive')}
                        </Typography>
                    )}
                </Box>
                <Box style={{ flex: 1 }} />
                <CustomButton
                    type='secondary'
                    size='small'
                    isLoading={isLoading}
                    style={{
                        width: 120,
                        marginRight: 10,
                    }}
                    title={t('check')}
                    onClick={onClick}
                />
            </Box>
        </Box>
    )
}

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        height: 72,
        flex: 1,
    },
    body: {
        height: 72,
        backgroundColor: theme.palette.natural.t6,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        border: `1px solid ${theme.palette.natural.t8}`,
        borderRadius: 4,
        marginTop: 24,
    },
    statusBox: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    iconContainer: {
        height: 32,
        width: 32,
        borderRadius: 16,
        marginLeft: 16,
        marginRight: 16,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    nonCheckedText: {
        fontSize: 16,
        color: theme.palette.natural.t1,
        marginLeft: 16,
        fontStyle: 'italic',
    },
    icon: {
        color: theme.palette.natural.t7,
    },
}))

export default StatusChecker

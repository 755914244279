import { Box, Typography, useTheme, Theme } from '@mui/material'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { makeStyles, withStyles } from '@mui/styles'
import { ReactElement } from 'react'
import { OrderRecordDto, RegistrationRecordDto } from '../../../shared/types/activityStorageTypes'

const StyledTableRow = withStyles((theme: Theme) => ({
    root: {
        height: 33,
        backgroundColor: theme.palette.natural.t6,
    },
}))(TableRow)

interface CampaignAnalyticsTableProps {
    analytics: OrderRecordDto[] | RegistrationRecordDto[]
}

const CampaignAnalyticsTable: React.FC<CampaignAnalyticsTableProps> = ({ analytics }) => {
    const classes = useStyles()
    const theme = useTheme()

    const rows: ReactElement[] = []

    analytics.forEach((analytic, index) => {
        rows.push(
            <StyledTableRow
                key={index}
                sx={{
                    '&:last-child td, &:last-child th': { border: 0 },
                }}
            >
                <TableCell className={classes.tableHeaderCell} align='left'>
                    <Typography style={{ fontWeight: 'bold' }}>{new Date(analytic.timestamp).toLocaleString('cs')}</Typography>
                </TableCell>
                <TableCell className={classes.tableHeaderCell} align='left'>
                    <Typography style={{ fontWeight: 'bold' }}>{analytic.customerName}</Typography>
                </TableCell>
                <TableCell className={classes.tableHeaderCell} component='th' scope='row'>
                    <Typography style={{ fontWeight: 'bold' }}>{analytic.customerLocation}</Typography>
                </TableCell>
            </StyledTableRow>
        )
    })

    return (
        <TableContainer component={Box}>
            <Table className={classes.table} sx={{ minWidth: 650 }} aria-label='simple table'>
                <TableHead classes={{ root: classes.thead }}>
                    <TableRow
                        style={{
                            backgroundColor: theme.palette.main.primary.d2,
                            height: 36,
                            borderRadius: 4,
                        }}
                    >
                        <TableCell className={classes.tableCell} align='left'>
                            <Typography style={{ fontWeight: 'bold' }}>Datum a čas</Typography>
                        </TableCell>
                        <TableCell className={classes.tableCell} align='left'>
                            <Typography style={{ fontWeight: 'bold' }}>Jméno zákazníka</Typography>
                        </TableCell>
                        <TableCell className={classes.tableCell} align='left'>
                            <Typography style={{ fontWeight: 'bold' }}>Poloha zákazníka</Typography>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>{rows}</TableBody>
            </Table>
        </TableContainer>
    )
}

const useStyles = makeStyles((theme: Theme) => ({
    table: {
        minWidth: 550,
    },
    tableHeaderCell: {
        padding: '0px 24px',
        color: theme.palette.natural.t1,
    },
    tableCell: {
        padding: '0px 24px',
        color: theme.palette.natural.t,
    },
    thead: {
        backgroundColor: theme.palette.natural.t2,
        '& th:first-child': {
            borderRadius: '4px 0 0 0px',
        },
        '& th:last-child': {
            borderRadius: '0 4px 0 0',
        },
    },
}))

export default CampaignAnalyticsTable

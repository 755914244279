import { Box, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { showFlashMessageWithTimeout } from '../../services/actions/flashMessage-actions'
import { NotificationManager } from '../../services/api/NotificationManager'
import { GetNotificationSettingsResponse } from '../../shared/payloads/notificationSettingsPayloads'
import ActualVisitorsModal from './ActualVisitorsModal'
import HeurekaNotificationModal from './HeurekaNotificationModal'
import IndividualNotificationModal from './IndividualNotificationModal'
import OrdersAndRegistrationsModal from './OrdersAndRegistrationsModal'
import SummaryModal from './SummaryModal'
import ZboziNotificationModal from './ZboziNotificationModal'
import ShoptetLastOrdersNotificationModal from './ShoptetLastOrdersNotificationModal'
import ShoptetCouponCodeNotificationModal from './ShoptetCouponCodeNotificationModal'
import { useTranslation } from 'react-i18next'
import { convertStringToLanguage } from '../../generic-utils'
import SpolehliveRecenzeNotificationModal from './SpolehliveRecenzeNotificationModal'

const EditNotification = () => {
    const { notificationId } = useParams()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { i18n } = useTranslation()

    const language = convertStringToLanguage(i18n.language)

    const [notificationToEdit, setNotificationToEdit] = useState<GetNotificationSettingsResponse>()
    const [ordersAndRegistrationsModalOpenFor, setOrdersAndRegistrationsModalOpenFor] = useState<
        'lastOrders' | 'lastRegistrations' | undefined
    >(undefined)
    const [isActualVisitorsModalOpen, setIsActualVisitorsModalOpen] = useState<boolean>(false)
    const [summaryModalOpenedFor, setSummaryModalOpenedFor] = useState<
        'visitorSummary' | 'registrationSummary' | 'orderSummary' | undefined
    >()
    const [isHeurekaNotificationModalOpen, setIsHeurekaNotificationModalOpen] = useState<boolean>(false)
    const [isSpolehliveRecenzeNotificationModalOpen, setIsSpolehliveRecenzeNotificationModalOpen] = useState<boolean>(false)
    const [isZboziNotificationModalOpen, setIsZboziNotificationModalOpen] = useState<boolean>(false)
    const [isIndividualNotificationModalOpen, setIsIndividualNotificationModalOpen] = useState<boolean>(false)
    const [isShoptetLastOrdersNotificationModalOpen, setIsShoptetLastOrdersNotificationModalOpen] = useState<boolean>(false)
    const [isShoptetCouponNotificationModalOpen, setIsShoptetCouponNotificationModalOpen] = useState<boolean>(false)

    useEffect(() => {
        const fetchNotification = async () => {
            if (notificationId) {
                try {
                    const _notificationManager = NotificationManager.getManager()
                    const result: GetNotificationSettingsResponse = await _notificationManager.getSingleNotificationSettingsAsync(
                        notificationId
                    )
                    setNotificationToEdit(result)
                    if (result.type === 'lastOrders' || result.type === 'lastRegistrations') {
                        setOrdersAndRegistrationsModalOpenFor(result.type)
                    }
                    if (result.type === 'actualVisitors') {
                        setIsActualVisitorsModalOpen(true)
                    }
                    if (
                        result.type === 'visitorSummary' ||
                        result.type === 'registrationSummary' ||
                        result.type === 'orderSummary'
                    ) {
                        setSummaryModalOpenedFor(result.type)
                    }
                    if (result.type === 'heureka') {
                        setIsHeurekaNotificationModalOpen(true)
                    }
                    if (result.type === 'zboziCompanyRating' || result.type === 'zboziProductRating') {
                        setIsZboziNotificationModalOpen(true)
                    }
                    if (result.type === 'individual') {
                        setIsIndividualNotificationModalOpen(true)
                    }
                    if (result.type === 'shoptetLastOrders') {
                        setIsShoptetLastOrdersNotificationModalOpen(true)
                    }
                    if (result.type === 'shoptetCouponCode') {
                        setIsShoptetCouponNotificationModalOpen(true)
                    }
                    if (result.type === 'spolehlivaRecenzeShopRating' || result.type === 'spolehlivaRecenzeProductRating') {
                        setIsSpolehliveRecenzeNotificationModalOpen(true)
                    }
                } catch (e: any) {
                    dispatch(showFlashMessageWithTimeout(dispatch, e.toString(), 'error'))
                }
            }
        }

        fetchNotification()
    }, [notificationId, dispatch])

    if (!notificationId) {
        return <Typography style={{ marginTop: 20, marginLeft: 20 }}>Nepodařilo se načíst nastavení</Typography>
    }

    return (
        <Box>
            {notificationToEdit && (
                <>
                    {ordersAndRegistrationsModalOpenFor !== undefined && (
                        <OrdersAndRegistrationsModal
                            data={notificationToEdit}
                            createdNotificationId={notificationId}
                            forOrders={ordersAndRegistrationsModalOpenFor === 'lastOrders'}
                            isOpen={!!ordersAndRegistrationsModalOpenFor}
                            onClose={() => {
                                setOrdersAndRegistrationsModalOpenFor(undefined)
                                navigate(-1)
                            }}
                            language={language}
                        />
                    )}
                    <ActualVisitorsModal
                        data={notificationToEdit}
                        createdNotificationId={notificationId}
                        isOpen={isActualVisitorsModalOpen}
                        onClose={() => {
                            setIsActualVisitorsModalOpen(false)
                            navigate(-1)
                        }}
                        language={language}
                    />
                    <SummaryModal
                        data={notificationToEdit}
                        createdNotificationId={notificationId}
                        isOpen={!!summaryModalOpenedFor}
                        onClose={() => {
                            setSummaryModalOpenedFor(undefined)
                            navigate(-1)
                        }}
                        language={language}
                    />
                    <HeurekaNotificationModal
                        data={notificationToEdit}
                        createdNotificationId={notificationId}
                        isOpen={isHeurekaNotificationModalOpen}
                        onClose={() => {
                            setIsHeurekaNotificationModalOpen(false)
                            navigate(-1)
                        }}
                    />
                    <SpolehliveRecenzeNotificationModal
                        data={notificationToEdit}
                        createdNotificationId={notificationId}
                        isOpen={isSpolehliveRecenzeNotificationModalOpen}
                        onClose={() => {
                            setIsSpolehliveRecenzeNotificationModalOpen(false)
                            navigate(-1)
                        }}
                    />
                    <ZboziNotificationModal
                        data={notificationToEdit}
                        createdNotificationId={notificationId}
                        isOpen={isZboziNotificationModalOpen}
                        onClose={() => {
                            setIsZboziNotificationModalOpen(false)
                            navigate(-1)
                        }}
                    />
                    <IndividualNotificationModal
                        data={notificationToEdit}
                        createdNotificationId={notificationId}
                        isOpen={isIndividualNotificationModalOpen}
                        onClose={() => {
                            setIsIndividualNotificationModalOpen(false)
                            navigate(-1)
                        }}
                    />
                    <ShoptetLastOrdersNotificationModal
                        data={notificationToEdit}
                        createdNotificationId={notificationId}
                        isOpen={isShoptetLastOrdersNotificationModalOpen}
                        onClose={() => {
                            setIsShoptetLastOrdersNotificationModalOpen(false)
                            navigate(-1)
                        }}
                        language={language}
                    />
                    <ShoptetCouponCodeNotificationModal
                        data={notificationToEdit}
                        createdNotificationId={notificationId}
                        isOpen={isShoptetCouponNotificationModalOpen}
                        onClose={() => {
                            setIsShoptetCouponNotificationModalOpen(false)
                            navigate(-1)
                        }}
                        language={language}
                    />
                </>
            )}
        </Box>
    )
}

export default EditNotification

import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import {
    Box,
    Tab,
    useTheme,
    Theme,
    Typography,
    TextField,
    SelectChangeEvent,
    Autocomplete,
    Chip,
    Stack,
    Grid,
} from '@mui/material'
import { FC, useLayoutEffect, useState } from 'react'
import ModalBase from '../../components/ModalBase'
import { makeStyles } from '@mui/styles'
import CloseIcon from '@mui/icons-material/Close'
import TabPanel from '@mui/lab/TabPanel'
import CustomTextField from '../../components/CustomTextField'
import CustomSwitch from '../../components/CustomSwitch'
import CustomButton from '../../components/CustomButton'
import { getActiveWebUrl } from '../../services/selectors/web-selectors'
import { useDispatch, useSelector } from 'react-redux'
import { NotificationManager } from '../../services/api/NotificationManager'
import {
    CreateNotificationSettingsResponse,
    GetNotificationSettingsResponse,
    UpdateNotificationSettingsRequest,
} from '../../shared/payloads/notificationSettingsPayloads'
import { showFlashMessageWithTimeout } from '../../services/actions/flashMessage-actions'
import CustomDropdown from '../../components/CustomDropdown'
import { DropdownMenuItem } from '../../components/types'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import { fireEvent } from '@testing-library/react'
import { NotificationType } from '../../shared/types/notificationSettingsTypes'
import { useParams } from 'react-router-dom'
import { transformFormsToUrlFilterDto, transformUrlFilterToForms } from '../../generic-utils'
import DOMPurify from 'dompurify'
import NotificationPreview from '../../components/NotificationPreview'
import { Language } from '../../shared/types/websiteTypes'
import HelpButton from '../../components/HelpButton'
import CustomColorPicker from '../../components/NotificationEditComponents/CustomColorPicker'
import BorderSelect, { BorderType } from '../../components/NotificationEditComponents/BorderSelect'
import { NotificationDefaultSettings } from '../../types/notification-default-settings'
import IconSelect from '../../components/NotificationEditComponents/IconSelect'
import { useTranslation } from 'react-i18next'
import { getActualVisitorsModalInitialText } from '../../utils/web-utils'
import { v4 } from 'uuid'

interface SummaryModalProps {
    isOpen: boolean
    onClose: () => void
    createdNotificationId: string
    language: Language
    openedFor?: 'visitorSummary' | 'registrationSummary' | 'orderSummary'
    data?: GetNotificationSettingsResponse
}

type ControlType = 'beginsWith' | 'contains' | 'isEqual'

interface FormType {
    control: ControlType
    include: string[]
    excludes: string[]
}

const menuItems: DropdownMenuItem[] = [
    {
        title: 'Začíná na',
        value: 'beginsWith',
    },
    {
        title: 'Obsahuje',
        value: 'contains',
    },
    {
        title: 'Shoduje se',
        value: 'isEqual',
    },
]

type TabType = 'rules' | 'pairing' | 'design'
type VariantType = 'visitorSummary' | 'registrationSummary' | 'orderSummary'

const getDaysString = (type: VariantType) => {
    if (type === 'visitorSummary') return 'visitors'
    if (type === 'registrationSummary') return 'registrations'
    if (type === 'orderSummary') return 'orders'
}

const getMinNumberString = (type: VariantType) => {
    if (type === 'visitorSummary') return 'Minimální počet návštěvníků pro zobrazení'
    if (type === 'registrationSummary') return 'Minimální počet registrací pro zobrazení'
    if (type === 'orderSummary') return 'Minimální počet objednávek pro zobrazení'
}

const dropdownItems = [
    { title: 'visitors', value: 'visitorSummary' },
    { title: 'registrations', value: 'registrationSummary' },
    { title: 'orders', value: 'orderSummary' },
]

const getAvailableControls = (forms: FormType[]) => {
    const unavailableControls: ControlType[] = []
    const allControls: ControlType[] = ['beginsWith', 'contains', 'isEqual']
    forms.forEach((form) => {
        unavailableControls.push(form.control)
    })
    const result = allControls.filter((ac) => !unavailableControls.includes(ac))
    return result
}

const daysDropdownItems = [
    { title: 'days', value: '1' },
    { title: 'days', value: '7' },
    { title: 'days', value: '30' },
]

const getMin = (data: GetNotificationSettingsResponse) => {
    if (data.type === 'visitorSummary' && data.minVisitors !== undefined) {
        return data.minVisitors
    }
    if (data.type === 'registrationSummary' && data.minRegistrations !== undefined) {
        return data.minRegistrations
    }
    if (data.type === 'orderSummary' && data.minOrders !== undefined) {
        return data.minOrders
    }
    return 1
}

const notificationStyleId = v4()

const SummaryModal: FC<SummaryModalProps> = ({ isOpen, onClose, createdNotificationId, language, data }) => {
    const theme = useTheme()
    const classes = useStyles()
    const dispatch = useDispatch()
    const activeWebUrl = useSelector(getActiveWebUrl)
    const { id } = useParams()
    const { t } = useTranslation()

    const [selectedTab, setSelectedTab] = useState<TabType>('rules')
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [text, setText] = useState<string>(data?.text ? data.text : t(getActualVisitorsModalInitialText(language)) ?? '')
    const [withRepeat, setWithRepeat] = useState<boolean>(
        data?.repeatNotification !== undefined ? data?.repeatNotification : true
    )
    const [days, setDays] = useState<number>(data?.maxLastDays !== undefined ? data?.maxLastDays : 7)
    const [minNumber, setMinNumber] = useState<number>(data !== undefined ? getMin(data) : 20)
    const [forms, setForms] = useState<FormType[]>(
        data?.urlFilter && Object.keys(data?.urlFilter).length !== 0
            ? transformUrlFilterToForms(data.urlFilter)
            : [{ control: 'beginsWith', include: [], excludes: [] }]
    )

    const [selectedVariant, setSelectedVariant] = useState<VariantType>(
        data?.type ? (data.type as VariantType) : 'visitorSummary'
    )

    const [notificationHtml, setNotificationHtml] = useState<string>('')

    const [backgroundColor, setBackgroundColor] = useState<string | null>(
        data?.notificationVisualSettings?.backgroundColor || null
    )
    const [textColor, setTextColor] = useState<string | null>(data?.notificationVisualSettings?.textColor || null)
    const [iconBackgroundColor, setIconBackgroundColor] = useState<string | null>(
        data?.notificationVisualSettings?.iconBackgroundColor || null
    )
    const [iconColor, setIconColor] = useState<string | null>(data?.notificationVisualSettings?.iconColor || null)
    const [borderRadius, setBorderRadius] = useState<BorderType | null>(data?.notificationVisualSettings?.roundness || null)
    const [icon, setIcon] = useState<string | null>(data?.notificationVisualSettings?.icon || null)

    const onCloseAndClearInputs = () => {
        setSelectedTab('rules')
        setText(t(getActualVisitorsModalInitialText(language)) ?? '')
        setWithRepeat(true)
        setDays(7)
        setMinNumber(20)
        setForms([{ control: 'beginsWith', include: [], excludes: [] }])
        onClose()
    }

    useLayoutEffect(() => {
        const createNotificationPreviewHtml = async () => {
            const result = await window.getNotificationPreview(
                {
                    campaign: {
                        notificationVisualSettings: {
                            roundness: 1,
                        },
                    },
                    settings: {
                        type: selectedVariant,
                        notificationVisualSettings: {
                            backgroundColor: backgroundColor ?? '',
                            textColor: textColor ?? '',
                            iconBackgroundColor: iconBackgroundColor ?? '',
                            iconColor: iconColor ?? '',
                            icon: icon ?? '',
                            roundness: borderRadius ?? 0,
                        },
                    },
                },
                selectedVariant,
                undefined,
                text,
                undefined,
                language,
                minNumber,
                days,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                notificationStyleId
            )
            // Transforms object htmldivelement to pure string
            const cleanHtmlString = DOMPurify.sanitize(result, {
                USE_PROFILES: { html: true, svg: true },
            })
            setNotificationHtml(cleanHtmlString)
        }

        createNotificationPreviewHtml()
    })

    const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
        setSelectedTab(newValue as TabType)
    }

    const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setText(event.target.value)
    }

    const handleDaysChange = (event: SelectChangeEvent) => {
        setDays(parseInt(event.target.value, 10))
    }

    const handleMinNumberChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setMinNumber(parseInt(event.target.value, 10))
    }

    const clearForms = () => {
        setForms([{ control: 'contains', include: [], excludes: [] }])
    }

    const toggleWithRepeat = () => {
        setWithRepeat(!withRepeat)
    }

    const onNotificationCreate = async (type: NotificationType): Promise<string | undefined> => {
        if (id) {
            try {
                const _notificationManager = NotificationManager.getManager()
                const result: CreateNotificationSettingsResponse = await _notificationManager.createNotificationSettingsAsync(
                    id,
                    type
                )
                if (result.id) {
                    return result.id
                } else {
                    throw new Error(t('popups.cannotCreateNotification') ?? '')
                }
            } catch (e: any) {
                showFlashMessageWithTimeout(dispatch, e.toString(), 'error')
            }
        }
    }

    const onSave = async () => {
        setIsLoading(true)
        try {
            const _notificationManager = NotificationManager.getManager()
            let newCreatedNotificationId = undefined
            if (data?.type !== selectedVariant) {
                await _notificationManager.deleteNotificationSettings(createdNotificationId)
                newCreatedNotificationId = await onNotificationCreate(selectedVariant)
            }
            const body: UpdateNotificationSettingsRequest = {
                id: newCreatedNotificationId ? newCreatedNotificationId : createdNotificationId,
                isActive: true,
                repeatNotification: withRepeat,
                text: text,
                maxLastDays: days,
                minVisitors: selectedVariant === 'visitorSummary' ? minNumber : undefined,
                minRegistrations: selectedVariant === 'registrationSummary' ? minNumber : undefined,
                minOrders: selectedVariant === 'orderSummary' ? minNumber : undefined,
                urlFilter: transformFormsToUrlFilterDto(forms),

                notificationVisualSettings: {
                    backgroundColor: backgroundColor ?? '',
                    textColor: textColor ?? '',
                    iconBackgroundColor: iconBackgroundColor ?? '',
                    iconColor: iconColor ?? '',
                    icon: icon ?? '',
                    roundness: borderRadius ?? 0,
                },
            }

            await _notificationManager.updateNotificationSettingsAsync(body)
            setIsLoading(false)
            onCloseAndClearInputs()
        } catch (e: any) {
            showFlashMessageWithTimeout(dispatch, e.toString(), 'error')
            setIsLoading(false)
        }
    }

    const getValuesToHide = (forms: FormType[]): string[] => {
        let unavailableControls: string[] = []
        forms.forEach((form) => unavailableControls.push(form.control))
        return unavailableControls
    }

    const handleControlChange = (event: SelectChangeEvent, index: number) => {
        setForms((state) => {
            const copy: FormType[] = [...state]
            const formToUpdate = copy[index]
            formToUpdate.control = event.target.value as ControlType
            return copy
        })
    }

    const onDeleteForm = (index: number) => {
        setForms((state) => {
            const copy: FormType[] = [...state]
            copy.splice(index, 1)
            return copy
        })
    }

    const onAddIncludeChip = (formIndex: number, values: string[]) => {
        setForms((state) => {
            const copy: FormType[] = [...state]
            copy[formIndex].include = values
            return copy
        })
    }

    const onAddExcludeChip = (formIndex: number, values: string[]) => {
        setForms((state) => {
            const copy: FormType[] = [...state]
            copy[formIndex].excludes = values
            return copy
        })
    }

    const addEmptyForm = () => {
        if (forms.length < 3) {
            const availableControls = getAvailableControls(forms)
            setForms((state) => {
                const result = [
                    ...state,
                    {
                        control: availableControls[0],
                        include: [],
                        excludes: [],
                    },
                ]
                return result
            })
        }
    }

    const activeColor = theme.palette.natural.t1
    const notActiveColor = theme.palette.natural.t2

    return (
        <ModalBase isOpen={isOpen} onClose={onCloseAndClearInputs} style={{ overflow: 'auto' }} center>
            <Stack
                direction='column'
                bgcolor={theme.palette.natural.t4}
                p={3}
                overflow='auto'
                sx={{
                    width: { xs: '80%', md: 900 },
                    maxHeight: '90vh',
                }}
            >
                <Stack direction='row' justifyContent='space-between'>
                    <Typography color={theme.palette.main.secondary.u1} fontWeight='bold' fontSize={{ xs: 18, md: 24 }}>
                        {t('campaign.notifications.visitorSummary.title')}
                    </Typography>
                    <CloseIcon style={{ color: theme.palette.natural.t2, cursor: 'pointer' }} onClick={onCloseAndClearInputs} />
                </Stack>

                <TabContext value={selectedTab}>
                    <TabList
                        TabIndicatorProps={{
                            style: {
                                overflow: 'auto',
                                background: theme.palette.main.primary.o,
                                height: 3,
                            },
                        }}
                        onChange={handleTabChange}
                    >
                        <Tab
                            disableRipple
                            style={{
                                color: selectedTab === 'rules' ? activeColor : notActiveColor,
                                textTransform: 'none',
                                fontSize: 16,
                            }}
                            label={t('campaign.notifications.settings.displayRules.title')}
                            value='rules'
                        />
                        <Tab
                            disableRipple
                            style={{
                                color: selectedTab === 'pairing' ? activeColor : notActiveColor,
                                textTransform: 'none',
                                fontSize: 16,
                            }}
                            label={t('campaign.notifications.settings.pairing.title')}
                            value='pairing'
                        />
                        <Tab
                            disableRipple
                            style={{
                                color: selectedTab === 'design' ? activeColor : notActiveColor,
                                textTransform: 'none',
                                fontSize: 16,
                            }}
                            label={t('campaign.notifications.settings.design.title')}
                            value='design'
                        />

                        <Box className={classes.tabsDivider} />
                    </TabList>
                    <TabPanel value='rules' style={{ padding: 0, flex: 1 }}>
                        <div style={{ marginTop: theme.spacing(1), marginBottom: theme.spacing(1) }}>
                            <HelpButton link='https://www.overenyweb.cz/napoveda/nastaveni-notifikaci/navstevnost-souhrnne-statistiky/' />
                        </div>
                        <NotificationPreview notificationHtml={notificationHtml} />
                        <Grid container rowSpacing={2} columnSpacing={2} width='100%'>
                            <Grid item xs={12} md={6}>
                                <Typography className={classes.sectionTitle} mb={2}>
                                    {t('campaign.notifications.settings.displayRules.variant')}
                                </Typography>
                                <CustomDropdown
                                    width='100%'
                                    value={selectedVariant}
                                    label={t('campaign.notifications.settings.displayRules.choseVariant')}
                                    items={dropdownItems.map((item) => {
                                        return {
                                            value: item.value,
                                            title: t('campaign.notifications.settings.displayRules.visitorSummary.' + item.title),
                                        }
                                    })}
                                    onChange={(event) => setSelectedVariant(event.target.value as VariantType)}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Typography className={classes.sectionTitle} mb={2}>
                                    {t('campaign.notifications.settings.displayRules.text')}
                                </Typography>
                                <CustomTextField
                                    fullWidth
                                    label={t('campaign.notifications.settings.displayRules.actualVisitors.nameVisitors') ?? ''}
                                    value={text}
                                    onChange={handleTextChange}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Typography className={classes.sectionTitle}>
                                    {t('campaign.notifications.settings.displayRules.show')}
                                </Typography>
                                <Stack>
                                    <Stack direction='row' alignItems='center' justifyContent='space-between' mb={1} spacing={1}>
                                        <Typography color={theme.palette.natural.t1}>
                                            {t(
                                                'campaign.notifications.settings.displayRules.visitorSummary.' +
                                                    getDaysString(selectedVariant)
                                            ) +
                                                ' ' +
                                                t('campaign.notifications.settings.displayRules.visitorSummary.last')}
                                        </Typography>
                                        <CustomDropdown
                                            width={144}
                                            label=''
                                            value={days.toString()}
                                            items={daysDropdownItems.map((item) => {
                                                return {
                                                    value: item.value,
                                                    title:
                                                        item.value +
                                                        ' ' +
                                                        t('campaign.notifications.settings.displayRules.' + item.title),
                                                }
                                            })}
                                            onChange={handleDaysChange}
                                        />
                                    </Stack>
                                    <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={1}>
                                        <Typography color={theme.palette.natural.t1}>
                                            {t('campaign.notifications.settings.displayRules.visitorSummary.minNumber') +
                                                ' ' +
                                                t(
                                                    'campaign.notifications.settings.displayRules.visitorSummary.' +
                                                        getDaysString(selectedVariant) +
                                                        '2'
                                                ) +
                                                ' ' +
                                                t('campaign.notifications.settings.displayRules.visitorSummary.toShow')}
                                        </Typography>
                                        <TextField
                                            style={{ maxWidth: 100 }}
                                            type='number'
                                            variant='outlined'
                                            value={minNumber}
                                            onChange={handleMinNumberChange}
                                            InputProps={{
                                                inputProps: { min: 0 },
                                                style: { height: 44 },
                                            }}
                                        />
                                    </Stack>
                                </Stack>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Stack direction='row' alignItems='center' justifyContent='space-between'>
                                    <Typography color={theme.palette.natural.t1}>
                                        {t('campaign.notifications.settings.displayRules.repeatNotification')}
                                    </Typography>
                                    <CustomSwitch checked={withRepeat} onSwitch={() => toggleWithRepeat()} disabled={false} />
                                </Stack>
                            </Grid>
                        </Grid>

                        <Box className={classes.firstButtonsContainer}>
                            <CustomButton
                                size='medium'
                                style={{ width: 132 }}
                                title={t('continue')}
                                onClick={() => {
                                    setSelectedTab('pairing')
                                    clearForms()
                                }}
                            />
                        </Box>
                    </TabPanel>
                    <TabPanel value='pairing' style={{ padding: 0 }}>
                        {selectedVariant === 'visitorSummary' && (
                            <Box style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
                                <Typography className={classes.secondTitle}>
                                    {t('campaign.notifications.settings.pairing.collectData')}
                                </Typography>
                                <Typography className={classes.titleHint}>
                                    {t('campaign.notifications.settings.pairing.cantEdit')}
                                </Typography>
                                <CustomTextField
                                    style={{ maxWidth: 780, marginTop: 40 }}
                                    disabled
                                    label={t('campaign.notifications.settings.pairing.pairingCondition') ?? ''}
                                    value={t('campaign.notifications.settings.pairing.domainVisitors')}
                                    onChange={() => {}}
                                />
                                <CustomTextField
                                    style={{ maxWidth: 780, marginTop: 40 }}
                                    disabled
                                    label={t('campaign.notifications.settings.pairing.celectedDomain') ?? ''}
                                    value={activeWebUrl || ''}
                                    onChange={() => {}}
                                />
                                <Typography className={classes.titleHint} style={{ marginTop: 24 }}>
                                    {t('campaign.notifications.settings.pairing.campaignCollects') ?? ''}
                                </Typography>
                            </Box>
                        )}
                        {selectedVariant !== 'visitorSummary' && (
                            <Box className={classes.secondTabContainer}>
                                <Typography className={classes.secondTitle}>
                                    {t('campaign.notifications.settings.pairing.collectData')}
                                </Typography>
                                <Box>
                                    {forms.map((form, index) => {
                                        return (
                                            <Box key={`dropdown-key-${index}`} className={classes.formContainer}>
                                                <Box className={classes.urlContainer}>
                                                    <Box style={{ width: 280, marginBottom: 40 }}>
                                                        <CustomDropdown
                                                            label='URL'
                                                            id={`${index}-form-dropdown`}
                                                            value={form.control}
                                                            items={menuItems.map((item) => {
                                                                return {
                                                                    value: item.value,
                                                                    title:
                                                                        item.value === 'beginsWith'
                                                                            ? t('campaign.settings.url.startsWith')
                                                                            : item.value === 'contains'
                                                                            ? t('campaign.settings.url.includes')
                                                                            : t('campaign.settings.url.equals') ?? '',
                                                                }
                                                            })}
                                                            hiddenValues={getValuesToHide(forms)}
                                                            onChange={(event) => handleControlChange(event, index)}
                                                        />
                                                    </Box>
                                                    <Box>
                                                        <DeleteOutlineIcon
                                                            onClick={() => onDeleteForm(index)}
                                                            className={classes.icon}
                                                        />
                                                    </Box>
                                                </Box>

                                                <Autocomplete
                                                    id={`include-${index}`}
                                                    style={{ marginBottom: 40 }}
                                                    multiple
                                                    options={[]}
                                                    freeSolo
                                                    onChange={(event: any, value: any) => {
                                                        onAddIncludeChip(index, value)
                                                    }}
                                                    value={form.include || []}
                                                    renderTags={(value: readonly string[], getTagProps) =>
                                                        value.map((option: string, index: number) => (
                                                            <Chip variant='outlined' label={option} {...getTagProps({ index })} />
                                                        ))
                                                    }
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            variant='outlined'
                                                            label={t('campaign.settings.includes')}
                                                            InputProps={{
                                                                ...params.InputProps,
                                                                endAdornment: (
                                                                    <Stack direction='row' spacing={1}>
                                                                        <CustomButton
                                                                            size='micro'
                                                                            style={{ width: 40 }}
                                                                            title='+'
                                                                            onClick={() => {
                                                                                const element = document.getElementById(
                                                                                    `include-${index}`
                                                                                )
                                                                                if (element) {
                                                                                    fireEvent.keyDown(element, {
                                                                                        key: 'Enter',
                                                                                    })
                                                                                }
                                                                            }}
                                                                        />
                                                                        {params.InputProps.endAdornment}
                                                                    </Stack>
                                                                ),
                                                            }}
                                                        />
                                                    )}
                                                />
                                                <Autocomplete
                                                    id={`excludes-${index}`}
                                                    multiple
                                                    options={[]}
                                                    freeSolo
                                                    onChange={(event: any, value: any, reason) => {
                                                        onAddExcludeChip(index, value)
                                                    }}
                                                    value={form.excludes || []}
                                                    renderTags={(value: readonly string[], getTagProps) =>
                                                        value.map((option: string, index: number) => (
                                                            <Chip variant='outlined' label={option} {...getTagProps({ index })} />
                                                        ))
                                                    }
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            variant='outlined'
                                                            label={t('campaign.settings.exclude')}
                                                            InputProps={{
                                                                ...params.InputProps,
                                                                endAdornment: (
                                                                    <Stack direction='row' spacing={1}>
                                                                        <CustomButton
                                                                            size='micro'
                                                                            style={{ width: 40 }}
                                                                            title='+'
                                                                            onClick={() => {
                                                                                const element = document.getElementById(
                                                                                    `excludes-${index}`
                                                                                )
                                                                                if (element) {
                                                                                    fireEvent.keyDown(element, {
                                                                                        key: 'Enter',
                                                                                    })
                                                                                }
                                                                            }}
                                                                        />
                                                                        {params.InputProps.endAdornment}
                                                                    </Stack>
                                                                ),
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </Box>
                                        )
                                    })}
                                    {forms.length < 3 && (
                                        <CustomButton
                                            size='small'
                                            style={{ width: 180 }}
                                            type='secondary'
                                            title={t('campaign.settings.addCondition')}
                                            onClick={addEmptyForm}
                                        />
                                    )}
                                </Box>
                            </Box>
                        )}
                        <Box
                            className={classes.secondButtonsContainer}
                            style={{
                                marginTop: selectedVariant === 'visitorSummary' ? 225 : 16,
                            }}
                        >
                            <CustomButton
                                style={{ width: 132, marginLeft: 24 }}
                                size='medium'
                                type='primary'
                                title={t('save')}
                                isLoading={isLoading}
                                onClick={onSave}
                            />
                            <CustomButton
                                style={{ width: 132 }}
                                size='medium'
                                type='secondary'
                                title={t('back')}
                                onClick={() => {
                                    setSelectedTab('rules')
                                    clearForms()
                                }}
                            />
                        </Box>
                    </TabPanel>
                    <TabPanel value='design' style={{ padding: 0 }}>
                        <NotificationPreview notificationHtml={notificationHtml} />

                        <Typography style={{ marginTop: 16 }} mb={1} color={theme.palette.natural.t1}>
                            {t('campaign.notifications.settings.design.description')}
                        </Typography>
                        <Grid container columnSpacing={3}>
                            <Grid item xs={12} md={6}>
                                <Stack direction='row' alignItems='center'>
                                    <Typography className={classes.sectionTitle} width={300}>
                                        {t('campaign.notifications.settings.design.backgroundColour')}
                                    </Typography>

                                    <CustomColorPicker
                                        color={backgroundColor}
                                        onChange={setBackgroundColor}
                                        defaultColor={NotificationDefaultSettings.BackgroundColor}
                                    />
                                </Stack>
                                <Stack direction='row' alignItems='center'>
                                    <Typography className={classes.sectionTitle} width={300}>
                                        {t('campaign.notifications.settings.design.textColour')}
                                    </Typography>

                                    <CustomColorPicker
                                        color={textColor}
                                        onChange={setTextColor}
                                        defaultColor={NotificationDefaultSettings.TextColor}
                                    />
                                </Stack>
                                <Stack direction='row' alignItems='center'>
                                    <Typography className={classes.sectionTitle} width={300}>
                                        {t('campaign.notifications.settings.design.iconBackgroundColour')}
                                    </Typography>

                                    <CustomColorPicker
                                        color={iconBackgroundColor}
                                        onChange={setIconBackgroundColor}
                                        defaultColor={NotificationDefaultSettings.IconBackgroundColor}
                                    />
                                </Stack>
                                <Stack direction='row' alignItems='center'>
                                    <Typography className={classes.sectionTitle} width={300}>
                                        {t('campaign.notifications.settings.design.iconColour')}
                                    </Typography>

                                    <CustomColorPicker
                                        color={iconColor}
                                        onChange={setIconColor}
                                        defaultColor={NotificationDefaultSettings.IconColor}
                                    />
                                </Stack>
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <Typography className={classes.sectionTitle}>
                                    {t('campaign.notifications.settings.design.roundedCorners')}
                                </Typography>

                                <BorderSelect
                                    selectedBorderValue={borderRadius}
                                    onChange={setBorderRadius}
                                    defaultBorderValue={Number(NotificationDefaultSettings.BorderRoundness)}
                                />

                                <Typography className={classes.sectionTitle} mt={1}>
                                    {t('campaign.notifications.settings.design.icon')}
                                </Typography>

                                <IconSelect
                                    selectedIcon={icon}
                                    onChange={setIcon}
                                    defaultIcon={NotificationDefaultSettings.VisitIcon}
                                />

                                <Stack direction='row' justifyContent='flex-end' mt={1}>
                                    <CustomButton
                                        style={{ width: 132 }}
                                        size='medium'
                                        type='secondary'
                                        title={t('back')}
                                        onClick={() => setSelectedTab('rules')}
                                    />
                                    <CustomButton
                                        style={{ width: 132, marginLeft: 24 }}
                                        size='medium'
                                        type='primary'
                                        title={t('save')}
                                        isLoading={isLoading}
                                        onClick={onSave}
                                    />
                                </Stack>
                            </Grid>
                        </Grid>
                    </TabPanel>
                </TabContext>
            </Stack>
        </ModalBase>
    )
}

const useStyles = makeStyles((theme: Theme) => ({
    tabsDivider: {
        width: 370,
        height: 3,
        backgroundColor: theme.palette.natural.t3,
        position: 'absolute',
        bottom: 0,
    },
    sectionTitle: {
        fontSize: 18,
        color: theme.palette.main.primary.o,
        fontWeight: 'bold',
    },
    firstButtonsContainer: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row-reverse',
        marginTop: 52,
    },
    secondTitle: {
        marginTop: 24,
        color: theme.palette.main.secondary.u1,
        fontSize: 18,
        fontWeight: 'bold',
    },
    titleHint: {
        fontSize: 14,
        color: theme.palette.natural.t2,
        marginTop: 8,
    },
    secondButtonsContainer: {
        flex: 1,
        height: 44,
        display: 'flex',
        flexDirection: 'row-reverse',
    },
    formContainer: {
        flex: 1,
        padding: 24,
        backgroundColor: theme.palette.natural.t8,
        marginBottom: 16,
        borderRadius: 8,
        maxWidth: 735,
    },
    urlContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    icon: {
        color: theme.palette.main.alert.o,
        fontSize: 24,
        cursor: 'pointer',
    },
    secondTabContainer: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        height: 522,
        overflow: 'auto',
    },
}))

export default SummaryModal

import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import MainNavigator from './navigation/MainNavigator'
import { StyledEngineProvider } from '@mui/material/styles'
import { theme } from './misc/theme'
import { ThemeProvider } from '@mui/material'
import { Provider as ReduxProvider } from 'react-redux'
import FlashMessages from './components/FlashMessages'
import { PersistGate } from 'redux-persist/integration/react'
import { store, persistor } from './services/store'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

function App() {
    return (
        <StyledEngineProvider injectFirst>
            <ReduxProvider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    <ThemeProvider theme={theme}>
                        <FlashMessages />
                        <BrowserRouter>
                            <MainNavigator />
                        </BrowserRouter>
                    </ThemeProvider>
                </PersistGate>
            </ReduxProvider>
        </StyledEngineProvider>
    )
}

export default App

import { FlashMessagesActionTypes, FlashMessagesReduxState } from '../types/flash-messages-types'
import initState from '../types/initState'
import { ReduxAction } from '../types/main-reducer-types'

export default function flashMessagesReducer(
    state: FlashMessagesReduxState = initState.flashMessages,
    action: ReduxAction
): FlashMessagesReduxState {
    switch (action.type) {
        case FlashMessagesActionTypes.SHOW_FLASH_MESSAGE: {
            const { message, type, id } = action.payload
            const newFlashMessageArray = state.flashMessages.concat({
                id: id ? id : Math.floor(Math.random() * 50000),
                message,
                type,
            })
            return { ...state, flashMessages: newFlashMessageArray }
        }
        case FlashMessagesActionTypes.REMOVE_FLASH_MESSAGE: {
            const { flashMessageId } = action.payload
            const newFlashMessageArray = state.flashMessages.filter((fm) => fm.id !== flashMessageId)
            return { ...state, flashMessages: newFlashMessageArray }
        }
        case FlashMessagesActionTypes.CLEAR_ALL_FLASH_MESSAGES: {
            return { ...state, flashMessages: [] }
        }
        default:
            return state
    }
}

import { Language, WebsiteDto } from '../../shared/types/websiteTypes'
import { SetActiveWeb, SetWebs, AddWeb, WebActionTypes, ClearWebs } from '../types/web-types'

export const setActiveWeb = (url?: string, id?: string): SetActiveWeb => ({
    type: WebActionTypes.SET_ACTIVE_WEB,
    payload: { active: url, activeId: id },
})

export const setWebs = (webs: WebsiteDto[]): SetWebs => ({
    type: WebActionTypes.SET_WEBS,
    payload: webs,
})

export const addWeb = (web: WebsiteDto): AddWeb => ({
    type: WebActionTypes.ADD_WEB,
    payload: web,
})

export const updateWeb = (id: string, name: string, url: string, language: Language) => ({
    type: WebActionTypes.UPDATE_WEB,
    payload: { id, name, url, language },
})

export const clearWebs = (): ClearWebs => ({
    type: WebActionTypes.CLEAR_WEBS,
})

export enum NotificationDefaultSettings {
    TextColor = '#555770',
    BackgroundColor = '#ffffff',
    IconBackgroundColor = '#F1F2FF',
    IconColor = '#7C76F7',
    ButtonTextColor = '#ffffff',
    LinkTextColor = '#7C76F7',
    ButtonBackgroundColor = '#7C76F7',
    BorderRoundness = '5',
    Icon = '',
    IndividualIcon = 'NotificationsActive',
    OrdersIcon = 'AddShoppingCart',
    RegisterIcon = 'MarkMailRead',
    ActualVisitIcon = 'People',
    VisitIcon = 'ListAlt',
    CouponIcon = 'CardGiftcard',
}

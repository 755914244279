import { Box, Theme, Typography, useTheme } from '@mui/material'
import { FC } from 'react'
import ModalBase from '../../../components/ModalBase'
import { makeStyles } from '@mui/styles'
import CloseIcon from '@mui/icons-material/Close'
import StandardSettingsImg from '../../../assets/images/standardSettings.png'
import CustomButton from '../../../components/CustomButton'

interface StandardSettingsModalProps {
    isOpen: boolean
    onModalClose: () => void
    isLoading: boolean
    onMainButtonPress: () => void
}

const StandardSettingsModal: FC<StandardSettingsModalProps> = ({ isOpen, onModalClose, isLoading, onMainButtonPress }) => {
    const classes = useStyles()
    const theme = useTheme()
    return (
        <ModalBase isOpen={isOpen} onClose={onModalClose}>
            <Box className={classes.container}>
                <Box className={classes.iconContainer}>
                    <CloseIcon style={{ color: theme.palette.natural.t2, cursor: 'pointer' }} onClick={onModalClose} />
                </Box>
                <Box className={classes.imgBox}>
                    <Box className={classes.imgContainer}>
                        <img src={StandardSettingsImg} alt='registration' />
                    </Box>
                </Box>
                <Box style={{ marginBottom: 32 }}>
                    <Typography align='center' className={classes.primaryText}>
                        Vzhled bude nastaven do výchozího nastavení
                    </Typography>
                    <Typography align='center' className={classes.secondaryText}>
                        Veškeré Vámi provedené změny budou přepsány.
                    </Typography>
                </Box>
                <Box className={classes.buttonsContainer}>
                    <CustomButton
                        size='medium'
                        style={{ width: 240 }}
                        type='primary'
                        title='Použít výchozí nastavení'
                        isLoading={isLoading}
                        onClick={onMainButtonPress}
                    />
                    <CustomButton
                        size='medium'
                        style={{ width: 87, marginRight: 24 }}
                        type='secondary'
                        title='Zavřít'
                        onClick={onModalClose}
                    />
                </Box>
            </Box>
        </ModalBase>
    )
}

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        width: 663,
        backgroundColor: '#FFFFFF',
        display: 'flex',
        flexDirection: 'column',
        borderRadius: 8,
        padding: '20px 32px 32px 32px',
        filter: 'drop-shadow(0px 2px 8px rgba(65, 32, 158, 0.08)) drop-shadow(0px 20px 32px rgba(136, 100, 211, 0.24))',
    },
    iconContainer: {
        display: 'flex',
        flexDirection: 'row-reverse',
    },
    imgContainer: {
        height: 161,
        width: 290,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    imgBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 12,
    },
    primaryText: {
        fontSize: 28,
        fontWeight: 'bold',
        marginTop: 32,
        color: theme.palette.main.secondary.u1,
        marginLeft: 24,
        marginRight: 24,
    },
    secondaryText: {
        fontSize: 16,
        marginTop: 24,
        color: theme.palette.natural.t,
    },
    buttonsContainer: {
        display: 'flex',
        flexDirection: 'row-reverse',
        flex: 1,
        height: 44,
    },
}))

export default StandardSettingsModal

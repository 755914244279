import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import { Box, Tab, useTheme, Theme, Typography, TextField, Stack, Grid } from '@mui/material'
import { FC, useLayoutEffect, useState } from 'react'
import ModalBase from '../../components/ModalBase'
import { makeStyles } from '@mui/styles'
import CloseIcon from '@mui/icons-material/Close'
import TabPanel from '@mui/lab/TabPanel'
import CustomTextField from '../../components/CustomTextField'
import CustomSwitch from '../../components/CustomSwitch'
import CustomButton from '../../components/CustomButton'
import { getActiveWebUrl } from '../../services/selectors/web-selectors'
import { useDispatch, useSelector } from 'react-redux'
import { NotificationManager } from '../../services/api/NotificationManager'
import { GetNotificationSettingsResponse } from '../../shared/payloads/notificationSettingsPayloads'
import { showFlashMessageWithTimeout } from '../../services/actions/flashMessage-actions'
import DOMPurify from 'dompurify'
import NotificationPreview from '../../components/NotificationPreview'
import { Language } from '../../shared/types/websiteTypes'
import HelpButton from '../../components/HelpButton'
import { getActualVisitorsModalInitialText } from '../../utils/web-utils'
import BorderSelect, { BorderType } from '../../components/NotificationEditComponents/BorderSelect'
import CustomColorPicker from '../../components/NotificationEditComponents/CustomColorPicker'
import IconSelect from '../../components/NotificationEditComponents/IconSelect'
import { NotificationDefaultSettings } from '../../types/notification-default-settings'
import { useTranslation } from 'react-i18next'
import { v4 } from 'uuid'

interface ActualVisitorsModalProps {
    isOpen: boolean
    onClose: () => void
    createdNotificationId: string
    language: Language
    data?: GetNotificationSettingsResponse
}

type TabType = 'rules' | 'pairing' | 'design'

const notificationStyleId = v4()

const ActualVisitorsModal: FC<ActualVisitorsModalProps> = ({ isOpen, onClose, createdNotificationId, language, data }) => {
    const theme = useTheme()
    const classes = useStyles()
    const dispatch = useDispatch()
    const activeWebUrl = useSelector(getActiveWebUrl)
    const { t } = useTranslation()

    const [selectedTab, setSelectedTab] = useState<TabType>('rules')
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [nameOfVisitors, setNameOfVisitors] = useState<string>(
        data?.text ? data.text : t(getActualVisitorsModalInitialText(language)) ?? ''
    )
    const [minNumberOfVisitors, setMinNumberOfVisitors] = useState<number>(
        data?.minVisitors !== undefined ? data?.minVisitors : 10
    )
    const [withRepeat, setWithRepeat] = useState<boolean>(
        data?.repeatNotification !== undefined ? data?.repeatNotification : true
    )
    const [notificationHtml, setNotificationHtml] = useState<string>('')

    const [backgroundColor, setBackgroundColor] = useState<string | null>(
        data?.notificationVisualSettings?.backgroundColor || null
    )
    const [textColor, setTextColor] = useState<string | null>(data?.notificationVisualSettings?.textColor || null)
    const [iconBackgroundColor, setIconBackgroundColor] = useState<string | null>(
        data?.notificationVisualSettings?.iconBackgroundColor || null
    )
    const [iconColor, setIconColor] = useState<string | null>(data?.notificationVisualSettings?.iconColor || null)
    const [borderRadius, setBorderRadius] = useState<BorderType | null>(data?.notificationVisualSettings?.roundness || null)
    const [icon, setIcon] = useState<string | null>(data?.notificationVisualSettings?.icon || null)

    const onCloseAndClearInputs = () => {
        setNameOfVisitors(data?.text ? data.text : getActualVisitorsModalInitialText(language))
        setMinNumberOfVisitors(10)
        setWithRepeat(true)
        onClose()
    }

    useLayoutEffect(() => {
        const createNotificationPreviewHtml = async () => {
            const result = await window.getNotificationPreview(
                {
                    campaign: {
                        notificationVisualSettings: {
                            roundness: 1,
                        },
                    },
                    settings: {
                        type: 'actualVisitors',
                        notificationVisualSettings: {
                            backgroundColor: backgroundColor ?? '',
                            textColor: textColor ?? '',
                            iconBackgroundColor: iconBackgroundColor ?? '',
                            iconColor: iconColor ?? '',
                            icon: icon ?? '',
                            roundness: borderRadius ?? 0,
                        },
                    },
                },
                'actualVisitors',
                undefined,
                nameOfVisitors,
                minNumberOfVisitors,
                language,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                notificationStyleId
            )
            // Transforms object htmldivelement to pure string
            const cleanHtmlString = DOMPurify.sanitize(result, {
                USE_PROFILES: { html: true, svg: true },
            })
            setNotificationHtml(cleanHtmlString)
        }

        createNotificationPreviewHtml()
    })

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setSelectedTab(newValue as TabType)
    }

    const handleNameOfVisitorsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNameOfVisitors(event.target.value)
    }

    const handleMinNumberOfVisitorsChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setMinNumberOfVisitors(parseInt(event.target.value, 10))
    }

    const toggleWithRepeat = () => {
        setWithRepeat(!withRepeat)
    }

    const activeColor = theme.palette.natural.t1
    const notActiveColor = theme.palette.natural.t2

    const onSave = async () => {
        try {
            setIsLoading(true)
            const _notificationManager = NotificationManager.getManager()

            await _notificationManager.updateNotificationSettingsAsync({
                id: createdNotificationId,
                isActive: true,
                repeatNotification: withRepeat,
                minVisitors: minNumberOfVisitors,
                text: nameOfVisitors,

                notificationVisualSettings: {
                    backgroundColor: backgroundColor ?? '',
                    textColor: textColor ?? '',
                    iconBackgroundColor: iconBackgroundColor ?? '',
                    iconColor: iconColor ?? '',
                    icon: icon ?? '',
                    roundness: borderRadius ?? 0,
                },
            })

            setIsLoading(false)
            onCloseAndClearInputs()
        } catch (e: any) {
            showFlashMessageWithTimeout(dispatch, e.toString(), 'error')
            setIsLoading(false)
        }
    }

    return (
        <ModalBase isOpen={isOpen} onClose={onCloseAndClearInputs} center>
            <Stack
                direction='column'
                bgcolor={theme.palette.natural.t4}
                p={3}
                overflow='auto'
                sx={{
                    width: { xs: '80%', md: 900 },
                    maxHeight: '90vh',
                }}
                boxShadow={theme.palette.main.shadow.secondary}
            >
                <Stack direction='row' justifyContent='space-between'>
                    <Typography color={theme.palette.main.secondary.u1} fontWeight='bold' fontSize={{ xs: 18, md: 24 }}>
                        {t('campaign.notifications.actualVisitors.title')}
                    </Typography>
                    <CloseIcon style={{ color: theme.palette.natural.t2, cursor: 'pointer' }} onClick={onCloseAndClearInputs} />
                </Stack>
                <TabContext value={selectedTab}>
                    <TabList
                        TabIndicatorProps={{
                            style: {
                                background: theme.palette.main.primary.o,
                                height: 3,
                            },
                        }}
                        onChange={handleChange}
                    >
                        <Tab
                            disableRipple
                            style={{
                                color: selectedTab === 'rules' ? activeColor : notActiveColor,
                                textTransform: 'none',
                                fontSize: 16,
                            }}
                            label={t('campaign.notifications.settings.displayRules.title')}
                            value='rules'
                        />
                        {/* Whole this tab was hidden for now */}
                        <Tab
                            disableRipple
                            style={{
                                color: selectedTab === 'pairing' ? activeColor : notActiveColor,
                                textTransform: 'none',
                                fontSize: 16,
                            }}
                            label={t('campaign.notifications.settings.pairing.title')}
                            value='pairing'
                        />
                        <Tab
                            disableRipple
                            style={{
                                color: selectedTab === 'design' ? activeColor : notActiveColor,
                                textTransform: 'none',
                                fontSize: 16,
                            }}
                            label={t('campaign.notifications.settings.design.title')}
                            value='design'
                        />
                        <Box className={classes.tabsDivider} />
                    </TabList>
                    <TabPanel value='rules' style={{ padding: 0 }}>
                        <div style={{ marginTop: theme.spacing(2) }}>
                            <HelpButton link='https://www.overenyweb.cz/napoveda/nastaveni-notifikaci/navstevnost-aktualni-statistiky/' />
                        </div>
                        <Box className={classes.rulesContainer}>
                            <NotificationPreview notificationHtml={notificationHtml} />
                            <Grid container rowSpacing={2} columnSpacing={2}>
                                <Grid item xs={12} md={6}>
                                    <Typography className={classes.sectionTitle} style={{ marginBottom: 16 }}>
                                        {t('campaign.notifications.settings.displayRules.title')}
                                    </Typography>
                                    <CustomTextField
                                        fullWidth
                                        label={
                                            t('campaign.notifications.settings.displayRules.actualVisitors.nameVisitors') ?? ''
                                        }
                                        value={nameOfVisitors}
                                        onChange={handleNameOfVisitorsChange}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6} />
                                <Grid item xs={12} md={6}>
                                    <Typography className={classes.sectionTitle}>
                                        {t('campaign.notifications.settings.displayRules.show')}
                                    </Typography>
                                    <Stack direction='row' alignItems='center'>
                                        <Typography color={theme.palette.natural.t1} fontSize={16}>
                                            {t('campaign.notifications.settings.displayRules.actualVisitors.maxVisitors')}
                                        </Typography>
                                        <TextField
                                            style={{ width: 100, marginRight: 6, marginLeft: 6 }}
                                            type='number'
                                            variant='outlined'
                                            value={minNumberOfVisitors}
                                            onChange={handleMinNumberOfVisitorsChange}
                                            InputProps={{
                                                inputProps: { min: 0 },
                                                style: { height: 44 },
                                            }}
                                        />
                                        <Typography style={{ color: theme.palette.natural.t1, fontSize: 16 }}>
                                            {t('campaign.notifications.settings.displayRules.actualVisitors.visitors')}
                                        </Typography>
                                    </Stack>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Stack direction='row' alignItems='center' justifyContent='space-between' mt={3.5}>
                                        {/* May be will be used later */}
                                        {/* <Box className={classes.switchesContainer}>
                                            <Typography className={classes.switchLabel}>
                                            Počet náštěvníků z celého webu
                                            </Typography>
                                            <DoneIcon className={classes.doneIcon} />
                                            </Box> */}
                                        <Typography
                                            color={theme.palette.natural.t1}
                                            fontSize={16}
                                            ml={{
                                                xs: 0,
                                                md: 3,
                                            }}
                                        >
                                            {t('campaign.notifications.settings.displayRules.repeatNotification')}
                                        </Typography>
                                        <CustomSwitch checked={withRepeat} onSwitch={() => toggleWithRepeat()} disabled={false} />
                                    </Stack>
                                </Grid>
                            </Grid>
                        </Box>

                        <Box className={classes.firstButtonsContainer}>
                            <CustomButton
                                size='medium'
                                style={{ width: 132 }}
                                title={t('continue')}
                                onClick={() => setSelectedTab('pairing')}
                            />
                        </Box>
                    </TabPanel>
                    <TabPanel value='pairing' style={{ padding: 0 }}>
                        <Box style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
                            <Typography className={classes.secondTitle}>
                                {t('campaign.notifications.settings.pairing.collectData')}
                            </Typography>
                            <Typography className={classes.titleHint}>
                                {t('campaign.notifications.settings.pairing.cantEdit')}
                            </Typography>
                            <CustomTextField
                                style={{ maxWidth: 780, marginTop: 40 }}
                                disabled
                                label={t('campaign.notifications.settings.pairing.pairingCondition') ?? ''}
                                value={t('campaign.notifications.settings.pairing.pageVisitors')}
                                onChange={() => {}}
                            />
                            <CustomTextField
                                style={{ maxWidth: 780, marginTop: 40 }}
                                disabled
                                label={t('campaign.notifications.settings.pairing.celectedDomain') ?? ''}
                                value={activeWebUrl || ''}
                                onChange={() => {}}
                            />
                        </Box>
                        <Box className={classes.secondButtonsContainer}>
                            <CustomButton
                                style={{ width: 132, marginLeft: 24 }}
                                size='medium'
                                type='primary'
                                title={t('save')}
                                isLoading={isLoading}
                                onClick={onSave}
                            />
                            <CustomButton
                                style={{ width: 132 }}
                                size='medium'
                                type='secondary'
                                title={t('back')}
                                onClick={() => setSelectedTab('rules')}
                            />
                        </Box>
                    </TabPanel>
                    <TabPanel value='design' style={{ padding: 0 }}>
                        <NotificationPreview notificationHtml={notificationHtml} />

                        <Typography style={{ marginTop: 16 }} mb={1} color={theme.palette.natural.t1}>
                            {t('campaign.notifications.settings.design.description')}
                        </Typography>
                        <Grid container columnSpacing={3}>
                            <Grid item xs={12} md={6}>
                                <Stack direction='row' alignItems='center'>
                                    <Typography className={classes.sectionTitle} width={300}>
                                        {t('campaign.notifications.settings.design.backgroundColour')}
                                    </Typography>

                                    <CustomColorPicker
                                        color={backgroundColor}
                                        onChange={setBackgroundColor}
                                        defaultColor={NotificationDefaultSettings.BackgroundColor}
                                    />
                                </Stack>
                                <Stack direction='row' alignItems='center'>
                                    <Typography className={classes.sectionTitle} width={300}>
                                        {t('campaign.notifications.settings.design.textColour')}
                                    </Typography>

                                    <CustomColorPicker
                                        color={textColor}
                                        onChange={setTextColor}
                                        defaultColor={NotificationDefaultSettings.TextColor}
                                    />
                                </Stack>
                                <Stack direction='row' alignItems='center'>
                                    <Typography className={classes.sectionTitle} width={300}>
                                        {t('campaign.notifications.settings.design.iconBackgroundColour')}
                                    </Typography>

                                    <CustomColorPicker
                                        color={iconBackgroundColor}
                                        onChange={setIconBackgroundColor}
                                        defaultColor={NotificationDefaultSettings.IconBackgroundColor}
                                    />
                                </Stack>
                                <Stack direction='row' alignItems='center'>
                                    <Typography className={classes.sectionTitle} width={300}>
                                        {t('campaign.notifications.settings.design.iconColour')}
                                    </Typography>

                                    <CustomColorPicker
                                        color={iconColor}
                                        onChange={setIconColor}
                                        defaultColor={NotificationDefaultSettings.IconColor}
                                    />
                                </Stack>
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <Typography className={classes.sectionTitle}>
                                    {t('campaign.notifications.settings.design.roundedCorners')}
                                </Typography>

                                <BorderSelect
                                    selectedBorderValue={borderRadius}
                                    onChange={setBorderRadius}
                                    defaultBorderValue={Number(NotificationDefaultSettings.BorderRoundness)}
                                />

                                <Typography className={classes.sectionTitle} mt={1}>
                                    {t('campaign.notifications.settings.design.icon')}
                                </Typography>

                                <IconSelect
                                    selectedIcon={icon}
                                    onChange={setIcon}
                                    defaultIcon={NotificationDefaultSettings.ActualVisitIcon}
                                />

                                <Stack direction='row' justifyContent='flex-end' mt={1}>
                                    <CustomButton
                                        style={{ width: 132 }}
                                        size='medium'
                                        type='secondary'
                                        title={t('back')}
                                        onClick={() => setSelectedTab('rules')}
                                    />
                                    <CustomButton
                                        style={{ width: 132, marginLeft: 24 }}
                                        size='medium'
                                        type='primary'
                                        title={t('save')}
                                        isLoading={isLoading}
                                        onClick={onSave}
                                    />
                                </Stack>
                            </Grid>
                        </Grid>
                    </TabPanel>
                </TabContext>
            </Stack>
        </ModalBase>
    )
}

const useStyles = makeStyles((theme: Theme) => ({
    tabsDivider: {
        width: 370,
        height: 3,
        backgroundColor: theme.palette.natural.t3,
        position: 'absolute',
        bottom: 0,
    },
    tab: {
        textTransform: 'none',
        fontSize: 16,
    },
    container: {
        flex: 1,
        width: 996,
        height: 660,
        backgroundColor: theme.palette.natural.t4,
        paddingLeft: 32,
        paddingRight: 32,
        paddingTop: 24,
        paddingBottom: 32,
        borderRadius: 8,
        boxShadow: theme.palette.main.shadow.secondary,
    },
    titleContainer: {
        height: 34,
        flex: 1,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 16,
    },
    title: {
        fontSize: 28,
        fontWeight: 'bold',
        color: theme.palette.main.secondary.u1,
    },
    rulesContainer: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: 24,
    },
    sectionTitle: {
        fontSize: 18,
        color: theme.palette.main.primary.o,
        fontWeight: 'bold',
    },
    rulesInputSectionContainer: {
        display: 'flex',
        height: 50,
        width: 528,
    },
    rulesSwitchesSectionContainer: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'center',
    },
    switchesContainer: {
        display: 'flex',
        flexDirection: 'row',
        flex: 1,
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 16,
        marginTop: 4,
    },
    buttonsContainer: {
        display: 'flex',
        flexDirection: 'row',
        flex: 1,
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    firstButtonsContainer: {
        display: 'flex',
        flex: 1,
        marginTop: 94,
        flexDirection: 'row-reverse',
    },
    secondTitle: {
        marginTop: 24,
        color: theme.palette.main.secondary.u1,
        fontSize: 18,
        fontWeight: 'bold',
    },
    titleHint: {
        fontSize: 14,
        color: theme.palette.natural.t2,
    },
    secondButtonsContainer: {
        flex: 1,
        height: 44,
        display: 'flex',
        flexDirection: 'row-reverse',
        marginTop: 278,
    },
    doneIcon: {
        color: theme.palette.natural.t2,
        marginRight: 20,
        fontSize: 26,
    },
}))

export default ActualVisitorsModal

import { Box, Theme, Typography, useTheme } from '@mui/material'
import { FC, useEffect, useState } from 'react'
import ModalBase from '../../components/ModalBase'
import StatusChecker from '../../components/StatusChecker'
import { makeStyles } from '@mui/styles'
import CustomButton from '../../components/CustomButton'
import { WebsiteDto } from '../../shared/types/websiteTypes'
import { WebsiteManager } from '../../services/api/WebsiteManager'
import CustomTextField from '../../components/CustomTextField'
import CloseIcon from '@mui/icons-material/Close'
import { showFlashMessageWithTimeout } from '../../services/actions/flashMessage-actions'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { ExternalApiManager } from '../../services/api/ExternalApiManager'

interface SpolehliveRecenzeLoginModalProps {
    isOpen: boolean
    onClose: () => void
    createdWebId: string
    websiteData?: WebsiteDto
    onAddKey?: (shopId: string, password: string) => void
}

const SpolehliveRecenzeLoginModal: FC<SpolehliveRecenzeLoginModalProps> = ({
    isOpen,
    onClose,
    createdWebId,
    websiteData,
    onAddKey,
}) => {
    const classes = useStyles()
    const theme = useTheme()
    const dispatch = useDispatch()
    const { t } = useTranslation()

    const [username, setUsername] = useState<string>('')
    const [password, setPassword] = useState<string>('')
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [isVerified, setIsVerified] = useState<boolean>(false)
    const [isCheckLoading, setIsCheckLoading] = useState<boolean>(false)

    useEffect(() => {
        // Resetting the fields when the modal is opened
        setUsername('')
        setPassword('')
    }, [isOpen])

    const handleUsernameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setUsername(event.target.value)
    }

    const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(event.target.value)
    }

    const onAdd = async () => {
        if (createdWebId && username && password && isVerified) {
            try {
                setIsLoading(true)
                const _websiteManager = WebsiteManager.getManager()
                const result = await _websiteManager.updateSpolehliveRecenzeAsync(createdWebId, username, password)
                if (result) {
                    showFlashMessageWithTimeout(dispatch, 'Přidáno.', 'success')
                }
                setIsLoading(false)
                if (onAddKey) onAddKey(username, password)
                onClose()
            } catch (e: any) {
                setIsLoading(false)
                showFlashMessageWithTimeout(dispatch, e.toString(), 'success')
            }
        } else {
            showFlashMessageWithTimeout(dispatch, 'Vyplňte všechna pole', 'info')
        }
    }

    const onCheck = async () => {
        setIsCheckLoading(true)
        if (username.length === 0 || password.length === 0) {
            showFlashMessageWithTimeout(dispatch, 'Zadejte uživatelské jméno a heslo', 'info')
        }

        try {
            setIsCheckLoading(true)
            const _externalApiManager = ExternalApiManager.getManager()
            const result = await _externalApiManager.verifySpolehliveRecenzeExternalApi(username, password)
            if (result.isVerified) {
                setIsVerified(true)
            } else {
                setIsVerified(false)
                showFlashMessageWithTimeout(dispatch, 'Není ověřeno', 'error')
            }
            setIsCheckLoading(false)
        } catch (e: any) {
            setIsCheckLoading(false)
            showFlashMessageWithTimeout(dispatch, e.toString(), 'error')
        }
    }

    return (
        <ModalBase isOpen={isOpen} onClose={onClose} center>
            <Box className={classes.body}>
                <Box className={classes.iconContainer}>
                    <CloseIcon style={{ color: theme.palette.natural.t2, cursor: 'pointer' }} onClick={onClose} />
                </Box>
                <Typography className={classes.title}>
                    {t('campaign.notifications.settings.displayRules.spolehlivaRecenze.howToAddApiKey.title')}
                </Typography>
                <Box className={classes.inputsContainer}>
                    <CustomTextField
                        style={{ marginRight: 24 }}
                        fullWidth
                        label={t('shopId') ?? ''}
                        isRequired
                        value={username}
                        onChange={handleUsernameChange}
                    />
                    <CustomTextField
                        fullWidth
                        label={t('loginScreen.password') ?? ''}
                        isRequired
                        value={password}
                        onChange={handlePasswordChange}
                    />
                </Box>
                <Box style={{ marginLeft: 32, marginRight: 32 }}>
                    <Box className={classes.rowWithMargin}>
                        <Typography className={classes.listItemNumber}>1.</Typography>
                        <Typography className={classes.listItemText}>
                            {t('campaign.notifications.settings.displayRules.spolehlivaRecenze.howToAddApiKey.first')}
                        </Typography>
                    </Box>
                    <Box className={classes.rowWithMargin}>
                        <Typography className={classes.listItemNumber}>2.</Typography>
                        <Typography className={classes.listItemText}>
                            {t('campaign.notifications.settings.displayRules.spolehlivaRecenze.howToAddApiKey.second')}
                        </Typography>
                    </Box>
                    <Box style={{ display: 'flex', flexDirection: 'row' }}>
                        <Typography className={classes.listItemNumber}>3.</Typography>
                        <Typography className={classes.listItemText}>
                            {t('campaign.notifications.settings.displayRules.spolehlivaRecenze.howToAddApiKey.third')}
                        </Typography>
                    </Box>
                    <StatusChecker isActive={isVerified} isLoading={isCheckLoading} onClick={onCheck} />
                    <Box className={classes.buttonContainer}>
                        <CustomButton
                            isLoading={isLoading}
                            title={websiteData?.spolehlivaRecenzeShopId || isVerified ? t('saveChanges') : t('add')}
                            onClick={onAdd}
                            isDisabled={!isVerified}
                            style={{ width: 120, marginLeft: 20 }}
                        />
                        <CustomButton
                            isLoading={false}
                            title={t('close')}
                            type='secondary'
                            onClick={onClose}
                            style={{ width: 120 }}
                        />
                    </Box>
                </Box>
            </Box>
        </ModalBase>
    )
}

const useStyles = makeStyles((theme: Theme) => ({
    buttonContainer: {
        height: 52,
        flex: 1,
        display: 'flex',
        flexDirection: 'row-reverse',
        marginTop: 32,
        marginBottom: 32,
    },
    body: {
        width: 698,
        backgroundColor: 'white',
        display: 'flex',
        flexDirection: 'column',
        borderRadius: 8,
        boxShadow: theme.palette.main.shadow.secondary,
    },
    title: {
        fontSize: 28,
        color: theme.palette.main.secondary.u1,
        textAlign: 'center',
        marginBottom: 30,
    },
    iconContainer: {
        display: 'flex',
        flexDirection: 'row-reverse',
        marginTop: 20,
        marginRight: 37,
        marginBottom: 4,
    },
    listItemNumber: {
        color: theme.palette.main.secondary.o,
        marginRight: 8,
        fontSize: 16,
        fontWeight: 'bold',
    },
    listItemText: {
        fontSize: 16,
        color: theme.palette.natural.t,
    },
    inputsContainer: {
        display: 'flex',
        flexDirection: 'row',
        marginLeft: 32,
        marginRight: 32,
        marginBottom: 32,
    },
    listTitle: {
        fontSize: 18,
        color: theme.palette.natural.t,
        marginBottom: 16,
        fontWeight: 'bold',
    },
    rowWithMargin: {
        display: 'flex',
        flexDirection: 'row',
        marginBottom: 24,
    },
    infoRow: {
        display: 'flex',
        flexDirection: 'row',
        height: 34,
        alignItems: 'center',
        marginBottom: 24,
        marginTop: 20,
    },
    infoIconContainer: {
        height: 34,
        width: 34,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
}))

export default SpolehliveRecenzeLoginModal

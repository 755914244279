import { Language, WebsiteDto } from '../../shared/types/websiteTypes'

export interface WebReduxState {
    active: string | undefined
    activeId: string | undefined
    webs: WebsiteDto[]
}

export enum WebActionTypes {
    SET_ACTIVE_WEB = 'SET_ACTIVE_WEB',
    SET_WEBS = 'SET_WEBS',
    ADD_WEB = 'ADD_WEB',
    UPDATE_WEB = 'UPDATE_WEB',
    CLEAR_WEBS = 'CLEAR_WEBS',
}

export interface SetActiveWeb {
    type: typeof WebActionTypes.SET_ACTIVE_WEB
    payload: { active: string | undefined; activeId: string | undefined }
}

export interface SetWebs {
    type: typeof WebActionTypes.SET_WEBS
    payload: WebsiteDto[]
}

export interface AddWeb {
    type: typeof WebActionTypes.ADD_WEB
    payload: WebsiteDto
}

export interface UpdateWeb {
    type: typeof WebActionTypes.UPDATE_WEB
    payload: { id: string; name: string; url: string; language: Language }
}

export interface ClearWebs {
    type: typeof WebActionTypes.CLEAR_WEBS
}

export type WebActions = SetActiveWeb | SetWebs | AddWeb | UpdateWeb | ClearWebs

import { Box, CircularProgress, useTheme } from '@mui/material'

const CircularProgressBox = () => {
    const theme = useTheme()
    return (
        <Box
            style={{
                flex: 1,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: 32,
            }}
        >
            <CircularProgress style={{ color: theme.palette.main.primary.o }} />
        </Box>
    )
}

export default CircularProgressBox

import { PricingPlanPeriod } from "../shared/types/pricingPlanTypes"

export const translatePeriodOfTime = (planPeriod: PricingPlanPeriod) => {
    switch (planPeriod) {
        case 'month':
            return 'měsíční'
        case 'year':
            return 'roční'
        
    }
}

import { GlobalVars } from '../../global'
import {
    CreateUserRequest,
    CreateUserResponse,
    GetUserCreditsResponse,
    GetUsersResponse,
    LoginRequest,
    LoginResponse,
    ResetPasswordRequest,
    TakeControlRequest,
    UpdatePasswordRequest,
    UpdateUserRequest,
} from '../../shared/payloads/userPayloads'
import { WebarealLoginRequest, WebarealLoginResponse } from '../../shared/payloads/webarealPayloads'
import { UserDto } from '../../shared/types/userTypes'
import { Language } from '../../shared/types/websiteTypes'
import { handleNetworkResponseAndGetJsonAsync, handleNetworkResponseWithNoPayloadAsync, Network } from './Network'

export class UserManager {
    static _instance: UserManager | null = null

    static getManager(): UserManager {
        if (this._instance === null) {
            this._instance = new UserManager()
        }
        return this._instance
    }

    async createUserAsync(
        email: string,
        password: string,
        name: string,
        language: Language,
        phoneNumber?: string,
        surname?: string,
        referralId?: string
    ): Promise<CreateUserResponse> {
        let response: Response | null = null
        const url = `${GlobalVars.SERVER_ENDPOINT}/user/create`
        const body: CreateUserRequest = {
            email: email,
            password: password,
            name: name,
            surname: surname,
            phoneNumber: phoneNumber,
            language: language,
            referralId: referralId ? referralId : undefined,
        }
        try {
            response = await Network.fetch(url, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body),
            })
        } catch (e: any) {
            throw new Error('Error executing fetch request.')
        }
        if (response) {
            const result: CreateUserResponse = await handleNetworkResponseAndGetJsonAsync(response)
            return result
        } else {
            throw new Error('No response from server returned.')
        }
    }

    async loginAsync(email: string, password: string): Promise<LoginResponse> {
        let response: Response | null
        const url = `${GlobalVars.SERVER_ENDPOINT}/user/login`

        // keepSignedIn - parameter that was used to change cookies expiration time.
        // TODO: remove it after BE update

        const request: LoginRequest = {
            email,
            password,
            keepSignedIn: true,
        }

        try {
            response = await Network.fetch(url, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(request),
            })
        } catch (e) {
            throw new Error("Couldn't execute server request.")
        }
        if (response) {
            const data: LoginResponse = await handleNetworkResponseAndGetJsonAsync(response, url)
            return data
        } else {
            throw new Error('No response returned.')
        }
    }

    async loginWebarealUserAsync(eshopId: string): Promise<WebarealLoginResponse> {
        let response: Response | null
        const url = `${GlobalVars.SERVER_ENDPOINT}/webareal/user/login`

        const request: WebarealLoginRequest = {
            webarealShopId: eshopId,
        }

        try {
            response = await Network.fetch(url, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(request),
            })
        } catch (e) {
            throw new Error("Couldn't execute server request.")
        }
        if (response) {
            const data: WebarealLoginResponse = await handleNetworkResponseAndGetJsonAsync(response, url)
            return data
        } else {
            throw new Error('No response returned.')
        }
    }

    async logoutUserAsync() {
        let response: Response | null = null
        const url = `${GlobalVars.SERVER_ENDPOINT}/user/logout`
        try {
            response = await Network.fetch(url, {
                method: 'DELETE',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
            })
        } catch (e: any) {
            throw new Error('logoutUserAsync:: Error executing fetch request')
        }
        if (response && response.status === 200 && response.statusText === 'OK') {
            return true
        } else {
            return false
        }
    }

    async getUserSingleAsync() {
        let response: Response | null = null
        const url = `${GlobalVars.SERVER_ENDPOINT}/user/single`

        try {
            response = await Network.fetch(url, {
                method: 'GET',
                credentials: 'include',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
            })
        } catch (e) {
            throw new Error('getUserSingle:: Error executing fetch request')
        }
        if (response) {
            const result: UserDto = await handleNetworkResponseAndGetJsonAsync(response)
            return result
        } else {
            throw new Error('No response from server returned.')
        }
    }

    async getUserCreditsAsync() {
        let response: Response | null = null
        const url = `${GlobalVars.SERVER_ENDPOINT}/user/credits`

        try {
            response = await Network.fetch(url, {
                method: 'GET',
                credentials: 'include',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
            })
        } catch (e) {
            throw new Error('getUserCreditsAsync:: Error executing fetch request')
        }
        if (response) {
            const result: GetUserCreditsResponse = await handleNetworkResponseAndGetJsonAsync(response)
            return result
        } else {
            throw new Error('No response from server returned.')
        }
    }

    async updateUserPasswordAsync(userId: string, oldPassword: string, newPassword: string) {
        let response: Response | null = null
        const url = `${GlobalVars.SERVER_ENDPOINT}/user/password/update`

        const body: UpdatePasswordRequest = {
            userId: userId,
            oldPassword: oldPassword,
            newPassword: newPassword,
        }
        try {
            response = await Network.fetch(url, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body),
            })
            if (response && response.status === 200) {
                return true
            } else {
                return false
            }
        } catch (e: any) {
            throw new Error('updateUserPassword:: Error executing fetch request')
        }
    }

    async getAllUsersAsync() {
        let response: Response | null = null
        const url = `${GlobalVars.SERVER_ENDPOINT}/user`

        try {
            response = await Network.fetch(url, {
                method: 'GET',
                credentials: 'include',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
            })
        } catch (e) {
            throw new Error('getAllUsersAsync:: Error executing fetch request')
        }
        if (response) {
            const result: GetUsersResponse = await handleNetworkResponseAndGetJsonAsync(response)
            return result.users
        } else {
            throw new Error('No response from server returned.')
        }
    }

    async updateUserAsync(body: UpdateUserRequest) {
        let response: Response | null = null
        const url = `${GlobalVars.SERVER_ENDPOINT}/user/update`

        try {
            response = await Network.fetch(url, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body),
            })
        } catch (e) {
            throw new Error('updateUserAsync:: Error executing fetch request')
        }
        if (response) {
            const result: UserDto = await handleNetworkResponseAndGetJsonAsync(response)
            return result
        } else {
            throw new Error('No response from server returned.')
        }
    }

    async takeUserControlAsync(userId: string) {
        let response: Response | null = null
        const url = `${GlobalVars.SERVER_ENDPOINT}/user/takeControl`

        const body: TakeControlRequest = {
            userId: userId,
        }

        try {
            response = await Network.fetch(url, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body),
            })
        } catch (e) {
            throw new Error('takeUserControlAsync:: Error executing fetch request')
        }
        if (response) {
            const result: LoginResponse = await handleNetworkResponseAndGetJsonAsync(response)
            return result
        } else {
            throw new Error('No response from server returned.')
        }
    }

    async resetUserPasswordAsync(email: string, language: Language) {
        let response: Response | null = null
        const url = `${GlobalVars.SERVER_ENDPOINT}/user/password/reset`

        const body: ResetPasswordRequest = {
            email: email,
            language: language,
        }
        try {
            response = await Network.fetch(url, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body),
            })
        } catch (e) {
            throw new Error('resetUserPasswordAsync:: Error executing fetch request')
        }
        if (response) {
            await handleNetworkResponseWithNoPayloadAsync(response)
        } else {
            throw new Error('No response from server returned.')
        }
    }

    async createStripeSubscriptionCheckout(body: any) {
        let response: Response | null = null
        const url = `${GlobalVars.SERVER_ENDPOINT}/user/subscription/create`

        try {
            response = await Network.fetch(url, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body),
            })
        } catch (e) {
            throw new Error('createStripeSubscriptionCheckout:: Error executing fetch request')
        }
        if (response) {
            const result = await handleNetworkResponseAndGetJsonAsync(response)
            return result
        } else {
            throw new Error('No response from server returned.')
        }
    }

    async cancelStripeSubscription() {
        let response: Response | null = null
        const url = `${GlobalVars.SERVER_ENDPOINT}/user/subscription/cancel`

        try {
            response = await Network.fetch(url, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
            })
        } catch (e) {
            throw new Error('cancelStripeSubscription:: Error executing fetch request')
        }
        if (response) {
            const result = await handleNetworkResponseWithNoPayloadAsync(response)
            return result
        } else {
            throw new Error('No response from server returned.')
        }
    }

    async confirmActivationAsync(userId: string) {
        let response: Response | null = null
        const url = `${GlobalVars.SERVER_ENDPOINT}/user/confirmActivation`

        try {
            response = await Network.fetch(url, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ userId: userId }),
            })
        } catch (e) {
            throw new Error('confirmActivationAsync:: Error executing fetch request')
        }
        if (response) {
            const result = await handleNetworkResponseWithNoPayloadAsync(response)
            return result
        } else {
            throw new Error('No response from server returned.')
        }
    }
}
